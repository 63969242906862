import axios from 'axios';
import SocieteAtraiter from '../../models/SocieteAtraiter';
import domain from '@/environment';

export default {
  state: {
    gestionSocieteAtraiter: [],
    errorAddGestionSocieteAtraiter: [],
    loadingAddSocieteAtraiter: false,
    loadingSocieteAtraiter: false
  },
  getters: {
    getGestionSocieteAtraiter: state => state.gestionSocieteAtraiter,
    getErrorAddGestionSocieteAtraiter: state =>
      state.errorAddGestionSocieteAtraiter,
    getLoadingAddSocieteAtraiter: state => state.loadingAddSocieteAtraiter,
    getLoadingSocieteAtraiter: state => state.loadingSocieteAtraiter
  },
  mutations: {
    NEW_GESTION_SOCIETE_A_TRAITER(state, payload) {
      state.gestionSocieteAtraiter.unshift(SocieteAtraiter.create(payload));
    },
    ERROR_ADD_GESTION_SOCIETE_A_TRAITER(state, payload) {
      state.errorAddGestionSocieteAtraiter = payload;
    },
    LOADING_SOCIETE_A_TRAITER(state, payload) {
      state.loadingSocieteAtraiter = payload;
    },
    UPDATE_SOCIETE_A_TRAITER(state, payload) {
      payload.oldData.update(payload.response);
    },
    LOADING_ADD_SOCIETE_A_TRAITER(state, payload) {
      state.loadingAddSocieteAtraiter = payload;
    },
    SET_ALL_GESTION_SOCIETE_A_TRAITER(state, payload) {
      state.gestionSocieteAtraiter = payload.map(item =>
        SocieteAtraiter.create(item)
      );
    },
    ASSIGN_SOCIETE_A_TRAITER_PERMISSION(state, payload) {
      state.gestionSocieteAtraiter.map(role => {
        if (role.id == payload.role) {
          payload.assigned.map(permission => {
            role.permissions.push(permission.key);
          });
        }
      });
    }
  },
  actions: {
    async addNewSocieteAtraiter({ commit }, payload) {
      commit('LOADING_ADD_SOCIETE_A_TRAITER', true);
      commit('ERROR_ADD_GESTION_SOCIETE_A_TRAITER', null);
      let formData = new FormData();
      formData.append('name', payload.name);
      for (let i = 0; i < payload.societe.length; i++) {
        formData.append('societe[' + i + '][id]', payload.societe[i].id);
        formData.append('societe[' + i + '][name]', payload.societe[i].name);
        formData.append(
          'societe[' + i + '][checked]',
          payload.societe[i].checked
        );
      }
      for (let i = 0; i < payload.interface.length; i++) {
        formData.append('interface[' + i + '][key]', payload.interface[i].id);
        formData.append(
          'interface[' + i + '][name]',
          payload.interface[i].name
        );
        formData.append(
          'interface[' + i + '][checked]',
          payload.interface[i].checked
        );
      }
      try {
        const response = await axios.post(
          domain + `/api/filiale/createSocieteTraitement`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('NEW_GESTION_SOCIETE_A_TRAITER', response.data.data);
        commit('ERROR_ADD_GESTION_SOCIETE_A_TRAITER', null);
        commit('LOADING_ADD_SOCIETE_A_TRAITER', false);

        return true;
      } catch (error) {
        if (error && error.response && error.response.data) {
          commit(
            'ERROR_ADD_GESTION_SOCIETE_A_TRAITER',
            error.response.data.errors.name
          );
        } else {
          commit(
            'ERROR_ADD_GESTION_SOCIETE_A_TRAITER',
            'Une erreur est survenue'
          );
        }
        commit('LOADING_ADD_SOCIETE_A_TRAITER', false);
        return false;
      }
    },
    async updateSocieteAtraiter({ commit }, payload) {
      commit('LOADING_ADD_SOCIETE_A_TRAITER', true);
      commit('ERROR_ADD_GESTION_SOCIETE_A_TRAITER', null);
      let formData = new FormData();
      formData.append('id', payload.data.id);
      for (let i = 0; i < payload.data.societe.length; i++) {
        formData.append(
          'societe[' + i + '][id]',
          payload.data.societe[i].filiale_id
        );
        formData.append(
          'societe[' + i + '][name]',
          payload.data.societe[i].filiale
        );
        formData.append(
          'societe[' + i + '][checked]',
          payload.data.societe[i].checked
        );
      }
      for (let i = 0; i < payload.data.interface.length; i++) {
        formData.append(
          'interface[' + i + '][key]',
          payload.data.interface[i].key
        );
        formData.append(
          'interface[' + i + '][name]',
          payload.data.interface[i].interface
        );
        formData.append(
          'interface[' + i + '][checked]',
          payload.data.interface[i].checked
        );
      }
      try {
        const response = await axios.post(
          domain + `/api/filiale/updateSocieteTraitement`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('UPDATE_SOCIETE_A_TRAITER', {
          response: response.data.data,
          oldData: payload.data
        });
        commit('ERROR_ADD_GESTION_SOCIETE_A_TRAITER', null);
        commit('LOADING_ADD_SOCIETE_A_TRAITER', false);

        return true;
      } catch (error) {
        if (error && error.response && error.response.data) {
          commit(
            'ERROR_ADD_GESTION_SOCIETE_A_TRAITER',
            error.response.data.errors.name
          );
        } else {
          commit(
            'ERROR_ADD_GESTION_SOCIETE_A_TRAITER',
            'Une erreur est survenue'
          );
        }
        commit('LOADING_ADD_SOCIETE_A_TRAITER', false);
        return false;
      }
    },
    async getAllSocieteAtraiters({ commit }) {
      commit('LOADING_SOCIETE_A_TRAITER', true);
      try {
        const response = await axios.get(
          domain + '/api/filiale/societeTraitements',
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('SET_ALL_GESTION_SOCIETE_A_TRAITER', response.data.data);
        commit('LOADING_SOCIETE_A_TRAITER', false);
        return true;
      } catch (error) {
        commit('LOADING_SOCIETE_A_TRAITER', false);
        return { success: false, errors: 'Une erreur est survenue' };
      }
    },
    resetDataSocieteAtraiter({ commit }) {
      commit('ERROR_ADD_GESTION_SOCIETE_A_TRAITER', null);
    }
  }
};
