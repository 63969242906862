export default class Role {
  constructor(data) {
    this.name = data.name;
    this.key = data.key;
    this.permissions = data.permissions;
    this.blocked = data.blocked;
    this.id = data.id;
  }
  update(data) {
    this.name = data.name;
    this.key = data.key;
    this.blocked = data.blocked;
    this.permissions = data.permissions;
    this.id = data.id;
  }
  updatePermission(data) {
    this.permissions.push(data);
  }
  static create(data) {
    return new Role(data);
  }
}
