import SubSimulationFactureFrais from './SubSimulationFactureFrais';
export default class SimulationFactureFraisModel {
  constructor(data) {
    this.project_id = data.project_id;
    this.project_numero = data.project_numero;
    this.project_statue_date = data.project_statue_date;
    this.project_client = data.project_client;
    this.project_installateur = data.project_installateur;
    this.project_confirmation = data.project_confirmation;
    this.project_regie = data.project_regie;
    this.facture = data.facture;
    this.project_montant_final = data.project_montant_final;
    // this.detailsShowing = false;
    // this.loadingDetailt = false;
    this.check = false;
    //facture data
    this.coef = data.coef;
    this.type = data.type;
    this.ttc = data.ttc;
    this.ht = data.ht;
    this.vendeur = data.vendeur;
    this.client = data.client;
    this.client_id = data.client_id;
    this.vendeur_id = data.vendeur_id;
    this.statue = data.statue;
    this.objet = data.objet;
    this.num = data.num;
    this.products = data.products;
    this.tva = data.tva;
    this.lock = data.lock;
    this.facture_id = data.facture_id;
    this.products = data.products;
    this.master = data.master;
    this.project_date_depot_dossier = data.project_date_depot_dossier;
    this.project_statut = data.project_statut;
    this.project_statut_date = data.project_statut_date;
    this.date_facture = data.date_facture;
    this.index = 0;
    this.ttc_int = data.ttc_int;
    this.ht_int = data.ht_int;
    this.coef_type = data.coef_type;
    this.project_type_isolation = data.project_type_isolation;
    this.montant_net_ht = data.montant_net_ht;

  }
  update(data) {
    this.project_type_isolation = data.project_type_isolation;
    this.project_id = data.project_id;
    this.project_numero = data.project_numero;
    this.project_statue_date = data.project_statue_date;
    this.project_client = data.project_client;
    this.project_installateur = data.project_installateur;
    this.project_confirmation = data.project_confirmation;
    this.project_regie = data.project_regie;
    this.facture = data.facture;
    this.project_montant_final = data.project_montant_final;
    // this.detailsShowing = false;
    // this.loadingDetailt = false;
    //facture data
    this.coef = data.coef;
    this.type = data.type;
    this.ttc = data.ttc;
    this.ht = data.ht;
    this.vendeur = data.vendeur;
    this.client = data.client;
    this.client_id = data.client_id;
    this.vendeur_id = data.vendeur_id;
    this.statue = data.statue;
    this.objet = data.objet;
    this.num = data.num;
    this.products = data.products;
    this.tva = data.tva;
    this.lock = data.lock;
    this.facture_id = data.facture_id;
    this.products = data.products;
    this.master = data.master;
    this.project_date_depot_dossier = data.project_date_depot_dossier;
    this.project_statut = data.project_statut;
    this.project_statut_date = data.project_statut_date;
    this.date_facture = data.date_facture;
    this.ttc_int = data.ttc_int;
    this.ht_int = data.ht_int;
    this.coef_type = data.coef_type;
    this.montant_net_ht = data.montant_net_ht;

  }
  static create(data) {
    return new SimulationFactureFraisModel(data);
  }
}
