import axios from 'axios';
import Role from '../../models/Role';
import domain from '@/environment';

export default {
  state: {
    famillysPermissions: [],
    errorFamillysPermissions: [],
    loadingFamillysPermissions: false
  },
  getters: {
    famillysPermissions: state => state.famillysPermissions,
    errorFamillysPermissions: state => state.errorFamillysPermissions,
    loadingFamillysPermissions: state => state.loadingFamillysPermissions
  },
  mutations: {
    ERROR_FAMILLE_PERMISSION(state, payload) {
      state.errorFamillysPermissions = payload;
    },
    LOADING_FAMILLE_PERMISSION(state, payload) {
      state.loadingFamillysPermissions = payload;
    },
    SET_DATA_FAMILLE_PERMISSION(state, payload) {
      state.famillysPermissions = payload;
    },
    ASSIGN_USER_PERMISSION_FAMILLY(state, payload) {
      state.famillysPermissions.map(user => {
        if (user.id == payload.user) {
          user.famillies = payload.response.famillies;
        }
      });
    },
    REMOVE_PERMISSIONS_USER_FAMILLY(state, payload) {
      state.famillysPermissions.map(user => {
        if (user.id == payload.user) {
          user.famillies = payload.response.famillies;
        }
      });
    }
  },
  actions: {
    async getAllFamillePermission({ commit }, payload) {
      const params = {
        deleted: 'no'
      };
      if (payload.search != null) {
        params.search = payload.search;
      }
      commit('LOADING_FAMILLE_PERMISSION', true);
      try {
        const response = await axios.get(domain + '/api/all_user', {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        });
        commit('SET_DATA_FAMILLE_PERMISSION', response.data.date);
        commit('LOADING_FAMILLE_PERMISSION', false);

        return true;
      } catch (error) {
        commit('ERROR_FAMILLE_PERMISSION');
        commit('LOADING_FAMILLE_PERMISSION', false);

        return false;
      }
    },
    async assignFamillyToUser({ commit }, payload) {
      try {
        const response = await axios.post(
          domain + '/api/config_societe_support/familly_support/affect_user',
          payload,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        const params = {
          user: payload.user_id,
          response: response.data.data
        };
        commit('ASSIGN_USER_PERMISSION_FAMILLY', params);
        return { success: true };
      } catch (error) {
        return false;
      }
    },
    async removeFamillyToUser({ commit }, payload) {
      try {
        const response = await axios.post(
          domain + '/api/config_societe_support/familly_support/unaffect_user',
          payload,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        const params = {
          user: payload.user_id,
          response: response.data.data
        };
        commit('REMOVE_PERMISSIONS_USER_FAMILLY', params);
        return { success: true };
      } catch (error) {
        return false;
      }
    }
  }
};
