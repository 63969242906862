import Vue from 'vue';
import App from './App.vue';
import { vsChip, vsIcon } from 'vuesax';
import 'vuesax/dist/vuesax.css';
import 'material-icons/iconfont/material-icons.css';
import router from './router';
import store from './store';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueSimpleAlert from 'vue-simple-alert';
import FlashMessage from '@smartweb/vue-flash-message';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'flatpickr/dist/flatpickr.css';
import DatePicker from 'vue2-datepicker';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {
  LoopingRhombusesSpinner,
  CirclesToRhombusesSpinner,
  HalfCircleSpinner,
  HollowDotsSpinner
} from 'epic-spinners';
import { far } from '@fortawesome/free-regular-svg-icons';
// import { ContentLoader } from 'vue-content-loader';
import PortalVue from 'portal-vue';
import custom from './assets/custom.scss';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'vue2-datepicker/index.css';
import {
  faAngleRight,
  faEuroSign,
  faFileMedical,
  fas,
  faUserSlash,
  faCloudUploadAlt,
  faBug,
  faEdit,
  faWindowClose,
  faPlus,
  faPencilAlt,
  faTrashAlt,
  faMinus,
  faSyncAlt,
  faBars,
  faUserCircle,
  faLongArrowAltLeft,
  faCheck,
  faDownload,
  faTimes,
  faInfo,
  faFilePdf,
  faSearch,
  faCircleNotch,
  faCloudDownloadAlt,
  faPen,
  faClone,
  faAt,
  faClosedCaptioning,
  faHouseUser,
  faAlignJustify,
  faRetweet,
  faClipboardCheck,
  faFileExcel,
  faFolder,
  faEnvelope,
  faArrowCircleDown,
  faExclamationCircle,
  faWrench,
  faFileInvoice,
  faFileInvoiceDollar,
  faFile,
  faArrowRight,
  faPhone,
  faBatteryEmpty
} from '@fortawesome/free-solid-svg-icons';
import { faBell, faCircle } from '@fortawesome/free-regular-svg-icons';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('multiselect', () =>
  import(/* webpackChunkName: "vue-multiselect" */ 'vue-multiselect')
);
Vue.component('LoopingRhombusesSpinner', LoopingRhombusesSpinner);
Vue.component('CirclesToRhombusesSpinner', CirclesToRhombusesSpinner);
Vue.component('HalfCircleSpinner', HalfCircleSpinner);
Vue.component('HollowDotsSpinner', HollowDotsSpinner);
import VueApexCharts from 'vue-apexcharts';

library.add(
  far,
  fas,
  faCloudUploadAlt,
  faBug,
  faEdit,
  faWindowClose,
  faPlus,
  faPencilAlt,
  faTrashAlt,
  faMinus,
  faSyncAlt,
  faBars,
  faUserCircle,
  faLongArrowAltLeft,
  faCheck,
  faDownload,
  faTimes,
  faInfo,
  faFilePdf,
  faSearch,
  faCircleNotch,
  faBell,
  faCircle,
  faCloudDownloadAlt,
  faFileMedical,
  faUserSlash,
  faPen,
  faAngleRight,
  faEuroSign,
  faClone,
  faAt,
  faClosedCaptioning,
  faHouseUser,
  faAlignJustify,
  faRetweet,
  faClipboardCheck,
  faFileExcel,
  faFolder,
  faEnvelope,
  faArrowCircleDown,
  faExclamationCircle,
  faWrench,
  faFileInvoice,
  faFileInvoiceDollar,
  faFile,
  faArrowRight,
  faPhone,
  faBatteryEmpty
);
Vue.use(VueMoment, {
  moment
});
Vue.use(PortalVue);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueSimpleAlert);
Vue.use(DatePicker);
Vue.use(custom);
Vue.use(VueSweetalert2);
Vue.use(vsChip);
Vue.use(AOS);
Vue.use(vsIcon);
Vue.use(FlashMessage, { strategy: 'multiple' });
Vue.use(VueApexCharts);
//Vue.use(FlashMessage);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
