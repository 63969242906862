import Router from '../router/index';
import state from '../store/index';
export default class Facture {
  constructor(data) {
    this.id = data.id;
    this.created_at = data.created_at;
    this.from_id = data.from_id;
    this.to_id = data.to_id;
    this.type = data.type;
    this.seen = data.seen;
    this.comment = data.comment;
    this.loaderDelet = data.loaderDelet;
  }
  update(data) {
    this.seen = data.seen;
    this.comment = data.comment;
  }
  showdetailsNotification() {
    if (this.notification_type === 'bill_refuse') {
      if (
        Router.history.current.fullPath === '/employes/validation-salaire-admin'
      ) {
        if (
          this.year !==
            JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
              .filteryear ||
          this.semaine !==
            JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
              .filterSemaine ||
          JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
            .filterUser != null ||
          JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
            .filterValidation != 'refuse'
        ) {
          localStorage.setItem(
            'Validation-salaire-Filters',
            JSON.stringify({
              filteryear: parseInt(this.year),
              filterSemaine: this.semaine,
              filterValidation: 'refuse',
              filterType: this.victime.role,
              filterUser: null,
              filterDepot: null,
              filterProjectType: null
            })
          );
          location.reload();
        }
      } else {
        localStorage.setItem(
          'Validation-salaire-Filters',
          JSON.stringify({
            filteryear: parseInt(this.year),
            filterSemaine: this.semaine,
            filterValidation: 'refuse',
            filterType: this.victime.role,
            filterUser: null,
            filterDepot: null,
            filterProjectType: null
          })
        );
        Router.push('/employes/validation-salaire-admin');
      }
    }
  }
  getMessageNotification() {
    switch (this.notification_type) {
      // case 'bill_redemande':
      //   return (
      //     '<strong>' +
      //     this.from +
      //     '</strong> a refusé la facture de ' +
      //     this.victime.name +
      //     " pour l'année " +
      //     this.year +
      //     ' dans semaine ' +
      //     this.semaine
      //   );

      default:
        return this.comment;
    }
  }
  getIconName() {
    return 'envelope';
    // switch (this.notification_type) {
    //   case 'bill_refuse':
    //     return 'user-times';
    //   case 'new_salary':
    //     return 'check-circle';
    //   case 'bill_demande':
    //     return 'check-circle';
    //   case 'regie_bill_waiting_for_validation':
    //     return 'file-pdf';
    //   case 'employee_bill_waiting_for_validation':
    //     return 'file-pdf';
    //   case 'bill_payed_successfully':
    //     return 'euro-sign';
    //   case 'new_bills':
    //     return 'check-circle';
    //   case 'revalidate_salary':
    //     return 'sync-alt';
    //   case 'revalidate_salary_regie':
    //     return 'sync-alt';
    //   case 'employee_bill_updated':
    //     return 'edit';
    //   case 'bill_redemande':
    //     return 'user-times';
    //   default:
    //     break;
    // }
  }

  static create(data) {
    return new Facture(data);
  }
}
