export default class gestionConfigurationFamilly {
  constructor(data) {
    this.id = data.id;
    this.familly = data.familly;
    this.blocked = data.blocked;
  }
  update(data) {
    this.familly = data.familly;
    this.blocked = data.blocked;
  }
  static create(data) {
    return new gestionConfigurationFamilly(data);
  }
}
