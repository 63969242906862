import AppelCommercialSimulationMail from './AppelCommercialSimulationMail';
export default class mailAppelCommercial {
  constructor(data) {
    this.simulations = data.commercials.map(item =>
      AppelCommercialSimulationMail.create(item)
    );
    this.client = data.client;
    this.mailSend = false;
  }

  /**
   * @param {*} data
   */

  updateMailSend(data) {
    this.mailSend = data;
  }
  static create(data) {
    if (!data) {
      throw new Error('mailAppelCommercial::create: "data" is undefined');
    }
    return new mailAppelCommercial(data);
  }
}
