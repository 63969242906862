export default class Template {
  constructor(data) {
    this.id = data.id;
    this.content = data.content;
    this.name = data.name;
    this.type = data.type;
    this.description = data.description;
    this.description2 = data.description2;
    this.familles = data.familles.map(i => {
      return { value: i.famille, text: i.famille };
    });
    this.group_id = data.group_id;
    this.columns = data.columns;
    this.category_id = data.category_id;
    this.not_deletable = data.not_deletable ? data.not_deletable : 0;
  }
  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('template::create: "data" is undefined');
    }
    return new Template(data);
  }
  update(data) {
    this.content = data.content;
    this.name = data.name;
    this.type = data.type;
    this.description = data.description;
    this.description2 = data.description2;
    this.familles = data.familles.map(i => {
      return { value: i.famille, text: i.famille };
    });
    this.group_id = data.group_id;

    this.columns = data.columns;
    this.category_id = data.category_id;
  }
}
