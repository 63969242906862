export default class GestionConfiguration {
  constructor(data) {
    this.id = data.id;
    this.filiale_id = data.filiale_id;
    this.filiale_name = data.filiale_name;
    this.acheteur_id = data.acheteur_id;
    this.tva = data.tva;
    this.coef = data.coef;
    this.coef_type = data.coef_type;
    this.label = data.label;
    this.condition_types = data.condition_types;
    this.formule_types = data.formule_types;
    this.reduction = data.reduction;
    this.reduction_type = data.reduction_type;
    this.date_debut = data.date_debut;
    this.date_fin = data.date_fin;
    this.conditions = data.conditions;
    this.specifics = data.specifics;
    this.coefs = data.coefs;
    this.formules = data.formules;
    this.blocked = data.blocked;
    this.acheteur_type = data.acheteur_type;
    this.description = data.description;
    this.famillies = data.famillies;
    this.grouped = data.grouped == 'oui' ? 'groupe' : 'dossier';
    this.group_by = data.group_by;
    this.main_family = data.main_family;
    this.facture_description = data.facture_description;
    this.facture_group_by = data.facture_group_by;
    this.payment_call = data.payment_call == 'oui' ? true : false;
    this.user = data.user;
    this.commercials = data.commercials;
  }
  update(data) {
    this.filiale_id = data.filiale_id;
    this.filiale_name = data.filiale_name;
    this.acheteur_id = data.acheteur_id;
    this.tva = data.tva;
    this.coef = data.coef;
    this.coef_type = data.coef_type;
    this.label = data.label;
    this.condition_types = data.condition_types;
    this.formule_types = data.formule_types;
    this.reduction = data.reduction;
    this.reduction_type = data.reduction_type;
    this.date_debut = data.date_debut;
    this.date_fin = data.date_fin;
    this.conditions = data.conditions;
    this.specifics = data.specifics;
    this.coefs = data.coefs;
    this.formules = data.formules;
    this.blocked = data.blocked;
    this.acheteur_type = data.acheteur_type;
    this.description = data.description;
    this.famillies = data.famillies;
    this.grouped = data.grouped == 'oui' ? 'groupe' : 'dossier';
    this.group_by = data.group_by;
    this.main_family = data.main_family;
    this.facture_description = data.facture_description;
    this.facture_group_by = data.facture_group_by;
    this.payment_call = data.payment_call == 'oui' ? true : false;
    this.user = data.user;
    this.commercials = data.commercials;
  }
  static create(data) {
    return new GestionConfiguration(data);
  }
}
