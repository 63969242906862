import axios from 'axios';
import domain from '@/environment';

const state = {
  loadingUploadFileCdg: false,
  errorUploadFileCdg: null,
  returnDataInjection: null
};
const getters = {
  getLoadingUploadFileCdg: state => state.loadingUploadFileCdg,
  getErrorUploadFileCdg: state => state.errorUploadFileCdg,
  getReturnDataInjection: state => state.returnDataInjection
};
const mutations = {
  SET_LOADING_UPLOAD_FILE_CDG(state, payload) {
    state.loadingUploadFileCdg = payload;
  },
  SET_ERROR_UPLOAD_FILE_CDG(state, payload) {
    state.errorUploadFileCdg = payload;
  },
  SET_DATA_INJECTION(state, payload) {
    state.returnDataInjection = payload;
  }
};
const actions = {
  async uploadFileCdg({ commit }, payload) {
    commit('SET_LOADING_UPLOAD_FILE_CDG', true);
    commit('SET_ERROR_UPLOAD_FILE_CDG', null);
    var bodyFormData = new FormData();
    bodyFormData.append('file', payload.file);
    bodyFormData.append('phase', payload.phase);
    if (payload.annee) {
      bodyFormData.append('annee', payload.annee);
    }
    if (payload.decisions) {
      payload.decisions.forEach((element, index) => {
        bodyFormData.append('decisions[' + index + '][numero]', element.numero);
        bodyFormData.append(
          'decisions[' + index + '][decision]',
          element.decision
        );
      });
    }

    try {
      const response = await axios.post(
        domain + '/api/validationAndInjection',
        bodyFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LOADING_UPLOAD_FILE_CDG', false);
      return {
        succes: true,
        data: response.data.data
      };
    } catch (error) {
      commit('SET_DATA_INJECTION', null);
      if (error?.response?.status == 406) {
        commit('SET_DATA_INJECTION', error.response.data);
        commit('SET_ERROR_UPLOAD_FILE_CDG', {
          error406: true
        });
      } else if (error.response && error.response.data.missing) {
        commit('SET_ERROR_UPLOAD_FILE_CDG', {
          missing: error.response.data.missing
        });
      } else if (error?.response?.data?.error) {
        commit('SET_ERROR_UPLOAD_FILE_CDG', {
          error: error.response.data.error
        });
      } else {
        commit('SET_ERROR_UPLOAD_FILE_CDG', {
          error: 'Une erreur est servenue'
        });
      }
      commit('SET_LOADING_UPLOAD_FILE_CDG', false);

      return {
        succes: false
      };
    }
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
