import axios from 'axios';
import domain from '@/environment';
const state = {
  loadingEcheancePaiemeent: false,
  errorEchacncePaiemeent: null,
  ribsPartenaireLcr: [],
  ribsPartenaireLcrLoader: false
};
const getters = {
  getLoadingEcheancePaiemeent: state => state.loadingEcheancePaiemeent,
  getErrorEchacncePaiemeent: state => state.errorEchacncePaiemeent,
  getRibsPartenaireLcr: state => state.ribsPartenaireLcr,
  geTribsPartenaireLcrLoader: state => state.ribsPartenaireLcrLoader
};
const mutations = {
  SET_LOADER_ECHEANCE_PAIEMENT(state, payload) {
    state.loadingEcheancePaiemeent = payload;
  },
  SET_ERROR_ECHEANCE_PAIEMENET(state, payload) {
    state.errorEchacncePaiemeent = payload;
  },
  SET_DATA_RIBS_PARTENAIRE_LCR(state, payload) {
    state.ribsPartenaireLcr = payload;
  },
  SET_LOADER_RIBS_PARTENAIRE_LCR(state, payload) {
    state.ribsPartenaireLcrLoader = payload;
  }
};
const actions = {
  async fetchRibsPartenaireLcr({ commit }, payload) {
    commit('SET_LOADER_RIBS_PARTENAIRE_LCR', true);

    try {
      const response = await axios.get(
        domain + `/api/filiale/all-valid-ribs?id=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_DATA_RIBS_PARTENAIRE_LCR', response.data.data);
      commit('SET_LOADER_RIBS_PARTENAIRE_LCR', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        // commit('SET_ERROR_ECHEANCE_PAIEMENET', error.response.data);
      } else {
        // commit('SET_ERROR_ECHEANCE_PAIEMENET', 'Une erreur est survenue');
      }
      //   commit('SET_LOADER_ECHEANCE_PAIEMENT', false);
      commit('SET_LOADER_RIBS_PARTENAIRE_LCR', false);
      return false;
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
