import axios from 'axios';
import domain from '@/environment';

var cancelTokenStatisticObligeData = null;
const state = {
  dataStatisticOblige: [],
  loaderStatisticOblige: false,
  errorStatisticOblige: null
};
const getters = {
  getDataStatisticOblige: state => state.dataStatisticOblige,
  getLoaderStatisticOblige: state => state.loaderStatisticOblige,
  getErrorStatisticOblige: state => state.errorStatisticOblige
};
const mutations = {
  SET_DATA_STATISTIC_OBLIGE(state, payload) {
    state.dataStatisticOblige = payload;
  },
  SET_LOADER_STATISTIC_OBLIGE(state, payload) {
    state.loaderStatisticOblige = payload;
  },
  SET_ERROR_STATISTIC_OBLIGE(state, payload) {
    state.errorStatisticOblige = payload;
  }
};
const actions = {
  async fetchDataStatisticOblige({ commit }, payload) {
    commit('SET_LOADER_STATISTIC_OBLIGE', true);
    try {
      if (cancelTokenStatisticObligeData) {
        cancelTokenStatisticObligeData.cancel('cancel-request');
      }
      cancelTokenStatisticObligeData = axios.CancelToken.source();
      const response = await axios.post(
        domain + `/api/obligee/rapport-obliges/statistiques-obliges`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          cancelToken: cancelTokenStatisticObligeData.token
        }
      );
      commit('SET_DATA_STATISTIC_OBLIGE', response.data);
      commit('SET_LOADER_STATISTIC_OBLIGE', false);

      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_STATISTIC_OBLIGE', error.response.data);
        commit('SET_LOADER_STATISTIC_OBLIGE', false);
      } else if (error.message !== 'cancel-request') {
        commit('SET_LOADER_STATISTIC_OBLIGE', false);
        commit('SET_ERROR_STATISTIC_OBLIGE', ['Une erreur est servenue']);
      }
      return false;
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
