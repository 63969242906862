import axios from 'axios';
import domain from '@/environment';
import fraisMasterFilialeModel from '@/models/fraisMasterFilialeModel.js';
let requestFraisThMasterFiliale = null;
let requestFraisThMasterFilialeYear = null;
let prefixFraisMasterFilialeTh = 'frais';

const state = {
  dataBeforSend: [],
  fraisThMasterFiliale: [],
  fraisThMasterFilialeLoader: false,
  fraisThMasterFilialeError: null,
  fraisThMasterFilialeRows: null,
  exportFraisThMasterFilialeLoader: false,
  fraisThYearDataMasterFilialeInstallateur: [],
  fraisThYearDataMasterFilialeMasterFiliale: [],
  fraisThYearDataMasterFilialeDepot: [],
  fraisThYearDataMasterFilialeLoader: false,
  masterFilialeTemplateList: [],
  templateMasterFilialeData: null,
  loadingMail: false,
  errorMailing: [],
  successSendMails: {},
  otherErrorMail: null
};
const getters = {
  fraisThMasterFiliale: state => state.fraisThMasterFiliale,
  fraisThMasterFilialeLoader: state => state.fraisThMasterFilialeLoader,
  fraisThMasterFilialeError: state => state.fraisThMasterFilialeError,
  fraisThMasterFilialeRows: state => state.fraisThMasterFilialeRows,
  exportFraisThMasterFilialeLoader: state =>
    state.exportFraisThMasterFilialeLoader,
  fraisThYearDataMasterFilialeInstallateur: state =>
    state.fraisThYearDataMasterFilialeInstallateur,
  fraisThYearDataMasterFilialeMasterFiliale: state =>
    state.fraisThYearDataMasterFilialeMasterFiliale,
  fraisThYearDataMasterFilialeDepot: state =>
    state.fraisThYearDataMasterFilialeDepot,
  fraisThYearDataMasterFilialeLoader: state =>
    state.fraisThYearDataMasterFilialeLoader,
  masterFilialeTemplateList: state => state.masterFilialeTemplateList,
  templateMasterFilialeData: state => state.templateMasterFilialeData,
  getDtatBeforeSendMasterFilial: state => state.dataBeforSend,
  getLoadingMailMasterFiliale: state => state.loadingMail,
  getErrorMailingMasterFiliale: state => state.errorMailing,
  getSuccessSendMailsMasterFliliale: state => state.successSendMails,
  getOtherMailMaster: state => state.otherErrorMail
};
const mutations = {
  SET_MASTER_FILIALE_FRAIS_TH(state, payload) {
    state.fraisThMasterFiliale = payload.map(item =>
      fraisMasterFilialeModel.create(item)
    );
  },
  SET_MASTER_FILIALE_LOADER_FRAIS_TH(state, payload) {
    state.fraisThMasterFilialeLoader = payload;
  },
  SET_MASTER_FILIALE_ERROR_FRAIS_TH(state, payload) {
    state.fraisThMasterFilialeError = payload;
  },
  SET_MASTER_FILIALE_ROWS_FRAIS_TH(state, payload) {
    state.fraisThMasterFilialeError = payload;
  },
  SET_MASTER_FILIALE_LOADER_EXPORT_FRAIS_TH(state, payload) {
    state.exportFraisThMasterFilialeLoader = payload;
  },
  //year data filter master filiale
  SET_YEAR_DATA_MASTER_FILIALE_FRAIS_TH(state, payload) {
    if (payload.reload != 'filiale') {
      state.fraisThYearDataMasterFilialeInstallateur =
        payload.response.installateurs;
    }
    state.fraisThYearDataMasterFilialeDepot = payload.response.lots;
    if (payload.reload != 'master-filiale') {
      state.fraisThYearDataMasterFilialeMasterFiliale =
        payload.response.masters;
    }
  },
  SET_YEAR_DATA_MASTER_FILIALE_LOADER_FRAIS_TH(state, payload) {
    state.fraisThYearDataMasterFilialeLoader = payload;
  },
  SET_DATA_FRAIS_VISION_MASTER_FILIALE_DETAILS(state, payload) {
    payload.row.update(payload.response);
  },
  SET_TEMPLATE_LIST_TO_UPLOAD_MF(state, payload) {
    state.masterFilialeTemplateList = payload;
  },
  INIT_TEMPLATE_MASTER_FILIALE() {
    state.templateMasterFilialeData = null;
  },
  SET_TEMPLATE_MF_DATA(state, payload) {
    state.templateMasterFilialeData = payload;
  },
  UPDATE_FACTURE_MF_UNIQUE(state, payload) {
    payload.item.update(payload.response);
  },
  UPDATE_AVOIR_RESULT_MASTER_FILIALE(state, payload) {
    payload.data.map(item => {
      let index = payload.response.findIndex(i => i.id == item.id);
      if (index != -1) {
        item.update(payload.response[index]);
      }
    });
  },
  UPDATE_AVOIR_RESULT_MASTER_FILIALE_ID(state, payload) {
    payload.data.map(item => {
      let index = payload.response.findIndex(
        i => i.id_facture == item.id_facture
      );
      if (index != -1) {
        item.update(payload.response[index]);
      }
    });
  },
  SET_DATA_TO_DISPLAY_BEFOR_SENDING_MASTER(state, payload) {
    state.dataBeforSend = payload;
  },
  SET_ERROR_MAILING_MASTER(state, payload) {
    state.errorMailing = payload;
  },
  SET_SUCCESS_SEND_MAILS_MASTER(state, payload) {
    state.successSendMails = payload;
  },
  SET_OTHER_ERROR_MASTER(state, payload) {
    state.otherErrorMail = payload;
  },
  SET_MAIL_LODING_MASTER(state, payload) {
    state.loadingMail = payload;
  }
};
const actions = {
  //get data master filiale
  async getAllMasterFilialeDraisTh({ commit }, payload) {
    commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', null);
    commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', true);
    const params = {
      page: payload.page,
      perPage: payload.perPage,
      type_date: payload.type_date
    };
    if (payload.master_filiale.length) {
      params.master_filiale = payload.master_filiale.map(({ id }) => id);
    }

    if (payload.filiale.length) {
      params.filiale = payload.filiale.map(({ id }) => id);
    }
    if (payload.search != null) {
      params.search = payload.search;
    }
    if (payload.date_debut != null) {
      params.date_debut = payload.date_debut;
    }
    if (payload.date_fin != null) {
      params.date_fin = payload.date_fin;
    }
    if (payload.depot.length) {
      params.depot = payload.depot;
    }
    if (payload.statue.length) {
      params.statue = payload.statue.map(({ value }) => value);
    }
    try {
      if (requestFraisThMasterFiliale) {
        commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', null);
        requestFraisThMasterFiliale.cancel('cancel-request');
      }
      requestFraisThMasterFiliale = axios.CancelToken.source();
      const response = await axios.get(
        domain +
          '/api/' +
          prefixFraisMasterFilialeTh +
          '/vision_master_filiale',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params,
          cancelToken: requestFraisThMasterFiliale.token
        }
      );
      commit('SET_MASTER_FILIALE_FRAIS_TH', response.data.data.data);
      commit('SET_MASTER_FILIALE_ROWS_FRAIS_TH', response.data.data.count);
      commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', false);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', error.response.data.errors);
      } else {
        if (!error.message == 'cancel-request') {
          commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', false);
          commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', [
            'Une erreur est servenue'
          ]);
        }
      }

      return false;
    }
  },
  //   get details projects
  async getProjectsDetailsMasterFilialeTh({ commit }, payload) {
    payload.item.loadingDetailt = true;
    commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', null);
    const params = {
      page: payload.page,
      perPage: payload.perPage,
      master_filiale: payload.master_filiale,
      type_date: payload.type_date
    };
    // if (payload.master_filiale.length) {
    //   params.master_filiale = payload.master_filiale;
    // }

    if (payload.filiale.length) {
      params.filiale = payload.filiale.map(({ id }) => id);
    }
    if (payload.search != null) {
      params.search = payload.search;
    }
    if (payload.date_debut != null) {
      params.date_debut = payload.date_debut;
    }
    if (payload.date_fin != null) {
      params.date_fin = payload.date_fin;
    }
    if (payload.depot.length) {
      params.depot = payload.depot;
    }
    if (payload.statue.length) {
      params.statue = payload.statue.map(({ value }) => value);
    }

    try {
      const response = await axios.get(
        domain + '/api/frais/vision_master_filiale_societe?',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_DATA_FRAIS_VISION_MASTER_FILIALE_DETAILS', {
        response: response.data.data.data,
        row: payload.item
      });
      payload.item.loadingDetailt = false;

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', error.response.data.errors);
      } else {
        commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', [
          'Une erreur est servenue'
        ]);
      }
      payload.item.loadingDetailt = false;

      return false;
    }
  },
  // getdata projetcs
  async getProjectsDetailsMasterFilialeDossierTh({ commit }, payload) {
    payload.item.loadingDetailt = true;
    commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', null);
    const params = {
      page: payload.page,
      perPage: payload.perPage,
      master_filiale: payload.master_filiale,
      type_date: payload.type_date,
      filiale: payload.filiale
    };
    // if (payload.master_filiale.length) {
    //   params.master_filiale = payload.master_filiale;
    // }

    // if (payload.filiale.length) {
    //   params.filiale = payload.filiale;
    // }
    if (payload.search != null) {
      params.search = payload.search;
    }
    if (payload.date_debut != null) {
      params.date_debut = payload.date_debut;
    }
    if (payload.date_fin != null) {
      params.date_fin = payload.date_fin;
    }
    if (payload.depot.length) {
      params.depot = payload.depot;
    }
    if (payload.statue.length) {
      params.statue = payload.statue.map(({ value }) => value);
    }

    try {
      const response = await axios.get(
        domain + '/api/frais/vision_filiale_projects_master?',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );

      commit('SET_DATA_FRAIS_VISION_MASTER_FILIALE_DETAILS', {
        response: response.data.projects,
        row: payload.item
      });

      payload.item.loadingDetailt = false;

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', error.response.data.errors);
      } else {
        commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', [
          'Une erreur est servenue'
        ]);
      }
      payload.item.loadingDetailt = false;

      return false;
    }
  },
  async getAllYearDataMasterFilialeDraisTh({ commit }, payload) {
    commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', null);
    commit('SET_YEAR_DATA_MASTER_FILIALE_LOADER_FRAIS_TH', true);
    const params = { type_date: payload.type_date };
    if (payload.date_debut != null) {
      params.date_debut = payload.date_debut;
    }
    if (payload.date_fin != null) {
      params.date_fin = payload.date_fin;
    }
    if (payload.master_filiale.length) {
      params.master_id = payload.master_filiale.map(item => item.id);
    }
    if (payload.filiales.length) {
      params.filiale_id = payload.filiales.map(item => item.id);
    }
    try {
      if (requestFraisThMasterFilialeYear) {
        commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', null);
        requestFraisThMasterFilialeYear.cancel('cancel-request');
      }
      requestFraisThMasterFilialeYear = axios.CancelToken.source();
      const response = await axios.get(
        domain + '/api/libre/filiales-lots-online',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params,
          cancelToken: requestFraisThMasterFilialeYear.token
        }
      );
      commit('SET_YEAR_DATA_MASTER_FILIALE_FRAIS_TH', {
        response: response.data,
        reload: payload.filterReload
      });

      commit('SET_YEAR_DATA_MASTER_FILIALE_LOADER_FRAIS_TH', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', error.response.data.errors);
      } else {
        if (!error.message == 'cancel-request') {
          commit('SET_YEAR_DATA_MASTER_FILIALE_LOADER_FRAIS_TH', false);
          commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', [
            'Une erreur est servenue'
          ]);
        }
      }

      return false;
    }
  },
  //export excel master filiale
  async exportExcelaMasterFilialeTh({ commit }, payload) {
    commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', null);
    commit('SET_MASTER_FILIALE_LOADER_EXPORT_FRAIS_TH', true);
    try {
      const response = await axios.post(
        domain + `/api/libre/exportFactureMaster`,
        payload,
        {
          // responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      // const link = document.createElement('a');
      // link.href = window.URL.createObjectURL(new Blob([response.data]));
      // link.setAttribute('download', 'MASTER FILIALE TH.xlsx');
      // document.body.appendChild(link);
      // link.click();
      commit('SET_MASTER_FILIALE_LOADER_EXPORT_FRAIS_TH', false);
      this.state.showNotifExportFile = true;
      return { succes: true, msg: response.data.data };
    } catch (error) {
      commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', ['Une erreur est servenue']);
      commit('SET_MASTER_FILIALE_LOADER_EXPORT_FRAIS_TH', false);
      return { succes: false };
    }
  },
  async exportZipMasterFiliale({ commit }, payload) {
    commit('SET_MASTER_FILIALE_LOADER_EXPORT_FRAIS_TH', true);
    try {
      const response = await axios.post(
        domain + `/api/libre/download-zip-master`,
        payload,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(
        new Blob([response.data], { type: 'application/pdf' })
      );
      link.setAttribute('download', 'MasterFiliale.zip');
      document.body.appendChild(link);
      link.click();
      commit('SET_MASTER_FILIALE_LOADER_EXPORT_FRAIS_TH', false);
      return true;
    } catch (error) {
      commit('SET_MASTER_FILIALE_LOADER_EXPORT_FRAIS_TH', false);
      return false;
    }
  },
  async exportPdfMasterFiliale({ commit }, payload) {
    commit('SET_MASTER_FILIALE_LOADER_EXPORT_FRAIS_TH', true);
    try {
      const response = await axios.post(
        domain + `/api/libre/downloadallpdf-master`,
        payload,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(
        new Blob([response.data], { type: 'application/pdf' })
      );
      link.setAttribute('download', 'MasterFiliale.pdf');
      document.body.appendChild(link);
      link.click();
      commit('SET_MASTER_FILIALE_LOADER_EXPORT_FRAIS_TH', false);
      return true;
    } catch (error) {
      commit('SET_MASTER_FILIALE_LOADER_EXPORT_FRAIS_TH', false);
      return false;
    }
  },
  //export pdf facture master filiale
  async exportPdfFactureaMasterFiliale({ commit }, payload) {
    commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', null);
    commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', true);
    try {
      const response = await axios.get(
        domain + `/api/filiale_template_generator/download_libre`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );

      var words = response.headers['cache-control'].split('.pdf');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', words[0] + '.pdf');
      document.body.appendChild(link);
      link.click();
      commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', false);
      return true;
    } catch (error) {
      commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', ['Une erreur est servenue']);
      commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', false);
      return false;
    }
  },
  //export avoir pdf facture master filiale
  async exportPdfFactureaMasterFilialeAvoir({ commit }, payload) {
    commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', null);
    commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', true);
    const params = {
      id: payload.id
    };
    try {
      const response = await axios.get(
        domain + `/api/filiale_template_generator/download`,
        {
          params: params,
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      var words = response.headers['cache-control'].split('.pdf');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', words[0] + '.pdf');
      document.body.appendChild(link);
      link.click();
      commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', false);
      return true;
    } catch (error) {
      commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', ['Une erreur est servenue']);
      commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', false);
      return false;
    }
  },
  // get template master filiale data
  async getTemplateAndFiledsDataMasterFilialeToUpdate({ commit }, payload) {
    commit('SET_ERROR_TEMPLATE_DATA_TO_UPLOAD', null);
    commit('SET_LOADING_GET_TEMPLATE_DATA', true);
    try {
      const response = await axios.get(
        domain +
          `/api/filiale_template_generator/get_uploaded_template_for_libre`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );
      commit('SET_TEMPLATE_MF_DATA', response.data.data);

      commit('SET_LOADING_GET_TEMPLATE_DATA', false);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_TEMPLATE_DATA_TO_UPLOAD', error.response.data.error);
      } else {
        commit('SET_ERROR_TEMPLATE_DATA_TO_UPLOAD', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_GET_TEMPLATE_DATA', false);

      return false;
    }
  },
  // get template master filiale data
  async getTemplateAndFiledsDataMasterFilialeToUpdateChange(
    { commit },
    payload
  ) {
    try {
      const response = await axios.get(
        domain +
          `/api/filiale_template_generator/get_uploaded_template_for_libre`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );

      return { succes: true, data: response.data.data };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return { succes: false, error: error.response.data.error };
      } else {
        return { succes: false, error: 'Une erreur est servenue' };
      }
    }
  },
  // get templates for societe
  async getSocieteTemplatesListMF({ commit }, payload) {
    try {
      const response = await axios.get(domain + `/api/filiale/templates`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: { famille: payload }
      });
      commit('SET_TEMPLATE_LIST_TO_UPLOAD_MF', response.data.data);
      return { succes: true };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return { succes: false, error: error.response.data.error };
      } else {
        return { succes: false, error: 'Une erreur est servenue' };
      }
    }
  },
  // upload template
  async uploadTemplateaMasterFiliale({ commit }, payload) {
    const params = {
      description: payload.description,
      iban: payload.iban,
      rib: payload.rib,
      swift: payload.swift,
      bank: payload.bank,
      date: payload.date,
      date_reglement: payload.date_reglement,
      ids: [payload.id_facture],
      id_template: payload.id_template
    };
    if (payload.payment_condition && payload.payment_condition.id != null) {
      params.payment_condition = payload.payment_condition.id;
    }

    try {
      const response = await axios.post(
        domain + `/api/libre/confirme-master`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_FACTURE_MF_UNIQUE', {
        response: response.data.data[0],
        item: payload.item
      });
      return {
        succes: true
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  //updateStatue
  async updateStatueaMasterFiliale({ commit }, payload) {
    commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', null);
    commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', true);
    const params = {
      ids: [payload.row.item.id_facture],
      etat: payload.value
    };
    try {
      const response = await axios.post(
        domain + `/api/libre/update-statut-facture`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_AVOIR_RESULT_MASTER_FILIALE', {
        data: [payload.row.item],
        response: response.data.data
      });
      commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', error.response.data.errors);
      } else {
        commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', false);

      return false;
    }
  },
  //update statue installateur
  async updateStatueInstallateuraMasterFiliale({ commit }, payload) {
    commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', null);
    commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', true);
    const params = {
      ids: [payload.row.item.id_facture],
      etat: payload.value
    };
    try {
      const response = await axios.post(
        domain + `/api/libre/update-statut-installateur`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      commit('UPDATE_AVOIR_RESULT_MASTER_FILIALE', {
        data: [payload.row.item],
        response: response.data.data
      });
      commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', error.response.data.errors);
      } else {
        commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', false);

      return false;
    }
  },
  //updateStatue
  async updateStatueaMasterFilialeGlobale({ commit }, payload) {
    payload.ids = payload.ids_facture.map(({ id_facture }) => id_facture);

    commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', null);
    commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', true);
    try {
      const response = await axios.post(
        domain + `/api/libre/update-statut-facture`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_AVOIR_RESULT_MASTER_FILIALE', {
        data: payload.ids_facture,
        response: response.data.data
      });
      commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', error.response.data.errors);
      } else {
        commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', false);

      return false;
    }
  },
  //update statue installateur
  async updateStatueInstallateuraMasterFilialeGlobale({ commit }, payload) {
    payload.ids = payload.ids_facture.map(({ id_facture }) => id_facture);
    commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', null);
    commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', true);
    try {
      const response = await axios.post(
        domain + `/api/libre/update-statut-installateur`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      commit('UPDATE_AVOIR_RESULT_MASTER_FILIALE', {
        data: payload.ids_facture,
        response: response.data.data
      });
      commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', error.response.data.errors);
      } else {
        commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', false);

      return false;
    }
  },
  //type de paiement
  async updattypePaiementMasterFiliale({ commit }, payload) {
    commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', null);
    commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', true);
    const params = {
      id: payload.idFacture,
      column: 'mode_reglement',
      value: payload.value
    };
    try {
      const response = await axios.post(
        domain + `/api/libre/update-facture-column`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      payload.row['type_paiement'] = payload.value;
      if (payload.value == 'LCR Lettre de change Relevé') {
        payload.row['lcr'] = 0;
      }

      commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', error.response.data.errors);
      } else {
        commit('SET_MASTER_FILIALE_ERROR_FRAIS_TH', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', false);

      return false;
    }
  },
  async avoirMasterFililaeTh({ commit }, payload) {
    commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', true);
    try {
      const response = await axios.post(
        domain + `/api/libre/generate_avoir`,
        payload.ids,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_AVOIR_RESULT_MASTER_FILIALE_ID', {
        response: response.data.data,
        data: payload.data
      });
      commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', false);
      return { succes: true };
    } catch (error) {
      commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', false);

      return false;
    }
  },
  // upload template
  async uploadGloabalTemplateaMasterFiliale({ commit }, payload) {
    const params = {
      description: payload.description,
      iban: payload.iban,
      rib: payload.rib,
      swift: payload.swift,
      date: payload.date,
      date_reglement: payload.date_reglement,
      ids: [...payload.id_facture],
      id_template: payload.id_template
    };
    try {
      const response = await axios.post(
        domain + `/api/libre/confirme-master`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_AVOIR_RESULT_MASTER_FILIALE', {
        response: response.data.data,
        data: payload.data
      });
      return {
        succes: true
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  setDaraTemplateUplodedMasterFiliale({ commit }) {
    commit('INIT_TEMPLATE_MASTER_FILIALE');
  },
  async sendMailMasterFilial({ commit }, payload) {
    commit('SET_MAIL_LODING_MASTER', true);
    try {
      const response = await axios.post(
        domain + `/api/libre/envoiMails`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_MAIL_LODING_MASTER', false);
      commit('SET_ERROR_MAILING_MASTER', response.data.error);
      commit('SET_SUCCESS_SEND_MAILS_MASTER', response.data.success);
      return { succes: true, data: response.data.factures };
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_OTHER_ERROR_MASTER', error?.response?.data?.error);
      } else {
        commit('SET_OTHER_ERROR_MASTER', 'Une erreur est servenue');
      }
      commit('SET_MAIL_LODING_MASTER', false);
      return { succes: false };
    }
  },
  // upload multi master filaile
  async confirmedFactureMasterFilialeMulti({ commit }, payload) {
    commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', true);
    payload.map(item => item.id_facture);
    var ids = new FormData();
    for (let i = 0; i < payload.length; i++) {
      ids.append('ids[' + [i] + ']', payload[i].id_facture);
    }
    try {
      const response = await axios.post(
        domain + `/api/libre/confirme-multi-master`,
        ids,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_AVOIR_RESULT_MASTER_FILIALE', {
        response: response.data.data,
        data: payload
      });
      commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', false);

      return {
        succes: true
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', false);
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        commit('SET_MASTER_FILIALE_LOADER_FRAIS_TH', false);
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
