import ProjectsFraisClient from './ProjectsFraisClient';
export default class FraisVisionFilialeTh {
  constructor(data) {
    this.id = data.id;
    this.operationnel = data.operationnel;
    this.operationnel_ht = data.operationnel_ht;

    // this.operationnel_download = data.operationnel_download;
    this.commercial = data.commercial;
    this.commercial_ht = data.commercial_ht;

    // this.commercial_donwload = data.commercial_donwload;
    this.marketing = data.marketing;
    this.marketing_ht = data.marketing_ht;
    // this.marketing_donwload = data.marketing_donwload;
    this.technique = data.technique;
    this.technique_ht = data.technique_ht;

    // this.technique_donwload = data.technique_donwload;
    this.rge = data.rge;
    this.rge_ht = data.rge_ht;

    // this.rge_donwload = data.rge_donwload;
    this.CDG = data.CDG;
    this.CDG_ht = data.CDG_ht;

    // this.CDG_donwload = data.CDG_donwload;
    this.administratif_sav = data.administratif_sav;
    this.administratif_sav_ht = data.administratif_sav_ht;

    // this.administratif_sav_donwload = data.administratif_sav_donwload;
    this.dsi = data.dsi;
    this.dsi_ht = data.dsi_ht;

    // this.dsi_donwload = data.dsi_donwload;
    this.juridique = data.juridique;
    this.juridique_ht = data.juridique_ht;

    // this.juridique_donwload = data.juridique_donwload;
    this.alger = data.alger;
    this.alger_ht = data.alger_ht;

    // this.alger_donwload = data.alger_donwload;
    this.comptabilite = data.comptabilite;
    this.comptabilite_download = data.comptabilite_download;
    this.comptabilite_id = data.comptabilite_id;
    this.comptabilite_statue = data.comptabilite_statue;
    this.comptabilite_date = data.comptabilite_date;
    this.RH = data.RH;
    this.RH_id = data.RH_id;
    this.RH_statue = data.RH_statue;
    this.RH_download = data.RH_download;
    this.RH_date = data.RH_date;
    this.bureau_detude = data.bureau_detude;
    this.mairie = data.mairie;
    this.mairie_ht = data.mairie_ht;

    this.maintenance_pac = data.maintenance_pac;
    this.maintenance_pac_ht = data.maintenance_pac_ht;

    this.upcube = data.upcube;
    this.total = data.total;
    this.nom = data.nom;
    this.alteo_control_gestion = data.alteo_control_gestion;
    this.alteo_control_gestion_ht = data.alteo_control_gestion_ht;

    this.check = false;
    this.loadingDetailt = false;

    this.projects = data.projects;
  }

  update(data) {
    this.projects = data.projects.map(item => ProjectsFraisClient.create(item));
  }
  static create(data) {
    return new FraisVisionFilialeTh(data);
  }
}
