export default class groupeTemplateTh {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.columns = data.columns;
    this.not_deletable = data.not_deletable ? data.not_deletable : 0;
  }
  update(data) {
    this.name = data.name;
    this.columns = data.columns;
  }
  static create(data) {
    return new groupeTemplateTh(data);
  }
}
