import ProjectsFraisWebAbondanceClient from './ProjectsFraisWebAbondanceClient';
export default class fraisWebAbondance {
  constructor(data) {
    this.dsi = data.dsi;
    this.dsi_ht = data.dsi_ht;

    this.upcube = data.upcube;
    this.upcube_ht = data.upcube_ht;

    this.total = data.total;
    this.nom = data.nom;
    this.check = false;
    this.projects = data.projects;
    this.loadingDetailt = false;
    // data.projects.map(item =>
    //   ProjectsFraisWebAbondanceClient.create(item)
    // );
  }

  update(data) {
    this.projects = data.projects.map(item =>
      ProjectsFraisWebAbondanceClient.create(item)
    );
  }
  static create(data) {
    return new fraisWebAbondance(data);
  }
}
