import FactureLibreTh from './factureLibreTh';
export default class FactureLibreVueClient {
  constructor(data) {
    this.factures = [];
    this.name = data.name;
    this.total_ht = data.total_ht;
    this.total_ttc = data.total_ttc;
    this.total_tva = data.total_tva;
    this.id = data.id;
    this.count = data.count;
    this.loadingDetailt = false;
    this.checkClient = false;
  }
  update(data) {
    this.factures = data.map(item => FactureLibreTh.create(item));
  }
  static create(data) {
    return new FactureLibreVueClient(data);
  }
}
