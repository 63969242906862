export default class paramsMasterFilialeSocieteLines {
  constructor(data) {
    this.coef = data.coef;
    this.config_id = data.config_id;
    this.id = data.id;
    this.libelle = data.libelle;
    this.societe_id = data.societe_id;
    this.tva = data.tva;
    this.type_calcul = data.type_calcul;
    this.updated_at = data.updated_at;
  }
  update(data) {
    this.coef = data.response.coef;
    this.id = data.response.id;
    this.libelle = data.response.libelle;
    this.tva = data.response.tva;
    this.config_id = data.response.config_id;
    this.societe_id = data.response.societe_id;
  }
  static create(data) {
    return new paramsMasterFilialeSocieteLines(data);
  }
}
