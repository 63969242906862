export default class AppelPaiementCommercial {
  constructor(data) {
    this.check = false;
    this.id = data.id;
    this.numero_dossier = data.numero_dossier;
    this.installateur_id = data.installateur_id;
    this.installeur = data.installeur;
    this.date_depot_dossier = data.date_depot_dossier;
    this.statut = data.statut;
    this.sous_regie = data.sous_regie;
    this.sous_regie_id = data.sous_regie_id;
    this.commercial_reste_a_charge = data.commercial_reste_a_charge;
    this.payment_call = data.payment_call;
    this.lock_commercial_factures = data.lock_commercial_factures;
    this.agent_commercial_terrain = data.agent_commercial_terrain;
    this.nom1 = data.nom1;
    this.prenom1 = data.prenom1;
    this.regie = data.regie;
    this.factures = [];
    this.loaderFacture = false;
    this.loadingLock = false;
    this.has_proforma = data.has_proforma;
    this.statut_appel_commercial = data.statut_appel_commercial;
    this.appel_facturation = data.appel_facturation
      ? data.appel_facturation.map(i => {
          return { ...i, show: false };
        })
      : [];
  }
  update(data) {
    this.check = false;
    this.id = data.id;
    this.numero_dossier = data.numero_dossier;
    this.installateur_id = data.installateur_id;
    this.installateur = data.installeur;
    this.date_depot_dossier = data.date_depot_dossier;
    this.statut = data.statut;
    this.sous_regie = data.sous_regie;
    this.sous_regie_id = data.sous_regie_id;
    this.commercial_reste_a_charge = data.commercial_reste_a_charge;
    this.payment_call = data.payment_call;
    this.lock_commercial_factures = data.lock_commercial_factures;
    this.agent_commercial_terrain = data.agent_commercial_terrain;
    this.nom1 = data.nom1;
    this.prenom1 = data.prenom1;
    this.regie = data.regie;
    this.has_proforma = data.has_proforma;
    this.statut_appel_commercial = data.statut_appel_commercial;
  }
  setDetails(data) {
    this.factures = data.map(item => {
      return { ...item, check: false };
    });
  }

  resetPaymentAppel(data) {
    this.payment_call = data.payment_call;
  }
  static create(data) {
    return new AppelPaiementCommercial(data);
  }
}
