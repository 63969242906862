export default class Bank {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
  }

  update(data) {
    this.name = data.name;
    this.updated_at = data.updated_at;
  }

  static create(data) {
    return new Bank(data);
  }
}
