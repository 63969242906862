import fraisBddInterneIhsProjectsTh from './fraisBddInterneIhsProjectsTh';
export default class fraisBddInterneIhsTh {
  constructor(data) {
    this.name = data.name;
    this.interne = data.interne;
    this.interne_ht = data.interne_ht;

    this.commercial = data.commercial;
    this.commercial_ht = data.commercial_ht;
    this.ihs = data.ihs;
    this.ihs_ht = data.ihs_ht;

    this.check = false;
    this.projects = data.projects;
  }

  update(data) {
    this.projects = data.projects.map(item =>
      fraisBddInterneIhsProjectsTh.create(item)
    );
  }
  static create(data) {
    return new fraisBddInterneIhsTh(data);
  }
}
