import FactureMaster2 from './FactureMaster2';
export default class Master2 {
  constructor(data) {
    this.check = false;
    this.name = data.name;
    this.total_net_ht = data.total_ht;
    this.total_tva = data.total_tva;
    this.total_ttc = data.total_ttc;
    this.factures = data.factures.map(facture =>
      FactureMaster2.create(facture)
    );
  }
  static create(data) {
    return new Master2(data);
  }
}
