import clientMailCdg from './clientMailCdg';
export default class mailCdg {
  constructor(data) {
    this.clients = data.clients.map(item => clientMailCdg.create(item));
    this.id_vendeur = data.id_vendeur;
    this.lot = data.lot;
    this.lot_id = data.lot_id;
    this.name_vendeur = data.name_vendeur;
    this.mailSend = false;
  }

  /**
   * @param {*} data
   */

  updateMailSend(data) {
    this.mailSend = data;
  }
  static create(data) {
    if (!data) {
      throw new Error('mailCdg::create: "data" is undefined');
    }
    return new mailCdg(data);
  }
}
