export default class TypeSociete {
  constructor(data) {
    this.id = data.id;
    this.type = data.type;
  }
  update(data) {
    this.type = data.type;
  }
  static create(data) {
    return new TypeSociete(data);
  }
}
