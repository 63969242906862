import axios from 'axios';
import domain from '@/environment';
import RowTh from '../../models/RowTh';
import Chart from '../../models/Chart';
import Filiale from '../../models/Filiale';
import Doublent from '../../models/Doublent';
import store from '../index';
import { Vue } from 'vue-property-decorator';
let request = null;
let requestDepotForYearTH = null;
let requestMonthForYearTH = null;
const state = {
  dataTh: null,
  errorTh: null,
  loadingTh: false,
  statuTh: '----',
  totalRowsTh: null,
  validiteDataTh: null,
  AnomalieDataTh: null,
  statusForRefrechDataTh: false,
  loadingEditTh: false,
  chargementTh: false,
  validationTh: null,
  ListAdminGETh: null,
  ListCommercialSedentaireTh: null,
  ListCommercialTerrainTh: null,
  ListPoseurTh: null,
  ListPrevisiteurTh: null,
  searchLoadingTh: false,
  totalSurfaceTh: null,
  intervaleValidationTh: null,
  // deValidationTh: false,
  deValidationTh: true,
  changePourcentageTh: false,
  filialesTh: null,
  anomalieLisTh: [
    'regie',
    'confirmateur',
    'administrateur',
    'poseur',
    'coposeurs',
    'depot_stockage',
    'responsable_zone',
    'agent_commercial_terrain',
    'previsiteur',
    'surface_101',
    'surface_102',
    'surface_103',
    'total_surface_a_isoler',
    'surface_103_plafonds',
    'surface_101_cp_deroule',
    'surface_101_cp_souffle',
    'surface_101_rampant',
    'surface_102_pignons',
    'surface_102_murs_iti',
    'surface_102_murs_ite',
    'surface_103_plafond_sous_sol',
    'surface_103_plafond_garage',
    'surface_103_plafond_cave',
    'surface_103_plafond_autres',
    'surface_103_vide_sanitaire'
  ],
  FilialeSelectedRapportTh: null,
  allDepotForYearTh: [],
  monthForYearTh: [],
  dossierDoublentTh: [],
  loadingDoublant: false,
  numDepotVideTh: [],
  dossierEronneTh: [],
  dossierEronneLoadingTh: false,
  totalPayeNotPayeData: null,
  filialeAdpTh: [],
  missingInstallateurThProjects: [],
  loaderProjectThWithoutInsta: false,
  loadingSearchTh: false
};
const getters = {
  getLoadingTh: state => state.loadingTh,
  getErrorTh: state => state.errorTh,
  getDataTh: state => state.dataTh,
  getStatuTh: state => state.statuTh,
  getTotalRowsTh: state => state.totalRowsTh,
  getValiditeDataTh: state => state.validiteDataTh,
  getAnomalieDataTh: state => state.AnomalieDataTh,
  //getStatusForRefrechDataTh: state => state.statusForRefrechDataTh,
  getloadingEditTh: state => state.loadingEditTh,
  getChargementTh: state => state.chargementTh,
  getValidationTh: state => state.validationTh,
  getListAdminGETh: state => state.ListAdminGETh,
  getListCommercialSedentaireTh: state => state.ListCommercialSedentaireTh,
  getListCommercialTerrainTh: state => state.ListCommercialTerrainTh,
  getListPoseurTh: state => state.ListPoseurTh,
  getListPrevisiteurTh: state => state.ListPrevisiteurTh,
  getSearchLoadingTh: state => state.searchLoadingTh,
  getTotalSurfaceTh: state => state.totalSurfaceTh,
  getIntervalTh: state => state.intervaleValidationTh,
  getDeValidationTh: state => state.deValidationTh,
  getChangePourcentageTh: state => state.changePourcentageTh,
  getFilialesTh: state => state.filialesTh,
  getFilialeSelectedRapportTh: state => state.FilialeSelectedRapportTh,
  getAllDepotForYearTh: state => state.allDepotForYearTh,
  getMonthForYearTh: state => state.monthForYearTh,
  getDossierDoublentTh: state => state.dossierDoublentTh,
  getLoadingDoublant: state => state.loadingDoublant,
  getNumDepotVideTh: state => state.numDepotVideTh,
  getDossierEronneTh: state => state.dossierEronneTh,
  getDossierEronneLoadingTh: state => state.dossierEronneLoadingTh,
  geTtotalPayeNotPayeData: state => state.totalPayeNotPayeData,
  getFilialeAdpTh: state => state.filialeAdpTh,
  loadingSearchTh: state => state.loadingSearchTh,
  getcomputedListWeek: state => month => {
    if (month != null && state.monthForYearTh.length) {
      let tab = state.monthForYearTh.filter(item => item.month_name == month);
      if (tab.length) {
        const tab1 = tab[0].weeks.map(item => ({
          value: item.id,
          text: 's' + item.num
        }));
        return tab1;
      }
    } else {
      let tab2 = [];
      state.monthForYearTh.map(items => {
        items.weeks?.map(item =>
          tab2.push({
            value: item.id,
            text: 's' + item.num
          })
        );
      });
      return tab2;
    }
  },
  getcomputedFiliale: state => (month, week) => {
    if (month !== null && week !== null && state.monthForYearTh.length) {
      let tab = state.monthForYearTh.filter(item => item.month_name == month);
      let tab1 = [];
      if (tab.length) {
        tab = tab[0].weeks.filter(item => item.id == week);
        if (tab.length) {
          tab1 = tab[0]?.filiales.map(item => ({
            value: item.name,
            text: item.name
          }));
        }
        let jsonObject = tab1.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        uniqueArray.sort((a, b) => a.value.localeCompare(b.value));
        return uniqueArray;
      }
    }
    if (month !== null && week === null && state.monthForYearTh.length) {
      let tab = state.monthForYearTh.filter(item => item.month_name == month);
      let tab2 = [];
      if (tab.length) {
        tab[0].weeks.map(item =>
          item.filiales.map(itemss =>
            tab2.push({
              value: itemss.name,
              text: itemss.name
            })
          )
        );
        let jsonObject = tab2.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        uniqueArray.sort((a, b) => a.value.localeCompare(b.value));
        return uniqueArray;
      }
    }
    if (month === null && week !== null && state.monthForYearTh.length) {
      let tab2 = [];
      state.monthForYearTh.map(items => {
        items.weeks?.map(item => {
          if (item.id == week) {
            item.filiales.map(itemss =>
              tab2.push({
                value: itemss.name,
                text: itemss.name
              })
            );
          }
        });
      });
      let jsonObject = tab2.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
      uniqueArray.sort((a, b) => a.value.localeCompare(b.value));
      return uniqueArray;
    }
    let tab2 = [];
    state.monthForYearTh.map(items => {
      items.weeks?.map(item =>
        item.filiales.map(itemss =>
          tab2.push({
            value: itemss.name,
            text: itemss.name
          })
        )
      );
    });
    let jsonObject = tab2.map(JSON.stringify);
    let uniqueSet = new Set(jsonObject);
    let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
    uniqueArray.sort((a, b) => a.value.localeCompare(b.value));
    return uniqueArray;
  },
  getcomputedDepots: state => (month, week, filiale) => {
    if (
      month != null &&
      week != null &&
      filiale != null &&
      state.monthForYearTh.length
    ) {
      let tab1 = [];
      let tab = [];
      tab = state.monthForYearTh.filter(item => item.month_name == month);
      if (tab.length) {
        tab = tab[0]?.weeks.filter(item => item.id == week);
        if (tab.length) {
          tab = tab[0]?.filiales.filter(item => item.name == filiale);
          if (tab.length) {
            tab1 = tab[0]?.depots.map(item => ({
              value: item.depot,
              text: item.depot
            }));
            return tab1;
          }
        }
      }
    }
    if (
      month != null &&
      week != null &&
      filiale == null &&
      state.monthForYearTh.length
    ) {
      let tab = state.monthForYearTh.filter(item => item.month_name == month);
      tab = tab[0].weeks.filter(item => item.id == week);
      let tab2 = [];
      tab[0]?.filiales.map(item =>
        item.depots.map(itemss =>
          tab2.push({
            value: itemss.depot,
            text: itemss.depot
          })
        )
      );
      return tab2;
    }
    if (
      month != null &&
      week == null &&
      filiale == null &&
      state.monthForYearTh.length
    ) {
      let tab = state.monthForYearTh.filter(item => item.month_name == month);
      let tab2 = [];
      tab[0]?.weeks.map(items => {
        items.filiales.map(item => {
          item.depots.map(itemss =>
            tab2.push({
              value: itemss.depot,
              text: itemss.depot
            })
          );
        });
      });
      return tab2;
    }
    if (
      month != null &&
      week == null &&
      filiale != null &&
      state.monthForYearTh.length
    ) {
      let tab = state.monthForYearTh.filter(item => item.month_name == month);
      let tab2 = [];
      tab[0]?.weeks.map(items => {
        items.filiales.map(item => {
          if (item.name == filiale) {
            item.depots.map(itemss =>
              tab2.push({
                value: itemss.depot,
                text: itemss.depot
              })
            );
          }
        });
      });
      return tab2;
    }
    if (
      month == null &&
      week != null &&
      filiale == null &&
      state.monthForYearTh.length
    ) {
      let tab2 = [];
      state.monthForYearTh.map(items => {
        items.weeks?.map(item => {
          if (item.id == week) {
            item.filiales.map(itemss =>
              itemss.depots.map(itemsss =>
                tab2.push({
                  value: itemsss.depot,
                  text: itemsss.depot
                })
              )
            );
          }
        });
      });
      return tab2;
    }
    if (
      month == null &&
      week != null &&
      filiale != null &&
      state.monthForYearTh.length
    ) {
      let tab2 = [];
      state.monthForYearTh.map(items => {
        items.weeks?.map(item => {
          if (item.id == week) {
            item.filiales.map(itemss => {
              if (itemss.name == filiale) {
                itemss.depots.map(itemsss => {
                  tab2.push({
                    value: itemsss.depot,
                    text: itemsss.depot
                  });
                });
              }
            });
          }
        });
      });
      return tab2;
    }

    if (
      month == null &&
      week == null &&
      filiale != null &&
      state.monthForYearTh.length
    ) {
      let tab2 = [];
      state.monthForYearTh.map(items => {
        items.weeks?.map(item => {
          item.filiales.map(itemss => {
            if (itemss.name == filiale) {
              itemss.depots.map(itemsss =>
                tab2.push({
                  value: itemsss.depot,
                  text: itemsss.depot
                })
              );
            }
          });
        });
      });
      return tab2;
    }
    let tab2 = [];
    state.monthForYearTh.map(items => {
      items.weeks?.map(item =>
        item.filiales.map(itemss =>
          itemss.depots.map(itemsss =>
            tab2.push({
              value: itemsss.depot,
              text: itemsss.depot
            })
          )
        )
      );
    });
    return tab2;
  },
  getMissingInstallateurThProjects: state =>
    state.missingInstallateurThProjects,
  getLoaderProjectThWithoutInsta: state => state.loaderProjectThWithoutInsta
};
const mutations = {
  SET_MONTH_FOR_YEAR_TH(state, payload) {
    state.monthForYearTh = payload;
  },
  SET_FILIALE_ADP_TH(state, payload) {
    state.filialeAdpTh = payload;
  },
  SET_LOADING_EDIT_TH(state, payload) {
    state.loadingEditTh = payload;
  },
  SET_CHARGEMENT_TH(state, payload) {
    state.chargementTh = payload;
  },
  // SET_STATUS_FOR_REFRECH_DATA_TH(state) {
  //   state.statusForRefrechDataTh = !state.statusForRefrechDataTh;
  // },
  SET_STATU_TH(state, payload) {
    state.statuTh = payload;
  },
  TOTAL_ROWS_TH(state, payload) {
    state.totalRowsTh = payload;
  },
  SET_LOADING_TH(state, payload) {
    state.loadingTh = payload;
  },
  SET_LOADING_TH_SEARCH(state, payload) {
    state.loadingSearchTh = payload;
  },
  SET_ROWS_TH(state, payload) {
    state.dataTh = payload.map(item => RowTh.create(item));
  },
  UPDATE_ROW_TH(state, payload) {
    payload.row.update(payload.response);
  },
  SET_ERROR_TH(state, payload) {
    state.errorTh = payload;
  },
  SET_PORCENTAGE_TYPE_TH(state, payload) {
    var type = [
      { name: 'Tous', pourcentage: 0 },
      { name: 'Invalide', pourcentage: payload.response.invalides },
      { name: 'Valide', pourcentage: payload.response.valides }
    ];
    if (state.validiteDataTh === null) {
      state.validiteDataTh = type.map(item => Chart.create(item));
    } else {
      if (payload.changeWeekYear && payload.changeWeekYear == true) {
        state.validiteDataTh = null;
        state.validiteDataTh = type.map(item => Chart.create(item));
      } else {
        var itemtoupdate;
        payload.type.map(item => {
          (itemtoupdate = type.filter(row => {
            if (row.name == item.name) return row;
          })),
            item.update(itemtoupdate[0]);
        });
      }
    }
  },
  SET_PORCENTAGE_ANOMALIE_TH(state, payload) {
    if (state.AnomalieDataTh === null) {
      state.AnomalieDataTh = payload.response.map(item => Chart.create(item));
    } else {
      if (payload?.changeWeekYear == true) {
        state.AnomalieDataTh = null;
        state.AnomalieDataTh = payload.response.map(item => Chart.create(item));
        state.changePourcentageTh = !state.changePourcentageTh;
      } else {
        var itemtoupdate;
        payload.anomalies.map(item => {
          itemtoupdate = payload.response.filter(row => {
            if (row.name == item.name) return row;
          });
          if (itemtoupdate.length) {
            item.update(itemtoupdate[0]);
          }
        });
      }
    }
  },
  SET_VALIDATION_TH(state, payload) {
    state.validationTh = payload;
  },
  SET_EMPLOYE_LIST_FOR_ALL_TYPE_TH(state, payload) {
    state.ListAdminGETh = payload.AdminGE;
    state.ListCommercialSedentaireTh = payload.commercialSedentaire;
    state.ListCommercialTerrainTh = payload.commercialTerrain;
    state.ListPoseurTh = payload.poseur;
    state.ListPrevisiteurTh = payload.previsiteur;
  },
  SET_LOADING_SEARCH_TH(state, payload) {
    state.searchLoadingTh = payload;
  },
  SET_TOTAL_SURFACE_TH(state, payload) {
    state.totalSurfaceTh = payload;
  },
  SET_INTERVAL_VALIDATION_TH(state, payload) {
    state.intervaleValidationTh = payload;
  },
  SET_DEVALIDATION_PARAMS_TH(state, payload) {
    state.deValidationTh = payload;
  },
  SET_RERRESH_CHART_DATA_TH(state) {
    state.changePourcentageTh = !state.changePourcentageTh;
  },
  SET_ALL_FILIALES_TH(state, responce) {
    state.filialesTh = responce.map(item => Filiale.create(item));
  },
  SET_FILIALE_ANOMALIES_TH(state, { response, filiale }) {
    filiale.update(response);
  },
  SET_FILIALE_RAPPORT_TH(state, payload = null) {
    state.FilialeSelectedRapportTh = payload;

    if (state.FilialeSelectedRapportTh !== null) {
      state.FilialeSelectedRapportTh['anomalies_per_depots'] = [];
    }
    if (
      state.FilialeSelectedRapportTh !== null &&
      state.FilialeSelectedRapportTh &&
      state.FilialeSelectedRapportTh.anomalies_per_depot &&
      Object.keys(state.FilialeSelectedRapportTh.anomalies_per_depot).length !==
        0
    ) {
      for (const [key, value] of Object.entries(
        state.FilialeSelectedRapportTh.anomalies_per_depot
      )) {
        state.FilialeSelectedRapportTh.anomalies_per_depots.push(value);
      }
    }
  },
  RESET_DATA_RAPPORT_TH(state) {
    state.dataTh = null;
    state.validiteDataTh = null;
    state.AnomalieDataTh = null;
    state.filialesTh = null;
    state.dossierDoublentTh = [];
    state.totalSurfaceTh = null;
  },
  FEATCH_DEPOT_IN_YEAR_TH(state, payload) {
    state.allDepotForYearTh = payload;
  },
  SET_DOUBLENT_DOSSIER_TH(state, payload) {
    state.dossierDoublentTh = payload.map(item => Doublent.create(item));
  },
  DELETE_DOUBLENT_DOSSIER_TH(state, payload) {
    for (let index = 0; index < state.dossierDoublentTh.length; index++) {
      if (state.dossierDoublentTh[index].id == payload.id) {
        state.dossierDoublentTh.splice(index, 1);
        break;
      }
    }
    for (let i = 0; i < state.dataTh.length; i++) {
      if (state.dataTh[i].id == payload.id) {
        state.dataTh.splice(i, 1);
        break;
      }
    }
  },
  SET_LOADING_DOUBLANT_TH(state, payload) {
    state.loadingDoublant = payload;
  },
  SET_NUMERO_DOSSIER_WITHOUT_INSTALLEUR_TH(state, payload) {
    state.numDepotVideTh = payload;
  },
  UPDATE_NUMERO_DOSSIER_WITHOUT_INSTALLEUR_TH(state, payload) {
    for (let index = 0; index < state.numDepotVideTh.length; index++) {
      if (state.numDepotVideTh[index].id == payload.item.id) {
        state.numDepotVideTh.splice(index, 1);
      }
    }
  },
  SET_DOSSIER_ERONNE_TH(state, payload) {
    state.dossierEronneTh = payload;
  },
  SET_LOADING_ERROR_PROJECT_TH(state, payload) {
    state.dossierEronneLoadingTh = payload;
  },
  DELETE_ERROR_PROJEC_TH(state, payload) {
    let index = state.dossierEronneTh.findIndex(item => item.id == payload.id);
    state.dossierEronneTh.splice(index, 1);
  },
  SET_TOTAL_PAYE_NON_PAYE_STATUE_TH(state, payload) {
    state.totalPayeNotPayeData = payload;
  },
  SET_LOCK_PROJECTS_TH(state, payload) {
    payload.map(item => {
      let index = state.dataTh.findIndex(i => i.id == item.id);
      state.dataTh[index].montant_min_lock = item.montant_min_lock;
    });
  },
  SET_MISSING_INSTALLATEUR_RAPPORT(state, payload) {
    state.missingInstallateurThProjects = payload;
  },
  LOADING_MISSING_INSTALLATEUR_RAPPORT(state, payload) {
    state.loaderProjectThWithoutInsta = payload;
  }
};
const actions = {
  async fetchAnalyseTh({ commit, state }, payload) {
    commit('SET_LOADING_TH', true);
    commit('SET_ERROR_TH', null);
    commit('SET_VALIDATION_TH', null);
    //commit('SET_CHARGEMENT_TH', false);
    commit('SET_LOADING_EDIT_TH', false);
    const body = {
      page: payload.page,
      per_page: payload.per_page,
      annee: payload.annee
    };
    try {
      if (payload.depot != null) {
        body.depot = payload.depot;
      }
      if (payload.month != null) {
        body.month = payload.month;
      }
      if (payload.type === 'valides') {
        body.type = payload.type;
      } else if (payload.type === 'invalides') {
        body.type = payload.type;
        if (payload.anomalies.length !== 0) {
          body.anomalies = payload.anomalies;
        }
      }
      if (
        payload.champ &&
        (payload.champ !== null || payload.champ !== '') &&
        (payload.name !== null || payload.name !== '')
      ) {
        body.champ = payload.champ.key;
        body.search = payload.name;
      }
      if (payload.filiale != null) {
        body.filiale = payload.filiale;
      }
      if (payload.week != null) {
        body.week = payload.week;
      }
      body.statue = payload.statue;
      if (payload.statue != 'paye' && payload.numbers_from_file != null) {
        body.numbers_from_file = payload.numbers_from_file;
      }
      const response = await axios.get(domain + `/api/getanalysesTH`, {
        params: body,
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      if (response.data && response.data.error) {
        commit('SET_ERROR_TH', response.data.error);
        commit('SET_STATU_TH', null);
      } else {
        commit('SET_ROWS_TH', response.data.data.data);
        commit('SET_TOTAL_SURFACE_TH', response.data.total_montant_net_ht);
        commit(
          'SET_TOTAL_PAYE_NON_PAYE_STATUE_TH',
          response.data.meta[0].pourcentages_paye_non_paye
        );
        commit('TOTAL_ROWS_TH', response.data.data.total);
        if (
          store.state.rapport.progress >= 100 ||
          store.state.rapport.progress === null
        ) {
          commit('SET_STATU_TH', response.data.meta[0].statue);
          commit('SET_PORCENTAGE_ANOMALIE_TH', {
            response: response.data.anomalies,
            anomalies: payload.anom,
            changeWeekYear: payload.changeWeekYear
              ? payload.changeWeekYear
              : null
          });
          commit('SET_PORCENTAGE_TYPE_TH', {
            response: response.data.meta[0].pourcentages,
            type: payload.typeData,
            changeWeekYear: payload.changeWeekYear
              ? payload.changeWeekYear
              : null
          });
        } else {
          state.AnomalieDataTh = null;
          state.validiteDataTh = null;
        }
      }
      commit('SET_LOADING_TH', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_TH', error.response.data.error);
      } else {
        commit('SET_ERROR_TH', 'Une erreur est servenue');
      }
      commit('RESET_DATA_RAPPORT_TH');
      commit('SET_PORCENTAGE_TYPE_TH', {
        response: { invalides: 0, valides: 0 },
        type: payload.typeData,
        changeWeekYear: payload.changeWeekYear ? payload.changeWeekYear : null
      });
      commit('SET_TOTAL_PAYE_NON_PAYE_STATUE_TH', { paye: 0, non_paye: 0 });
      commit('SET_LOADING_TH', false);
      return false;
    }
  },
  async fetchAnalyseChartTh({ commit, state }, payload) {
    commit('SET_LOADING_EDIT_TH', false);
    commit('SET_CHARGEMENT_TH', true);
    commit('SET_ERROR_TH', null);
    const body = {
      page: payload.page,
      per_page: payload.per_page,
      annee: payload.annee
    };
    try {
      if (request) {
        request.cancel('cancel-request');
      }
      request = axios.CancelToken.source();
      if (payload.depot != null) {
        body.depot = payload.depot;
      }
      if (payload.month != null) {
        body.month = payload.month;
      }
      if (payload.type === 'valides') {
        body.type = payload.type;
      } else if (payload.type === 'invalides') {
        body.type = payload.type;
        if (payload.anomalies.length !== 0) {
          body.anomalies = payload.anomalies;
        }
      }
      if (
        payload.champ &&
        (payload.champ !== null || payload.champ !== '') &&
        (payload.name !== null || payload.name !== '')
      ) {
        body.champ = payload.champ.key;
        body.search = payload.name;
      }
      if (payload.filiale != null) {
        body.filiale = payload.filiale;
      }
      if (payload.week != null) {
        body.week = payload.week;
      }
      body.statue = payload.statue;
      if (payload.statue != 'paye' && payload.numbers_from_file != null) {
        body.numbers_from_file = payload.numbers_from_file;
      }
      const response = await axios.get(domain + `/api/getanalysesTH`, {
        params: body,
        cancelToken: request.token,
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      if (response.data.error) {
        commit('SET_ERROR_TH', response.data.error);
      } else {
        commit('SET_ROWS_TH', response.data.data.data);
        commit('SET_TOTAL_SURFACE_TH', response.data.total_montant_net_ht);
        commit(
          'SET_TOTAL_PAYE_NON_PAYE_STATUE_TH',
          response.data.meta[0].pourcentages_paye_non_paye
        );
        commit('SET_INTERVAL_VALIDATION_TH', response.data.interval);
        commit('TOTAL_ROWS_TH', response.data.data.total);
        if (
          store.state.rapport.progress >= 100 ||
          store.state.rapport.progress === null
        ) {
          commit('SET_STATU_TH', response.data.meta[0].statue);
          commit('SET_VALIDATION_TH', response.data.validation);
          commit('SET_PORCENTAGE_TYPE_TH', {
            response: response.data.meta[0].pourcentages,
            type: payload.typeData,
            changeWeekYear: true
          });
          commit('SET_PORCENTAGE_ANOMALIE_TH', {
            response: response.data.anomalies,
            anomalies: payload.anom,
            changeWeekYear: true
          });
        } else {
          state.AnomalieDataTh = null;
          state.validiteDataTh = null;
          commit('SET_PORCENTAGE_TYPE_TH', {
            response: { invalides: 0, valides: 0 },
            type: payload.typeData,
            changeWeekYear: payload.changeWeekYear
              ? payload.changeWeekYear
              : null
          });
        }
      }
      commit('SET_CHARGEMENT_TH', false);
      return true;
    } catch (error) {
      if (error.message !== 'cancel-request') {
        commit('SET_CHARGEMENT_TH', false);
      }
      commit('RESET_DATA_RAPPORT_TH');
      commit('SET_TOTAL_PAYE_NON_PAYE_STATUE_TH', { paye: 0, non_paye: 0 });
      commit('SET_PORCENTAGE_TYPE_TH', {
        response: { invalides: 0, valides: 0 },
        type: payload.typeData,
        changeWeekYear: payload.changeWeekYear ? payload.changeWeekYear : null
      });
      return false;
    }
  },
  async updateRowTh({ commit, dispatch }, payload) {
    commit('SET_LOADING_TH', true);
    const body = { annee: payload.filterObject.annee };
    if (payload.filterObject.type === 'valides') {
      body.type = payload.filterObject.type;
    } else if (payload.filterObject.type === 'invalides') {
      body.type = payload.filterObject.type;
      if (payload.anomalies.length !== 0) {
        body.anomalies = payload.anomalies;
      }
    }
    if (payload.filterObject.depot != null) {
      body.depot = payload.filterObject.depot;
    }
    if (payload.filterObject.month != null) {
      body.month = payload.filterObject.month;
    }
    if (payload.filterObject.week != null) {
      body.week = payload.filterObject.week;
    }
    if (payload.filterObject.filiale != null) {
      body.filiale = payload.filterObject.filiale;
    }
    if (payload.filterObject.statue != null) {
      body.statue = payload.filterObject.statue;
    }
    if (
      payload.champ &&
      (payload.champ !== null || payload.champ !== '') &&
      (payload.name !== null || payload.name !== '')
    ) {
      body.champ = payload.champ.key;
      body.search = payload.name;
    }
    if (
      payload.column.includes('surface') &&
      (payload.value == '' || payload.value == '')
    ) {
      payload.value = 0;
    }
    try {
      const response = await axios.put(
        domain +
          `/api/updateTH/${payload.row.id}?column=${payload.column}&value=${payload.value}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: response.data.data[0],
        row: payload.row
      };
      if (payload.column == 'installeur') {
        dispatch('fetchMissingInstallateursRapport', {
          statut: payload.filterObject.statue
        });
      }
      commit('UPDATE_ROW_TH', obj);
      commit('SET_TOTAL_SURFACE_TH', response.data.total_montant_net_ht);
      commit(
        'SET_TOTAL_PAYE_NON_PAYE_STATUE_TH',
        response.data.meta[0].pourcentages_paye_non_paye
      );
      commit('SET_INTERVAL_VALIDATION_TH', response.data.interval);
      commit('SET_STATU_TH', response.data.meta[0].statue);
      commit('SET_VALIDATION_TH', response.data.validation);
      commit('SET_PORCENTAGE_ANOMALIE_TH', {
        response: response.data.anomalies,
        anomalies: payload.filterObject.anom,
        changeWeekYear: true
      });
      commit('SET_PORCENTAGE_TYPE_TH', {
        response: response.data.meta[0].pourcentages,
        type: payload.filterObject.typeData,
        changeWeekYear: true
      });
      commit('SET_RERRESH_CHART_DATA_TH');
      commit('SET_LOADING_TH', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_TH', false);
      return false;
    }
  },
  async exportFileTh({ commit }, payload) {
    commit('SET_LOADING_TH', true);
    const params = { annee: payload.annee };
    try {
      if (payload.type === 'valides') {
        params.type = payload.type;
      } else if (payload.type === 'invalides') {
        params.type = payload.type;
        if (payload.anomalies.length !== 0) {
          params.anomalies = payload.anomalies;
        }
      }
      if (payload.depot != null) {
        params.depot = payload.depot;
      }
      if (payload.month != null) {
        params.month = payload.month;
      }
      if (payload.filiale != null && payload.filiale) {
        params.filiale = payload.filiale;
      }
      if (payload.week != null && payload.week) {
        params.week = payload.week;
      }
      if (payload.statue != null && payload.statue) {
        params.statue = payload.statue;
      }
      if (
        payload.champ &&
        (payload.champ !== null || payload.champ !== '') &&
        (payload.name !== null || payload.name !== '')
      ) {
        params.champ = payload.champ.key;
        params.search = payload.name;
      }
      if (payload.view != null && payload.view) {
        params.view = payload.view;
      }
      const response = await axios.get(domain + `/api/exportTH`, {
        params: params,
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      const name =
        payload.month != null
          ? payload.month + ' ' + payload.annee
          : '' + '' + payload.annee;
      link.setAttribute('download', 'Compil-TH ' + name + '.xlsx');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_TH', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_TH', false);
      return false;
    }
  },
  async fetchProgressAnalyseTh({ commit, state }, payload) {
    const body = {
      page: payload.page,
      per_page: payload.per_page,
      annee: payload.annee
    };
    try {
      if (payload.depot != null) {
        body.depot = payload.depot;
      }
      if (payload.month != null) {
        body.month = payload.month;
      }
      if (payload.type === 'valides') {
        body.type = payload.type;
      } else if (payload.type === 'invalides') {
        body.type = payload.type;
        if (payload.anomalies.length !== 0) {
          body.anomalies = payload.anomalies;
        }
      }
      if (
        payload.champ &&
        (payload.champ !== null || payload.champ !== '') &&
        (payload.name !== null || payload.name !== '')
      ) {
        body.champ = payload.champ.key;
        body.search = payload.name;
      }
      if (payload.filiale != null) {
        body.filiale = payload.filiale;
      }
      if (payload.week != null) {
        body.week = payload.week;
      }
      body.statue = payload.statue;
      if (payload.statue != 'paye' && payload.numbers_from_file != null) {
        body.numbers_from_file = payload.numbers_from_file;
      }
      const response = await axios.get(domain + `/api/getanalysesTH`, {
        params: body,
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('TOTAL_ROWS_TH', response.data.data.total);
      commit('SET_LOADING_EDIT_TH', true);
      if (store.state.rapport.progress >= 100) {
        commit('SET_PORCENTAGE_ANOMALIE_TH', {
          response: response.data.anomalies,
          anomalies: payload.anom
        });
        commit('SET_PORCENTAGE_TYPE_TH', {
          response: response.data.meta[0].pourcentages,
          type: payload.typeData
        });
      } else {
        state.AnomalieDataTh = null;
        state.validiteDataTh = null;
      }
      return true;
    } catch (error) {
      commit('RESET_DATA_RAPPORT_TH');
      commit('SET_PORCENTAGE_TYPE_TH', {
        response: { invalides: 0, valides: 0 },
        type: payload.typeData,
        changeWeekYear: payload.changeWeekYear ? payload.changeWeekYear : null
      });
      commit('SET_TOTAL_PAYE_NON_PAYE_STATUE_TH', { paye: 0, non_paye: 0 });
      commit('SET_LOADING_TH', false);
      return false;
    }
  },
  async validStepTh({ commit }, payload) {
    commit('SET_LOADING_TH', true);
    try {
      const params = new URLSearchParams();
      if (payload.month != null) {
        params.append('month', payload.month);
      }
      // if (payload.depot != null) {
      //   params.append('depot' + payload.depot);
      // }
      params.append('year', payload.year);
      const response = await axios.post(domain + `/api/makebill_TH`, params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      if (response.data.data == 'done') {
        commit('SET_VALIDATION_TH', true);
      }
      commit('SET_LOADING_TH', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_TH', false);
      return false;
    }
  },
  async fetchEmployeParTypeTh({ commit }) {
    try {
      const response = await axios.get(domain + `/api/EmployeesByType`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_EMPLOYE_LIST_FOR_ALL_TYPE_TH', response.data);
    } catch {}
  },
  async fastSearchTh({ commit }, payload) {
    commit('SET_LOADING_SEARCH_TH', true);
    try {
      const body = {
        page: payload.page,
        per_page: payload.per_page,
        annee: payload.annee,
        search: payload.coloneValue
      };
      if (request) {
        request.cancel('cancel-request');
      }
      request = axios.CancelToken.source();
      if (payload.depot != null) {
        body.depot = payload.depot;
      }
      if (payload.month != null) {
        body.month = payload.month;
      }
      if (payload.type === 'valides') {
        body.type = payload.type;
      } else if (payload.type === 'invalides') {
        body.type = payload.type;
        if (payload.anomalies.length !== 0) {
          body.anomalies = payload.anomalies;
        }
      }
      if (
        payload.champ &&
        (payload.champ !== null || payload.champ !== '') &&
        (payload.coloneValue !== null || payload.coloneValue !== '')
      ) {
        body.champ = payload.champ.key;
      }
      if (payload.filiale != null) {
        body.filiale = payload.filiale;
      }
      if (payload.week != null) {
        body.week = payload.week;
      }
      if (payload.statue != null) {
        body.statue = payload.statue;
      }
      const response = await axios.get(domain + `/api/getanalysesTH`, {
        params: body,
        cancelToken: request.token,
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      if (response.data.error) {
        commit('SET_ERROR_TH', response.data.error);
      } else {
        commit('SET_ROWS_TH', response.data.data.data);
        commit('SET_TOTAL_SURFACE_TH', response.data.total_montant_net_ht);
        commit(
          'SET_TOTAL_PAYE_NON_PAYE_STATUE_TH',
          response.data.meta[0].pourcentages_paye_non_paye
        );
        commit('SET_INTERVAL_VALIDATION_TH', response.data.interval);

        commit('TOTAL_ROWS_TH', response.data.data.total);
        if (
          store.state.rapport.progress >= 100 ||
          store.state.rapport.progress === null
        ) {
          commit('SET_STATU_TH', response.data.meta[0].statue);
          commit('SET_VALIDATION_TH', response.data.validation);
          commit('SET_PORCENTAGE_TYPE_TH', {
            response: response.data.meta[0].pourcentages,
            type: payload.typeData
          });
          commit('SET_PORCENTAGE_ANOMALIE_TH', {
            response: response.data.anomalies,
            anomalies: payload.anom
          });
        } else {
          state.AnomalieDataTh = null;
          state.validiteDataTh = null;
        }
      }

      commit('SET_LOADING_SEARCH_TH', false);
      return true;
    } catch (error) {
      if (error.message !== 'cancel-request') {
        commit('SET_LOADING_SEARCH_TH', false);
      }
      commit('RESET_DATA_RAPPORT_TH');
      commit('SET_PORCENTAGE_TYPE_TH', {
        response: { invalides: 0, valides: 0 },
        type: payload.typeData,
        changeWeekYear: payload.changeWeekYear ? payload.changeWeekYear : null
      });
      commit('SET_TOTAL_PAYE_NON_PAYE_STATUE_TH', { paye: 0, non_paye: 0 });
      return false;
    }
  },
  async getAllFilialesTh({ commit }) {
    try {
      const response = await axios.get(domain + `/api/allFiliales`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_ALL_FILIALES_TH', response.data.teams);
      return true;
    } catch {
      return false;
    }
  },
  async getAnomalieForFilialeTh({ commit, state }, { semaine, annee }) {
    const params = {
      annee: annee
    };
    if (switchMonth == true) {
      params.mois = month;
    } else {
      params.semaine = 's' + semaine.value;
    }
    try {
      for (let i = 0; i < state.filialesTh.length; i++) {
        params.id = state.filialesTh[i].id;
        state.filialesTh[i].loadingAnomalie = true;
        const response = await axios.get(domain + `/api/filialeWithanomalie`, {
          params: params,
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        });
        commit('SET_FILIALE_ANOMALIES_TH', {
          response: response.data,
          filiale: state.filialesTh[i]
        });
        state.filialesTh[i].loadingAnomalie = false;
      }
    } catch {}
  },

  async getRapportFilialeTh({ commit }, payload) {
    commit('SET_FILIALE_RAPPORT_TH');
    const body = {
      annee: payload.annee,
      id: payload.filiale.id
    };
    if (payload.switchMonth == true) {
      body.mois = payload.month;
    } else {
      body.semaine = 's' + payload.semaine.value;
    }
    try {
      const response = await axios.get(domain + `/api/filialePDFrapport`, {
        params: body,
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_FILIALE_RAPPORT_TH', response.data);
      return true;
    } catch (error) {
      return false;
    }
  },
  async getPdfRapportFilialeTh({ commit }, payload) {
    commit('SET_FILIALE_RAPPORT_TH');
    const body = {
      annee: payload.annee,
      url: payload.url
    };
    if (payload.filiale && payload.filiale.id) {
      body.id = payload.filiale.id;
    }
    const dataURLtoFile = (dataurl, filename) => {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1);
        n -= 1; // to make eslint happy
      }
      return new File([u8arr], filename, { type: mime });
    };
    const file = dataURLtoFile(payload.url);
    // put file into form data
    const data = new FormData();
    data.append('annee', payload.annee);
    if (payload.filiale && payload.filiale.id) {
      data.append('id', payload.filiale.id);
    }

    data.append('url', file);
    if (payload.switchMonth == true) {
      data.append('mois', payload.month);
    } else {
      data.append('semaine', 's' + payload.semaine.value);
    }
    try {
      const response = await axios.post(
        domain + `/api/filialePDFrapport2`,
        data,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`,
            'Content-Type': 'multipart/form-data'
          },
          responseType: 'blob'
        }
      );
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      let s = payload.switchMonth ? payload.semaine.value : payload.month;

      link.setAttribute(
        'download',
        payload.filiale.name + '_' + '_S' + s + '_A' + payload.annee + '.pdf'
      );
      link.click();
      return true;
    } catch (error) {
      return false;
    }
  },
  async fastSearchColonneCotentTh({ commit }, payload) {
    commit('SET_LOADING_TH_SEARCH', true);
    commit('SET_ERROR_TH', null);

    try {
      const body = {
        page: payload.page,
        per_page: payload.per_page,
        search: payload.searchValue,
        annee: payload.annee
      };
      if (request) {
        request.cancel('cancel-request');
      }
      request = axios.CancelToken.source();
      if (payload.depot != null) {
        body.depot = payload.depot;
      }
      if (payload.month != null) {
        body.month = payload.month;
      }
      if (payload.type === 'valides') {
        body.type = payload.type;
      } else if (payload.type === 'invalides') {
        body.type = payload.type;
        if (payload.anomalies.length !== 0) {
          body.anomalies = payload.anomalies;
        }
      }
      if (
        payload.champ &&
        (payload.champ !== null || payload.champ !== '') &&
        (payload.name !== null || payload.name !== '')
      ) {
        body.champ = payload.champ.key;
        body.search = payload.name;
      }
      if (payload.filiale != null) {
        body.filiale = payload.filiale;
      }
      if (payload.week != null) {
        body.week = payload.week;
      }
      body.statue = payload.statue;
      if (payload.statue != 'paye' && payload.numbers_from_file != null) {
        body.numbers_from_file = payload.numbers_from_file;
      }

      const response = await axios.get(domain + `/api/getanalysesTH`, {
        params: body,
        cancelToken: request.token,
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      if (response.data.error) {
        commit('SET_ERROR_TH', response.data.error);
      } else {
        commit('SET_ROWS_TH', response.data.data.data);
        commit('SET_TOTAL_SURFACE_TH', response.data.total_montant_net_ht);
        commit(
          'SET_TOTAL_PAYE_NON_PAYE_STATUE_TH',
          response.data.meta[0].pourcentages_paye_non_paye
        );
        commit('SET_INTERVAL_VALIDATION_TH', response.data.interval);

        commit('TOTAL_ROWS_TH', response.data.data.total);
        if (
          store.state.rapport.progress >= 100 ||
          store.state.rapport.progress === null
        ) {
          commit('SET_STATU_TH', response.data.meta[0].statue);
          commit('SET_VALIDATION_TH', response.data.validation);
          commit('SET_PORCENTAGE_TYPE_TH', {
            response: response.data.meta[0].pourcentages,
            changeWeekYear: true
          });
          commit('SET_PORCENTAGE_ANOMALIE_TH', {
            response: response.data.anomalies,
            changeWeekYear: true
          });
        } else {
          state.AnomalieDataTh = null;
          state.validiteDataTh = null;
        }
      }

      commit('SET_LOADING_TH_SEARCH', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_TH', error.response.data.error);
      } else {
        commit('SET_ERROR_TH', 'Une erreur est servenue');
      }
      if (error.message !== 'cancel-request') {
        commit('SET_LOADING_TH_SEARCH', false);
      }
      commit('RESET_DATA_RAPPORT_TH');
      commit('SET_PORCENTAGE_TYPE_TH', {
        response: { invalides: 0, valides: 0 },
        type: payload.typeData,
        changeWeekYear: payload.changeWeekYear ? payload.changeWeekYear : null
      });
      commit('SET_TOTAL_PAYE_NON_PAYE_STATUE_TH', { paye: 0, non_paye: 0 });
      return false;
    }
  },
  async ResetDataForWeekTh({ commit }, payload) {
    const body = {
      depot: payload.depot
    };
    try {
      const response = await axios.post(
        domain + `/api/delete_rapport_TH`,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      if (response) {
        commit('RESET_DATA_RAPPORT_TH');
      }
    } catch {}
  },
  async featchDepotForYearTH({ commit }, payload) {
    try {
      if (requestDepotForYearTH) {
        requestDepotForYearTH.cancel('cancel-request');
      }
      requestDepotForYearTH = axios.CancelToken.source();
      const response = await axios.get(
        domain + `/api/depots_per_year/${payload}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          cancelToken: requestDepotForYearTH.token
        }
      );
      commit('FEATCH_DEPOT_IN_YEAR_TH', response.data.data);
      return true;
    } catch {
      return false;
    }
  },
  async fetchMonthForYearTH({ commit }, payload) {
    try {
      if (requestMonthForYearTH) {
        requestMonthForYearTH.cancel('cancel-request');
      }
      requestMonthForYearTH = axios.CancelToken.source();
      const response = await axios.get(domain + `/api/all_months_calendar_th`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: { year: payload },
        cancelToken: requestMonthForYearTH.token
      });
      commit('SET_MONTH_FOR_YEAR_TH', response.data.data);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_TH', error.response.data);
      } else {
        if (!error.message == 'cancel-request') {
          commit('SET_ERROR_TH', 'Une erreur est survenue');
        }
      }

      return false;
    }
  },
  async fetchFilialeADPThForYearTH({ commit }) {
    try {
      const response = await axios.get(
        domain + `/api/filiales_in_not_paied_projects`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_FILIALE_ADP_TH', response.data.data);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_TH', error.response.data);
      } else {
        commit('SET_ERROR_TH', 'Une erreur est survenue');
      }

      return false;
    }
  },
  async fetchDoublentDossierTh({ commit }, payload) {
    try {
      const response = await axios.get(domain + `/api/checkdoubleTH`, {
        params: payload,
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_DOUBLENT_DOSSIER_TH', response.data.project);
    } catch {}
  },
  async deleteProjetTh({ commit }, payload) {
    commit('SET_LOADING_DOUBLANT_TH', true);
    const body = {
      id: payload.item.id
    };
    try {
      const response = await axios.post(
        domain + `/api/delete_project_TH`,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DELETE_DOUBLENT_DOSSIER_TH', payload.item);
      commit('SET_LOADING_DOUBLANT_TH', false);
    } catch {
      commit('SET_LOADING_DOUBLANT_TH', false);

      return false;
    }
  },
  async fetchNumDepotVideTh({ commit }, payload) {
    try {
      const response = await axios.get(domain + `/api/orphan_projects_th`, {
        params: payload,
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_NUMERO_DOSSIER_WITHOUT_INSTALLEUR_TH', response.data.data);
    } catch {
      return false;
    }
  },
  async updateDossierWithoutNumDepot({ commit }, payload) {
    let params = {
      id: payload.row.id,
      depot: payload.value
    };
    try {
      const response = await axios.post(
        domain + `/api/update_project_depot`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_NUMERO_DOSSIER_WITHOUT_INSTALLEUR_TH', {
        response: response.data.data,
        item: payload.row
      });
      commit('SET_LOADING_EDIT_TH', true);
    } catch {
      return false;
    }
  },
  async resfershBddAnalyseValidation({ commit }) {
    commit('SET_LOADING_TH', true);
    try {
      const response = await axios.get(domain + `/api/fix_orphan_projects`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_LOADING_TH', false);
      commit('SET_NUMERO_DOSSIER_WITHOUT_INSTALLEUR_TH', []);
      return { succes: true, data: response.data.data };
    } catch (error) {
      commit('SET_LOADING_TH', false);
      return { succes: false, error: ['Une erreur est servenue '] };
    }
  },
  ReloadChartDataTh({ commit }) {
    commit('SET_RERRESH_CHART_DATA_TH');
  },
  async fetchDossierEronneTh({ commit }) {
    try {
      const response = await axios.get(domain + `/api/buged_project`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_DOSSIER_ERONNE_TH', response.data.data);
    } catch {
      return false;
    }
  },
  async deleteErroeProjetTh({ commit }, payload) {
    commit('SET_LOADING_ERROR_PROJECT_TH', true);
    try {
      const response = await axios.post(
        domain + `/api/delete_buged_project/${payload.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DELETE_ERROR_PROJEC_TH', payload);
      commit('SET_LOADING_ERROR_PROJECT_TH', false);
    } catch {
      commit('SET_LOADING_ERROR_PROJECT_TH', false);

      return false;
    }
  },
  // async changeStatutInAnalyseValidation({ commit, state }, payload) {
  //   var bodyFormData = new FormData();
  //   if (payload.statut != null) {
  //     bodyFormData.append('type', payload.statut);
  //   }
  //   if (payload.date != null) {
  //     bodyFormData.append('date', payload.date);
  //   }
  //   let table = state.dataTh.filter(item => item.check == true);
  //   for (let i = 0; i < table.length; i++) {
  //     bodyFormData.append('ids[' + [i] + ']', table[i].id);
  //   }
  //   try {
  //     const responce = await axios.post(
  //       domain + `/api/pay_projects`,
  //       bodyFormData,
  //       {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //           Authorization: `Bearer ${this.state.User.access_token}`
  //         }
  //       }
  //     );
  //     return { succes: true, data: responce.data };
  //   } catch (error) {
  //     if (error?.responce?.data?.error) {
  //       return { succes: false, error: error.responce.data.error };
  //     } else {
  //       return { succes: false, error: ['Une erreur est servenue'] };
  //     }
  //   }
  // },

  // async uploadFileExcelNumDossier({ commit, dispatch }, payload) {
  //   commit('SET_LOADING_TH', true);
  //   commit('SET_ERROR_TH', null);
  //   var bodyFormData = new FormData();
  //   bodyFormData.append('file', payload);
  //   const config = {
  //     headers: {
  //       'Content-Type': 'multipart/form-data',
  //       Authorization: `Bearer ${this.state.User.access_token}`
  //     }
  //   };
  //   try {
  //     const response = await axios.post(
  //       domain + '/api/get_numbers_from_excel',
  //       bodyFormData,
  //       config
  //     );
  //     commit('SET_LOADING_TH', false);
  //     return { succes: true, data: response.data.data };
  //   } catch (error) {
  //     commit('SET_LOADING_TH', false);
  //     if (
  //       error.response &&
  //       error.response.data.extra &&
  //       error.response.data.missing
  //     ) {
  //       commit('SET_ERROR_TH', {
  //         extra: error.response.data.extra,
  //         missing: error.response.data.missing
  //       });
  //     } else {
  //       commit('SET_ERROR_TH', error.response.data.error);
  //     }
  //     return { succes: false };
  //   }
  // },
  // async changeLockProjectsTh({ commit, state }, payload) {
  //   var bodyFormData = new FormData();
  //   let table = state.dataTh.filter(item => item.check == true);
  //   for (let i = 0; i < table.length; i++) {
  //     bodyFormData.append('ids[' + [i] + ']', table[i].id);
  //   }
  //   try {
  //     const responce = await axios.post(
  //       domain + `/api/lock_montant_min_project`,
  //       bodyFormData,
  //       {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //           Authorization: `Bearer ${this.state.User.access_token}`
  //         }
  //       }
  //     );
  //     commit('SET_LOCK_PROJECTS_TH', responce.data.data);
  //     return { succes: true };
  //   } catch (error) {
  //     if (error?.responce?.data?.error) {
  //       return { succes: false, error: error.responce.data.error };
  //     } else {
  //       return { succes: false, error: ['Une erreur est servenue'] };
  //     }
  //   }
  // },
  async fetchMissingInstallateursRapport({ commit }, payload) {
    commit('LOADING_MISSING_INSTALLATEUR_RAPPORT', true);
    try {
      const response = await axios.get(
        domain + `/api/folders_without_installeur`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );
      commit('SET_MISSING_INSTALLATEUR_RAPPORT', response.data.data);
      commit('LOADING_MISSING_INSTALLATEUR_RAPPORT', false);

      return true;
    } catch (error) {
      commit('LOADING_MISSING_INSTALLATEUR_RAPPORT', false);

      return false;
    }
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
