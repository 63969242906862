import axios from 'axios';
import domain from '@/environment';
import stateGlobale from '../index';
import fraisBddCommericalCactusTh from '../../models/fraisBddCommericalCactusTh';
const prefixsuivi_commercial_global = 'suivi_commercial_global';
let requestCommercialCactus = null;
const state = {
  fraisBddCommericalCactusData: [],
  loadingFraisBddCommericalCactus: false,
  errorFraisBddCommericalCactus: null,
  globaleTemplateCactus: null,
  regieOnlineInCactusFrais: [],
  fraisBddCommericalCactusDataTotal: null
};
const getters = {
  getFraisBddCommericalCactusData: state => state.fraisBddCommericalCactusData,
  getLoadingFraisBddCommericalCactus: state =>
    state.loadingFraisBddCommericalCactus,
  getErrorFraisBddCommericalCactus: state =>
    state.errorFraisBddCommericalCactus,
  getGlobaleTemplateCactus: state => state.globaleTemplateCactus,
  getRegieOnlineInCactusFrais: state => state.regieOnlineInCactusFrais,
  getfraisBddCommericalCactusDataTotal: state =>
    state.fraisBddCommericalCactusDataTotal
};
const mutations = {
  SET_DATA_FRAIS_BDD_COMMERCIAL_CACTUS(state, payload) {
    state.fraisBddCommericalCactusData = payload.map(item =>
      fraisBddCommericalCactusTh.create(item)
    );
  },
  SET_TOTAL_DATA_FRAIS_BDD_COMMERCIAL_CACTUS(state, payload) {
    state.fraisBddCommericalCactusDataTotal = payload;
  },
  SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS(state, payload) {
    state.loadingFraisBddCommericalCactus = payload;
  },
  SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS(state, payload) {
    state.errorFraisBddCommericalCactus = payload;
  },
  UPDATE_FRAIS_BDD_COMMERCIAL_CACTUS_STAT(state, payload) {
    payload.row.update(payload.response);
  },
  SET_TEMPLATE_CACTUS_TO_UPLOAD(state, payload) {
    state.globaleTemplateCactus = payload;
  },
  SET_STATUE_MAIL_CACTUES__VALUE(state, payload) {
    payload.item.update(payload.response);
  },
  SET_REGIE_ONLINE_CACTUS_FREAIS(state, payload) {
    state.regieOnlineInCactusFrais = payload;
  },
  SET_DATA_FRAIS_VISION_FILIALE_DETAILS_C(state, payload) {
    payload.row.update(payload.response);
  }
};
const actions = {
  async getAllFraisBddCommericalCactus({ commit }, payload) {
    commit('SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS', null);
    commit('SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS', true);
    const params = {
      year: payload.year,
      page: payload.page,
      perPage: payload.perPage
    };
    if (payload.month != null) {
      params.month = payload.month;
    }
    if (payload.search != null) {
      params.search = payload.search;
    }
    if (payload.filiale != null) {
      params.filiale = payload.filiale;
    }
    if (payload.week != null) {
      params.week = payload.week;
    }
    if (payload.depot != null) {
      params.depot = payload.depot;
    }
    if (payload.type != null) {
      params.type = payload.type;
    }
    if (payload.statue != null) {
      params.statue = payload.statue;
    }
    if (payload.regie != null) {
      params.regie = payload.regie;
    }
    if (payload.super_regie != null) {
      params.super_regie = payload.super_regie;
    }

    try {
      if (requestCommercialCactus) {
        commit('SET_ERROR_FRAIS_BDD', null);
        requestCommercialCactus.cancel('cancel-request');
      }
      requestCommercialCactus = axios.CancelToken.source();

      const response = await axios.get(
        domain + '/api/' + prefixsuivi_commercial_global + '/all',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params,
          cancelToken: requestCommercialCactus.token
        }
      );
      commit('SET_DATA_FRAIS_BDD_COMMERCIAL_CACTUS', response.data.data);
      commit('SET_TOTAL_DATA_FRAIS_BDD_COMMERCIAL_CACTUS', response.data.count);
      commit('SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS', false);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        commit(
          'SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS',
          error.response.data.errors
        );
      } else {
        if (!error.message == 'cancel-request') {
          commit('SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS', false);
          commit('SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS', [
            'Une erreur est servenue'
          ]);
        }
      }

      return false;
    }
  },
  async updateFraisBddCommericalCactus({ commit }, payload) {
    commit('SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS', null);
    commit('SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS', true);
    const params = {
      id: payload.row.id,
      column: payload.column,
      value:
        payload.column == 'super_regie'
          ? payload.value
          : parseFloat((payload.value + '').replace(',', '.'))
    };
    try {
      const response = await axios.post(
        domain + `/api/${prefixsuivi_commercial_global}/update`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: response.data.data,
        row: payload.row
      };
      commit('UPDATE_FRAIS_BDD_COMMERCIAL_CACTUS_STAT', obj);
      commit('SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        commit(
          'SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS',
          error.response.data.errors
        );
      } else {
        commit('SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS', false);

      return false;
    }
  },
  // statut
  async updateFraisBddCommericalCactusStatut({ commit }, payload) {
    commit('SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS', null);
    commit('SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS', true);
    const params = {
      id: payload.row.id_to_use,
      column: 'statue',
      value: payload.value
    };
    try {
      const response = await axios.post(
        domain + `/api/frais/vision_filiale_update_statue_date`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      payload.row.item[payload.column] = payload.value;
      if (payload.column.split('_')[1]) {
        let champName = payload.column.split('_')[1] + 'statue_installateur';
        payload.row.item[champName] = payload.value;
      }
      commit('SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        commit(
          'SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS',
          error.response.data.errors
        );
      } else {
        commit('SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS', false);

      return false;
    }
  },
  // statut installateur
  async updateFraisBddCommericalCactusStatutInstallateur({ commit }, payload) {
    commit('SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS', null);
    commit('SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS', true);
    const params = {
      id: payload.row.id_to_use,
      statue_installateur: payload.value,
      vision: stateGlobale.state.currentInterfaceFrais
    };
    try {
      const response = await axios.post(
        domain + `/api/frais/update_statue_installateur`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_STATUE_MAIL_CACTUES__VALUE', {
        item: payload.row.item,
        response: response.data.data
      });
      payload.row.item[payload.column] = payload.value;
      commit('SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        commit(
          'SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS',
          error.response.data.errors
        );
      } else {
        commit('SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS', false);

      return false;
    }
  },
  async refreshDataBddCommericalCactusCommercialCactus({ commit }, payload) {
    commit('SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS', null);
    commit('SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS', true);
    const params = {
      year: payload.year
    };
    if (payload.month != null) {
      params.month = payload.month;
    }
    try {
      const response = await axios.post(
        domain + '/api/' + prefixsuivi_commercial_global + '/refresh',
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS', false);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        commit(
          'SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS',
          error.response.data.errors
        );
      } else {
        commit('SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS', false);

      return false;
    }
  },

  //global validation CACTUS
  async getGlobalTemplateAndFiledsDataToUpdateForCactus({ commit }, payload) {
    commit('SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS', null);
    commit('SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS', true);
    const params = {
      type: payload.type
    };

    try {
      const response = await axios.get(domain + `/api/templates/get_one`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
      });
      commit('SET_TEMPLATE_CACTUS_TO_UPLOAD', response.data.data);

      commit('SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit(
          'SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS',
          error.response.data.error
        );
      } else {
        commit('SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS', false);

      return false;
    }
  },
  async uploadGlobalTemplateForCactus({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/filiale_template_generator/upload_all`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      return {
        succes: true,
        message: response.data.data
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async exportPdfFraisVisionCactusTh({ commit }, payload) {
    commit('SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS', null);
    commit('SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS', true);
    if (payload.month && payload.month != null) {
      params.month = payload.month;
    }
    try {
      const response = await axios.post(
        domain + `/api/filiale_template_generator/download_all`,
        payload,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const month_name =
        payload.month && payload.month != null ? '-' + payload.month : '';
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'VISION COMMERCIAL CACTUS.zip');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS', false);
      return true;
    } catch (error) {
      commit('SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS', [
        'Une erreur est servenue'
      ]);
      commit('SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS', false);
      return false;
    }
  },
  async exportPdfInvalidFraisVisionCactusTh({ commit }, payload) {
    commit('SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS', null);
    commit('SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS', true);
    if (payload.month && payload.month != null) {
      params.month = payload.month;
    }
    try {
      const response = await axios.post(
        domain + `/api/download_pdfNonValide`,
        payload,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const month_name =
        payload.month && payload.month != null ? '-' + payload.month : '';
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'VISION COMMERCIAL CACTUS INVALID.pdf');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS', false);
      return true;
    } catch (error) {
      commit('SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS', [
        'Une erreur est servenue'
      ]);
      commit('SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS', false);
      return false;
    }
  },
  async exportExcelFraisVisionCommercialCactusTh({ commit }, payload) {
    commit('SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS', null);
    commit('SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS', true);
    try {
      const response = await axios.post(
        domain + `/api/frais/export_vision_filiale`,
        payload,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      let year = payload.get('year') ? '-' + payload.get('year') : '';

      link.setAttribute(
        'download',
        'VISION COMMERCIAL CACTUS  ' +
          payload.get('type').toUpperCase() +
          year +
          '.xlsx'
      );
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS', false);
      return true;
    } catch (error) {
      commit('SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS', [
        'Une erreur est servenue'
      ]);
      commit('SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS', false);
      return false;
    }
  },
  async getOnlineRegieForCactusFrais({ commit }, payload) {
    commit('SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS', null);
    const params = {
      year: payload.year
    };
    if (payload.month != null) {
      params.month = payload.month;
    }
    if (payload.master_filiale != null) {
      params.master_filiale = payload.master_filiale;
    }
    if (payload.search != null) {
      params.search = payload.search;
    }
    if (payload.filiale != null) {
      params.filiale = payload.filiale;
    }
    if (payload.week != null) {
      params.week = payload.week;
    }
    if (payload.depot != null) {
      params.depot = payload.depot;
    }
    if (payload.statue != null) {
      params.statue = payload.statue;
      params.type = payload.type;
    }
    if (payload.regie != null) {
      params.regie = payload.regie;
    }
    if (payload.super_regie != null) {
      params.super_regie = payload.super_regie;
    }

    try {
      const response = await axios.get(
        domain + '/api/' + prefixsuivi_commercial_global + '/regie_online',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_REGIE_ONLINE_CACTUS_FREAIS', response.data.data);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        commit(
          'SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS',
          error.response.data.errors
        );
      } else {
        commit('SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS', [
          'Une erreur est servenue'
        ]);
      }

      return false;
    }
  },
  async getDataForRowFraisC({ commit }, payload) {
    payload.item.loadingDetailt = true;
    const params = {
      year: payload.year,
      page: payload.page,
      perPage: payload.perPage,
      filiale: payload.item.name
    };
    if (payload.month != null) {
      params.month = payload.month;
    }
    if (payload.search != null) {
      params.search = payload.search;
    }
    if (payload.week != null) {
      params.week = payload.week;
    }
    if (payload.depot != null) {
      params.depot = payload.depot;
    }
    if (payload.type != null) {
      params.type = payload.type;
    }
    if (payload.statue != null) {
      params.statue = payload.statue;
    }
    if (payload.regie != null) {
      params.regie = payload.regie;
    }
    if (payload.super_regie != null) {
      params.super_regie = payload.super_regie;
    }

    try {
      const response = await axios.get(
        domain + '/api/suivi_commercial_global/all_projects',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_DATA_FRAIS_VISION_FILIALE_DETAILS_C', {
        response: response.data,
        row: payload.item
      });
      payload.item.loadingDetailt = false;

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        commit(
          'SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS',
          error.response.data.errors
        );
      } else {
        commit('SET_LOADING_FRAIS_BDD_COMMERCIAL_CACTUS', false);
        commit('SET_ERROR_FRAIS_BDD_COMMERCIAL_CACTUS', [
          'Une erreur est servenue'
        ]);
      }
      payload.item.loadingDetailt = false;

      return false;
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
