import Template from './Template';
export default class CategoryTemplate {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    if (data.templates) {
      this.templates = data.templates.map(item => Template.create(item));
    } else {
      this.templates = [];
    }
  }
  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('template::create: "data" is undefined');
    }
    return new CategoryTemplate(data);
  }
  update(data) {
    this.name = data.name;
  }
}
