export default class SocieteAtraiter {
  constructor(data) {
    this.id = data.id ;
    this.checked = false;
    this.name = data.name;
    this.societe = data.filiales;
    this.interface = data.interfaces ;
  }


  update(data)
  {
    this.id = data.id ;
    this.checked = data.checked;
    this.name = data.name;
    this.societe = data.filiales;
    this.interface = data.interfaces ;
  }

  static create(data) {
    return new SocieteAtraiter(data);
  }
}
