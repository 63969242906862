export default class GestionClientPassage {
  constructor(data) {
    this.id = data.id;
    this.adresse = data.adresse;
    this.code_postal = data.code_postal;
    this.description = data.description;
    this.email = data.email;
    this.name = data.name;
    this.pays = data.pays;
    this.siren_value = data.siren_value;
    this.telephone = data.telephone;
    this.telephone_portable = data.telephone_portable;
    this.tva_value = data.tva_value;
    this.ville = data.ville;
    this.siret = data.siret;
    if (data && data.tva_value) {
      this.siren_tva = 'Numéro TVA';
    }
    if (data && data.siren_value) {
      this.siren_tva = 'SIREN';
    }
    this.abreviation = data.abreviation;
    this.active_master = data.active_master;
    this.all_master_filiales = data.all_master_filiales;
    this.banque = data.banque;
    this.biens = data.biens;
    this.capital = data.capital;
    this.civilite = data.civilite;
    this.commercials = data.commercials;
    this.couleur = data.couleur;
    this.deleted_at = data.deleted_at;
    this.depots = data.depots;
    this.domaine_key = data.domaine_key;
    this.end_day = data.end_day;
    this.end_month = data.end_month;
    this.end_year = data.end_year;
    this.fax = data.fax;
    this.files = data.files;
    this.footer = data.footer;
    this.footer_type = data.footer_type;
    this.has_rib = data.has_rib;
    this.has_sequence = data.has_sequence;
    this.iban = data.iban;
    this.is_societe_support = data.is_societe_support;
    this.logo = data.logo;
    this.masterFiliales = data.masterFiliales;
    this.nom_famille = data.nom_famille;
    this.payment_condition = data.payment_condition;
    this.payment_type = data.payment_type;
    this.pixel_id = data.pixel_id;
    this.prenom = data.prenom;
    this.rcs = data.rcs;
    this.responsables = data.responsables;
    this.ribs = data.ribs;
    this.sequences_factures = data.sequences_factures;
    this.site_internet = data.site_internet;
    this.societes_of_master_filiale = data.societes_of_master_filiale;
    this.start_day = data.start_day;
    this.start_month = data.start_month;
    this.start_year = data.start_year;
    this.swift = data.swift;
    this.type_societe = data.type_societe;
    this.type_societe_support = data.type_societe_support;
    this.volant = data.volant;
    this.type = data.type;

  }
  update(data) {
    this.adresse = data.adresse;
    this.code_postal = data.code_postal;
    this.description = data.description;
    this.email = data.email;
    this.name = data.name;
    this.pays = data.pays;
    this.siren_value = data.siren_value;
    this.telephone = data.telephone;
    this.telephone_portable = data.telephone_portable;
    this.tva_value = data.tva_value;
    this.siret = data.siret;
    if (data && data.tva_value) {
      this.siren_tva = 'Numéro TVA';
    }
    if (data && data.siren_value) {
      this.siren_tva = 'SIREN';
    }
    this.ville = data.ville;
    this.abreviation = data.abreviation;
    this.active_master = data.active_master;
    this.all_master_filiales = data.all_master_filiales;
    this.banque = data.banque;
    this.biens = data.biens;
    this.capital = data.capital;
    this.civilite = data.civilite;
    this.commercials = data.commercials;
    this.couleur = data.couleur;
    this.deleted_at = data.deleted_at;
    this.depots = data.depots;
    this.domaine_key = data.domaine_key;
    this.end_day = data.end_day;
    this.end_month = data.end_month;
    this.end_year = data.end_year;
    this.fax = data.fax;
    this.files = data.files;
    this.footer = data.footer;
    this.footer_type = data.footer_type;
    this.has_rib = data.has_rib;
    this.has_sequence = data.has_sequence;
    this.iban = data.iban;
    this.is_societe_support = data.is_societe_support;
    this.logo = data.logo;
    this.masterFiliales = data.masterFiliales;
    this.nom_famille = data.nom_famille;
    this.payment_condition = data.payment_condition;
    this.payment_type = data.payment_type;
    this.pixel_id = data.pixel_id;
    this.prenom = data.prenom;
    this.rcs = data.rcs;
    this.responsables = data.responsables;
    this.ribs = data.ribs;
    this.sequences_factures = data.sequences_factures;
    this.site_internet = data.site_internet;
    this.societes_of_master_filiale = data.societes_of_master_filiale;
    this.start_day = data.start_day;
    this.start_month = data.start_month;
    this.start_year = data.start_year;
    this.swift = data.swift;
    this.type_societe = data.type_societe;
    this.type_societe_support = data.type_societe_support;
    this.volant = data.volant;
    this.type = data.type;

  }
  static create(data) {
    return new GestionClientPassage(data);
  }
}
