import axios from 'axios';
import domain from '@/environment';
import Notification from '../../models/Notification';
const state = {
  notifications: [],
  countNotification: null,
  newNotification: false,
  noutificationToShow: null,
  totalPageNotification: null,
  notificationsLoader: false
};
const getters = {
  getAllNotifications: state => state.notifications,
  getCountNotification: state => state.countNotification,
  getnewNotification: state => state.newNotification,
  getNoutificationToShow: state => state.noutificationToShow,
  gettotalPageNotification: state => state.totalPageNotification,
  getNotificationsLoader: state => state.notificationsLoader
};
const mutations = {
  CREATE_NOTIFICATIONS(state, payload) {
    state.notifications = payload.map(item => Notification.create(item));
  },
  CREATE_NEW_NOTIFICATIONS(state, payload) {
    payload.map(item => state.notifications.push(Notification.create(item)));
  },
  SET_NOTIFICATION_COUNT(state, payload) {
    state.countNotification = payload;
  },
  SET_NOTIFICATION(state, payload) {
    state.notifications.map(item => {
      if (item.id == payload.id) {
        item.seen = true;
      }
    });
  },
  NEW_NOTIFICATION(state, payload) {
    this.state.showNotifExportFile = false;
    state.notifications.unshift(Notification.create(payload));
    state.noutificationToShow = payload;
    state.newNotification = !state.newNotification;
    state.countNotification = state.countNotification + 1;
  },
  TOTAL_PAGE_NOTIFICATION(state, payload) {
    state.totalPageNotification = payload;
  },
  NOTIFICATIONS_LOADER(state, payload) {
    state.notificationsLoader = payload;
  },
  REMOVE_NOTIFICATIONG(state, payload) {
    state.notifications = state.notifications.filter(
      item => item.id != payload
    );
  }
};
const actions = {
  async fetchAllNotifications({ commit }, payload) {
    commit('NOTIFICATIONS_LOADER', true);

    try {
      const response = await axios.get(domain + `/api/activeNotifications`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: payload
      });
      commit('CREATE_NOTIFICATIONS', response.data.notifications.data);
      commit('TOTAL_PAGE_NOTIFICATION', response.data.notifications.total);
      commit('SET_NOTIFICATION_COUNT', response.data.actives);
      commit('NOTIFICATIONS_LOADER', false);
      return true;
    } catch (error) {
      commit('NOTIFICATIONS_LOADER', false);

      return false;
    }
  },
  async fetchNewNotification({ commit }, payload) {
    try {
      const response = await axios.get(
        domain + `/api/activeNotifications?page=${payload.page}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        },
        { page: payload.page }
      );
      commit('CREATE_NEW_NOTIFICATIONS', response.data.notifications.data);
      commit('TOTAL_PAGE_NOTIFICATION', response.data.notifications.total);
      commit('SET_NOTIFICATION_COUNT', response.data.actives);
      return true;
    } catch (error) {
      return false;
    }
  },

  async updateNotification({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/seeNotification/${payload.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      if (response.data == 'done') {
        commit('SET_NOTIFICATION', payload);
      }
      return true;
    } catch (error) {
      return false;
    }
  },
  async markAllAsHoverdAction({ commit }, payload) {
    commit('SET_NOTIFICATION_COUNT', 0);
    try {
      const response = await axios.post(
        domain + `/api/markAllAsHoverd`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      return true;
    } catch (error) {
      return false;
    }
  },
  async deleteNot({ commit }, payload) {
    try {
      const response = await axios.get(
        domain + '/api/delete_notification/' + payload.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('REMOVE_NOTIFICATIONG', payload.id);
      return true;
    } catch (error) {
      return false;
    }
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
