import axios from 'axios';
import Bien from '../../models/Bien';
import domain from '@/environment';

const state = {
  biens: [],
  loadingBien: false,
  errorBiens: [],
  filialesBien: [],
  errorNomBien: null,
  errorLocataire: null,
  errorProprietaire: null,
  totalRowsBien: 0
};
const getters = {
  biens: state => state.biens,
  loadingBien: state => state.loadingBien,
  errorBiens: state => state.errorBiens,
  getFilialesBiens: state => state.filialesBien,
  errorNomBien: state => state.errorNomBien,
  errorLocataire: state => state.errorLocataire,
  errorProprietaire: state => state.errorProprietaire,
  totalRowsBien: state => state.totalRowsBien
};
const mutations = {
  SET_BIENS(state, payload) {
    state.biens = payload.map(bien => Bien.create(bien));
  },
  SET_LOADING_BIENS(state, payload) {
    state.loadingBien = payload;
  },
  SET_ERROR_BIENS(state, payload) {
    state.errorBiens = payload;
  },
  SET_FILIALES_BIEN(state, payload) {
    state.filialesBien = payload;
  },
  ADD_NEW_BIEN(state, payload) {
    state.biens.unshift(Bien.create(payload));
  },
  ERROR_NOM_BIEN(state, payload) {
    state.errorNomBien = payload;
  },
  ERROR_LOCATAIRES_BIEN(state, payload) {
    state.errorLocataire = payload;
  },
  ERROR_PROPRIETAIRE_BIEN(state, payload) {
    state.errorProprietaire = payload;
  },
  UPDATE_BLOCKED_BIEN(state, payload) {
    payload.item.update(payload.response);
  },
  UPDATE_BIEN(state, payload) {
    payload.item.update(payload.response);
  },
  DELETE_LOCATAIRE_BIEN(state, payload) {
    state.biens.map(bien => {
      if (bien.id == payload.bien) {
        let index = bien.locataires.findIndex(
          locataire => locataire.id == payload.locataire
        );
        bien.locataires.splice(index, 1);
      }
    });
  },
  SET_TOTAL_ROWS_BIEN(state, payload) {
    state.totalRowsBien = payload;
  }
};
const actions = {
  async getAllBiens({ commit }, payload) {
    commit('SET_LOADING_BIENS', true);
    let params = {};
    if (payload?.page != null) {
      params.page = payload.page;
    }
    if (payload?.per_page != null) {
      params.per_page = payload.per_page;
    }
    if (payload?.nom != null && payload?.nom != '') {
      params.nom = payload.nom;
    }
    try {
      const response = await axios.get(domain + '/api/biens/getAllBiens', {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
      });
      if (payload?.per_page != null) {
        commit('SET_BIENS', response.data.data.data);
        commit('SET_TOTAL_ROWS_BIEN', response.data.data.total);
      } else {
        commit('SET_BIENS', response.data.data);
      }
      commit('SET_LOADING_BIENS', false);
    } catch (error) {
      commit('SET_LOADING_BIENS', false);
      commit('SET_ERROR_BIENS', ['Une erreur est survenue']);
    }
  },
  async getAllFilialesBiensListe({ commit }) {
    try {
      const response = await axios.get(domain + `/api/filiale/all_filiale`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_FILIALES_BIEN', response.data.data);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_BIENS', ['Une erreur est survenue']);
        return false;
      }
    }
  },
  async createBien({ commit }, payload) {
    commit('SET_ERROR_BIENS', []);
    commit('ERROR_NOM_BIEN', null);
    commit('ERROR_LOCATAIRES_BIEN', null);
    commit('ERROR_PROPRIETAIRE_BIEN', null);
    commit('SET_LOADING_BIENS', true);
    try {
      const response = await axios.post(domain + `/api/biens/create`, payload, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('ADD_NEW_BIEN', response.data.data);
      commit('SET_LOADING_BIENS', false);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        commit('ERROR_NOM_BIEN', error.response.data.errors.nom);
        commit('SET_ERROR_BIENS', error.response.data.error);
      } else {
        if (error && error.response && error.response.data) {
          commit('ERROR_NOM_BIEN', error.response.data.error);
        } else {
          commit('SET_ERROR_BIENS', ['Une erreur est survenue']);
        }
      }
      commit('SET_LOADING_BIENS', false);
    }
    return false;
  },
  async updateBlockedBien({ commit }, payload) {
    commit('SET_LOADING_BIENS', true);
    try {
      let params = {
        id: payload.id,
        blocked: payload.blocked
      };
      const response = await axios.post(
        domain + `/api/biens/blocked_bien`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_BLOCKED_BIEN', {
        item: payload.item,
        response: response.data.data
      });

      commit('SET_LOADING_BIENS', false);

      return true;
    } catch (error) {
      commit('SET_LOADING_BIENS', false);
      return false;
    }
  },
  async updateBien({ commit }, payload) {
    commit('SET_ERROR_BIENS', []);
    commit('ERROR_NOM_BIEN', null);
    commit('ERROR_LOCATAIRES_BIEN', null);
    commit('ERROR_PROPRIETAIRE_BIEN', null);
    commit('SET_LOADING_BIENS', true);
    try {
      const response = await axios.post(
        domain + `/api/biens/update`,
        payload.bienToUpdate,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_BIEN', {
        item: payload.oldBienToUpdate,
        response: response.data.data
      });
      commit('SET_LOADING_BIENS', false);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        commit('ERROR_NOM_BIEN', error.response.data.errors.nom);
      } else {
        if (error && error.response && error.response.data) {
          commit('ERROR_NOM_BIEN', error.response.data.error);
        } else {
          commit('SET_ERROR_BIENS', ['Une erreur est survenue']);
        }
      }
      commit('SET_LOADING_BIENS', false);
      return false;
    }
  },
  async updateLocataireBien({ commit }, payload) {
    commit('SET_ERROR_BIENS', []);
    commit('SET_LOADING_BIENS', true);
    try {
      const response = await axios.post(
        domain + `/api/biens/updateLocataire`,
        payload.bienToUpdate,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_BIEN', {
        item: payload.oldBienToUpdate,
        response: response.data.data
      });
      commit('SET_LOADING_BIENS', false);

      return true;
    } catch (error) {
      commit('SET_ERROR_BIENS', ['Une erreur est survenue']);
      commit('SET_LOADING_BIENS', false);
      return false;
    }
  },

  async updateBlockedLocataire({ commit }, payload) {
    commit('SET_LOADING_BIENS', true);
    try {
      let params = {
        id: payload.id,
        blocked: payload.blocked
      };
      const response = await axios.post(
        domain + `/api/biens/blocked_locataire`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_BLOCKED_BIEN', {
        item: payload.item,
        response: response.data.data
      });

      commit('SET_LOADING_BIENS', false);

      return true;
    } catch (error) {
      commit('SET_LOADING_BIENS', false);
      return false;
    }
  },

  async deleteLocataireBien({ commit }, payload) {
    commit('SET_LOADING_BIENS', true);
    try {
      const response = await axios.delete(
        domain + `/api/biens/deleteLocataire/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DELETE_LOCATAIRE_BIEN', {
        bien: payload.id_bien,
        locataire: payload.id
      });
      commit('SET_LOADING_BIENS', false);

      return true;
    } catch (error) {
      commit('SET_LOADING_BIENS', false);
      return false;
    }
  },
  resetState({ commit }) {
    commit('SET_ERROR_BIENS', null);
    commit('ERROR_NOM_BIEN', null);
    commit('SET_LOADING_BIENS', false);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
