import projectSubMasterFiliale from './projectSubMasterFiliale';
export default class fraisMasterFilialeModel {
  constructor(data) {
    this.id = data.id;
    this.montant_total = data.montant_total;
    this.montant_ht = data.montant_ht;
    this.montant_tva = data.montant_tva;
    this.nom = data.nom;
    this.check = false;
    this.loadingDetailt = false;
    this.projects = data.projects;
  }

  update(data) {
    this.projects = data.map(item => projectSubMasterFiliale.create(item));
  }
  static create(data) {
    return new fraisMasterFilialeModel(data);
  }
}
