import Axios from 'axios';
import domain from '@/environment';
import CategoryTemplate from '../../models/CategoryTemplate';
import Template from '../../models/Template';
import axios from 'axios';
const prefixTemplates = 'templates';
const prefixCategoriesTemplates = 'categories';
const prefixTemplates_image = 'templates_image';

const state = {
  TemplatesFields: null,
  newTemplateLoading: false,
  newTemplateError: null,
  TemplateLoadingFetch: false,
  allTemplate: [],
  templateLoading: false,
  imageTemplateList: [],
  fieldsErrorTemplate: null,
  errorRibTemplate: null,
  loadingRibTemplate: false,
  allRib: [],
  lodingDynamicTable: false,
  dynamicTableErrors: null,
  templateSociete: [],
  errorTemplateSociete: [],
  templateOne: null,
  lodingOneTemplate: false,
  templateFamilleLoader: false,
  templateFamilleData: []
};
const getters = {
  errorTemplateSociete: state => state.errorTemplateSociete,
  templateSociete: state => state.templateSociete,
  getTemplatesFields: state => state.TemplatesFields,
  getAllTemplate: state => state.allTemplate,
  TemplateLoadingFetch: state => state.TemplateLoadingFetch,
  getTemplateError: state => state.newTemplateError,
  getNewTemplateLoading: state => state.newTemplateLoading,
  getImageTemplateList: state => state.imageTemplateList,
  getfieldsErrorTemplate: state => state.fieldsErrorTemplate,
  getAllRib: state => state.allRib,
  getLoadingRibTemplate: state => state.loadingRibTemplate,
  getErrorRibTemplate: state => state.errorRibTemplate,
  getEmptyRib: state => state.emptyRib,
  getlodingDynamicTable: state => state.lodingDynamicTable,
  getDynamicTableErrors: state => state.dynamicTableErrors,
  getOneTemplate: state => state.templateOne,
  getLoadingOneTemplate: state => state.lodingOneTemplate,
  getTemplateLoading: state => state.templateLoading,
  getTemplateFamilleLoader: state => state.templateFamilleLoader,
  getTemplateFamilleData: state => state.templateFamilleData
};
const mutations = {
  SET_DYNAMIC_TABLE_ERRORS(state, payload) {
    state.DynamicTableErrors = payload;
  },
  SET_ONE_TEMPLATE(state, payload) {
    state.templateOne = payload;
  },
  SET_LOADING_ONE_TEMPLATE(state, payload) {
    state.lodingOneTemplate = payload;
  },
  SET_LOADING_DYNAMIC_TABLE(state, payload) {
    state.lodingDynamicTable = payload;
  },
  SET_TEMPLATES_SOCIETES(state, payload) {
    payload.push({
      name: 'Template par défaut',
      id: null
    });
    state.templateSociete = payload;
  },
  SET_ERROR_TEMPLATES_SOCIETES(state, payload) {
    state.errorTemplateSociete = payload;
  },
  SET_TEMPLATE_FIELDS(state, payload) {
    state.TemplatesFields = payload;
  },
  ADD_NEW_TEMPLATE(state, payload) {
    for (let i = 0; i < state.allTemplate.length; i++) {
      let id = payload.category_id;
      if (payload.category_id == null) {
        id = 0;
      }
      if (state.allTemplate[i].id == id) {
        state.allTemplate[i].templates.push(Template.create(payload));
        break;
      }
    }
  },
  SET_FETCH_TEMPLATE_LOADING(state, payload = false) {
    state.TemplateLoadingFetch = payload;
  },
  SET_ALL_TEMPLATE(state, payload) {
    state.allTemplate = payload.map(item => CategoryTemplate.create(item));
  },
  SET_ALL_TEMPLATE_LIBRE(state, payload) {
    if (payload.refresh && payload.refresh == true) {
      for (let i = 0; i < state.allTemplate.length; i++) {
        if (state.allTemplate[i].id == 0) {
          state.allTemplate[i].templates = payload.response.map(item =>
            Template.create(item)
          );
          break;
        }
      }
    } else {
      let params = {
        id: 0,
        name: 'Facture Libre',
        templates: payload.response
      };
      state.allTemplate.push(CategoryTemplate.create(params));
    }
  },
  SET_NEW_TEMPLATE_LOADING(state, payload = false) {
    state.newTemplateLoading = payload;
  },
  SET_TEMPLATE_ERROR(state, payload) {
    state.newTemplateError = payload;
  },
  SET_TEMPLATE_LOADING(state, payload = false) {
    state.templateLoading = payload;
  },
  UPDATE_TEMPLATE(state, { response, TemplateToUpdateOrigine }) {
    TemplateToUpdateOrigine.update(response);
  },
  DELETE_TEMPLATE(state, payload) {
    for (let i = 0; i < state.allTemplate.length; i++) {
      let id = payload.category_id;
      if (payload.category_id == null) {
        id = 0;
      }
      if (state.allTemplate[i].id == id) {
        state.allTemplate[i].templates = state.allTemplate[i].templates.filter(
          Template => {
            return Template.id != payload.id;
          }
        );
        break;
      }
    }
  },
  ADD_NEW_IMAGE(state, payload) {
    state.imageTemplateList.push(payload);
  },
  SET_LIST_IMAGE(state, payload) {
    state.imageTemplateList = payload;
  },
  SET_FIELD_ERROR(state, payload) {
    state.fieldsErrorTemplate = payload;
  },
  ADD_NEW_CATEGORIE_TEMPLATE(state, payload) {
    state.allTemplate.push(CategoryTemplate.create(payload));
  },
  UPDATE_CATEGORIE(state, payload) {
    payload.oldCategorie.update(payload.response);
  },
  DELETE_CATEGORIE_TEMPLATE(state, payload) {
    for (let i = 0; i < state.allTemplate.length; i++) {
      if (state.allTemplate[i].id == payload.id) {
        state.allTemplate.splice(i, 1);
        break;
      }
    }
  },
  ADD_RIB(state, payload) {
    payload.ribToUpdate.ribs[payload.index].blocked = false;
    payload.ribToUpdate.ribs[payload.index].id = payload.response.id;
    payload.ribToUpdate.ribs[payload.index].swift = payload.response.swift;
    payload.ribToUpdate.ribs[payload.index].iban = payload.response.iban;
    payload.ribToUpdate.ribs[payload.index].rib =
      payload.response.iban + ' ' + payload.response.swift;
    payload.ribToUpdate.ribs[payload.index].template_id =
      payload.response.template_id;
    payload.ribToUpdate.ribs[payload.index].changeSave = true;
    payload.ribToUpdate.ribs[payload.index].newLine = false;
  },
  SET_RIB_BLOCK(state, payload) {
    payload.item.ribs[payload.index].blocked = payload.response.blocked;
  },
  SET_RIB(state, payload) {
    payload.item.ribs[payload.index].swift = payload.response.swift;
    payload.item.ribs[payload.index].iban = payload.response.iban;
    payload.item.ribs[payload.index].changeSave = true;
    payload.item.ribs[payload.index].newLine = false;
  },
  SET_TEMPLATE_RIB_ERROR(state, payload) {
    state.errorRibTemplate = payload;
  },
  SET_TEMPLATE_RIB_LOADING(state, payload) {
    state.loadingRibTemplate = payload;
  },
  SET_LOADER_TEMPLATES_FAMILLES(state, payload) {
    state.templateFamilleLoader = payload;
  },
  SET_TEMPLATES_FAMILLES(state, payload) {
    state.templateFamilleData = payload;
  }
};
const actions = {
  async getOneTemplateAfterUpdate({ commit }, payload) {
    commit('SET_TEMPLATE_ERROR', null);
    commit('SET_LOADING_ONE_TEMPLATE', true);
    const params = {
      id: payload.id
    };
    try {
      const response = await Axios.get(
        `${domain}/api/${prefixTemplates}/get_one`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_ONE_TEMPLATE', response.data.data);
      commit('SET_LOADING_ONE_TEMPLATE', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_TEMPLATE_ERROR', error.response.data.error);
      } else {
        commit('SET_TEMPLATE_ERROR', ['Une erreur est survenue']);
      }
      commit('SET_LOADING_ONE_TEMPLATE', false);

      return false;
    }
  },
  async updateTableProductsTemplate({ commit }, payload) {
    commit('SET_DYNAMIC_TABLE_ERRORS', null);
    commit('SET_LOADING_DYNAMIC_TABLE', true);
    try {
      const response = await Axios.post(
        `${domain}/api/${prefixTemplates}/template_columns`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LOADING_DYNAMIC_TABLE', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_DYNAMIC_TABLE_ERRORS', error.response.data.error);
      } else {
        commit('SET_DYNAMIC_TABLE_ERRORS', ['Une erreur est survenue']);
      }
      commit('SET_LOADING_DYNAMIC_TABLE', false);

      return false;
    }
  },
  async fetchTemplatesFields({ commit }) {
    try {
      const response = await Axios.get(
        `${domain}/api/${prefixTemplates}/get_variables`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_TEMPLATE_FIELDS', response.data.data);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_FIELD_ERROR', error.response.data.error.messages);
      } else {
        commit('SET_FIELD_ERROR', ['Une erreur est survenue']);
      }
      return false;
    }
    return true;
  },
  async newTemplate({ commit }, payload) {
    commit('SET_TEMPLATE_ERROR', null);
    commit('SET_NEW_TEMPLATE_LOADING', true);
    try {
      const response = await Axios.post(
        `${domain}/api/${prefixTemplates}/create`,

        payload.template,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      commit('ADD_NEW_TEMPLATE', response.data.data);
      commit('SET_NEW_TEMPLATE_LOADING');
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_TEMPLATE_ERROR', error.response.data.error);
      } else {
        commit('SET_TEMPLATE_ERROR', ['Une erreur est survenue']);
      }
      commit('SET_NEW_TEMPLATE_LOADING');

      return false;
    }
  },
  async newCategorieTemplate({ commit }, payload) {
    commit('SET_TEMPLATE_ERROR', null);
    commit('SET_NEW_TEMPLATE_LOADING', true);
    try {
      const response = await Axios.post(
        `${domain}/api/${prefixCategoriesTemplates}/create`,

        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      commit('ADD_NEW_CATEGORIE_TEMPLATE', response.data);
      commit('SET_NEW_TEMPLATE_LOADING');
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_TEMPLATE_ERROR', error.response.data.error);
      } else {
        commit('SET_TEMPLATE_ERROR', ['Une erreur est survenue']);
      }
      commit('SET_NEW_TEMPLATE_LOADING');

      return false;
    }
  },
  async fetchAllTemplates({ commit }) {
    commit('SET_FETCH_TEMPLATE_LOADING', true);
    try {
      const response = await Axios.get(
        `${domain}/api/${prefixCategoriesTemplates}/all`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      commit('SET_ALL_TEMPLATE', response.data.data);
      commit('SET_FETCH_TEMPLATE_LOADING');
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TEMPLATE_ERROR', error.response.data.error.messages);
      } else {
        commit('SET_TEMPLATE_ERROR', ['Une erreur est survenue']);
      }
      commit('SET_FETCH_TEMPLATE_LOADING');

      return false;
    }
    return true;
  },
  async fetchTemplateLibre({ commit }, payload) {
    commit('SET_FETCH_TEMPLATE_LOADING', true);
    try {
      const response = await Axios.get(
        `${domain}/api/${prefixTemplates}/allwithnull`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_ALL_TEMPLATE_LIBRE', {
        response: response.data.data,
        refresh: payload && payload.refresh ? true : false
      });
      commit('SET_FETCH_TEMPLATE_LOADING');
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TEMPLATE_ERROR', error.response.data.error.messages);
      } else {
        commit('SET_TEMPLATE_ERROR', ['Une erreur est survenue']);
      }
      commit('SET_FETCH_TEMPLATE_LOADING');

      return false;
    }
    return true;
  },

  async updateTemplate(
    { commit },
    { TemplateToUpdate, TemplateToUpdateOrigine }
  ) {
    commit('SET_TEMPLATE_ERROR', null);
    commit('SET_TEMPLATE_LOADING', true);
    // if (TemplateToUpdate.ribs.length == 0) {
    //   commit('SET_TEMPLATE_ERROR', [
    //     'Une erreur est survenue il faut ajouter au moin un RIB'
    //   ]);
    //   commit('SET_TEMPLATE_LOADING', false);
    //   return false;
    // }
    try {
      const response = await Axios.post(
        `${domain}/api/${prefixTemplates}/update`,
        {
          id: TemplateToUpdate.id,
          name: TemplateToUpdate.name,
          content: TemplateToUpdate.content,
          description: TemplateToUpdate.description,
          description2: TemplateToUpdate.description2,
          category_id: TemplateToUpdate.category_id,
          familles: TemplateToUpdate.familles.map(i => i.value),
          group_id: TemplateToUpdate.group_id
        },
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_TEMPLATE', {
        response: response.data.data,
        TemplateToUpdateOrigine: TemplateToUpdateOrigine
      });
      commit('SET_TEMPLATE_LOADING');
      return {
        succes: true,
        categorychanged: response.data.categorychanged
      };
    } catch (error) {
      commit('SET_TEMPLATE_LOADING');
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TEMPLATE_ERROR', error.response.data.error.messages);
      } else {
        commit('SET_TEMPLATE_ERROR', ['Une erreur est survenue']);
      }
      return {
        succes: false
      };
    }
  },
  async showTemplate({ commit }, payload) {
    try {
      const response = await Axios.get(
        domain + `/api/categories/getTemplateById/` + payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { success: true, response: response.data.data };
    } catch (error) {
      return { success: false, error: 'Une erreur est survenue' };
    }
  },
  async deleteTemplate({ commit }, payload) {
    commit('SET_TEMPLATE_LOADING', true);
    commit('SET_TEMPLATE_ERROR');
    try {
      await Axios.get(
        domain + `/api/${prefixTemplates}/delete/` + payload.template.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DELETE_TEMPLATE', payload.template);
      commit('SET_TEMPLATE_LOADING');
    } catch (error) {
      commit('SET_TEMPLATE_LOADING');
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TEMPLATE_ERROR', error.response.data.error.messages);
      } else {
        commit('SET_TEMPLATE_ERROR', ['Une erreur est survenue']);
      }
      return false;
    }
    return true;
  },

  async setLisImage({ commit }, param) {
    return new Promise((resolve, reject) => {
      Axios.post(`${domain}/api/${prefixTemplates_image}/create`, param, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`,
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(response => {
          commit('ADD_NEW_IMAGE', response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async getAllImageTemplate({ commit }) {
    try {
      const response = await Axios.get(
        `${domain}/api/${prefixTemplates_image}/all`,

        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LIST_IMAGE', response.data.data);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TEMPLATE_ERROR', error.response.data.error.messages);
      } else {
        commit('SET_TEMPLATE_ERROR', ['Une erreur est survenue']);
      }
      return false;
    }
    return true;
  },
  async updateNameCategorie({ commit }, payload) {
    commit('SET_TEMPLATE_ERROR', null);
    commit('SET_TEMPLATE_LOADING', true);
    const params = {
      id: payload.categorie.id,
      name: payload.categorie.name
    };
    try {
      const response = await Axios.put(
        `${domain}/api/${prefixCategoriesTemplates}/update`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_CATEGORIE', {
        oldCategorie: payload.oldCategorie,
        response: response.data.data
      });
      commit('SET_TEMPLATE_LOADING');
      return true;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TEMPLATE_ERROR', error.response.data.error.messages);
      } else {
        commit('SET_TEMPLATE_ERROR', ['Une erreur est survenue']);
      }
      commit('SET_TEMPLATE_LOADING');
      return false;
    }
  },
  async deleteCategorie({ commit }, payload) {
    commit('SET_TEMPLATE_LOADING', true);

    try {
      await Axios.delete(
        `${domain}/api/${prefixCategoriesTemplates}/delete/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DELETE_CATEGORIE_TEMPLATE', payload);
      commit('SET_TEMPLATE_LOADING');
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_TEMPLATE_ERROR', error.response.data.error);
      } else {
        commit('SET_TEMPLATE_ERROR', ['Une erreur est survenue']);
      }
      commit('SET_TEMPLATE_LOADING');
      return false;
    }
  },
  async addRib({ commit }, payload) {
    commit('SET_TEMPLATE_RIB_ERROR', null);

    const params = {
      template_id: payload.template_id,
      swift: payload.swift,
      iban: payload.iban
    };

    //if(payload.swift || payload.iban == null){
    //  commit('SET_TEMPLATE_RIB_ERROR', ['Iban et swift obligatoire']);
    //}
    commit('SET_TEMPLATE_RIB_LOADING', true);
    try {
      const response = await Axios.post(
        domain + `/api/RIBTemplate/add`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      commit('ADD_RIB', {
        response: response.data.data,
        ribToUpdate: payload.item,
        index: payload.index
      });
      commit('SET_TEMPLATE_RIB_LOADING', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_TEMPLATE_RIB_ERROR', error.response.data.error);
      } else {
        commit('SET_TEMPLATE_RIB_ERROR', ['Une erreur est survenue']);
      }
      commit('SET_TEMPLATE_RIB_LOADING', false);

      return false;
    }
  },

  async blockunblockRib({ commit }, payload) {
    commit('SET_TEMPLATE_RIB_ERROR', null);
    commit('SET_TEMPLATE_RIB_LOADING', true);

    const params = {
      id: payload.id,
      blocked: payload.blocked
    };

    try {
      const response = await Axios.post(
        domain + `/api/RIBTemplate/block_unblock`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      const obj = {
        response: response.data.data,
        item: payload.item,
        index: payload.index
      };
      commit('SET_RIB_BLOCK', obj);

      commit('SET_TEMPLATE_RIB_LOADING', false);

      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_TEMPLATE_RIB_ERROR', error.response.data.error);
      } else {
        commit('SET_TEMPLATE_RIB_ERROR', ['Une erreur est survenue']);
      }
      commit('SET_TEMPLATE_RIB_LOADING', false);

      return false;
    }
  },

  async updateRib({ commit }, payload) {
    commit('SET_TEMPLATE_RIB_ERROR', null);
    commit('SET_TEMPLATE_RIB_LOADING', true);

    const params = {
      id: payload.id,
      swift: payload.swift,
      iban: payload.iban
    };

    try {
      const response = await Axios.post(
        domain + `/api/RIBTemplate/edit`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      const obj = {
        response: response.data.data,
        item: payload.item,
        index: payload.index
      };
      commit('SET_RIB', obj);

      commit('SET_TEMPLATE_RIB_LOADING', false);

      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_TEMPLATE_RIB_ERROR', error.response.data.error);
      } else {
        commit('SET_TEMPLATE_RIB_ERROR', ['Une erreur est survenue']);
      }
      commit('SET_TEMPLATE_RIB_LOADING', false);

      return false;
    }
  },
  async getAllTemplateSociete({ commit }, payload) {
    commit('SET_ERROR_TEMPLATES_SOCIETES', null);

    try {
      const response = await axios.get(
        domain + `/api/filiale/templates?famille=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_TEMPLATES_SOCIETES', response.data.data);
      commit('SET_ERROR_TEMPLATES_SOCIETES', null);

      return true;
    } catch (error) {
      if (error && error.response) {
        commit('SET_ERROR_TEMPLATES_SOCIETES', error.response.data.errors);
      } else {
        commit('SET_ERROR_TEMPLATES_SOCIETES', 'Une erreur est survenue');
      }
      return false;
    }
  },
  async fetchFamilleListForTemplate({ commit }) {
    commit('SET_LOADER_TEMPLATES_FAMILLES', true);

    try {
      const response = await axios.get(
        domain + `/api/libre/factures-families`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_TEMPLATES_FAMILLES', response.data.data);
      commit('SET_LOADER_TEMPLATES_FAMILLES', false);

      return true;
    } catch (error) {
      commit('SET_LOADER_TEMPLATES_FAMILLES', false);

      return false;
    }
  },
  resetErrorTemplate({ commit }) {
    commit('SET_TEMPLATE_ERROR', null);
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
