import axios from 'axios';
import domain from '@/environment';
import groupeTemplateTh from '../../models/groupeTemplateTh';

const state = {
  groupeTemplate: [],
  groupeTemplateError: null,
  groupeTemplateDetailsLoading: false,
  totalRowGroupeTemplate: 0,
  dynamicTableErrorsGroupe: null,
  lodingDynamicTableGroupe: false
};
const getters = {
  getGroupeTemplate: state => state.groupeTemplate,
  groupeTemplateError: state => state.groupeTemplateError,
  getGroupeTemplateDetailsLoading: state => state.groupeTemplateDetailsLoading,
  getTotalRowGroupeTemplate: state => state.totalRowGroupeTemplate,
  getDynamicTableErrorsGroupe: state => state.dynamicTableErrorsGroupe,
  getLodingDynamicTableGroupe: state => state.lodingDynamicTableGroupe
};
const mutations = {
  ADD_NEW_GROUPE_TEMPLATE(state, payload) {
    state.groupeTemplate.unshift(groupeTemplateTh.create(payload));
  },
  SET_ALL_GROUPES_TEMPLATE(state, payload) {
    state.groupeTemplate = payload.map(item => groupeTemplateTh.create(item));
  },
  DELETE_GROUPE_TEMPLATE(state, payload) {
    const index = state.groupeTemplate.findIndex(
      element => element.id === payload.id
    );
    state.groupeTemplate.splice(index, 1);
  },
  UPDATE_GROUPE_TEMPLATE(state, payload) {
    payload.groupe.update(payload.response);
  },
  SET_GROUPES_TEMPLATE_ERROR(state, payload) {
    state.groupeTemplateError = payload;
  },
  SET_GROUPES_TEMPLATE_IS_LOADING(state, payload) {
    state.groupeTemplateDetailsLoading = payload;
  },
  SET_TOTAL_ROWS_GROUPE_TEMPLATE(state, payload) {
    state.totalRowGroupeTemplate = payload;
  },
  SET_DYNAMIC_TABLE_ERRORS_GROUPE(state, payload) {
    state.dynamicTableErrorsGroupe = payload;
  },
  SET_LOADING_DYNAMIC_TABLE_GROUPE(state, payload) {
    state.lodingDynamicTableGroupe = payload;
  }
};
const actions = {
  async fetchAllGroupeTemplate({ commit }, payload) {
    const params = {};
    if (payload?.page) {
      params.page = payload.page;
    }
    if (payload?.per_page) {
      params.per_page = payload.per_page;
    }
    commit('SET_GROUPES_TEMPLATE_ERROR', null);
    commit('SET_GROUPES_TEMPLATE_IS_LOADING', true);
    try {
      const response = await axios.get(`${domain}/api/template-group/all`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
      });
      commit('SET_ALL_GROUPES_TEMPLATE', response.data.data);
      // if (payload?.per_page && payload?.page) {
      //   commit('SET_TOTAL_ROWS_GROUPE_TEMPLATE', response.data.total);
      // }
      commit('SET_GROUPES_TEMPLATE_IS_LOADING', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit(
          'SET_GROUPES_TEMPLATE_ERROR',
          error?.response?.data?.errors?.nom
        );
      } else {
        commit('SET_GROUPES_TEMPLATE_ERROR', 'Une erreur est survenue');
      }
      commit('SET_GROUPES_TEMPLATE_IS_LOADING', false);
    }
  },

  async addNewGroupeTemplate({ commit }, payload) {
    commit('SET_GROUPES_TEMPLATE_ERROR', null);
    commit('SET_GROUPES_TEMPLATE_IS_LOADING', true);
    try {
      const response = await axios.post(
        `${domain}/api/template-group/create`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('ADD_NEW_GROUPE_TEMPLATE', response.data);
      return true;
    } catch (error) {
      if (error?.response?.data) {
        commit('SET_GROUPES_TEMPLATE_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_GROUPES_TEMPLATE_ERROR', 'Une erreur est survenue');
      }
      commit('SET_GROUPES_TEMPLATE_IS_LOADING', false);
    }
  },

  async deleteGroupeTemplate({ commit }, payload) {
    commit('SET_GROUPES_TEMPLATE_ERROR', null);
    commit('SET_GROUPES_TEMPLATE_IS_LOADING', true);
    try {
      const response = await axios.get(
        `${domain}/api/template-group/delete/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DELETE_GROUPE_TEMPLATE', payload);
      commit('SET_GROUPES_TEMPLATE_IS_LOADING', false);

      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_GROUPES_TEMPLATE_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_GROUPES_TEMPLATE_ERROR', 'Une erreur est survenue');
      }
      commit('SET_GROUPES_TEMPLATE_IS_LOADING', false);
    }
  },

  async updateGroupeTemplate({ commit }, payload) {
    commit('SET_GROUPES_TEMPLATE_ERROR', null);
    commit('SET_GROUPES_TEMPLATE_IS_LOADING', true);
    const params = {
      id: payload.groupe.id,
      name: payload.name
    };
    try {
      const response = await axios.post(
        `${domain}/api/template-group/update`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      commit('UPDATE_GROUPE_TEMPLATE', {
        response: response.data.data,
        groupe: payload.groupe
      });
      commit('SET_GROUPES_TEMPLATE_IS_LOADING', false);

      return true;
    } catch (error) {
      if (error?.response?.data.error) {
        commit('SET_GROUPES_TEMPLATE_ERROR', error?.response?.data.error);
      } else {
        commit('SET_GROUPES_TEMPLATE_ERROR', 'Une erreur est survenue');
      }
      commit('SET_GROUPES_TEMPLATE_IS_LOADING', false);
    }
  },
  async updateTableProductsGroupe({ commit }, payload) {
    commit('SET_DYNAMIC_TABLE_ERRORS_GROUPE', null);
    commit('SET_LOADING_DYNAMIC_TABLE_GROUPE', true);
    try {
      const response = await axios.post(
        `${domain}/api/template-group/group-columns`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LOADING_DYNAMIC_TABLE_GROUPE', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_DYNAMIC_TABLE_ERRORS_GROUPE', error.response.data.error);
      } else {
        commit('SET_DYNAMIC_TABLE_ERRORS_GROUPE', ['Une erreur est survenue']);
      }
      commit('SET_LOADING_DYNAMIC_TABLE_GROUPE', false);

      return false;
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
