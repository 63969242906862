import axios from 'axios';
import domain from '@/environment';
import RowFilialeFraisDynamic from '../../models/RowFilialeFraisDynamic';
import { indexOf } from 'lodash';
let prefixFraisDync = 'frais';
let requestFraisDyncFactureFrais = null;
let requestFraisDyncFactureFraisFilter = null;
const state = {
  errorFraisDyncFilter: null,
  listSupportFraisDync: [],
  errorFraisDyn: null,
  loadingFraisDyn: false,
  listProjectsFraisDync: [],
  loaderDataFilterListFraisDyn: null,
  listMasterFraisDync: [],
  listInstallateurFraisDync: [],
  listProjectsFraisDyncRows: null,
  //
  templateFraisDyncData: null,
  errorTemplateDataToUpploadeedFraisDync: null,
  loadingGetDataTemplateFraisDync: false,
  fraisTFraisDynamicError: null,
  fraisTFraisDynamicEoader: null,
  produitsFactureFrais: []
  // successSendMails: {},
  // errorInvalidElmail: {},
  // errorHasntEmail: {},
  // otherErrorMail: null,
  // errorHasentTemplateGlobal: {},
  // errorHasentTemplateEmailGlobal: {}
};
const getters = {
  getErorFraisDyncFilter: state => state.errorFraisDyncFilter,
  getListSupportFraisDync: state => state.listSupportFraisDync,
  getErrorFraisDyn: state => state.errorFraisDyn,
  getLoadingFraisDyn: state => state.loadingFraisDyn,
  getListProjectsFraisDync: state => state.listProjectsFraisDync,
  getLoaderDataFilterListFraisDyn: state => state.loaderDataFilterListFraisDyn,
  getListMasterFraisDync: state => state.listMasterFraisDync,
  getListInstallagteurFraisDync: state => state.listInstallateurFraisDync,
  getListProjectsFraisDyncRows: state => state.listProjectsFraisDyncRows,
  getErrorTemplateDataToUpploadeedFraisDync: state =>
    state.errorTemplateDataToUpploadeedFraisDync,
  getLoadingGetDataTemplateFraisDync: state =>
    state.loadingGetDataTemplateFraisDync,
  templateFraisDyncData: state => state.templateFraisDyncData,
  fraisTFraisDynamicError: state => state.fraisTFraisDynamicError,
  fraisTFraisDynamicEoader: state => state.fraisTFraisDynamicEoader,
  produitsFactureFrais: state => state.produitsFactureFrais
};
const mutations = {
  ERROR_GET_FILTER_FRAIS_DYN(state, payload) {
    state.errorFraisDyncFilter = payload;
  },
  SET_DATA_FILTER_DYNC_LIST(state, payload) {
    if (payload.refreshMaster) {
      state.listMasterFraisDync = payload.response.masters;
    }
    state.listSupportFraisDync = payload.response.supports;
    state.listInstallateurFraisDync = payload.response.clients;
  },
  SET_ERROR_DYNAMIC_FACTURE_FRAIS(state, payload) {
    state.errorFraisDyn = payload;
  },
  SET_LOADING_DYNAMIC_FACTURE_FRAIS(state, payload) {
    state.loadingFraisDyn = payload;
  },
  SET_DATA_DYNAMIC_FACTURE_FRAIS(state, payload) {
    state.listProjectsFraisDync = payload.map(item =>
      RowFilialeFraisDynamic.create(item)
    );
  },
  SET_DATA_DYNAMIC_FACTURE_FRAIS_ROWS(state, payload) {
    state.listProjectsFraisDyncRows = payload;
  },
  LOEDER_GET_DATA_FILTER_DYN(state, payload) {
    state.loaderDataFilterListFraisDyn = payload;
  },
  //
  SET_ERROR_TEMPLATE_DATA_TO_UPLOAD_FRAIS_DYNC(state, payload) {
    state.errorTemplateDataToUpploadeedFraisDync = payload;
  },
  SET_LOADING_GET_TEMPLATE_DATA_FRAIS_DYNC(state, payload) {
    state.loadingGetDataTemplateFraisDync = payload;
  },
  SET_TEMPLATE_MF_DATA_FRAIS_DYNC(state, payload) {
    state.templateFraisDyncData = payload;
  },
  SET_ERROR_FRAIS_TH_FRAIS_DYNC(state, payload) {
    state.fraisTFraisDynamicError = payload;
  },
  SET_LOADER_FRAIS_TH_FRAIS_DYNC(state, payload) {
    state.fraisTFraisDynamicEoader = payload;
  },
  UPDATE_FACTURE_MF_UNIQUE_FRAIS_DYNC(state, payload) {
    payload.item.update(payload.response);
  },
  INIT_TEMPLATE_FRAIS_DYNC() {
    state.templateFraisDyncData = null;
  },
  UPDATE_AVOIR_RESULT_FRAIS_DYNC_ID(commit, payload) {
    payload.data.map(item => {
      let index = payload.response.findIndex(i => i.id == item.id);
      if (index != -1) {
        {
          item.update(payload.response[index]);
        }
      }
    });
  },
  SET_FACTURE_DYNAMIC_FACTURE_FRAIS(state, payload) {
    payload.row.update(payload.response);
  },
  UPDATE_FACTURE_AVOIR_FRAIS(state, payload) {
    payload.row.updateAvoir(payload.response);
  },
  DELETE_TRANSFERT_IDS(state, payload) {
    for (let i = 0; i < payload.length; i++) {
      let parentindex = state.listProjectsFraisDync.findIndex(
        item => item.name == payload[i].indexOfFather
      );
      let childIndex = state.listProjectsFraisDync[
        parentindex
      ].projects.findIndex(item => item.id == payload[i].indexOfChild);
      state.listProjectsFraisDync[parentindex].projects.splice(childIndex, 1);
      if (state.listProjectsFraisDync[parentindex].projects.length === 0) {
        state.listProjectsFraisDync.splice(parentindex, 1);
      }
    }
  },
  SET_PRODUITS_FACTURE_FRAIS(state, payload) {
    payload = payload.map(element => {
      if (parseFloat(element.qte_apres_avoir) == 0) {
        {
          return {
            ...element,
            disabled: true,
            qte_disabled : element.unite == '%' ? false : true, 
            qte_apres: element.unite == '%' ? 0 : 1,
          };
        }
      } else {
        return {
          ...element,
          disabled: false,
          qte_disabled : element.unite == '%' ? false : true, 
          qte_apres: element.unite == '%' ? 0 : 1,
        };
      }
    });
    state.produitsFactureFrais = payload;
  }
};
const actions = {
  async getAllDynamicFactureFrais({ commit }, payload) {
    commit('SET_ERROR_DYNAMIC_FACTURE_FRAIS', null);
    commit('SET_LOADING_DYNAMIC_FACTURE_FRAIS', true);

    const params = { page: payload.page, perPage: payload.per_page };
    if (payload.date_debut != null) {
      params.date_debut = payload.date_debut;
    }
    if (payload.date_fin != null) {
      params.date_fin = payload.date_fin;
    }
    if (payload.filterFiliale.length) {
      params.clients = payload.filterFiliale.map(item => item.id);
    }
    if (payload.search != null) {
      params.search = payload.search;
    }
    if (payload.type_date != null) {
      params.type_date = payload.type_date;
    }
    if (payload.filterMasterFiliale.length) {
      params.masters = payload.filterMasterFiliale.map(item => item.id);
    }
    if (payload.filterSupport.length) {
      params.supports = payload.filterSupport.map(item => item.id);
    }
    if (payload.filterFamilly != null) {
      params.support_familly = payload.filterFamilly.id;
    }
    if (payload?.statue_installateur?.length) {
      params.statue_installateur = payload.statue_installateur.map(
        item => item.value
      );
    }
    if (payload?.statut?.length) {
      params.statut = payload.statut.map(item => item.value);
    }
    if (payload && payload.nums) {
      params.nums = payload.nums;
    }
    try {
      if (requestFraisDyncFactureFrais) {
        commit('SET_ERROR_DYNAMIC_FACTURE_FRAIS', null);
        requestFraisDyncFactureFrais.cancel('cancel-request');
      }
      requestFraisDyncFactureFrais = axios.CancelToken.source();
      const response = await axios.get(
        domain + '/api/' + prefixFraisDync + '/get_all',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params,
          cancelToken: requestFraisDyncFactureFrais.token
        }
      );
      commit('SET_DATA_DYNAMIC_FACTURE_FRAIS', response.data.data);
      commit('SET_DATA_DYNAMIC_FACTURE_FRAIS_ROWS', response.data.count);
      commit('SET_LOADING_DYNAMIC_FACTURE_FRAIS', false);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_DYNAMIC_FACTURE_FRAIS', error.response.data.error);
        commit('SET_LOADING_DYNAMIC_FACTURE_FRAIS', false);
      } else {
        if (!error.message == 'cancel-request') {
          commit('SET_ERROR_DYNAMIC_FACTURE_FRAIS', [
            'Une erreur est servenue'
          ]);
          commit('SET_LOADING_DYNAMIC_FACTURE_FRAIS', false);
        }
      }

      return false;
    }
  },
  async getAllFactureForDynamicProject({ commit }, payload) {
    payload.item.loadingDetailt = true;
    const params = { id: payload.id };
    if (payload.filterSupport.length) {
      params.supports = payload.filterSupport.map(item => item.id);
    }
    if (payload.filterFamilly != null) {
      params.support_familly = payload.filterFamilly.id;
    }
    try {
      const response = await axios.get(
        domain + '/api/frais/get_project_factures',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_FACTURE_DYNAMIC_FACTURE_FRAIS', {
        response: response.data,
        row: payload.item
      });
      payload.item.loadingDetailt = false;

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_DYNAMIC_FACTURE_FRAIS', error.response.data.error);
        payload.item.loadingDetailt = false;
      } else {
        commit('SET_ERROR_DYNAMIC_FACTURE_FRAIS', ['Une erreur est servenue']);
        payload.item.loadingDetailt = false;
      }

      return false;
    }
  },
  async getAllDataForFilterDynamicFrais({ commit }, payload) {
    commit('LOEDER_GET_DATA_FILTER_DYN', true);
    const params = {};
    if (payload.date_debut != null) {
      params.date_debut = payload.date_debut;
    }
    if (payload.date_fin != null) {
      params.date_fin = payload.date_fin;
    }
    if (payload.masters.length) {
      params.masters = payload.masters;
    }
    // if (payload.confirmation != null) {
    //   params.confirmation = payload.confirmation;
    // }
    try {
      if (requestFraisDyncFactureFraisFilter) {
        commit('ERROR_GET_FILTER_FRAIS_DYN', null);
        requestFraisDyncFactureFraisFilter.cancel('cancel-request');
      }
      const response = await axios.get(domain + '/api/frais/filter_online', {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
        // cancelToken: requestFraisDyncFactureFraisFilter.token
      });
      commit('SET_DATA_FILTER_DYNC_LIST', {
        response: response.data,
        refreshMaster: payload.refreshMaster
      });
      commit('LOEDER_GET_DATA_FILTER_DYN', false);
    } catch (error) {
      if (error?.response?.error) {
        commit('ERROR_GET_FILTER_FRAIS_DYN', error?.response?.error);
      } else {
        commit('ERROR_GET_FILTER_FRAIS_DYN', ['Une erreur est servenue']);
      }
      commit('LOEDER_GET_DATA_FILTER_DYN', false);
    }
  },
  async getListProductInFraisProject({ commit }, payload) {
    try {
      const response = await axios.get(
        domain + '/api/frais/facture_products/' + payload.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { succes: true, data: response.data.data };
    } catch (error) {
      if (error?.response?.error) {
        return { succes: false, error: error?.response?.errora };
      } else {
        return { succes: false, error: ['Une erreur est servenue'] };
      }
    }
  },
  async updateCoefFrais({ commit }, payload) {
    commit('SET_ERROR_SIMULATION_FACTURE_FRAIS', null);
    commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', true);

    const params = {
      id: payload.row.id,
      column: payload.column == 'alteo_value' ? 'alteo1_value' : payload.column,
      value: payload.value
    };
    try {
      const response = await axios.post(
        domain + `/api/frais/update_frais`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: response.data.data,
        payload: payload.row
      };
      commit('UPDATE_SIMULATION_FACTURE_FRAIS_STAT', obj);
      commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_SIMULATION_FACTURE_FRAIS', error.response.data.error);
      } else {
        commit('SET_ERROR_SIMULATION_FACTURE_FRAIS', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', false);

      return false;
    }
  },
  async getTemplateAndFiledsDataFraisDynamicToUpdate({ commit }, payload) {
    commit('SET_ERROR_TEMPLATE_DATA_TO_UPLOAD_FRAIS_DYNC', null);
    commit('SET_LOADING_GET_TEMPLATE_DATA_FRAIS_DYNC', true);
    try {
      const response = await axios.get(
        domain +
          `/api/filiale_template_generator/get_uploaded_template_for_libre`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );
      commit('SET_TEMPLATE_MF_DATA_FRAIS_DYNC', response.data.data);

      commit('SET_LOADING_GET_TEMPLATE_DATA_FRAIS_DYNC', false);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit(
          'SET_ERROR_TEMPLATE_DATA_TO_UPLOAD_FRAIS_DYNC',
          error.response.data.error
        );
      } else {
        commit('SET_ERROR_TEMPLATE_DATA_TO_UPLOAD_FRAIS_DYNC', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_GET_TEMPLATE_DATA_FRAIS_DYNC', false);

      return false;
    }
  },
  // get template frais  data
  async getTemplateAndFiledsDataFraisDynamicToUpdateChange(
    { commit },
    payload
  ) {
    try {
      const response = await axios.get(
        domain +
          `/api/filiale_template_generator/get_uploaded_template_for_libre`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );

      return { succes: true, data: response.data.data };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return { succes: false, error: error.response.data.error };
      } else {
        return { succes: false, error: 'Une erreur est servenue' };
      }
    }
  },
  // upload template
  async uploadTemplateaFraisDynamic({ commit }, payload) {
    const params = {
      description: payload.description,
      iban: payload.iban,
      rib: payload.rib,
      swift: payload.swift,
      bank: payload.bank,
      date: payload.date,
      date_reglement: payload.date_reglement,
      ids: [payload.id_facture],
      id_template: payload.id_template
    };
    if (payload.payment_condition && payload.payment_condition.id != null) {
      params.payment_condition = payload.payment_condition.id;
    }
    try {
      const response = await axios.post(
        domain + `/api/libre/confirme-master`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_FACTURE_MF_UNIQUE_FRAIS_DYNC', {
        response: response.data.data[0],
        item: payload.item
      });
      return {
        succes: true
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        return {
          succes: false,
          error: error.response.data.errors
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  //export pdf facture frais
  async exportPdfFactureaFraisDynamic({ commit }, payload) {
    commit('SET_ERROR_FRAIS_TH_FRAIS_DYNC', null);
    commit('SET_LOADER_FRAIS_TH_FRAIS_DYNC', true);
    try {
      const response = await axios.get(
        domain + `/api/filiale_template_generator/download_libre`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );

      var words = response.headers['cache-control'].split('.pdf');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', words[0] + '.pdf');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADER_FRAIS_TH_FRAIS_DYNC', false);
      return true;
    } catch (error) {
      commit('SET_ERROR_FRAIS_TH_FRAIS_DYNC', ['Une erreur est servenue']);
      commit('SET_LOADER_FRAIS_TH_FRAIS_DYNC', false);
      return false;
    }
  },
  //updateStatue
  async updateStatueaFraisDynamic({ commit }, payload) {
    commit('SET_ERROR_DYNAMIC_FACTURE_FRAIS', null);
    commit('SET_LOADING_DYNAMIC_FACTURE_FRAIS', true);
    const params = {
      ids: [payload.row.id_to_use],
      etat: payload.value
    };
    try {
      const response = await axios.post(
        domain + `/api/libre/update-statut-facture`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_FACTURE_MF_UNIQUE_FRAIS_DYNC', {
        response: response.data.data[0],
        item: payload.row.item
      });
      commit('SET_LOADING_DYNAMIC_FACTURE_FRAIS', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        commit('SET_ERROR_DYNAMIC_FACTURE_FRAIS', error.response.data.errors);
      } else {
        commit('SET_ERROR_DYNAMIC_FACTURE_FRAIS', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_DYNAMIC_FACTURE_FRAIS', false);

      return false;
    }
  },
  //update statue installateur
  async updateStatueInstallateuraFraisDynamic({ commit }, payload) {
    commit('SET_ERROR_DYNAMIC_FACTURE_FRAIS', null);
    commit('SET_LOADING_DYNAMIC_FACTURE_FRAIS', true);
    const params = {
      ids: [payload.row.id_to_use],
      etat: payload.value
    };
    try {
      const response = await axios.post(
        domain + `/api/libre/update-statut-installateur`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      commit('UPDATE_FACTURE_MF_UNIQUE_FRAIS_DYNC', {
        response: response.data.data[0],
        item: payload.row.item
      });
      commit('SET_LOADING_DYNAMIC_FACTURE_FRAIS', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        commit('SET_ERROR_DYNAMIC_FACTURE_FRAIS', error.response.data.errors);
      } else {
        commit('SET_ERROR_DYNAMIC_FACTURE_FRAIS', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_DYNAMIC_FACTURE_FRAIS', false);

      return false;
    }
  },
  //updateStatue
  async updateStatueaFraisDynamicGlobale({ commit }, payload) {
    payload.ids = payload.ids_facture.map(({ id }) => id);

    commit('SET_ERROR_DYNAMIC_FACTURE_FRAIS', null);
    commit('SET_LOADING_DYNAMIC_FACTURE_FRAIS', true);
    try {
      const response = await axios.post(
        domain + `/api/libre/update-statut-facture`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_AVOIR_RESULT_FRAIS_DYNC_ID', {
        data: payload.ids_facture,
        response: response.data.data,
        champ: 'statue'
      });
      commit('SET_LOADING_DYNAMIC_FACTURE_FRAIS', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        commit('SET_ERROR_DYNAMIC_FACTURE_FRAIS', error.response.data.errors);
      } else {
        commit('SET_ERROR_DYNAMIC_FACTURE_FRAIS', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_DYNAMIC_FACTURE_FRAIS', false);

      return false;
    }
  },
  //update statue installateur
  async updateStatueInstallateuraFraisDynamicGlobale({ commit }, payload) {
    payload.ids = payload.ids_facture.map(({ id }) => id);
    commit('SET_ERROR_DYNAMIC_FACTURE_FRAIS', null);
    commit('SET_LOADING_DYNAMIC_FACTURE_FRAIS', true);
    try {
      const response = await axios.post(
        domain + `/api/libre/update-statut-installateur`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      commit('UPDATE_AVOIR_RESULT_FRAIS_DYNC_ID', {
        data: payload.ids_facture,
        response: response.data.data,
        champ: 'statue'
      });
      commit('SET_LOADING_DYNAMIC_FACTURE_FRAIS', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        commit('SET_ERROR_DYNAMIC_FACTURE_FRAIS', error.response.data.errors);
      } else {
        commit('SET_ERROR_DYNAMIC_FACTURE_FRAIS', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_DYNAMIC_FACTURE_FRAIS', false);

      return false;
    }
  },
  //type de paiement
  async updattypePaiementFraisDynamic({ commit }, payload) {
    commit('SET_ERROR_DYNAMIC_FACTURE_FRAIS', null);
    commit('SET_LOADING_DYNAMIC_FACTURE_FRAIS', true);
    const params = {
      id: payload.idFacture,
      column: 'mode_reglement',
      value: payload.value
    };
    try {
      const response = await axios.post(
        domain + `/api/libre/update-facture-column`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      payload.row['type_paiement'] = payload.value;
      if (payload.value == 'LCR Lettre de change Relevé') {
        payload.row['lcr'] = 0;
      }

      commit('SET_LOADING_DYNAMIC_FACTURE_FRAIS', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        commit('SET_ERROR_DYNAMIC_FACTURE_FRAIS', error.response.data.errors);
      } else {
        commit('SET_ERROR_DYNAMIC_FACTURE_FRAIS', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_DYNAMIC_FACTURE_FRAIS', false);

      return false;
    }
  },
  async avoirFraisDynamicTh({ commit }, payload) {
    commit('SET_LOADING_DYNAMIC_FACTURE_FRAIS', true);
    try {
      const response = await axios.post(
        domain + `/api/libre/generate_avoir`,
        payload.ids,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_AVOIR_RESULT_FRAIS_DYNC_ID', {
        response: response.data.data,
        data: payload.data,
        champ: 'statue'
      });
      commit('SET_LOADING_DYNAMIC_FACTURE_FRAIS', false);
      return { succes: true };
    } catch (error) {
      commit('SET_LOADING_DYNAMIC_FACTURE_FRAIS', false);
      return false;
    }
  },
  async transfertFacturesToLibre({ commit }, payload) {
    commit('SET_LOADING_DYNAMIC_FACTURE_FRAIS', true);
    try {
      const response = await axios.post(
        domain + `/api/libre/transfer-to-libre`,
        payload.ids,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      commit('DELETE_TRANSFERT_IDS', payload.indexsToRemove);

      commit('SET_LOADING_DYNAMIC_FACTURE_FRAIS', false);
      return { succes: true };
    } catch (error) {
      commit('SET_LOADING_DYNAMIC_FACTURE_FRAIS', false);
      return false;
    }
  },
  // upload multi factures dynamic
  async confirmedFactureFraisDynamicMulti({ commit }, payload) {
    payload.map(item => item.id_facture);
    var ids = new FormData();
    for (let i = 0; i < payload.length; i++) {
      ids.append('ids[' + [i] + ']', payload[i].id);
    }
    try {
      const response = await axios.post(
        domain + `/api/libre/confirme-multi-master`,
        ids,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_AVOIR_RESULT_FRAIS_DYNC_ID', {
        response: response.data.data,
        data: payload
      });
      return {
        succes: true
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async createAvoirFrais({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/frais/create_avoir_partiel`,
        payload.bodyFormData,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_FACTURE_AVOIR_FRAIS', {
        response: response.data.data,
        row: payload.item
      });
      return {
        success: true
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          success: false,
          error: error.response.data.error
        };
      } else {
        return {
          success: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async getProductsFraisFacture({ commit }, payload) {
    try {
      const response = await axios.get(
        domain + `/api/frais/productsFactureFrais/${payload}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_PRODUITS_FACTURE_FRAIS', response.data.data);
      return { success: true };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return { succes: false, error: error.response.data.error };
      } else {
        return { succes: false, error: 'Une erreur est servenue' };
      }
    }
  },
  async deleteProformaFraisAction({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/libre/delete_facture_proforma`,
        payload.ids,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { succes: true };
    } catch (error) {
      return { succes: false };
    }
  },
  setDaraTemplateUplodedFraisDynamic({ commit }) {
    commit('INIT_TEMPLATE_FRAIS_DYNC');
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
