import axios from 'axios';
import domain from '@/environment';
import factureLibreTh from '../../models/factureLibreTh';
import FactureLibreVueClient from '../../models/ModelFactureLibreVueClient';
let requestLibre = null;

const state = {
  facturesLibre: [],
  factureLibreLoading: false,
  factureLibreErrores: null,
  factureLibreTotalRows: 0,
  PaysList: [],
  listProduitForFiltre: [],
  factureLibreToUpdate: null,
  listFactureForAvoir: [],
  filialeOfResponsable: [],
  allUnites: [],
  allTvas: [],
  allCategorie: [],
  loadingMailFactureLibre: false,
  errorMailingFactureLibre: [],
  successSendMailsFactureLibre: {},
  otherErrorMailFacturelibre: null,
  addFactureLibre: null,
  errorAddFactureLibre: [],
  errorUpdateFactureLibre: [],
  errorCreationFactureLibre: null,
  TransformFacture: []
};
const getters = {
  errorUpdateFactureLibre: state => state.errorUpdateFactureLibre,
  errorAddFactureLibre: state => state.errorAddFactureLibre,
  addFactureLibre: state => state.addFactureLibre,
  getFacturesLibresTh: state => state.facturesLibre,
  getFactureLibreLoadingTh: state => state.factureLibreLoading,
  getFactureLibreErroresTh: state => state.factureLibreErrores,
  getFactureLibreTotalRowsTh: state => state.factureLibreTotalRows,
  getPaysList: state => state.PaysList,
  getFactureLibreToUpdate: state => state.factureLibreToUpdate,
  getListFactureForAvoir: state => state.listFactureForAvoir,
  getFiliaeOfResponsable: state => state.filialeOfResponsable,
  getAllUnites: state => state.allUnites,
  getAllTvas: state => state.allTvas,
  getAllCategories: state => state.allCategorie,
  getLoadingMailFactureLibre: state => state.loadingMailFactureLibre,
  getErrorMailingFactureLibre: state => state.errorMailingFactureLibre,
  getSuccessSendMailsFactureLibre: state => state.successSendMailsFactureLibre,
  getOtherErrorMailFactureLibre: state => state.otherErrorMailFacturelibre,
  getErrorCreationFactureLibre: state => state.errorCreationFactureLibre,
  getListProduitForFiltre: state => state.listProduitForFiltre,
  getListTransformFacture: state => state.TransformFacture
};
const mutations = {
  INIT_FCATURE_LIBRE(state, payload) {
    state.facturesLibre = payload;
  },
  ERROR_UPDATE_FACTURE_LIBRE(state, payload) {
    state.errorUpdateFactureLibre = payload;
  },
  SET_ADD_FACTURE_LIBRE_ERROR(state, payload) {
    state.errorAddFactureLibre = payload;
  },
  ADD_NEW_FACTURE_LIBRE(state, payload) {
    state.addFactureLibre = payload;
  },
  SET_FACTURE_LIBRE_ERROR(state, payloade) {
    state.factureLibreErrores = payloade;
  },
  SET_FACTURE_LIBRE_IS_LOADING(state, payload) {
    state.factureLibreLoading = payload;
  },
  SET_ALL_FACTURE_LIBRES(state, payload) {
    if (payload.ordreBy === true) {
      state.facturesLibre = payload?.response?.map(item =>
        FactureLibreVueClient.create(item)
      );
    } else {
      state.facturesLibre = payload?.response?.data?.map(item =>
        factureLibreTh.create(item)
      );
    }
  },
  SET_TOTAL_ROWS_FACTURE_LIBRES(state, payload) {
    if (payload.ordreBy === true) {
      state.factureLibreTotalRows = payload.response.count;
    } else {
      state.factureLibreTotalRows = payload.response.data.count;
    }
  },
  SET_ALL_CONTRIES(state, payload) {
    state.PaysList = payload;
  },
  UPDATE_STATUS_FACTURE_LIBRE(satet, payload) {
    payload.payload.updateStatue(payload.response);
  },
  // SET_FACTURE_CONFIRMED_STATUE(satet, payload) {
  //   payload.map(item => item.updateStatueConfirmation());
  // },
  SET_ONE_FACTURE_LIBRE(state, payload) {
    state.factureLibreToUpdate = payload;
  },
  SET_ALL_FACTURES_AVOIR(state, payload) {
    state.listFactureForAvoir = payload;
  },
  SET_FILIALS_OF_RESPONSABLE(state, payload) {
    let jsonObject = payload
      .filter(i => i.type != 'particulier')
      .map(JSON.stringify);
    let uniqueSet = new Set(jsonObject);
    let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
    uniqueArray.sort((a, b) => a.name.localeCompare(b.name));
    state.filialeOfResponsable = uniqueArray;
  },
  SET_ALL_UNITES(state, payload) {
    state.allUnites = payload;
  },
  SET_ALL_TVA(state, payload) {
    state.allTvas = payload;
  },
  SET_ALL_CATEGORIES_FACTURE_LIBRE(state, payload) {
    state.allCategorie = payload;
  },
  SET_MAIL_LODING_FACTURE_LIBRE(state, payload) {
    state.loadingMailFactureLibre = payload;
  },
  SET_ERROR_MAILING_FACTURE_LIBRE(state, payload) {
    state.errorMailingFactureLibre = payload;
  },
  SET_SUCCESS_SEND_MAILS_FACTURE_LIBRE(state, payload) {
    state.successSendMailsFactureLibre = payload;
  },
  SET_OTHER_ERROR_FACTURE_LIBRE(state, payload) {
    state.otherErrorMailFacturelibre = payload;
  },
  SET_ERROR_CREATION_FACTURE_LIBRE(state, payload) {
    state.errorCreationFactureLibre = payload;
  },
  SET_ALL_PRODUCT_NAME(state, payload) {
    state.listProduitForFiltre = payload;
  },
  UPDATE_STATUE_CLIENT(state, payload) {
    payload.payload.updateStatueClient(payload.response);
  },
  UPDATE_FILES_FACTURE(state, payload) {
    payload.oldFacture.updateFilesFactures(payload.response);
  },
  ADD_NEW_FILES_FACTURES(state, payload) {
    if (payload.vue == false) {
      state.facturesLibre.map(item => {
        if (item.id == payload.facture_id) {
          item.addFilesFacture(payload.response);
        }
      });
    } else {
      state.facturesLibre.map(facture => {
        if (facture.factures.length != 0) {
          facture.factures.map(item => {
            if (item.id == payload.facture_id) {
              item.addFilesFacture(payload.response);
            }
          });
        }
      });
    }
  },
  SET_DATA_FOR_ONE_ROW(state, payload) {
    payload.row.update(payload.response);
  },
  SET_TRANSFORM_FACTURE_LIBRE(state, payload) {
    state.TransformFacture = payload;
  }
};
const actions = {
  initFactureLibreState({ commit }, payload) {
    commit('INIT_FCATURE_LIBRE', []);
  },
  async getAllErrorsCreationFactureLibre({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    try {
      const response = await axios.post(
        `${domain}/api/libre/treat_errors`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { success: true };
    } catch (error) {
      return { success: false, error: error.response.data.error };
    }
  },
  async addNewFactureLibreTh({ commit }, payload) {
    commit('SET_ADD_FACTURE_LIBRE_ERROR', null);

    try {
      const response = await axios.post(
        `${domain}/api/libre/facture`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('ADD_NEW_FACTURE_LIBRE', response.data.data);
      commit('SET_ADD_FACTURE_LIBRE_ERROR', null);
      return { success: true, response: response.data.data.id };
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        commit('SET_ADD_FACTURE_LIBRE_ERROR', data);
      } else {
        commit('SET_ADD_FACTURE_LIBRE_ERROR', 'Une erreur est survenue');
      }
      return false;
    }
  },
  async uploadFileLibre({ commit }, payload) {
    try {
      const response = await axios.post(
        `${domain}/api/libre/facture/attached-files`,
        payload.bodyFormData,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('ADD_NEW_FILES_FACTURES', {
        response: response.data.data,
        facture_id: payload.facture_id,
        vue: payload.vue
      });
      commit('SET_ADD_FACTURE_LIBRE_ERROR', null);
      return { success: true };
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        commit('SET_ADD_FACTURE_LIBRE_ERROR', data);
      } else {
        commit('SET_ADD_FACTURE_LIBRE_ERROR', 'Une erreur est survenue');
      }
      return { success: false };
    }
  },
  async updateFileLibre({ commit }, payload) {
    try {
      const response = await axios.post(
        `${domain}/api/libre/facture/attached-files/update`,
        payload.bodyFormData,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_FILES_FACTURE', {
        response: response.data.data,
        oldFacture: payload.facture
      });
      commit('SET_ADD_FACTURE_LIBRE_ERROR', null);
      return { success: true, response: response.data.data };
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        commit('SET_ADD_FACTURE_LIBRE_ERROR', data);
      } else {
        commit('SET_ADD_FACTURE_LIBRE_ERROR', 'Une erreur est survenue');
      }
      return false;
    }
  },
  async deleteAttachementLibre({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + '/api/libre/facture/remove-attached-file/' + payload,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return true;
    } catch (error) {
      return false;
    }
  },
  async displayFacture({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    try {
      const response = await axios.post(
        `${domain}/api/libre/generate`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { success: true, response: response.data.data };
    } catch (error) {
      if (error?.response?.data?.errors) {
        return { success: false, error: data };
      } else {
        return { success: false, error: 'Une erreur est survenue' };
      }
    }
  },
  async displayFactureTemplate({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    try {
      const response = await axios.post(
        `${domain}/api/libre/generate_template`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          responseType: 'arraybuffer'
        }
      );
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const objectUrl = URL.createObjectURL(blob);
      return { success: true, response: objectUrl };
    } catch (error) {
      if (error?.response?.data?.errors) {
        return { success: false, error: data };
      } else {
        return { success: false, error: 'Une erreur est survenue' };
      }
    }
  },
  async displayFact({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    try {
      const response = await axios.get(
        `${domain}/api/libre/generate_facture?id=` + payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          responseType: 'arraybuffer'
        }
      );

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const objectUrl = URL.createObjectURL(blob);
      return { success: true, response: objectUrl };
    } catch (error) {
      if (error?.response?.data?.errors) {
        return { success: false, error: data };
      } else {
        return { success: false, error: 'Une erreur est survenue' };
      }
    }
  },

  async fetchAllFacturesLibres({ commit }, payload) {
    var params = { page: payload.page, per_page: payload.per_page };
    if (payload && payload.nums) {
      params.nums = payload.nums;
    }
    if (payload && payload.num != null) {
      params.num = payload.num;
    }
    for (let i = 0; i < payload?.type?.length; i++) {
      params['type[' + i + ']'] = payload?.type[i]?.value;
    }
    for (let i = 0; i < payload?.etat?.length; i++) {
      params['etat[' + i + ']'] = payload.etat[i].value;
    }
    for (let i = 0; i < payload?.mode_reglement?.length; i++) {
      params['mode_reglement[' + i + ']'] = payload?.mode_reglement[i]?.value;
    }
    for (let i = 0; i < payload?.vendeur?.length; i++) {
      params['vendeur_id[' + i + ']'] = payload?.vendeur[i]?.value;
    }
    for (let i = 0; i < payload?.categorie_id?.length; i++) {
      params['categorie_id[' + i + ']'] = payload?.categorie_id[i]?.value;
    }
    for (let i = 0; i < payload?.acheteur_id?.length; i++) {
      params['acheteur_id[' + i + ']'] = payload?.acheteur_id[i]?.value;
    }
    for (let i = 0; i < payload?.produit?.length; i++) {
      params['nom_produit[' + i + ']'] = payload?.produit[i]?.value;
    }
    if (payload.groupedBySociete === true) {
      params.groupedBySociete = payload.groupedBySociete;
    }
    if (payload.date_debut != null) {
      params.date_debut = payload.date_debut;
    }
    if (payload.date_fin != null) {
      params.date_fin = payload.date_fin;
    }
    if (payload.column != null) {
      params.column = payload.column;
    }
    if (payload.order != null) {
      params.order = payload.order;
    }
    if (payload.objet != null) {
      params.objet = payload.objet;
    }
    if (payload.famille != null) {
      params.famille = payload.famille;
    }
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.get(`${domain}/api/libre/factures`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
      });
      commit('SET_ALL_FACTURE_LIBRES', {
        ordreBy: payload.groupedBySociete,
        response: response.data.data
      });
      commit('SET_TOTAL_ROWS_FACTURE_LIBRES', {
        ordreBy: payload.groupedBySociete,
        response: response.data
      });
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_FACTURE_LIBRE_ERROR', 'Une erreur est survenue');
      }
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return false;
    }
  },
  async fetshDataDetailsFacturs({ commit }, payload) {
    payload.item.loadingDetailt = true;
    var params = { page: payload.page, per_page: payload.per_page };
    if (payload.num != null) {
      params.num = payload.num;
    }
    for (let i = 0; i < payload?.type?.length; i++) {
      params['type[' + i + ']'] = payload?.type[i]?.value;
    }
    for (let i = 0; i < payload?.etat?.length; i++) {
      params['etat[' + i + ']'] = payload.etat[i].value;
    }
    for (let i = 0; i < payload?.mode_reglement?.length; i++) {
      params['mode_reglement[' + i + ']'] = payload?.mode_reglement[i]?.value;
    }
    for (let i = 0; i < payload?.vendeur?.length; i++) {
      params['vendeur_id[' + i + ']'] = payload?.vendeur[i]?.value;
    }
    for (let i = 0; i < payload?.categorie_id?.length; i++) {
      params['categorie_id[' + i + ']'] = payload?.categorie_id[i]?.value;
    }
    // for (let i = 0; i < payload?.acheteur_id?.length; i++) {
    params['acheteur_id[' + 0 + ']'] = payload?.item?.id;
    // }
    for (let i = 0; i < payload?.produit?.length; i++) {
      params['nom_produit[' + i + ']'] = payload?.produit[i]?.value;
    }

    // if (payload.item != null) {
    //   params.item = payload.item;
    // }
    if (payload.date_debut != null) {
      params.date_debut = payload.date_debut;
    }
    if (payload.date_fin != null) {
      params.date_fin = payload.date_fin;
    }
    if (payload.column != null) {
      params.column = payload.column;
    }
    if (payload.order != null) {
      params.order = payload.order;
    }
    if (payload.objet != null) {
      params.objet = payload.objet;
    }
    if (payload.famille != null) {
      params.famille = payload.famille;
    }
    commit('SET_FACTURE_LIBRE_ERROR', null);
    try {
      const response = await axios.get(`${domain}/api/libre/factures`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
      });
      commit('SET_DATA_FOR_ONE_ROW', {
        response: response.data.data.data,
        row: payload.item
      });
      payload.item.loadingDetailt = false;
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_FACTURE_LIBRE_ERROR', ['Une erreur est servenue']);
      }
      payload.item.loadingDetailt = false;
      return false;
    }
  },
  async getFactureLibreNumber({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.get(
        `${domain}/api/libre/numero?id=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { success: true, response: response.data };
    } catch (error) {
      if (error?.response?.data?.errors) {
        commit('SET_FACTURE_LIBRE_ERROR', error?.response?.data?.errors);
      } else {
        commit('SET_FACTURE_LIBRE_ERROR', 'Une erreur est survenue');
      }
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return { success: false };
    }
  },
  async getAllCountreies({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.get(`${domain}/api/libre/pays`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_ALL_CONTRIES', response.data);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, error: error?.response?.data?.error };
      } else {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, error: 'Une erreur est survenue' };
      }
    }
  },
  async updatePaymentDate({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.put(`${domain}/api/libre/etat`, payload, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('UPDATE_STATUS_FACTURE_LIBRE', {
        payload: payload,
        response: response.data.data
      });
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);

      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_FACTURE_LIBRE_ERROR', 'Une erreur est survenue');
      }
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return { success: false };
    }
  },
  async updateClientStatuPayment({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.post(
        `${domain}/api/libre/statut_client`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_STATUE_CLIENT', {
        payload: payload,
        response: response.data.data
      });
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);

      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_FACTURE_LIBRE_ERROR', 'Une erreur est survenue');
      }
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return { success: false };
    }
  },
  async downloadPDF({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.get(
        domain + `/api/filiale_template_generator/download_libre`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );
      var words = response.headers['cache-control'].split('.pdf');
      var FacRef = words[0].split(' ');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', FacRef[FacRef.length - 1] + '.pdf');
      document.body.appendChild(link);
      link.click();
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_FACTURE_LIBRE_ERROR', 'Une erreur est survenue');
      }
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return false;
    }
  },
  async downloadZIP({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.post(
        `${domain}/api/libre/downloadzip`,
        payload,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'Facture CRM CDG.zip');
      document.body.appendChild(link);
      link.click();
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_FACTURE_LIBRE_ERROR', 'Une erreur est survenue');
      }
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return false;
    }
  },
  async confirmedBills({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.post(
        `${domain}/api/libre/confirme`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_FACTURE_LIBRE_ERROR', 'Une erreur est survenue');
      }
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return false;
    }
  },
  async transformProformatToFacture({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.post(
        `${domain}/api/libre/transform_proforma`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_TRANSFORM_FACTURE_LIBRE', response.data.created);
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_FACTURE_LIBRE_ERROR', 'Une erreur est survenue');
      }
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return false;
    }
  },
  async downloadOnePDF({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.post(
        `${domain}/api/libre/downloadpdf?id=${payload.id}`,
        {},
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', `${payload.num}.pdf`);
      document.body.appendChild(link);
      link.click();
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_FACTURE_LIBRE_ERROR', 'Une erreur est survenue');
      }
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return false;
    }
  },
  async getOneFacturesLibres({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.get(
        `${domain}/api/libre/facture/${payload}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_ONE_FACTURE_LIBRE', response.data.data);
      return { success: true, response: response.data.data };
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, error: error?.response?.data?.error };
      } else {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, error: error?.response?.data?.error };
      }
    }
  },
  async updateFactureLibre({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.put(
        `${domain}/api/libre/facture`,
        payload.factureLibre,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_ONE_FACTURE_LIBRE', {
        response: response.data.data,
        factureLibreOrigin: payload.factureLibreOrigin
      });
      return { success: true, response: response.data.data };
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        commit('ERROR_UPDATE_FACTURE_LIBRE', data);
        return false;
      } else {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        commit('ERROR_UPDATE_FACTURE_LIBRE', 'Une erreur est survenue');
      }
      return false;
    }
  },

  async getFactureFilterForAvoir({ commit }, payload) {
    try {
      if (requestLibre) {
        requestLibre.cancel('cancel-request');
      }
      requestLibre = axios.CancelToken.source();
      const response = await axios.get(
        `${domain}/api/libre/getFactureFilterAvoir`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload,
          cancelToken: requestLibre.token
        }
      );
      return { success: true, response: response.data.data };
    } catch (error) {
      if (error?.response?.data?.error) {
        return { success: false, response: error?.response?.data?.error };
      } else {
        return { success: false, response: error?.response?.data?.error };
      }
    }
  },
  async getFilialsOfConnectedResponsable({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    const params = {};
    if (payload?.minimum) {
      params.minimum = payload.minimum;
    }
    try {
      const response = await axios.get(`${domain}/api/libre/vendeur`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
      });
      commit('SET_FILIALS_OF_RESPONSABLE', response.data.data);
      return { success: true };
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, response: error?.response?.data?.error };
      } else {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, response: error?.response?.data?.error };
      }
    }
  },
  async addUniteTva({ commit }, payload) {
    try {
      const response = await axios.post(
        `${domain}/api/libre/unite_tva`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { success: true, response: response.data };
    } catch (error) {
      if (error?.response?.data?.error) {
        return { success: false, response: error?.response?.data?.error };
      } else {
        return { success: false, response: error?.response?.data?.error };
      }
    }
  },
  async addCategorie({ commit }, payload) {
    try {
      const response = await axios.post(
        `${domain}/api/libre/categorie/create`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { success: true, response: response.data };
    } catch (error) {
      if (error?.response?.data?.error) {
        return {
          success: false,
          error: error?.response?.data?.error
        };
      } else {
        return { success: false, error: 'Une erreur est survenue' };
      }
    }
  },
  async getUnites({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.get(`${domain}/api/libre/unite`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_ALL_UNITES', response.data);
      return { success: true };
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, response: error?.response?.data?.error };
      } else {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, response: error?.response?.data?.error };
      }
    }
  },
  async getTvas({ commit }) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.get(`${domain}/api/libre/tva`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_ALL_TVA', response.data);
      return { success: true };
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, response: error?.response?.data?.error };
      } else {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, response: error?.response?.data?.error };
      }
    }
  },
  async getCategoriesFactureLibre({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.get(`${domain}/api/libre/categorie_all`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_ALL_CATEGORIES_FACTURE_LIBRE', response.data);
      return { success: true };
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, response: error?.response?.data?.error };
      } else {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, response: error?.response?.data?.error };
      }
    }
  },
  async CreateBillAvoirGlobal({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.post(
        `${domain}/api/libre/avoir_globale`,
        { id: payload.id, date: payload.date },
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      // commit('SET_ALL_TVA', response.data);
      return { success: true };
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, response: error?.response?.data?.error };
      } else {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, response: error?.response?.data?.error };
      }
    }
  },
  async exportExcelFactureLibre({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.post(domain + `/api/libre/export`, payload, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'Facture Libre' + '.xlsx');
      document.body.appendChild(link);
      link.click();
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return true;
    } catch (error) {
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return false;
    }
  },
  async exportFactureLibre({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/libre/exportFactureLibre`,
        payload,
        {
          // responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      // var words = response.headers['cache-control'].split('.xlsx');
      // const link = document.createElement('a');
      // link.href = window.URL.createObjectURL(new Blob([response.data]));
      // link.setAttribute('download', words[0] + '.xlsx');
      // document.body.appendChild(link);
      // link.click();
      this.state.showNotifExportFile = true;
      return { succes: true, msg: response.data.data };
    } catch (error) {
      return { succes: false };
    }
  },
  async sendMailFactureLibre({ commit }, payload) {
    commit('SET_MAIL_LODING_FACTURE_LIBRE', true);
    try {
      const response = await axios.post(
        domain + `/api/libre/envoiMails`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_MAIL_LODING_FACTURE_LIBRE', false);
      commit('SET_ERROR_MAILING_FACTURE_LIBRE', response.data.error);
      commit('SET_SUCCESS_SEND_MAILS_FACTURE_LIBRE', response.data.success);
      return { succes: true };
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_OTHER_ERROR_FACTURE_LIBRE', error?.response?.data?.error);
      } else {
        commit('SET_OTHER_ERROR_FACTURE_LIBRE', 'Une erreur est servenue');
      }
      commit('SET_MAIL_LODING_FACTURE_LIBRE', false);
      return { succes: false };
    }
  },
  async getAllProductsForfiltre({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.get(`${domain}/api/libre/nom_produits`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_ALL_PRODUCT_NAME', response.data);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, error: error?.response?.data?.error };
      } else {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, error: 'Une erreur est survenue' };
      }
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
