import axios from 'axios';
import domain from '@/environment';
import AppelObligeTh from '../../models/AppelObligeeTh';
import { forEach } from 'lodash';
let requestgetPaiementObligeeThFiltred = null;
let requestgetObligeeTemplate = null;
let requestrefreshPaiementObligee = null;
let requestrefreshPaiementObligeeFilter = null;

const state = {
  chargementAppelPaiementThObligee: false,
  appeliPaiementThTableObligee: [],
  errorAppeliPaiementUpdateThObligee: null,
  errorAppeliPaiementThObligee: null,
  totalAppelPaiementObligee: null,
  templateObligeeToUpload: null,
  chargementAppelPaiementThObligeeFacture: false,
  errorAppeliPaiementThObligeeFacture: null,
  totalRowsThObligee: null,
  ListOrganismeObligee: [],
  ListInstallateurbligee: [],
  ListLotsOligee: [],
  loadingDataFilterObligeeThProjects: false,
  mailLodingVertigo: false,
  successMailsSent: [],
  errorMailsSent: [],
  otherErrorsMail: [],
  errorObligeXls: null,
  errorAppeliPaiementThObligeeExportXls: null,
  chargementAppelPaiementThObligeeExportXlsFacture: false,
  missingOrganismeInObligeeFile: [],
  responsevalidationGlobal: null,
  loaderDelteProjectVertigo: false,
  errorDelteProjectVertigo: null,
  typeListFactureObligee: [],
  listProjectForSpecificDate: []
};
const getters = {
  getChargementAppelPaiementThObligee: state =>
    state.chargementAppelPaiementThObligee,
  getAppeliPaiementThTableObligee: state => state.appeliPaiementThTableObligee,
  getErrorAppeliPaiementThObligee: state => state.errorAppeliPaiementThObligee,
  getErrorAppeliPaiementUpdateThObligee: state =>
    state.errorAppeliPaiementUpdateThObligee,
  getTotalAppelPaiementObligee: state => state.totalAppelPaiementObligee,
  getTemplateObligeeToUpload: state => state.templateObligeeToUpload,
  getChargementAppelPaiementThObligeeFacture: state =>
    state.chargementAppelPaiementThObligeeFacture,
  getErrorAppeliPaiementThObligeeFacture: state =>
    state.errorAppeliPaiementThObligeeFacture,
  geTtotalRowsThObligee: state => state.totalRowsThObligee,
  getListOrganismeObligee: state => state.ListOrganismeObligee,
  getListInstallateurbligee: state => state.ListInstallateurbligee,
  getListLotsOligee: state => state.ListLotsOligee,
  getLoadingDataFilterObligeeThProjects: state =>
    state.loadingDataFilterObligeeThProjects,
  getMailLoadingVertigo: state => state.mailLodingVertigo,
  getSuccessMailsSent: state => state.successMailsSent,
  getErrorMailsSent: state => state.errorMailsSent,
  getOtherErrorsMail: state => state.otherErrorsMail,
  geterrorObligeXls: state => state.errorObligeXls,
  errorAppeliPaiementThObligeeExportXls: state =>
    state.errorAppeliPaiementThObligeeExportXls,
  chargementAppelPaiementThObligeeExportXlsFacture: state =>
    state.chargementAppelPaiementThObligeeExportXlsFacture,
  missingOrganismeInObligeeFile: state => state.missingOrganismeInObligeeFile,
  getResponsevalidationGlobal: state => state.responsevalidationGlobal,
  getLoaderDelteProjectVertigo: state => state.loaderDelteProjectVertigo,
  getErrorDelteProjectVertigo: state => state.errorDelteProjectVertigo,
  getTypeListFactureObligee: state => state.typeListFactureObligee,
  getListProjectForSpecificDate: state => state.listProjectForSpecificDate
};
const mutations = {
  SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE(state, payload) {
    state.chargementAppelPaiementThObligee = payload;
  },
  APPEL_PAIEMENT_TH_FILTRED_OBLIGEE(state, payload) {
    state.appeliPaiementThTableObligee = payload.map(item =>
      AppelObligeTh.create(item)
    );
  },
  APPEL_PAIEMENT_TH_FILTRED_OBLIGEE_MISSING(state, payload) {
    state.missingOrganismeInObligeeFile = payload;
  },
  SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH(state, payload) {
    state.errorAppeliPaiementThObligee = payload;
  },
  SET_ERROR_APPEL_PAIEMENT_OBLIGEE_UPDATE_TH(state, payload) {
    state.errorAppeliPaiementUpdateThObligee = payload;
  },
  SET_TOTAL_ROW_APPEL_PAIEMENT_TH(state, payload) {
    state.totalAppelPaiementObligee = payload;
  },
  SET_TEMPLATE_OBLIGEE_TO_UPLOAD(state, payload) {
    state.templateObligeeToUpload = payload;
    state.templateObligeeToUpload.products = payload?.products?.map(item => {
      return { ...item, checked: true };
    });
  },
  SET_TEMPLATE_OBLIGEE_TO_UPLOAD_PDF(state, payload) {
    state.templateObligeeToUpload.template = payload.template;
  },
  SET_DATA_FOR_FACTURE_OBLIGEE_UPLOADED(state, payload) {
    payload.item.update(payload.response);
  },
  SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_FACTURE(state, payload) {
    state.errorAppeliPaiementThObligeeFacture = payload;
  },
  SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE_FACTURE(state, payload) {
    state.chargementAppelPaiementThObligeeFacture = payload;
  },
  SET_PAGINATION_APPEL_PAIEMENT_TH(state, payload) {
    state.totalRowsThObligee = payload;
  },
  SET_FILTER_OBLIGEE_DATA(state, payload) {
    state.ListOrganismeObligee = payload.organismes;
    state.ListInstallateurbligee = payload.installateurs;
    state.ListLotsOligee = payload.lots;
  },
  SET_LOADING_GET_DATA_FILTER_TH_OBLIGEE_FACTURE(state, payload) {
    state.loadingDataFilterObligeeThProjects = payload;
  },
  UPDATE_FACTURE_SUIVI_PAIEMENT_DATA_TH(state, payload) {
    payload.row.updateData(payload.response);
  },
  SET_MAIL_LODING_VERTIGO(state, payload) {
    state.mailLodingVertigo = payload;
  },
  SET_ERROR_EMAIL(state, payload) {
    state.errorMailsSent = payload;
  },
  SET_SUCCESS_EMAIL(state, payload) {
    state.successMailsSent = payload;
  },
  SET_OTHER_EMAIL(state, payload) {
    state.otherErrorsMail = payload;
  },
  DOWNLOAD_EXCEL(state, payload) {
    const blob = new Blob([payload.data]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download.xlsx'); //or any other extension
    document.body.appendChild(link);
    link.click();
  },
  SET_ERROR_OBLIGEE_XLS(state, payload) {
    state.errorObligeXls = payload;
  },
  SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_EXPORT_XLS(state, payload) {
    state.errorAppeliPaiementThObligeeExportXls = payload;
  },
  SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE_EXPORT_XLS(state, payload) {
    state.chargementAppelPaiementThObligeeExportXlsFacture = payload;
  },
  SET_RESPONSE_REQUEST(state, payload) {
    state.responsevalidationGlobal = payload;
  },
  SET_DELTE_PROJECT_LODING_VERTIGO(state, payload) {
    state.loaderDelteProjectVertigo = payload;
  },
  SET_ERROR_DELETE_PROJECT_VERTIGO(state, payload) {
    state.errorDelteProjectVertigo = payload;
  },
  SET_DATA_DELETE_PROJECT_VERTIGO(state, payload) {
    let lotToDelete = [];
    payload.data.map(item => {
      let indexF = payload.response.findIndex(i => i.name == item.name);
      if (indexF != -1) {
        let index = payload.response[indexF].lots.findIndex(
          i => i.id == item.lot.id
        );

        if (index != -1) {
          item.lot.updateData(payload.response[indexF].lots[index]);
        } else {
          lotToDelete.push(item);
        }
      } else {
        lotToDelete.push(item);
      }
    });

    lotToDelete.forEach(l => {
      let indexF = state.appeliPaiementThTableObligee.findIndex(
        i => i.name == l.name
      );
      if (indexF != -1) {
        state.appeliPaiementThTableObligee[
          indexF
        ].lots = state.appeliPaiementThTableObligee[indexF].lots.filter(
          i => i.name != l.lot.name
        );
      }
    });
    state.appeliPaiementThTableObligee = state.appeliPaiementThTableObligee.filter(
      item => item.lots.length
    );
  },
  SET_LIST_TYPE_FACTURE_OBLIGEE(state, payload) {
    state.typeListFactureObligee = payload;
  },
  SET_DATA_PROJECT_FOR_SPECIFIC_DATE(state, payload = []) {
    state.listProjectForSpecificDate = payload;
  }
};
const actions = {
  async refreshFactureDossier({ commit }, payload) {
    commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_FACTURE', null);
    commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE_FACTURE', true);
    try {
      let params = new FormData();
      params.append('lot', payload.lot);
      params.append('type', payload.type);
      params.append('id_facture', payload.id_facture);
      if (requestgetObligeeTemplate) {
        requestgetObligeeTemplate.cancel('cancel-request');
      }
      requestgetObligeeTemplate = axios.CancelToken.source();

      const response = await axios.post(
        domain + `/api/obligee/refresh_facture_dossiers`,
        params,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          // paramsSerializer: params => {
          //   return Qs.stringify(params.products);
          // },
          cancelToken: requestgetObligeeTemplate.token
        }
      );
      commit('SET_TEMPLATE_OBLIGEE_TO_UPLOAD', response.data.data);

      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE_FACTURE', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit(
          'SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_FACTURE',
          error.response.data.error
        );
      } else if (error.message !== 'cancel-request') {
        commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE_FACTURE', false);
        commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_FACTURE', [
          'Une erreur est servenue'
        ]);
      }

      return false;
    }
  },
  async getAppelPaiementObligeeThFiltred({ commit }, payload) {
    commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', null);
    commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', true);
    commit('APPEL_PAIEMENT_TH_FILTRED_OBLIGEE_MISSING', []);

    try {
      if (requestgetPaiementObligeeThFiltred) {
        requestgetPaiementObligeeThFiltred.cancel('cancel-request');
      }
      requestgetPaiementObligeeThFiltred = axios.CancelToken.source();

      const params = {
        year: payload.year,
        page: payload.page,
        per_page: payload.per_page
      };
      // if (payload.month != null) {
      //   params.month = payload.month;
      // }
      // if (payload.depot != null) {
      //   params.depot = payload.depot;
      // }
      if (payload.organisme.length) {
        params.organisme = payload.organisme.map(({ id }) => id);
      }
      // if (payload.week != null) {
      //   params.week = payload.week;
      // }
      if (payload.filiale.length) {
        params.installateur = payload.filiale.map(({ id }) => id);
      }
      if (payload.date_debut != null) {
        params.date_debut = payload.date_debut;
      }
      if (payload.date_fin != null) {
        params.date_fin = payload.date_fin;
      }
      if (payload.lot.length) {
        params.lot = payload.lot;
      }
      if (payload.search != null) {
        params.num = payload.search;
      }
      const response = await axios.get(domain + `/api/obligee/all_per_lot`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params,
        cancelToken: requestgetPaiementObligeeThFiltred.token
      });
      commit('APPEL_PAIEMENT_TH_FILTRED_OBLIGEE', response.data.data);
      commit(
        'APPEL_PAIEMENT_TH_FILTRED_OBLIGEE_MISSING',
        response.data.missing_organisme
      );
      commit('SET_PAGINATION_APPEL_PAIEMENT_TH', response.data.count);
      commit('SET_TOTAL_ROW_APPEL_PAIEMENT_TH', {
        total_pu_commission: response.data.total_pu_commission,
        total_pu_prime: response.data.total_pu_prime,
        total_pu_surprime: response.data.total_pu_surprime,
        total_pu_total: response.data.total_pu_total,
        total_pu_missing_prime: response.data.total_pu_missing_prime
      });
      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit(
          'SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH',
          error.response.data.error
        );
      } else if (error.message !== 'cancel-request') {
        commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);
        commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', [
          'Une erreur est survenue'
        ]);
      }

      return false;
    }
  },
  async downloadFactureObligeeItem({ commit }, payload) {
    commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', true);
    try {
      const response = await axios.get(
        domain + '/api/obligee/pdf/download/' + payload.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          responseType: 'blob'
        }
      );
      var words = response.headers['cache-control'].split('.pdf');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', words[0] + '.pdf');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit(
          'SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH',
          error.response.data.error
        );
      } else {
        commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);
    }
  },

  async downloadFactureGlobaleObligeeItem({ commit }, payload) {
    commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', true);
    try {
      const params = {
        type: payload.type,
        depot: payload.item.name
      };
      const response = await axios.get(
        domain + '/api/download_suivi_th_per_depot_pdf',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params,
          responseType: 'blob'
        }
      );
      var words = response.headers['cache-control'].split('.pdf');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', words[0] + '.pdf');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit(
          'SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH',
          error.response.data.error
        );
      } else {
        commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);
    }
  },
  async getObligeeTemplateAndFiledsDataToUpdate({ commit }, payload) {
    commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_FACTURE', null);
    commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE_FACTURE', true);
    try {
      let params = new FormData();
      // if (payload.lot) {
      params.append('vendeur', payload.vendeur);
      params.append('lot', payload.lot);
      params.append('acheteur', payload.acheteur);
      // }
      // if (payload.id) {
      //   params.append('id', payload.id);
      // }
      params.append('type', payload.type);
      params.append('av', payload.av);
      if (payload.date) {
        params.append('date', payload.date);
      }
      if (payload.payment_condition && payload.payment_condition.id != null) {
        params.append('payment_condition', payload.payment_condition.id);
      }
      if (payload.p) {
        payload.p.forEach((p, index) => {
          params.append('products[' + index + '][contenu]', p.contenu);
          params.append('products[' + index + '][created_at]', p.created_at);
          params.append('products[' + index + '][depot]', p.depot);
          params.append('products[' + index + '][description]', p.description);
          params.append(
            'products[' + index + '][factureLibre_id]',
            p.factureLibre_id
          );
          params.append('products[' + index + '][id]', p.id);
          params.append('products[' + index + '][index]', p.index);
          params.append(
            'products[' + index + '][isSelectInput]',
            p.isSelectInput
          );
          params.append('products[' + index + '][net_ht]', p.net_ht);
          params.append(
            'products[' + index + '][net_ht_apres]',
            p.net_ht_apres
          );
          params.append('products[' + index + '][nom]', p.nom);
          params.append('products[' + index + '][parent_id]', p.parent_id);
          params.append('products[' + index + '][produit_id]', p.produit_id);
          params.append('products[' + index + '][pu_ht]', p.pu_ht);
          params.append('products[' + index + '][pu_ht_apres]', p.pu_ht_apres);
          params.append('products[' + index + '][qte]', p.qte);
          params.append('products[' + index + '][qte_apres]', p.qte_apres);
          params.append('products[' + index + '][reduction]', p.reduction);
          params.append(
            'products[' + index + '][reduction_apres]',
            p.reduction_apres
          );
          params.append(
            'products[' + index + '][reduction_calcule]',
            p.reduction_calcule
          );
          params.append(
            'products[' + index + '][reduction_calcule_apres]',
            p.reduction_calcule_apres
          );
          params.append('products[' + index + '][reference]', p.reference);
          params.append('products[' + index + '][total_ht]', p.total_ht);
          params.append(
            'products[' + index + '][total_ht_apres]',
            p.total_ht_apres
          );
          params.append('products[' + index + '][total_ttc]', p.total_ttc);
          params.append(
            'products[' + index + '][total_ttc_apres]',
            p.total_ttc_apres
          );
          params.append('products[' + index + '][tva]', p.tva);
          params.append('products[' + index + '][tva_apres]', p.tva_apres);
          params.append('products[' + index + '][tva_calcule]', p.tva_calcule);
          params.append(
            'products[' + index + '][tva_calcule_apres]',
            p.tva_calcule_apres
          );
          params.append('products[' + index + '][type]', p.type);
          params.append(
            'products[' + index + '][type_obligee]',
            p.type_obligee
          );
          params.append(
            'products[' + index + '][type_produit]',
            p.type_produit
          );
          params.append('products[' + index + '][unite]', p.unite);
          params.append('products[' + index + '][unite_apres]', p.unite_apres);
          params.append('products[' + index + '][updated_at]', p.updated_at);
        });
      }

      if (requestgetObligeeTemplate) {
        requestgetObligeeTemplate.cancel('cancel-request');
      }
      requestgetObligeeTemplate = axios.CancelToken.source();

      const response = await axios.post(
        domain + `/api/obligee/get_uploaded_template`,
        params,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          // paramsSerializer: params => {
          //   return Qs.stringify(params.products);
          // },
          cancelToken: requestgetObligeeTemplate.token
        }
      );
      if (payload.updatePdf && payload.updatePdf == 'yes') {
        commit('SET_TEMPLATE_OBLIGEE_TO_UPLOAD_PDF', response.data.data);
      } else {
        commit('SET_TEMPLATE_OBLIGEE_TO_UPLOAD', response.data.data);
      }

      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE_FACTURE', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit(
          'SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_FACTURE',
          error.response.data.error
        );
      } else if (error.message !== 'cancel-request') {
        commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE_FACTURE', false);
        commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_FACTURE', [
          'Une erreur est servenue'
        ]);
      }

      return false;
    }
  },
  async getObligeeTemplateAndFiledsDataToUpdateForFcature({ commit }, payload) {
    commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_FACTURE', null);
    commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE_FACTURE', true);
    try {
      let params = new FormData();
      params.append('id', payload.id);
      if (payload.date) {
        params.append('date', payload.date);
      }
      if (payload.payment_condition && payload.payment_condition.id != null) {
        params.append('payment_condition', payload.payment_condition.id);
      }
      params.append('av', payload.av);
      params.append('type', payload.type);
      if (payload.p) {
        // payload.prodc = payload.prodc.filter(i => i.check == true);
        payload.p.forEach((p, index) => {
          params.append('products[' + index + '][contenu]', p.contenu);
          params.append('products[' + index + '][created_at]', p.created_at);
          params.append('products[' + index + '][depot]', p.depot);
          params.append('products[' + index + '][description]', p.description);
          params.append(
            'products[' + index + '][factureLibre_id]',
            p.factureLibre_id
          );
          params.append('products[' + index + '][id]', p.id);
          params.append('products[' + index + '][index]', p.index);
          params.append(
            'products[' + index + '][isSelectInput]',
            p.isSelectInput
          );
          params.append('products[' + index + '][net_ht]', p.net_ht);
          params.append(
            'products[' + index + '][net_ht_apres]',
            p.net_ht_apres
          );
          params.append('products[' + index + '][nom]', p.nom);
          params.append('products[' + index + '][parent_id]', p.parent_id);
          params.append('products[' + index + '][produit_id]', p.produit_id);
          params.append('products[' + index + '][pu_ht]', p.pu_ht);
          params.append('products[' + index + '][pu_ht_apres]', p.pu_ht_apres);
          params.append('products[' + index + '][qte]', p.qte);
          params.append('products[' + index + '][qte_apres]', p.qte_apres);
          params.append('products[' + index + '][reduction]', p.reduction);
          params.append(
            'products[' + index + '][reduction_apres]',
            p.reduction_apres
          );
          params.append(
            'products[' + index + '][reduction_calcule]',
            p.reduction_calcule
          );
          params.append(
            'products[' + index + '][reduction_calcule_apres]',
            p.reduction_calcule_apres
          );
          params.append('products[' + index + '][reference]', p.reference);
          params.append('products[' + index + '][total_ht]', p.total_ht);
          params.append(
            'products[' + index + '][total_ht_apres]',
            p.total_ht_apres
          );
          params.append('products[' + index + '][total_ttc]', p.total_ttc);
          params.append(
            'products[' + index + '][total_ttc_apres]',
            p.total_ttc_apres
          );
          params.append('products[' + index + '][tva]', p.tva);
          params.append('products[' + index + '][tva_apres]', p.tva_apres);
          params.append('products[' + index + '][tva_calcule]', p.tva_calcule);
          params.append(
            'products[' + index + '][tva_calcule_apres]',
            p.tva_calcule_apres
          );
          params.append('products[' + index + '][type]', p.type);
          params.append(
            'products[' + index + '][type_obligee]',
            p.type_obligee
          );
          params.append(
            'products[' + index + '][type_produit]',
            p.type_produit
          );
          params.append('products[' + index + '][unite]', p.unite);
          params.append('products[' + index + '][unite_apres]', p.unite_apres);
          params.append('products[' + index + '][updated_at]', p.updated_at);
        });
      }

      if (requestgetObligeeTemplate) {
        requestgetObligeeTemplate.cancel('cancel-request');
      }
      requestgetObligeeTemplate = axios.CancelToken.source();

      const response = await axios.post(
        domain + `/api/obligee/get_facture_products`,
        params,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          // paramsSerializer: params => {
          //   return Qs.stringify(params.products);
          // },
          cancelToken: requestgetObligeeTemplate.token
        }
      );
      if (payload.updatePdf && payload.updatePdf == 'yes') {
        commit('SET_TEMPLATE_OBLIGEE_TO_UPLOAD_PDF', response.data.data);
      } else {
        commit('SET_TEMPLATE_OBLIGEE_TO_UPLOAD', response.data.data);
      }

      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE_FACTURE', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit(
          'SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_FACTURE',
          error.response.data.error
        );
      } else if (error.message !== 'cancel-request') {
        commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE_FACTURE', false);
        commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_FACTURE', [
          'Une erreur est servenue'
        ]);
      }

      return false;
    }
  },
  async uploadObligeeTemplateInServieVisionFiliale({ commit }, payload) {
    var bodyFormData = new FormData();
    if (payload.id) {
      bodyFormData.append('id', payload.id);
    }
    if (payload.lot) {
      bodyFormData.append('lot', payload.lot);
    }
    if (payload.vendeur) {
      bodyFormData.append('vendeur', payload.vendeur);
    }
    if (payload.acheteur) {
      bodyFormData.append('acheteur', payload.acheteur);
    }

    bodyFormData.append('type', payload.type);
    // bodyFormData.append('description', payload.description);
    bodyFormData.append('iban', payload.iban);
    bodyFormData.append('swift', payload.swift);
    bodyFormData.append('ttc', payload.ttc);
    bodyFormData.append('date', payload.date);
    bodyFormData.append('tva', payload.tva);
    bodyFormData.append('ht', payload.ht);
    bodyFormData.append('av', payload.av);
    bodyFormData.append('id_rib', payload.id_rib);
    if (payload.payment_condition && payload.payment_condition.id != null) {
      bodyFormData.append('payment_condition', payload.payment_condition.id);
    }
    if (payload.additionel) {
      bodyFormData.append('additionel', payload.additionel);
    }
    // if (payload.id_facture) {
    //   bodyFormData.append('id_facture', payload.id_facture);
    // }

    if (payload.products) {
      let prod = payload.products.filter(
        p => p.reste_avoir !== 0 && p.total_ht > 0
      );
      for (let i = 0; i < prod.length; i++) {
        bodyFormData.append(
          'products[' + [i] + '][produit_id]',
          prod[i].produit_id
        );
        bodyFormData.append('products[' + [i] + '][tva]', prod[i].tva);
        bodyFormData.append('products[' + [i] + '][nom]', prod[i].nom);
        bodyFormData.append(
          'products[' + [i] + '][reference]',
          prod[i].reference
        );
        bodyFormData.append('products[' + [i] + '][qte]', prod[i].qte);
        bodyFormData.append('products[' + [i] + '][pu_ht]', prod[i].pu_ht);
        bodyFormData.append(
          'products[' + [i] + '][total_ht]',
          prod[i].total_ht
        );
        bodyFormData.append(
          'products[' + [i] + '][type_obligee]',
          prod[i].type_obligee
        );
        bodyFormData.append(
          'products[' + [i] + '][total_ttc]',
          prod[i].total_ttc
        );
        bodyFormData.append(
          'products[' + [i] + '][description]',
          prod[i].description
        );
      }
    }
    try {
      const response = await axios.post(
        domain + `/api/obligee/upload`,
        bodyFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_DATA_FOR_FACTURE_OBLIGEE_UPLOADED', {
        response: response.data.data.original.data[0].lots[0],
        item: payload.item
      });
      return {
        succes: true,
        message: response.data.data
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async uploadObligeAvoir({ commit }, payload) {
    var bodyFormData = new FormData();
    bodyFormData.append('type', payload.type);
    bodyFormData.append('id', payload.id);
    bodyFormData.append('iban', payload.iban);
    // bodyFormData.append('lot', payload.lot);
    bodyFormData.append('swift', payload.swift);
    bodyFormData.append('ttc', payload.ttc);
    bodyFormData.append('date', payload.date);
    bodyFormData.append('tva', payload.tva);
    bodyFormData.append('ht', payload.ht);
    bodyFormData.append('av', payload.av);
    if (payload.date_debut) {
      bodyFormData.append('date_debut', payload.date_debut);
    }
    if (payload.date_fin) {
      bodyFormData.append('date_fin', payload.date_fin);
    }
    // bodyFormData.append('vendeur', payload.vendeur);
    // bodyFormData.append('acheteur', payload.acheteur);
    bodyFormData.append('id_rib', payload.id_rib);
    if (payload.payment_condition && payload.payment_condition.id != null) {
      bodyFormData.append('payment_condition', payload.payment_condition.id);
    }
    if (payload.products) {
      let prod = payload.products.filter(
        p => p.reste_avoir !== 0 && p.total_ht > 0
      );
      for (let i = 0; i < prod.length; i++) {
        bodyFormData.append(
          'products[' + [i] + '][produit_id]',
          prod[i].produit_id
        );
        bodyFormData.append('products[' + [i] + '][tva]', prod[i].tva);
        bodyFormData.append('products[' + [i] + '][nom]', prod[i].nom);
        bodyFormData.append(
          'products[' + [i] + '][reference]',
          prod[i].reference
        );
        bodyFormData.append('products[' + [i] + '][qte]', prod[i].qte);
        bodyFormData.append('products[' + [i] + '][pu_ht]', prod[i].pu_ht);
        bodyFormData.append('products[' + [i] + '][type]', prod[i].pu_ht);
        bodyFormData.append(
          'products[' + [i] + '][type_obligee]',
          prod[i].type_obligee
        );
        bodyFormData.append(
          'products[' + [i] + '][total_ht]',
          prod[i].total_ht
        );
        bodyFormData.append(
          'products[' + [i] + '][total_ttc]',
          prod[i].total_ttc
        );
        bodyFormData.append(
          'products[' + [i] + '][description]',
          prod[i].description
        );
      }
    }

    try {
      const response = await axios.post(
        domain + `/api/obligee/create-avoir`,
        bodyFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_DATA_FOR_FACTURE_OBLIGEE_UPLOADED', {
        response: response.data.data.original.data[0].lots[0],
        item: payload.item
      });
      return {
        succes: true,
        message: response.data.data,
        rapport:
          response && response.data && response.data.rapport
            ? response.data.rapport
            : null
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async refreshAooelPaiementObligeeAction({ commit }, payload) {
    commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', null);
    commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', true);
    const params = {
      lots: payload.lot
    };
    try {
      if (requestrefreshPaiementObligee) {
        requestrefreshPaiementObligee.cancel('cancel-request');
      }
      requestrefreshPaiementObligee = axios.CancelToken.source();

      const response = await axios.post(
        domain + '/api/obligee/fixlot',
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          cancelToken: requestrefreshPaiementObligee.token
        }
      );
      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE_FACTURE', false);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit(
          'SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_FACTURE',
          error.response.data.error
        );
      } else if (error.message !== 'cancel-request') {
        commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE_FACTURE', false);
        commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_FACTURE', [
          'Une erreur est servenue'
        ]);
      }

      return false;
    }
  },
  async getAllDataForFilterObligee({ commit }, payload) {
    commit('SET_LOADING_GET_DATA_FILTER_TH_OBLIGEE_FACTURE', true);

    try {
      if (requestrefreshPaiementObligeeFilter) {
        requestrefreshPaiementObligeeFilter.cancel('cancel-request');
      }
      requestrefreshPaiementObligeeFilter = axios.CancelToken.source();
      const response = await axios.get(domain + '/api/obligee/lot_online', {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        cancelToken: requestrefreshPaiementObligeeFilter.token,
        params: payload
      });
      commit('SET_FILTER_OBLIGEE_DATA', response.data);
      commit('SET_LOADING_GET_DATA_FILTER_TH_OBLIGEE_FACTURE', false);
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit(
          'SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_FACTURE',
          error.response.data.error
        );
      } else if (error.message !== 'cancel-request') {
        commit('SET_LOADING_GET_DATA_FILTER_TH_OBLIGEE_FACTURE', true);
        commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_FACTURE', [
          'Une erreur est servenue'
        ]);
      }

      return false;
    }
  },
  async updateSuiviPaiementDataTh({ commit }, payload) {
    commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', null);
    commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', true);
    commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_UPDATE_TH', null);
    const params = {
      column: payload.column,
      value: payload.value,
      id: payload.row.id
    };

    try {
      const response = await axios.post(
        domain + `/api/obligee/update_project`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: response.data.data,
        row: payload.data
      };

      if (response.data.error == true) {
        commit(
          'SET_ERROR_APPEL_PAIEMENT_OBLIGEE_UPDATE_TH',
          'Cette mise à jour ne peut se faire car il y a une facture déjà valide dans ce lot.'
        );
      }
      commit('UPDATE_FACTURE_SUIVI_PAIEMENT_DATA_TH', obj);
      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);

      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit(
          'SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH',
          error.response.data.error
        );
      } else {
        commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', [
          'Une erreur est survenue'
        ]);
      }
      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);

      return false;
    }
  },
  async updateSuiviPaiementStatutTh({ commit }, payload) {
    commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', null);
    commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', true);
    var lastIndex = payload.column.lastIndexOf('_');
    let idName = payload.column.substring(0, lastIndex);
    const params = {
      id: payload.row[idName + '_id'],
      statut: payload.value
    };
    try {
      const response = await axios.post(
        domain + `/api/obligee/update_statut`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      payload.row[payload.column] = payload.value;
      // const obj = {
      //   response: response.data.data,
      //   row: payload.data
      // };
      // commit('UPDATE_FACTURE_SUIVI_PAIEMENT_DATA_TH', obj);
      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);

      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit(
          'SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH',
          error.response.data.error
        );
      } else {
        commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', [
          'Une erreur est survenue'
        ]);
      }
      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);

      return false;
    }
  },
  // setDaraTemplateObligeUploded({ commit }) {
  //   commit('SET_TEMPLATE_OBLIGEE_TO_UPLOAD', null);
  // },
  async downloadPaiementObligeeThFiltredExcel({ commit }, payload) {
    commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', null);
    commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', true);
    try {
      if (requestgetPaiementObligeeThFiltred) {
        requestgetPaiementObligeeThFiltred.cancel('cancel-request');
      }
      requestgetPaiementObligeeThFiltred = axios.CancelToken.source();
      const params = {};
      if (payload?.dataCheck?.length) {
        params.lot = payload.dataCheck;
      } else {
        if (payload.organisme.length) {
          params.organisme = payload.organisme.map(({ id }) => id);
        }
        if (payload.filiale.length) {
          params.installateur = payload.filiale.map(({ id }) => id);
        }
        if (payload.date_debut != null) {
          params.date_debut = payload.date_debut;
        }
        if (payload.date_fin != null) {
          params.date_fin = payload.date_fin;
        }
        if (payload.lot.length) {
          params.lot = payload.lot;
        }
      }
      if (payload?.exportExcel) {
        params.exportExcel = payload.exportExcel;
      }

      const response = await axios.get(domain + `/api/obligee/all_per_lot`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params,
        cancelToken: requestgetPaiementObligeeThFiltred.token,
        responseType: 'blob'
      });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'OBLIGEE' + '.xlsx');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit(
          'SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH',
          error.response.data.error
        );
      } else if (error.message !== 'cancel-request') {
        commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);
        commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', [
          'Une erreur est survenue'
        ]);
      }

      return false;
    }
  },
  async validationFactureObligee({ commit }, payload) {
    commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', null);
    commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', true);
    try {
      if (requestgetPaiementObligeeThFiltred) {
        requestgetPaiementObligeeThFiltred.cancel('cancel-request');
      }
      requestgetPaiementObligeeThFiltred = axios.CancelToken.source();
      const params = {};
      if (payload?.dataCheck?.length) {
        params.lot = payload.dataCheck;
      } else {
        if (payload.organisme.length) {
          params.organisme = payload.organisme.map(({ id }) => id);
        }
        if (payload.filiale.length) {
          params.installateur = payload.filiale.map(({ id }) => id);
        }
        if (payload.date_debut != null) {
          params.date_debut = payload.date_debut;
        }
        if (payload.date_fin != null) {
          params.date_fin = payload.date_fin;
        }
        if (payload.lot.length) {
          params.lot = payload.lot;
        }
      }
      if (payload?.exportExcel) {
        params.exportExcel = payload.exportExcel;
      }
      if (payload?.type) {
        params.type = payload.type;
      }

      const response = await axios.post(
        domain + `/api/obligee/validation_multiple`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },

          cancelToken: requestgetPaiementObligeeThFiltred.token
        }
      );

      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);
      commit('SET_RESPONSE_REQUEST', response.data);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit(
          'SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH',
          error.response.data.error
        );
      } else if (error.message !== 'cancel-request') {
        commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);
        commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', [
          'Une erreur est survenue'
        ]);
      }

      return false;
    }
  },
  async downloadPaiementObligeeThFactureFiltredExcel({ commit }, payload) {
    commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_EXPORT_XLS', null);
    commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE_EXPORT_XLS', true);
    try {
      if (requestgetPaiementObligeeThFiltred) {
        requestgetPaiementObligeeThFiltred.cancel('cancel-request');
      }
      requestgetPaiementObligeeThFiltred = axios.CancelToken.source();
      const params = {};
      if (payload?.dataCheck?.length) {
        params.lot = payload.dataCheck;
        if (payload.famille != null) {
          params.famille = payload.famille;
        }
        if (payload.modelExport_id != null) {
          params.modelExport_id = payload.modelExport_id;
        }
      } else {
        if (payload.organisme.length) {
          params.organisme = payload.organisme.map(({ id }) => id);
        }
        if (payload.filiale.length) {
          params.installateur = payload.filiale.map(({ id }) => id);
        }
        if (payload.date_debut != null) {
          params.date_debut = payload.date_debut;
        }
        if (payload.date_fin != null) {
          params.date_fin = payload.date_fin;
        }
        if (payload.famille != null) {
          params.famille = payload.famille;
        }
        if (payload.modelExport_id != null) {
          params.modelExport_id = payload.modelExport_id;
        }
        if (payload.lot.length) {
          params.lot = payload.lot;
        }
      }
      if (payload?.type) {
        params.type = payload.type;
      }
      this.state.showNotifExportFile = true;

      const response = await axios.post(
        domain + `/api/obligee/exportExcelFacture`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          cancelToken: requestgetPaiementObligeeThFiltred.token,
          responseType: 'blob'
        }
      );

      // const link = document.createElement('a');
      // link.href = window.URL.createObjectURL(new Blob([response.data]));
      // link.setAttribute('download', 'Facture obligé' + '.xlsx');
      // document.body.appendChild(link);
      // link.click();
      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE_EXPORT_XLS', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit(
          'SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_EXPORT_XLS',
          error.response.data.error
        );
      } else if (error.message !== 'cancel-request') {
        commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE_EXPORT_XLS', false);
        commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_EXPORT_XLS', [
          'Une erreur est survenue'
        ]);
      }

      return false;
    }
  },
  async exportRapportFactureObligee({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/obligee/rapport-obliges/create-report`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      this.state.showNotifExportFile = true;
      return { succes: true, msg: response.data.data };
    } catch (error) {
      if (error && error.response && error.response.data) {
        return { succes: false, error: error.response.data };
      } else {
        return { succes: false, error: 'Une erreur est servenue' };
      }
    }
  },
  async downloadPaiementObligeeThFactureFiltredPdf({ commit }, payload) {
    commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', null);
    commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', true);
    try {
      if (requestgetPaiementObligeeThFiltred) {
        requestgetPaiementObligeeThFiltred.cancel('cancel-request');
      }
      requestgetPaiementObligeeThFiltred = axios.CancelToken.source();
      const params = {
        ids: []
      };
      if (payload?.dataCheck?.length) {
        for (let i = 0; i < payload.dataCheck.length; i++) {
          for (let j = 0; j < payload.dataCheck[i].lots.length; j++) {
            if (payload.dataCheck[i].lots[j].check == true) {
              if (payload.dataCheck[i].lots[j].prime_factures.length > 0) {
                for (
                  let k = 0;
                  k < payload.dataCheck[i].lots[j].prime_factures.length;
                  k++
                ) {
                  let fact = payload.dataCheck[i].lots[j].prime_factures[k];
                  params.ids.push(fact.id);
                  if (fact.FactureAvoir.length) {
                    for (let h = 0; h < fact.FactureAvoir.length; h++) {
                      params.ids.push(fact.FactureAvoir[h].id);
                    }
                  }
                }
              }
              if (
                payload.dataCheck[i].lots[j].missing_prime_factures.length > 0
              ) {
                for (
                  let k = 0;
                  k <
                  payload.dataCheck[i].lots[j].missing_prime_factures.length;
                  k++
                ) {
                  let fact =
                    payload.dataCheck[i].lots[j].missing_prime_factures[k];
                  params.ids.push(fact.id);
                  if (fact.FactureAvoir.length) {
                    for (let h = 0; h < fact.FactureAvoir.length; h++) {
                      params.ids.push(fact.FactureAvoir[h].id);
                    }
                  }
                }
              }
              if (
                payload.dataCheck[i].lots[j].commission_gestion_factures
                  .length > 0
              ) {
                for (
                  let k = 0;
                  k <
                  payload.dataCheck[i].lots[j].commission_gestion_factures
                    .length;
                  k++
                ) {
                  let fact =
                    payload.dataCheck[i].lots[j].commission_gestion_factures[k];
                  params.ids.push(fact.id);
                  if (fact.FactureAvoir.length) {
                    for (let h = 0; h < fact.FactureAvoir.length; h++) {
                      params.ids.push(fact.FactureAvoir[h].id);
                    }
                  }
                }
              }
              if (
                payload.dataCheck[i].lots[j].consolidee_energie_invest_factures
                  .length > 0
              ) {
                for (
                  let k = 0;
                  k <
                  payload.dataCheck[i].lots[j]
                    .consolidee_energie_invest_factures.length;
                  k++
                ) {
                  let fact =
                    payload.dataCheck[i].lots[j]
                      .consolidee_energie_invest_factures[k];
                  params.ids.push(fact.id);
                  if (fact.FactureAvoir.length) {
                    for (let h = 0; h < fact.FactureAvoir.length; h++) {
                      params.ids.push(fact.FactureAvoir[h].id);
                    }
                  }
                }
              }
              if (payload.dataCheck[i].lots[j].commission_factures.length > 0) {
                for (
                  let k = 0;
                  k < payload.dataCheck[i].lots[j].commission_factures.length;
                  k++
                ) {
                  let fact =
                    payload.dataCheck[i].lots[j].commission_factures[k];
                  params.ids.push(fact.id);
                  if (fact.FactureAvoir.length) {
                    for (let h = 0; h < fact.FactureAvoir.length; h++) {
                      params.ids.push(fact.FactureAvoir[h].id);
                    }
                  }
                }
              }
              if (payload.dataCheck[i].lots[j].surprime_factures.length > 0) {
                for (
                  let k = 0;
                  k < payload.dataCheck[i].lots[j].surprime_factures.length;
                  k++
                ) {
                  let fact = payload.dataCheck[i].lots[j].surprime_factures[k];
                  params.ids.push(fact.id);
                  if (fact.FactureAvoir.length) {
                    for (let h = 0; h < fact.FactureAvoir.length; h++) {
                      params.ids.push(fact.FactureAvoir[h].id);
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        params.ids = payload.ids;
      }
      if (payload?.type) {
        params.type = payload.type;
      }
      const response = await axios.post(
        domain + `/api/obligee/download_pdf`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          cancelToken: requestgetPaiementObligeeThFiltred.token,
          responseType: 'blob'
        }
      );

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'FACTURE OBLIGEE.zip');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit(
          'SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH',
          error.response.data.error
        );
      } else if (error.message !== 'cancel-request') {
        commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);
        commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', [
          'Une erreur est survenue'
        ]);
      }

      return false;
    }
  },
  async downloadMultiAvoirPdfs({ commit }, payload) {
    commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', null);
    commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', true);
    try {
      if (requestgetPaiementObligeeThFiltred) {
        requestgetPaiementObligeeThFiltred.cancel('cancel-request');
      }
      requestgetPaiementObligeeThFiltred = axios.CancelToken.source();
      const params = {
        ids: [],
        type: []
      };
      params.ids = payload.ids;
      params.type = payload.type;
      const response = await axios.post(
        domain + `/api/obligee/download_pdf`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          cancelToken: requestgetPaiementObligeeThFiltred.token,
          responseType: 'blob'
        }
      );

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'FACTURE OBLIGEE.zip');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit(
          'SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH',
          error.response.data.error
        );
      } else if (error.message !== 'cancel-request') {
        commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);
        commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', [
          'Une erreur est survenue'
        ]);
      }

      return false;
    }
  },
  async updateStatueObligeeGlobale({ commit }, payload) {
    commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', null);
    commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', true);
    try {
      const response = await axios.post(
        domain + '/api/obligee/update_statut_multiple',
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { succes: true, message: response.data };
    } catch (error) {
      return { succes: false };
    }
  },
  async updateCoefObligee({ commit }, payload) {
    commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', null);
    commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', true);
    try {
      await axios.post(
        domain + '/api/obligee/update_project_multiple',
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return true;
    } catch (error) {
      return false;
    }
  },
  async sendMailVertigo({ commit }, payload) {
    commit('SET_MAIL_LODING_VERTIGO', true);
    try {
      const response = await axios.post(domain + `/api/obligee/mail`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_MAIL_LODING_VERTIGO', false);
      commit('SET_ERROR_EMAIL', response.data.error);
      commit('SET_SUCCESS_EMAIL', response.data.success);
      return { succes: true };
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_OTHER_EMAIL', error?.response?.data?.error);
      } else {
        commit('SET_OTHER_EMAIL', 'Une erreur est servenue');
      }
      commit('SET_MAIL_LODING_VERTIGO', false);
      return { succes: false };
    }
  },
  async uploadObligeexls({ commit }, payload) {
    try {
      var bodyFormData = new FormData();
      bodyFormData.append('file', payload.file);

      const response = await axios.post(
        domain + `/api/check_first_file_before_analyse`,
        bodyFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return {
        succes: true,
        data: response.data
      };
    } catch (error) {
      // commit('SET_LOADING', false);
      if (error.response && error.response.data.missing) {
        commit('SET_ERROR_OBLIGEE_XLS', {
          missing: error.response.data.missing
        });
      } else if (error?.response?.data?.error) {
        commit('SET_ERROR_OBLIGEE_XLS', {
          error: error.response.data.error
        });
      } else {
        commit('SET_ERROR_OBLIGEE_XLS', ['Une erreur est servenue']);
      }
      return {
        succes: false
      };
    }
  },
  async uploadObligeexlsAfterConfirmation({ commit }, payload) {
    try {
      var bodyFormData = new FormData();
      bodyFormData.append('file', payload.file);
      bodyFormData.append('force', payload.force);
      const response = await axios.post(
        domain + `/api/analyse_first_file`,
        bodyFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return {
        succes: true
      };
    } catch (error) {
      return {
        succes: false
      };
    }
  },
  async delteProjetInobligee({ commit }, payload) {
    commit('SET_DELTE_PROJECT_LODING_VERTIGO', true);
    try {
      const response = await axios.post(
        domain + `/api/obligee/remove-projects`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_DELTE_PROJECT_LODING_VERTIGO', false);
      // commit('SET_ERROR_DELETE_PROJECT_VERTIGO', response.data.error);
      commit('SET_DATA_DELETE_PROJECT_VERTIGO', {
        response: response.data.data,
        data: payload.data
      });
      return {
        succes: true,
        deleted: response.data.deleted,
        not_deleted: response.data.not_deleted
      };
    } catch (error) {
      if (error?.response?.data?.error) {
        commit(
          'SET_ERROR_DELETE_PROJECT_VERTIGO',
          error?.response?.data?.error
        );
      } else {
        commit('SET_ERROR_DELETE_PROJECT_VERTIGO', 'Une erreur est servenue');
      }
      commit('SET_DELTE_PROJECT_LODING_VERTIGO', false);
      return { succes: false };
    }
  },
  async fetchTypeListFactureObligee({ commit }) {
    try {
      const response = await axios.get(
        domain + `/api/obligee/type_facture_obligee`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LIST_TYPE_FACTURE_OBLIGEE', response.data.data);
      return true;
    } catch (error) {
      return false;
    }
  },

  async generateFactureMastersObliges({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/obligee/generateProformaMasters`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return {
        success: true,
        response: response.data.data
      };
    } catch (error) {
      return {
        success: false,
        errors: error?.response?.data?.errors
          ? error.response.data.errors
          : 'Une erreur est servenue'
      };
    }
  },
  async fetchProjectForSpecifcDate({ commit }, payload) {
    try {
      const response = await axios.get(
        domain + `/api/get_projects_with_depot`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );
      commit('SET_DATA_PROJECT_FOR_SPECIFIC_DATE', response.data.data);
      return true;
    } catch (error) {
      return {
        success: false
      };
    }
  },
  resetProjectForSpecifcDate({ commit }) {
    commit('SET_DATA_PROJECT_FOR_SPECIFIC_DATE');
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
