import axios from 'axios';
import domain from '@/environment';
const state = { produitsFactureAvoir: [] };
const getters = {
  getProduitsFactureAvoir: state => state.produitsFactureAvoir,
  getSumAvoirFacturePartial: state => Avoirs => {
    return Avoirs.map(({ total_ttc }) =>
      parseFloat(total_ttc.replaceAll(' ', ''))
    )
      .reduce((a, b) => parseFloat(a) + parseFloat(b))
      .toFixed(2);
  }
};
const mutations = {
  SET_PRODUITS_FACTURE_AVOIR(state, payload) {
    payload = payload.map(element => {
      if (parseFloat(element.qte_apres_avoir) == 0) {
        {
          return {
            ...element,
            disabled: true,
            initial_apres_avoir: element.qte_apres_avoir
          };
        }
      } else {
        return {
          ...element,
          disabled: false,
          initial_apres_avoir: element.qte_apres_avoir
        };
      }
    });
    state.produitsFactureAvoir = payload;
  },
  UPDATE_FACTURE_AVOIR(state, payload) {
    payload.row.updateAvoir(payload.response);
  }
};
const actions = {
  async getProductsAvoirFacture({ commit }, payload) {
    try {
      const response = await axios.get(
        domain + `/api/libre/productsFacture/${payload}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_PRODUITS_FACTURE_AVOIR', response.data.data);
      return { success: true };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return { succes: false, error: error.response.data.error };
      } else {
        return { succes: false, error: 'Une erreur est servenue' };
      }
    }
  },
  async createAvoirFacture({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/libre/create_avoir_partiel`,
        payload.bodyFormData,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_FACTURE_AVOIR', {
        response: response.data.data,
        row: payload.item
      });
      return {
        success: true
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          success: false,
          error: error.response.data.error
        };
      } else {
        return {
          success: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async exportPdfFactureAvoirDynamic({ commit }, payload) {
    try {
      const response = await axios.get(
        domain + `/api/filiale_template_generator/download_libre`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );

      var words = response.headers['cache-control'].split('.pdf');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', words[0] + '.pdf');
      document.body.appendChild(link);
      link.click();
      return true;
    } catch (error) {
      return false;
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
