import axios from 'axios';
import moment from 'moment';
import domain from '@/environment';
import paramsMasterFiliale from '@/models/paramsMasterFiliale';
export default {
  state: {
    masterFiliales: [],
    masterFilialesError: [],
    variableMasterFiliale: null,
    variableMasterFilialeError: [],
    loaderMaster: false,
    variableAddError: [],
    config: null,
    end_date: null,
    begin_date: null,
    totalRowsMaster: null,
    loaderAdd: false
  },
  getters: {
    masterFiliales: state => state.masterFiliales,
    variableMasterFiliale: state => state.variableMasterFiliale,
    masterFilialesError: state => state.masterFilialesError,
    variableMasterFilialeError: state => state.variableMasterFilialeError,
    loaderMaster: state => state.loaderMaster,
    variableAddError: state => state.variableAddError,
    configuration: state => state.config,
    end_date: state => state.end_date,
    begin_date: state => state.begin_date,
    getVariableMasterTotalRows: state => state.totalRowsMaster,
    loaderAdd: state => state.loaderAdd
  },
  mutations: {
    SET_MASTER_FILIALES(state, payload) {
      state.masterFiliales = payload;
    },
    SET_ERROR_MASTER_FILIALE(state, payload) {
      state.masterFilialesError = payload;
    },
    SET_VARIABLE_MASTER_FILIALE(state, payload) {
      let newData = [];
      payload.forEach(element => {
        element.forEach(items => {
          newData.push(paramsMasterFiliale.create(items));
        });
      });
      state.variableMasterFiliale = newData;
    },
    SET_ERROR_VARIABLE_MASTER_FILIALE(state, payload) {
      state.variableMasterFilialeError = payload;
    },
    SET_ERROR_CONFIG(state, payload) {
      state.config = payload;
    },
    SET_ERROR_BEGIN_DATE(state, payload) {
      state.begin_date = payload;
    },

    SET_ERROR_END_DATE(state, payload) {
      state.end_date = payload;
    },

    SET_ERROR_ADD_MASTER_FILIALE(state, payload) {
      state.variableAddError = payload;
    },
    SET_LOADER_MASTER_FILIALE(state, payload) {
      state.loaderMaster = payload;
    },
    SET_VARIALBLE_MASTER_TOTAL_ROWS(state, payload) {
      state.totalRowsMaster = payload;
    },
    SET_LOADER_ADD(state, payload) {
      state.loaderAdd = payload;
    },
    SET_DATA_CINFIG_MF(state, payload) {
      payload.payload.row.update(payload);
    },
    SET_DATA_CINFIG_MF_SUM(state, payload) {
      if (payload.response.length) {
        payload.payload.updateSum(payload.response[0]);
      }
    },
    SET_DATA_CINFIG_MF_UPDATE_LIGNES(state, payload) {
      payload.payload.row.updatelignes(payload);
    }
  },
  actions: {
    async getAllMasterFiliales({ commit }) {
      try {
        const response = await axios.get(
          domain + `/api/filiale/masters-and-societes`,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('SET_MASTER_FILIALES', response.data.data);
        return true;
      } catch (error) {
        if (error && error.response && error.response.data) {
          commit('SET_ERROR_MASTER_FILIALE', error.response.data.data);
        } else {
          commit('SET_ERROR_MASTER_FILIALE', 'Une erreur est survenue');
        }
        return false;
      }
    },
    async createMasterFiliale({ commit }, payload) {
      commit('SET_ERROR_ADD_MASTER_FILIALE', null);
      commit('SET_ERROR_CONFIG', null);
      commit('SET_ERROR_BEGIN_DATE', null);
      commit('SET_ERROR_END_DATE', null);
      commit('SET_LOADER_ADD', true);

      try {
        const response = await axios.post(
          domain + `/api/filiale/master-filiales/create`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );

        commit('SET_ERROR_ADD_MASTER_FILIALE', null);
        commit('SET_ERROR_CONFIG', null);
        commit('SET_ERROR_BEGIN_DATE', null);
        commit('SET_ERROR_END_DATE', null);
        if (
          response.data.data &&
          (response.data.data?.deja_exisit.length ||
            response.data.data?.error.length ||
            response.data.data?.exist > 0)
        ) {
          if (response.data.data?.deja_exisit.length) {
            commit('SET_ERROR_CONFIG', response.data.data?.deja_exisit);
          }
          if (response.data.data?.error.length) {
            commit('SET_ERROR_ADD_MASTER_FILIALE', response.data.data?.error);
          }
          commit('SET_LOADER_ADD', false);
          return false;
        }
        commit('SET_LOADER_ADD', false);
        return true;
      } catch (error) {
        if (error && error.response && error.response.data) {
          commit('SET_ERROR_CONFIG', error.response.data.errors.config);
          commit('SET_ERROR_BEGIN_DATE', error.response.data.errors.begin_date);
          commit('SET_ERROR_END_DATE', error.response.data.errors.end_date);
          commit('SET_ERROR_ADD_MASTER_FILIALE', error.response.data.errors);
        } else {
          commit('SET_ERROR_ADD_MASTER_FILIALE', 'Une erreur est survenue');
        }
        commit('SET_LOADER_ADD', false);
        return false;
      }
    },
    async getAllVariableMasterFiliale({ commit }, payload) {
      const params = { page: payload.page, per_page: payload.per_page };
      if (payload?.date_debut != null) {
        params.begin_date = payload.date_debut;
      }
      if (payload?.date_fin != null) {
        params.end_date = payload.date_fin;
      }
      commit('SET_ERROR_VARIABLE_MASTER_FILIALE', null);
      commit('SET_LOADER_MASTER_FILIALE', true);
      try {
        const response = await axios.get(
          domain + `/api/filiale/master-filiales/all`,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            },
            params: params
          }
        );
        commit('SET_VARIALBLE_MASTER_TOTAL_ROWS', response?.data?.data?.total);
        commit('SET_VARIABLE_MASTER_FILIALE', response?.data?.data?.data);
        commit('SET_LOADER_MASTER_FILIALE', false);
        commit('SET_ERROR_VARIABLE_MASTER_FILIALE', null);
        return true;
      } catch (error) {
        if (error && error.response && error.response.data) {
          commit('SET_ERROR_VARIABLE_MASTER_FILIALE', error.response.data.data);
        } else {
          commit(
            'SET_ERROR_VARIABLE_MASTER_FILIALE',
            'Une erreur est survenue'
          );
        }
        commit('SET_LOADER_MASTER_FILIALE', false);
        return false;
      }
    },
    async updateVariableMasterFiliale({ commit }, { id, payload }) {
      commit('SET_LOADER_ADD', true);
      try {
        const response = await axios.put(
          domain + `/api/filiale/master-filiales/update/${id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('SET_LOADER_ADD', false);

        return true;
      } catch (error) {
        if (error && error.response && error.response.data) {
          commit('SET_ERROR_CONFIG', error.response.data.errors.coef);
          commit('SET_ERROR_BEGIN_DATE', error.response.data.errors.begin_date);
          commit('SET_ERROR_END_DATE', error.response.data.errors.end_date);

          commit(
            'SET_ERROR_VARIABLE_MASTER_FILIALE',
            'Une erreur est survenue'
          );
          commit('SET_LOADER_ADD', false);
          return false;
        }
      }
    },
    async updateVariableMasterFilialeN({ commit }, payload) {
      commit('SET_LOADER_ADD', true);
      var variable = {
        begin_date:
          payload.row.begin_date != null
            ? moment(payload.row.begin_date, 'DD-MM-YYYY').format('YYYY-MM-DD')
            : payload.row.begin_date,
        end_date:
          payload.row.end_date != null
            ? moment(payload.row.end_date, 'DD-MM-YYYY').format('YYYY-MM-DD')
            : payload.row.end_date,
        coef: parseFloat(payload.row.coef),
        type_calcul: payload.row.type_calcul,
        tva: payload.row.tva
      };
      variable[payload.column] = payload.value;
      if (payload.column == 'begin_date' || payload.column == 'end_date') {
        variable[payload.column] =
          variable[payload.column] != null
            ? moment(variable[payload.column], 'DD-MM-YYYY').format(
                'YYYY-MM-DD'
              )
            : variable[payload.column];
      }
      try {
        const response = await axios.put(
          domain +
            `/api/filiale/master-filiales/update/${payload.row.id_config}`,
          variable,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('SET_DATA_CINFIG_MF', {
          payload: payload,
          response: response.data
        });
        commit('SET_LOADER_ADD', false);

        return true;
      } catch (error) {
        if (error && error.response && error.response.data) {
          commit('SET_ERROR_CONFIG', error.response.data.errors.coef);
          commit('SET_ERROR_BEGIN_DATE', error.response.data.errors.begin_date);
          commit('SET_ERROR_END_DATE', error.response.data.errors.end_date);

          commit(
            'SET_ERROR_VARIABLE_MASTER_FILIALE',
            'Une erreur est survenue'
          );
          commit('SET_LOADER_ADD', false);
          return false;
        }
      }
    },
    async updateDataMasterFilialeF({ commit }, payload) {
      commit('SET_LOADER_ADD', true);
      var variable = {
        begin_date: payload.row.begin_date,
        end_date: payload.row.end_date,
        coef: parseFloat(payload.row.coef),
        type_calcul: payload.row.type_calcul,
        tva: payload.row.tva,
        lines: payload.row.lines
      };
      variable[payload.column] = payload.value;
      try {
        const response = await axios.put(
          domain + `/api/filiale/master-filiales/update/${payload.row.id}`,
          variable,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('SET_DATA_CINFIG_MF', {
          payload: payload,
          response: response.data
        });
        commit('SET_LOADER_ADD', false);

        return true;
      } catch (error) {
        if (error && error.response && error.response.data) {
          commit('SET_ERROR_CONFIG', error.response.data.errors.coef);
          commit('SET_ERROR_BEGIN_DATE', error.response.data.errors.begin_date);
          commit('SET_ERROR_END_DATE', error.response.data.errors.end_date);

          commit(
            'SET_ERROR_VARIABLE_MASTER_FILIALE',
            'Une erreur est survenue'
          );
          commit('SET_LOADER_ADD', false);
          return false;
        }
      }
    },
    async updateDataMasterFilialeLines({ commit }, payload) {
      commit('SET_LOADER_ADD', true);
      var variable = {
        begin_date: payload.data.begin_date,
        end_date: payload.data.end_date,
        coef: parseFloat(payload.data.coef),
        type_calcul: payload.data.type_calcul,
        tva: payload.data.tva,
        lines: payload.data.lines
      };
      if (payload.column == 'tva' || payload.column == 'coef') {
        variable.tva = 0;
        variable.coef = 0;
        if (variable.lines.length) {
          variable.lines.forEach(element => {
            if (element.id == payload.row.id) {
              element[payload.column] = parseFloat(payload.value);
            }
            variable.coef += parseFloat(element.coef);
            variable.tva += parseFloat(element.tva);
          });
        }
      } else {
        let index = variable.lines.findIndex(item => item.id == payload.row.id);
        if (index >= 0) {
          variable.lines[index][payload.column] = payload.value;
        }
      }
      try {
        const response = await axios.put(
          domain + `/api/filiale/master-filiales/update/${payload.data.id}`,
          variable,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('SET_DATA_CINFIG_MF_SUM', {
          payload: payload.data,
          response: response.data.filiales.filter(
            item => item.id == payload.data.id
          )
        });
        let res = response.data.filiales.filter(
          item => item.id == payload.data.id
        );
        res = res[0]?.lines.filter(item => item.id == payload.row.id);
        commit('SET_DATA_CINFIG_MF', {
          payload: payload,
          response: res[0]
        });

        commit('SET_LOADER_ADD', false);

        return true;
      } catch (error) {
        if (error && error.response && error.response.data) {
          commit('SET_ERROR_CONFIG', error.response.data.errors.coef);
          commit('SET_ERROR_BEGIN_DATE', error.response.data.errors.begin_date);
          commit('SET_ERROR_END_DATE', error.response.data.errors.end_date);

          commit(
            'SET_ERROR_VARIABLE_MASTER_FILIALE',
            'Une erreur est survenue'
          );
          commit('SET_LOADER_ADD', false);
          return false;
        }
      }
    },
    async deletLinesGestionMasterFiliale({ commit }, payload) {
      commit('SET_LOADER_ADD', true);
      var variable = {
        begin_date: payload.row.begin_date,
        end_date: payload.row.end_date,
        coef:
          parseFloat(payload.row.coef) -
          parseFloat(payload.row.lines[payload.index].coef),
        type_calcul: payload.row.type_calcul,
        tva:
          parseFloat(payload.row.tva) -
          parseFloat(payload.row.lines[payload.index].tva),
        deleted_Lines: [payload.row.lines[payload.index]],
        lines: payload.row.lines.filter((item, index) => index != payload.index)
      };
      try {
        const response = await axios.put(
          domain + `/api/filiale/master-filiales/update/${payload.row.id}`,
          variable,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('SET_DATA_CINFIG_MF', {
          payload: payload,
          response: response.data.filiales.filter(
            item => item.id == payload.row.id
          )[0]
        });
        commit('SET_LOADER_ADD', false);

        return true;
      } catch (error) {
        if (error && error.response && error.response.data) {
          commit('SET_ERROR_CONFIG', error.response.data.errors.coef);
          commit('SET_ERROR_BEGIN_DATE', error.response.data.errors.begin_date);
          commit('SET_ERROR_END_DATE', error.response.data.errors.end_date);

          commit(
            'SET_ERROR_VARIABLE_MASTER_FILIALE',
            'Une erreur est survenue'
          );
          commit('SET_LOADER_ADD', false);
          return false;
        }
      }
    },
    async addNewLinesInfilialeParamsMasterFiliale({ commit }, payload) {
      commit('SET_LOADER_ADD', true);
      var variable = {
        begin_date: payload.row.begin_date,
        end_date: payload.row.end_date,
        coef: parseFloat(payload.row.coef),
        type_calcul: payload.row.type_calcul,
        tva: payload.row.tva,
        lines: payload.row.lines.filter(
          (item, index) => item.id || (!item.id && index == payload.indexc)
        )
      };

      if (variable.lines.length) {
        variable.tva = 0;
        variable.coef = 0;
        variable.lines.forEach(element => {
          variable.coef += parseFloat(element.coef);
          variable.tva += parseFloat(element.tva);
        });
      }
      try {
        const response = await axios.put(
          domain + `/api/filiale/master-filiales/update/${payload.row.id}`,
          variable,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        let res = response.data.filiales.filter(
          item => item.id == payload.row.id
        )[0];
        commit('SET_DATA_CINFIG_MF', {
          payload: payload,
          response: res
        });
        commit('SET_DATA_CINFIG_MF_UPDATE_LIGNES', {
          payload: payload,
          response: res
        });
        commit('SET_LOADER_ADD', false);

        return true;
      } catch (error) {
        if (error && error.response && error.response.data) {
          commit('SET_ERROR_CONFIG', error.response.data.errors.coef);
          commit('SET_ERROR_BEGIN_DATE', error.response.data.errors.begin_date);
          commit('SET_ERROR_END_DATE', error.response.data.errors.end_date);

          commit(
            'SET_ERROR_VARIABLE_MASTER_FILIALE',
            'Une erreur est survenue'
          );
          commit('SET_LOADER_ADD', false);
          return false;
        }
      }
    },
    resetMutationsError({ commit }) {
      commit('SET_ERROR_ADD_MASTER_FILIALE', null);
      commit('SET_ERROR_CONFIG', null);
      commit('SET_ERROR_BEGIN_DATE', null);
      commit('SET_ERROR_END_DATE', null);
      commit('SET_LOADER_ADD', false);
    }
  }
};
