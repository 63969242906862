import axios from 'axios';
import domain from '@/environment';
import FactureFiltered from '../../models/FactureFiltered';

const state = {
  chargementFactures: false,
  filteredFactures: [],
  errorFactures: null,
  totalFactures: null,
  totalRowsFactures: null
};
const getters = {
  getChargementFactures: state => state.chargementFactures,
  getErrorFactures: state => state.errorFactures,
  getFilteredFactures: state => state.filteredFactures,
  getTotalFactures: state => state.totalFactures,
  getTotalRowsFactures: state => state.totalRowsFactures
};
const mutations = {
  SET_LOADING_FACTURES(state, payload) {
    state.chargementFactures = payload;
  },
  SET_FILTRED_FACTURES(state, payload) {
    state.filteredFactures = payload.map(item => FactureFiltered.create(item));
  },
  SET_ERROR_FACTURES(state, payload) {
    state.errorFactures = payload;
  },
  SET_TOTAL_ROW_FACTURES(state, payload) {
    state.totalFactures = payload;
  },
  SET_PAGINATION_FACTURES(state, payload) {
    state.totalRowsFactures = payload;
  },
  UPDATE_LOCK_FACTURE(state, payload) {
    state.totalRowsFactures = payload;
  }
};
const actions = {
  async getFacturesFiltred({ commit }, payload) {
    commit('SET_ERROR_FACTURES', null);
    commit('SET_LOADING_FACTURES', true);
    try {
      const params = new FormData();
      params.append('page', payload.page);
      params.append('per_page', payload.per_page);

      if (payload?.acheteur.length) {
        for (let i = 0; i < payload.acheteur.length; i++) {
          params.append('acheteur[' + i + ']', payload.acheteur[i].value);
        }
      }
      if (payload?.vendeur.length) {
        for (let i = 0; i < payload.vendeur.length; i++) {
          params.append('vendeur[' + i + ']', payload.vendeur[i].value);
        }
      }
      if (payload?.date_facturation != null) {
        params.append('date_facturation', payload.date_facturation);
      }
      if (payload?.date_paiement != null) {
        params.append('date_paiement', payload.date_paiement);
      }
      if (payload?.date_reglement != null) {
        params.append('date_reglement', payload.date_reglement);
      }
      if (payload?.numero_facture != null) {
        params.append('numero_facture', payload.numero_facture);
      }
      const response = await axios.post(
        domain + `/api/factures/dashboard`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_FILTRED_FACTURES', response.data.data);
      commit('SET_LOADING_FACTURES', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_FACTURES', error.response.data.error);
      } else if (error.message !== 'cancel-request') {
        commit('SET_LOADING_FACTURES', false);
        commit('SET_ERROR_FACTURES', ['Une erreur est survenue']);
      }

      return false;
    }
  },
  async updateLockFacture({ commit }, payload) {
    commit('SET_ERROR_FACTURES', null);
    commit('SET_LOADING_FACTURES', true);
    try {
      const params = {
        id: payload.id,
        locked: payload.locked
      };
      const response = await axios.post(
        domain + `/api/factures/` + params.id + '/lock-unlock',
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LOADING_FACTURES', false);
      commit('UPDATE_LOCK_FACTURE', response.data.data);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_FACTURES', error.response.data.error);
      } else if (error.message !== 'cancel-request') {
        commit('SET_LOADING_FACTURES', false);
        commit('SET_ERROR_FACTURES', ['Une erreur est survenue']);
      }

      return false;
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
