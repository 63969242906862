import axios from 'axios';
import domain from '@/environment';
import mailCdg from '../../models/mailCdg';
const state = {
  loadingMailDataCdg: false,
  loadingSendMailDataCdg: false,
  errorMailDataCdg: null,
  errorSendMailDataCdg: null,
  dataForMailModelCdg: [],
  errorSendMailCdg: null,
  loaderSendMailCdg: false
};
const getters = {
  getDataForMailModelCdg: state => state.dataForMailModelCdg,
  getLoadingMailDataCdg: state => state.loadingMailDataCdg,
  getErrorMailDataCdg: state => state.errorMailDataCdg,
  getLoadingSendMailDataCdg: state => state.loadingMailDataCd,
  getErrorSendMailDataCdg: state => state.errorSendMailDataCdg,
  getErrorSendMailCdg: state => state.errorSendMailCdg,
  getLoaderSendMailCdg: state => state.loaderSendMailCdg
};
const mutations = {
  SET_LOADER_GET_DATA_MAIL_CDG(state, payload) {
    state.loadingMailDataCdg = payload;
  },
  SET_ERROR_DATA_MAIL_CDG(state, payload) {
    state.errorMailDataCdg = payload;
  },
  SET_DATA_MAIL_CDG(state, payload) {
    state.dataForMailModelCdg = payload.map(item => mailCdg.create(item));
  },
  SET_ERROR_SEND_MAIL_CDG(state, payload) {
    state.errorSendMailCdg = payload;
  },
  SET_LOADER_SEND_MAIL_CDG(state, payload) {
    state.loaderSendMailCdg = payload;
  },
  RESET_DATA_MAIL_CDG(state, payload) {
    state.dataForMailModelCdg = payload;
  }
};
const actions = {
  async fetchDataForMailModelCdg({ commit }, payload) {
    commit('SET_ERROR_DATA_MAIL_CDG', null);
    let params = { ids: payload.data.map(item => item[payload.selecteur]) };
    commit('SET_LOADER_GET_DATA_MAIL_CDG', true);
    try {
      const response = await axios.post(
        domain + `/api/libre/mailling2`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_DATA_MAIL_CDG', response.data.data);
      commit('SET_LOADER_GET_DATA_MAIL_CDG', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_DATA_MAIL_CDG', error.response.data);
      } else {
        commit('SET_ERROR_DATA_MAIL_CDG', 'Une erreur est survenue');
      }
      commit('SET_LOADER_GET_DATA_MAIL_CDG', false);

      return false;
    }
  },
  async sendMailCdg({ commit }, payload) {
    commit('SET_ERROR_SEND_MAIL_CDG', null);
    commit('SET_LOADER_SEND_MAIL_CDG', true);
    try {
      const response = await axios.post(
        domain + `/api/libre/send_mail2`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      commit('SET_LOADER_SEND_MAIL_CDG', false);
      return { succes: true, data: response.data.data };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        commit('SET_ERROR_SEND_MAIL_CDG', error.response.data.errors);
      } else {
        commit('SET_ERROR_SEND_MAIL_CDG', 'Une erreur est survenue');
      }
      commit('SET_LOADER_SEND_MAIL_CDG', false);
      return { succes: false };
    }
  },
  resetDataModalMailCdg({ commit }) {
    commit('RESET_DATA_MAIL_CDG', []);
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
