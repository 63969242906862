import axios from 'axios';
import domain from '@/environment';
const actions = {
  async refreshFactureCdg({ commit }, payload) {
    try {
      const response = await axios.post(
        `${domain}/api/libre/refresh-templates`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { succes: true, data: response.data };
    } catch (error) {
      return { succes: false, error: 'Une erreur est servenue' };
    }
  },
  async fecthFactureNumbersInExcelFile({ commit }, payload) {
    var bodyFormData = new FormData();
    bodyFormData.append('file', payload);
    try {
      const response = await axios.post(
        `${domain}//api/facture/num_factures`,
        bodyFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { succes: true, data: response.data.data };
    } catch (error) {
      if (error?.response?.data?.error) {
        return { succes: false, error: error.response.data.error };
      } else {
        return { succes: false, error: 'Une erreur est servenue' };
      }
    }
  }
};
export default {
  actions
};
