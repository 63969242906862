export default class itemExport {
  constructor(data) {
    this.id = data.id;
    this.comment = data.comment;
    this.file_name = data.file_name;
    this.created_at = data.created_at;
    this.loaderDelet = data.loaderDelet;
    this.file_type = data.file_type;
    this.user_id = data.user_id;
    this.loaderExport = data.loaderExport;
  }
  static create(data) {
    return new itemExport(data);
  }
}
