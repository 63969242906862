import AppelSubObligeeTh from './AppelSubObligeeTh';
export default class AppelObligeTh {
  constructor(data) {
    this.name = data.name;
    this.check = false;
    this.details = false;
    this.showDetails = false;

    this.ttc_avoir = data.ttc_avoir;
    this.kwh = data.kwh;
    this.mega = data.mega;
    this.cee = data.cee;
    this.montant_total_ttc = data.montant_total_ttc;
    this.reste_a_charge = data.reste_a_charge;

    this.id_vendeur = data.id_vendeur;
    this.id_acheteur = data.id_acheteur;
    this.id_group = data.id_group;
    this.name_acheteur = data.name_acheteur;
    this.name_group = data.name_group;
    this.name_vendeur = data.name_vendeur;
    this.warning = data.warning;
    this.can_reload = data.can_reload;
    this.error_missing_prime = data.error_missing_prime;
    ///error

    this.error_vendeur = data.error_vendeur;
    this.error_acheteur = data.error_acheteur;
    this.error_group = data.error_group;
    this.error_avoir = data.error;
    //missing prime
    this.missing_prime_ht = data.missing_prime_ht;
    this.missing_prime_tva = data.missing_prime_tva;
    this.missing_prime_ttc = data.missing_prime_ttc;
    this.missing_prime_id = data.missing_prime_id;
    this.missing_prime_statut = data.missing_prime_statut;
    this.missing_num_facture = data.missing_num_facture;
    this.missing_prime_avoir = data.missing_prime_avoir;
    this.missing_prime_avoirs = data.missing_prime_avoirs;
    this.missing_prime_avoir_id = data.missing_prime_avoir_id;
    this.missing_prime_reste_avoir = data.missing_prime_reste_avoir;
    this.missing_prime_avoirs = data.missing_prime_avoirs;
    this.missing_prime_factures = data.missing_prime_factures;
    this.missing_prime_factures_id = data.missing_prime_factures_id;

    //avoir prime
    this.missing_prime_av_ht = data.missing_prime_av_ht;
    this.missing_prime_av_tva = data.missing_prime_av_tva;
    this.missing_prime_av_ttc = data.missing_prime_av_ttc;
    this.missing_prime_av_id = data.missing_prime_av_id;
    this.missing_prime_av_statut = data.missing_prime_av_statut;

    //prime
    this.prime_ht = data.prime_ht;
    this.prime_tva = data.prime_tva;
    this.prime_ttc = data.prime_ttc;
    this.prime_id = data.prime_id;
    this.prime_statut = data.prime_statut;
    this.prime_num_facture = data.prime_num_facture;
    this.prime_avoir_id = data.prime_avoir_id;
    this.prime_avoir = data.prime_avoir;
    this.prime_avoirs = data.prime_avoirs;
    this.prime_reste_avoir = data.prime_reste_avoir;
    this.prime_avoirs = data.prime_avoirs;
    this.prime_factures = data.prime_factures;
    this.prime_factures_id = data.prime_factures_id;

    //surprime
    this.surprime_ht = data.surprime_ht;
    this.surprime_tva = data.surprime_tva;
    this.surprime_ttc = data.surprime_ttc;
    this.surprime_id = data.surprime_id;
    this.surprime_statut = data.surprime_statut;
    this.surprime_num_facture = data.surprime_num_facture;
    this.surprime_avoir = data.surprime_avoir;
    this.surprime_avoir_id = data.surprime_avoir_id;
    this.surprime_avoirs = data.surprime_avoirs;
    this.surprime_reste_avoir = data.surprime_reste_avoir;
    this.surprime_avoirs = data.surprime_avoirs;
    this.surprime_factures = data.surprime_factures;
    this.surprime_factures_id = data.surprime_factures_id;

    //commission
    this.commission_ht = data.commission_ht;
    this.commission_tva = data.commission_tva;
    this.commission_ttc = data.commission_ttc;
    this.commission_id = data.commission_id;
    this.commission_statut = data.commission_statut;
    this.commission_num_facture = data.commission_num_facture;
    this.commission_avoir_id = data.commission_avoir_id;
    this.commission_avoir = data.commission_avoir;
    this.commission_avoirs = data.commission_avoirs;
    this.commission_reste_avoir = data.commission_reste_avoir;
    this.commission_avoirs = data.commission_avoirs;
    this.commission_factures = data.commission_factures;
    this.commission_factures_id = data.commission_factures_id;

    //commission gestion
    this.commission_gestion_avoir = data.commission_gestion_avoir;
    this.commission_gestion_avoir_id = data.commission_gestion_avoir_id;
    this.commission_gestion_avoirs = data.commission_gestion_avoirs;
    this.commission_gestion_factures = data.commission_gestion_factures;
    this.commission_gestion_factures_id = data.commission_gestion_factures_id;
    this.commission_gestion_ht = data.commission_gestion_ht;
    this.commission_gestion_id = data.commission_gestion_id;
    this.commission_gestion_num_facture = data.commission_gestion_num_facture;
    this.commission_gestion_reste_avoir = data.commission_gestion_reste_avoir;
    this.commission_gestion_statut = data.commission_gestion_statut;
    this.commission_gestion_ttc = data.commission_gestion_ttc;
    this.commission_gestion_tva = data.commission_gestion_tva;
    this.commission_gestion_valid = data.commission_gestion_valid;

    //consolidee energie invest
    this.consolidee_energie_invest_avoir = data.consolidee_energie_invest_avoir;
    this.consolidee_energie_invest_avoir_id =
      data.consolidee_energie_invest_avoir_id;
    this.consolidee_energie_invest_avoirs =
      data.consolidee_energie_invest_avoirs;
    this.consolidee_energie_invest_factures =
      data.consolidee_energie_invest_factures;
    this.consolidee_energie_invest_factures_id =
      data.consolidee_energie_invest_factures_id;
    this.consolidee_energie_invest_ht = data.consolidee_energie_invest_ht;
    this.consolidee_energie_invest_id = data.consolidee_energie_invest_id;
    this.consolidee_energie_invest_num_facture =
      data.consolidee_energie_invest_num_facture;
    this.consolidee_energie_invest_reste_avoir =
      data.consolidee_energie_invest_reste_avoir;
    this.consolidee_energie_invest_statut =
      data.consolidee_energie_invest_statut;
    this.consolidee_energie_invest_ttc = data.consolidee_energie_invest_ttc;
    this.consolidee_energie_invest_tva = data.consolidee_energie_invest_tva;
    this.consolidee_energie_invest_valid = data.consolidee_energie_invest_valid;
    this.can_facture_commission = data.can_facture_commission;
    this.can_facture_commission_gestion = data.can_facture_commission_gestion;
    this.can_facture_consolidee_energie_invest =
      data.can_facture_consolidee_energie_invest;
    this.can_facture_surprime = data.can_facture_surprime;
    this.can_avoir_commission = data.can_avoir_commission;
    this.can_avoir_commission_gestion = data.can_avoir_commission_gestion;
    this.can_avoir_consolidee_energie_invest =
      data.can_avoir_consolidee_energie_invest;
    this.can_avoir_surprime = data.can_avoir_surprime;
    //total
    this.total = data.total;
    this.projects = data.projects.map(item => AppelSubObligeeTh.create(item));
  }
  updateCoef(data) {
    let column = 'pu_' + data.column;
    let column1 =
      data.column == 'commission'
        ? 'montant_' + data.column + 's_cee'
        : 'montant_' + data.column + '_cee';
    this[column] = (
      parseFloat((this[column] + '').replace(',', '.')) -
      parseFloat((data.item[data.column] + '').replace(',', '.')) +
      parseFloat((data.response[column1] + '').replace(',', '.'))
    ).toFixed(2);
    this.total = (
      parseFloat((this.total + '').replace(',', '.')) -
      parseFloat((data.item[data.column] + '').replace(',', '.')) +
      parseFloat((data.response[column1] + '').replace(',', '.'))
    ).toFixed(2);
    if (data.column == 'prime') {
      this.pu_missing_prime = (
        parseFloat((this.pu_missing_prime + '').replace(',', '.')) -
        parseFloat((data.item['missing_prime'] + '').replace(',', '.')) +
        parseFloat(
          (data.response['montant_missing_prime_cee'] + '').replace(',', '.')
        )
      ).toFixed(2);
      this.total = (
        parseFloat((this.total + '').replace(',', '.')) -
        parseFloat((data.item['missing_prime'] + '').replace(',', '.')) +
        parseFloat(
          (data.response['montant_missing_prime_cee'] + '').replace(',', '.')
        )
      ).toFixed(2);
    }
  }
  update(data) {
    this.ttc_avoir = data.ttc_avoir;
    this.kwh = data.kwh;
    this.mega = data.mega;
    this.cee = data.cee;
    this.montant_total_ttc = data.montant_total_ttc;
    this.reste_a_charge = data.reste_a_charge;

    this.id_vendeur = data.id_vendeur;
    this.id_acheteur = data.id_acheteur;
    this.id_group = data.id_group;
    this.name_acheteur = data.name_acheteur;
    this.name_group = data.name_group;
    this.name_vendeur = data.name_vendeur;
    this.warning = data.warning;
    this.can_reload = data.can_reload;
    this.error_missing_prime = data.error_missing_prime;
    ///error

    this.error_vendeur = data.error_vendeur;
    this.error_acheteur = data.error_acheteur;
    this.error_group = data.error_group;
    this.error_avoir = data.error;
    //missing prime
    this.missing_prime_ht = data.missing_prime_ht;
    this.missing_prime_tva = data.missing_prime_tva;
    this.missing_prime_ttc = data.missing_prime_ttc;
    this.missing_prime_id = data.missing_prime_id;
    this.missing_prime_statut = data.missing_prime_statut;
    this.missing_num_facture = data.missing_num_facture;
    this.missing_prime_avoir = data.missing_prime_avoir;
    this.missing_prime_avoirs = data.missing_prime_avoirs;
    this.missing_prime_avoir_id = data.missing_prime_avoir_id;
    this.missing_prime_reste_avoir = data.missing_prime_reste_avoir;
    this.missing_prime_factures = data.missing_prime_factures;
    this.missing_prime_factures_id = data.missing_prime_factures_id;

    //avoir prime
    this.missing_prime_av_ht = data.missing_prime_av_ht;
    this.missing_prime_av_tva = data.missing_prime_av_tva;
    this.missing_prime_av_ttc = data.missing_prime_av_ttc;
    this.missing_prime_av_id = data.missing_prime_av_id;
    this.missing_prime_av_statut = data.missing_prime_av_statut;
    this.missing_prime_av_num_facture = data.missing_prime_av_num_facture;

    //prime
    this.prime_ht = data.prime_ht;
    this.prime_tva = data.prime_tva;
    this.prime_ttc = data.prime_ttc;
    this.prime_id = data.prime_id;
    this.prime_statut = data.prime_statut;
    this.prime_num_facture = data.prime_num_facture;
    this.prime_avoir_id = data.prime_avoir_id;
    this.prime_avoir = data.prime_avoir;
    this.prime_avoirs = data.prime_avoirs;
    this.prime_reste_avoir = data.prime_reste_avoir;
    this.prime_factures = data.prime_factures;
    this.prime_factures_id = data.prime_factures_id;

    //surprime
    this.surprime_ht = data.surprime_ht;
    this.surprime_tva = data.surprime_tva;
    this.surprime_ttc = data.surprime_ttc;
    this.surprime_id = data.surprime_id;
    this.surprime_statut = data.surprime_statut;
    this.surprime_num_facture = data.surprime_num_facture;
    this.surprime_avoir = data.surprime_avoir;
    this.surprime_avoir_id = data.surprime_avoir_id;
    this.surprime_avoirs = data.surprime_avoirs;
    this.surprime_reste_avoir = data.surprime_reste_avoir;
    this.surprime_factures = data.surprime_factures;
    this.surprime_factures_id = data.surprime_factures_id;
    //commission
    this.commission_ht = data.commission_ht;
    this.commission_tva = data.commission_tva;
    this.commission_ttc = data.commission_ttc;
    this.commission_id = data.commission_id;
    this.commission_statut = data.commission_statut;
    this.commission_num_facture = data.commission_num_facture;
    this.commission_avoir_id = data.commission_avoir_id;
    this.commission_avoir = data.commission_avoir;
    this.commission_avoirs = data.commission_avoirs;
    this.commission_reste_avoir = data.commission_reste_avoir;
    this.commission_factures = data.commission_factures;
    this.commission_factures_id = data.commission_factures_id;

    //commission gestion
    this.commission_gestion_avoir = data.commission_gestion_avoir;
    this.commission_gestion_avoir_id = data.commission_gestion_avoir_id;
    this.commission_gestion_avoirs = data.commission_gestion_avoirs;
    this.commission_gestion_factures = data.commission_gestion_factures;
    this.commission_gestion_factures_id = data.commission_gestion_factures_id;
    this.commission_gestion_ht = data.commission_gestion_ht;
    this.commission_gestion_id = data.commission_gestion_id;
    this.commission_gestion_num_facture = data.commission_gestion_num_facture;
    this.commission_gestion_reste_avoir = data.commission_gestion_reste_avoir;
    this.commission_gestion_statut = data.commission_gestion_statut;
    this.commission_gestion_ttc = data.commission_gestion_ttc;
    this.commission_gestion_tva = data.commission_gestion_tva;
    this.commission_gestion_valid = data.commission_gestion_valid;

    //consolidee energie invest
    this.consolidee_energie_invest_avoir = data.consolidee_energie_invest_avoir;
    this.consolidee_energie_invest_avoir_id =
      data.consolidee_energie_invest_avoir_id;
    this.consolidee_energie_invest_avoirs =
      data.consolidee_energie_invest_avoirs;
    this.consolidee_energie_invest_factures =
      data.consolidee_energie_invest_factures;
    this.consolidee_energie_invest_factures_id =
      data.consolidee_energie_invest_factures_id;
    this.consolidee_energie_invest_ht = data.consolidee_energie_invest_ht;
    this.consolidee_energie_invest_id = data.consolidee_energie_invest_id;
    this.consolidee_energie_invest_num_facture =
      data.consolidee_energie_invest_num_facture;
    this.consolidee_energie_invest_reste_avoir =
      data.consolidee_energie_invest_reste_avoir;
    this.consolidee_energie_invest_statut =
      data.consolidee_energie_invest_statut;
    this.consolidee_energie_invest_ttc = data.consolidee_energie_invest_ttc;
    this.consolidee_energie_invest_tva = data.consolidee_energie_invest_tva;
    this.consolidee_energie_invest_valid = data.consolidee_energie_invest_valid;

    this.can_facture_commission = data.can_facture_commission;
    this.can_facture_commission_gestion = data.can_facture_commission_gestion;
    this.can_facture_consolidee_energie_invest =
      data.can_facture_consolidee_energie_invest;
    this.can_facture_surprime = data.can_facture_surprime;

    this.can_avoir_commission = data.can_avoir_commission;
    this.can_avoir_commission_gestion = data.can_avoir_commission_gestion;
    this.can_avoir_consolidee_energie_invest =
      data.can_avoir_consolidee_energie_invest;
    this.can_avoir_surprime = data.can_avoir_surprime;
    //total
    this.projects = data.projects.map(item => AppelSubObligeeTh.create(item));
  }
  updateData(data) {
    this.ttc_avoir = data.ttc_avoir;
    this.kwh = data.kwh;
    this.mega = data.mega;
    this.cee = data.cee;
    this.montant_total_ttc = data.montant_total_ttc;
    this.reste_a_charge = data.reste_a_charge;

    this.id_vendeur = data.id_vendeur;
    this.id_acheteur = data.id_acheteur;
    this.id_group = data.id_group;
    this.name_acheteur = data.name_acheteur;
    this.name_group = data.name_group;
    this.name_vendeur = data.name_vendeur;
    this.warning = data.warning;
    this.can_reload = data.can_reload;
    this.error_missing_prime = data.error_missing_prime;
    ///error

    this.error_vendeur = data.error_vendeur;
    this.error_acheteur = data.error_acheteur;
    this.error_group = data.error_group;
    this.error_avoir = data.error;
    //missing prime
    this.missing_prime_ht = data.missing_prime_ht;
    this.missing_prime_tva = data.missing_prime_tva;
    this.missing_prime_ttc = data.missing_prime_ttc;
    this.missing_prime_id = data.missing_prime_id;
    this.missing_prime_statut = data.missing_prime_statut;
    this.missing_num_facture = data.missing_num_facture;
    this.missing_prime_avoir = data.missing_prime_avoir;
    this.missing_prime_avoirs = data.missing_prime_avoirs;
    this.missing_prime_avoir_id = data.missing_prime_avoir_id;
    this.missing_prime_reste_avoir = data.missing_prime_reste_avoir;
    this.missing_prime_avoirs = data.missing_prime_avoirs;
    this.missing_prime_factures = data.missing_prime_factures;
    this.missing_prime_factures_id = data.missing_prime_factures_id;

    //avoir prime
    this.missing_prime_av_ht = data.missing_prime_av_ht;
    this.missing_prime_av_tva = data.missing_prime_av_tva;
    this.missing_prime_av_ttc = data.missing_prime_av_ttc;
    this.missing_prime_av_id = data.missing_prime_av_id;
    this.missing_prime_av_statut = data.missing_prime_av_statut;

    //prime
    this.prime_ht = data.prime_ht;
    this.prime_tva = data.prime_tva;
    this.prime_ttc = data.prime_ttc;
    this.prime_id = data.prime_id;
    this.prime_statut = data.prime_statut;
    this.prime_num_facture = data.prime_num_facture;
    this.prime_avoir_id = data.prime_avoir_id;
    this.prime_avoir = data.prime_avoir;
    this.prime_avoirs = data.prime_avoirs;
    this.prime_reste_avoir = data.prime_reste_avoir;
    this.prime_avoirs = data.prime_avoirs;
    this.prime_factures = data.prime_factures;
    this.prime_factures_id = data.prime_factures_id;

    //surprime
    this.surprime_ht = data.surprime_ht;
    this.surprime_tva = data.surprime_tva;
    this.surprime_ttc = data.surprime_ttc;
    this.surprime_id = data.surprime_id;
    this.surprime_statut = data.surprime_statut;
    this.surprime_num_facture = data.surprime_num_facture;
    this.surprime_avoir = data.surprime_avoir;
    this.surprime_avoir_id = data.surprime_avoir_id;
    this.surprime_avoirs = data.surprime_avoirs;
    this.surprime_reste_avoir = data.surprime_reste_avoir;
    this.surprime_avoirs = data.surprime_avoirs;
    this.surprime_factures = data.surprime_factures;
    this.surprime_factures_id = data.surprime_factures_id;

    //commission
    this.commission_ht = data.commission_ht;
    this.commission_tva = data.commission_tva;
    this.commission_ttc = data.commission_ttc;
    this.commission_id = data.commission_id;
    this.commission_statut = data.commission_statut;
    this.commission_num_facture = data.commission_num_facture;
    this.commission_avoir_id = data.commission_avoir_id;
    this.commission_avoir = data.commission_avoir;
    this.commission_avoirs = data.commission_avoirs;
    this.commission_reste_avoir = data.commission_reste_avoir;
    this.commission_avoirs = data.commission_avoirs;
    this.commission_factures = data.commission_factures;
    this.commission_factures_id = data.commission_factures_id;

    //commission gestion
    this.commission_gestion_avoir = data.commission_gestion_avoir;
    this.commission_gestion_avoir_id = data.commission_gestion_avoir_id;
    this.commission_gestion_avoirs = data.commission_gestion_avoirs;
    this.commission_gestion_factures = data.commission_gestion_factures;
    this.commission_gestion_factures_id = data.commission_gestion_factures_id;
    this.commission_gestion_ht = data.commission_gestion_ht;
    this.commission_gestion_id = data.commission_gestion_id;
    this.commission_gestion_num_facture = data.commission_gestion_num_facture;
    this.commission_gestion_reste_avoir = data.commission_gestion_reste_avoir;
    this.commission_gestion_statut = data.commission_gestion_statut;
    this.commission_gestion_ttc = data.commission_gestion_ttc;
    this.commission_gestion_tva = data.commission_gestion_tva;
    this.commission_gestion_valid = data.commission_gestion_valid;

    //consolidee energie invest
    this.consolidee_energie_invest_avoir = data.consolidee_energie_invest_avoir;
    this.consolidee_energie_invest_avoir_id =
      data.consolidee_energie_invest_avoir_id;
    this.consolidee_energie_invest_avoirs =
      data.consolidee_energie_invest_avoirs;
    this.consolidee_energie_invest_factures =
      data.consolidee_energie_invest_factures;
    this.consolidee_energie_invest_factures_id =
      data.consolidee_energie_invest_factures_id;
    this.consolidee_energie_invest_ht = data.consolidee_energie_invest_ht;
    this.consolidee_energie_invest_id = data.consolidee_energie_invest_id;
    this.consolidee_energie_invest_num_facture =
      data.consolidee_energie_invest_num_facture;
    this.consolidee_energie_invest_reste_avoir =
      data.consolidee_energie_invest_reste_avoir;
    this.consolidee_energie_invest_statut =
      data.consolidee_energie_invest_statut;
    this.consolidee_energie_invest_ttc = data.consolidee_energie_invest_ttc;
    this.consolidee_energie_invest_tva = data.consolidee_energie_invest_tva;
    this.consolidee_energie_invest_valid = data.consolidee_energie_invest_valid;

    this.can_facture_commission = data.can_facture_commission;
    this.can_facture_commission_gestion = data.can_facture_commission_gestion;
    this.can_facture_consolidee_energie_invest =
      data.can_facture_consolidee_energie_invest;
    this.can_facture_surprime = data.can_facture_surprime;

    this.can_avoir_commission = data.can_avoir_commission;
    this.can_avoir_commission_gestion = data.can_avoir_commission_gestion;
    this.can_avoir_consolidee_energie_invest =
      data.can_avoir_consolidee_energie_invest;
    this.can_avoir_surprime = data.can_avoir_surprime;
    //total
    this.total = data.total;
    this.projects = data.projects.map(item => AppelSubObligeeTh.create(item));
  }

  static create(data) {
    return new AppelObligeTh(data);
  }
}
