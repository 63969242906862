import axios from 'axios';
import domain from '@/environment';
import Row from '../../models/Row';
import Chart from '../../models/Chart';
import Filiale from '../../models/Filiale';
import Doublent from '../../models/Doublent';
import store from '../index';
import { Vue } from 'vue-property-decorator';
let request = null;
const state = {
  data: null,
  error: null,
  loading: false,
  statu: '----',
  totalRows: null,
  validiteData: null,
  AnomalieData: null,
  statusForRefrechData: false,
  progress: null,
  loadingEdit: false,
  chargement: false,
  validation: null,
  ListAdminGE: null,
  ListCommercialSedentaire: null,
  ListCommercialTerrain: null,
  ListPoseur: null,
  ListPrevisiteur: null,
  searchLoading: false,
  totalSurface: null,
  intervaleValidation: null,
  intervalSemaineValider: null,
  // deValidation: false , : updated => no validation variable returned from back => rapport toujour a editable
  deValidation: true,
  progressValidation: null,
  progressValidationP: null,
  changePourcentage: false,
  filiales: null,
  anomalieLis: [
    'regie',
    'confirmateur',
    'administrateur',
    'poseur',
    'coposeurs',
    'depot_stockage',
    'responsable_zone',
    'agent_commercial_terrain',
    'previsiteur',
    'surface_101',
    'surface_102',
    'surface_103',
    'total_surface_a_isoler',
    'surface_103_plafonds',
    'surface_101_cp_deroule',
    'surface_101_cp_souffle',
    'surface_101_rampant',
    'surface_102_pignons',
    'surface_102_murs_iti',
    'surface_102_murs_ite',
    'surface_103_plafond_sous_sol',
    'surface_103_plafond_garage',
    'surface_103_plafond_cave',
    'surface_103_plafond_autres',
    'surface_103_vide_sanitaire'
  ],
  FilialeSelectedRapport: null,
  dossierDoublent: [],
  missigSemaineRapport: null
};
const getters = {
  getLoading: state => state.loading,
  getError: state => state.error,
  getData: state => state.data,
  getStatu: state => state.statu,
  getTotalRows: state => state.totalRows,
  getValiditeData: state => state.validiteData,
  getAnomalieData: state => state.AnomalieData,
  getStatusForRefrechData: state => state.statusForRefrechData,
  getProgress: state => state.progress,
  getloadingEdit: state => state.loadingEdit,
  getChargement: state => state.chargement,
  getValidation: state => state.validation,
  getListAdminGE: state => state.ListAdminGE,
  getListCommercialSedentaire: state => state.ListCommercialSedentaire,
  getListCommercialTerrain: state => state.ListCommercialTerrain,
  getListPoseur: state => state.ListPoseur,
  getListPrevisiteur: state => state.ListPrevisiteur,
  getSearchLoading: state => state.searchLoading,
  getTotalSurface: state => state.totalSurface,
  getInterval: state => state.intervaleValidation,
  getIntervalSemaineValider: state => state.intervalSemaineValider,
  getDeValidation: state => state.deValidation,
  getProgressValidation: state => state.progressValidation,
  getChangePourcentage: state => state.changePourcentage,
  getFiliales: state => state.filiales,
  getFilialeSelectedRapport: state => state.FilialeSelectedRapport,
  getProgressValidationP: state => state.progressValidationP,
  getDossierDoublent: state => state.dossierDoublent,
  getMissigSemaineRapport: state => state.missigSemaineRapport
};
const mutations = {
  SET_LOADING_EDIT(state, payload) {
    state.loadingEdit = payload;
  },
  SET_CHARGEMENT(state, payload) {
    state.chargement = payload;
  },
  SET_PROGRESS(state, payload) {
    state.progress = payload;
  },
  SET_STATUS_FOR_REFRECH_DATA(state) {
    state.statusForRefrechData = !state.statusForRefrechData;
  },
  SET_STATU(state, payload) {
    state.statu = payload;
  },
  TOTAL_ROWS(state, payload) {
    state.totalRows = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_ROWS(state, payload) {
    /*state.data = [];
    for (let row of payload) {
      state.data.push(Row.create(row));
    }*/
    state.data = payload.map(item => Row.create(item));
  },
  UPDATE_ROW(state, payload) {
    payload.row.update(payload.response);
  },
  SET_ERROR(state, payload) {
    state.error = payload;
  },
  SET_PORCENTAGE_TYPE(state, payload) {
    var type = [
      { name: 'Tous', pourcentage: 0 },
      { name: 'Invalide', pourcentage: payload.response.invalides },
      { name: 'Valide', pourcentage: payload.response.valides }
    ];
    if (state.validiteData === null) {
      state.validiteData = type.map(item => Chart.create(item));
    } else {
      if (payload.changeWeekYear && payload.changeWeekYear == true) {
        state.validiteData = null;
        state.validiteData = type.map(item => Chart.create(item));
      } else {
        var itemtoupdate;
        payload.type.map(item => {
          (itemtoupdate = type.filter(row => {
            if (row.name == item.name) return row;
          })),
            item.update(itemtoupdate[0]);
        });
      }
    }
  },
  SET_PORCENTAGE_ANOMALIE(state, payload) {
    if (state.AnomalieData === null) {
      state.AnomalieData = payload.response.map(item => Chart.create(item));
    } else {
      if (payload.changeWeekYear && payload.changeWeekYear == true) {
        state.AnomalieData = null;
        state.AnomalieData = payload.response.map(item => Chart.create(item));
        state.changePourcentage = !state.changePourcentage;
      } else {
        var itemtoupdate;
        payload.anomalies.map(item => {
          (itemtoupdate = payload.response.filter(row => {
            if (row.name == item.name) return row;
          })),
            item.update(itemtoupdate[0]);
        });
      }
    }
  },
  SET_VALIDATION(state, payload) {
    state.validation = payload;
  },
  SET_EMPLOYE_LIST_FOR_ALL_TYPE(state, payload) {
    state.ListAdminGE = payload.AdminGE;
    state.ListCommercialSedentaire = payload.commercialSedentaire;
    state.ListCommercialTerrain = payload.commercialTerrain;
    state.ListPoseur = payload.poseur;
    state.ListPrevisiteur = payload.previsiteur;
  },
  SET_LOADING_SEARCH(state, payload) {
    state.searchLoading = payload;
  },
  SET_TOTAL_SURFACE(state, payload) {
    state.totalSurface = payload;
  },
  SET_INTERVAL_VALIDATION(state, payload) {
    state.intervaleValidation = payload;
  },
  SET_SEMAINE_VALIDER(state, payload) {
    state.intervalSemaineValider = payload;
  },
  SET_DEVALIDATION_PARAMS(state, payload) {
    state.deValidation = payload;
  },
  SET_PROGRESS_VALIDATION(state, payload) {
    if (state.progressValidationP == 100) {
      state.progressValidationP = null;
    }
    if (state.progressValidationP == null) {
      state.progressValidationP = 0;
    }
    state.progressValidation = payload;
    state.progressValidationP = state.progressValidationP + payload.percent;
  },
  SET_RERRESH_CHART_DATA(state) {
    state.changePourcentage = !state.changePourcentage;
  },
  SET_ALL_FILIALES(state, responce) {
    state.filiales = responce.map(item => Filiale.create(item));
  },
  SET_FILIALE_ANOMALIES(state, { response, filiale }) {
    filiale.update(response);
  },
  SET_FILIALE_RAPPORT(state, payload = null) {
    state.FilialeSelectedRapport = payload;

    if (state.FilialeSelectedRapport !== null) {
      state.FilialeSelectedRapport['anomalies_per_depots'] = [];
    }
    if (
      state.FilialeSelectedRapport !== null &&
      state.FilialeSelectedRapport &&
      state.FilialeSelectedRapport.anomalies_per_depot &&
      Object.keys(state.FilialeSelectedRapport.anomalies_per_depot).length !== 0
    ) {
      for (const [key, value] of Object.entries(
        state.FilialeSelectedRapport.anomalies_per_depot
      )) {
        state.FilialeSelectedRapport.anomalies_per_depots.push(value);
      }
    }
  },
  SET_DOUBLENT_DOSSIER(state, payload) {
    state.dossierDoublent = payload.map(item => Doublent.create(item));
  },
  DELETE_DOUBLENT_DOSSIER(state, payload) {
    for (let index = 0; index < state.dossierDoublent.length; index++) {
      if (state.dossierDoublent[index].id == payload.id) {
        state.dossierDoublent.splice(index, 1);
        break;
      }
    }
  },
  RESET_DATA_RAPPORT(state) {
    state.data = null;
    state.validiteData = null;
    state.AnomalieData = null;
    state.filiales = null;
    state.dossierDoublent = [];
    state.totalSurface = null;
  },
  SET_MISSING_SEMAINE_RAPPORT(state, payload) {
    state.missigSemaineRapport = payload;
  }
};
const actions = {
  async uploadFile({ commit }, payload) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    var bodyFormData = new FormData();
    bodyFormData.append('file', payload.file);
    bodyFormData.append('annee', payload.annee);
    if (store.state.currentType && store.state.currentType.value) {
      bodyFormData.append('type', store.state.currentType.value.toUpperCase());
      if (store.state.currentType.value == 'iso') {
        bodyFormData.append('semaine', payload.semaine);
      }
      // else {
      //   bodyFormData.append('depot', payload.depot);
      // }
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${this.state.User.access_token}`
      }
    };
    try {
      const responce = await axios.post(
        domain + '/api/analyse',
        bodyFormData,
        config
      );
      commit('SET_PROGRESS', null);
      commit('SET_LOADING', false);
      return true;
    } catch (error) {
      commit('SET_LOADING', false);
      if (
        error.response &&
        error.response.data.extra &&
        error.response.data.missing
      ) {
        commit('SET_ERROR', {
          extra: error.response.data.extra,
          missing: error.response.data.missing
        });
      } else {
        commit('SET_ERROR', error.response.data.error);
      }
      return false;
    }
  },
  async CheckuploadFileTh({ commit }, payload) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    var bodyFormData = new FormData();
    bodyFormData.append('file', payload.file);
    bodyFormData.append('annee', payload.annee);
    if (store.state.currentType && store.state.currentType.value) {
      bodyFormData.append('type', store.state.currentType.value.toUpperCase());
      if (store.state.currentType.value == 'iso') {
        bodyFormData.append('semaine', payload.semaine);
      }
      // else {
      //   bodyFormData.append('depot', payload.depot);
      // }
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${this.state.User.access_token}`
      }
    };
    try {
      const responce = await axios.post(
        domain + '/api/check_file_before_analyse',
        bodyFormData,
        config
      );
      commit('SET_PROGRESS', null);
      commit('SET_LOADING', false);
      if (responce.data.empty) {
        return {
          succes: true
        };
      } else {
        commit('SET_ERROR', responce.data);
        return {
          succes: false
        };
      }
    } catch (error) {
      commit('SET_LOADING', false);
      if (
        error.response &&
        error.response.data.extra &&
        error.response.data.missing
      ) {
        commit('SET_ERROR', {
          extra: error.response.data.extra,
          missing: error.response.data.missing
        });
      } else if (error?.response?.data?.error) {
        commit('SET_ERROR', error.response.data.error);
      } else {
        commit('SET_ERROR', ['Une erreur est servenue']);
      }
      return {
        succes: false
      };
    }
  },
  
  async uploadFileTh({ commit }, payload) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    var bodyFormData = new FormData();
    bodyFormData.append('file', payload.file);
    bodyFormData.append('annee', payload.annee);
    bodyFormData.append('force', payload.force);
    if (store.state.currentType && store.state.currentType.value) {
      bodyFormData.append('type', store.state.currentType.value.toUpperCase());
      if (store.state.currentType.value == 'iso') {
        bodyFormData.append('semaine', payload.semaine);
      }
      // else {
      //   bodyFormData.append('depot', payload.depot);
      // }
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${this.state.User.access_token}`
      }
    };
    try {
      const responce = await axios.post(
        domain + '/api/analyse',
        bodyFormData,
        config
      );
      commit('SET_PROGRESS', null);
      commit('SET_LOADING', false);
      return {
        succes: true
      };
    } catch (error) {
      commit('SET_LOADING', false);
      if (
        error.response &&
        error.response.data.extra &&
        error.response.data.missing
      ) {
        commit('SET_ERROR', {
          extra: error.response.data.extra,
          missing: error.response.data.missing
        });
      } else {
        commit('SET_ERROR', error.response.data.error);
      }
      return {
        succes: false
      };
    }
  },
  async fetchAnalyse({ commit, state }, payload) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    commit('SET_VALIDATION', null);
    //commit('SET_CHARGEMENT', false);
    commit('SET_LOADING_EDIT', false);
    const body = {
      page: payload.page,
      per_page: payload.per_page,
      annee: payload.annee
    };
    try {
      if (payload.switchMonth == true) {
        body.mois = payload.month;
      } else {
        body.semaine = 's' + payload.semaine.value;
      }
      if (payload.type === 'valides') {
        body.type = payload.type;
      } else if (payload.type === 'invalides') {
        body.type = payload.type;
        if (payload.anomalies.length !== 0) {
          body.anomalies = payload.anomalies;
        }
      }
      if (
        payload.champ &&
        (payload.champ !== null || payload.champ !== '') &&
        (payload.name !== null || payload.name !== '')
      ) {
        body.champ = payload.champ.key;
        body.search = payload.name;
      }
      if (payload.filiale_id != null && payload.filiale_id.id) {
        body.filiale_id = payload.filiale_id.id;
      }
      const response = await axios.get(domain + `/api/getanalyses`, {
        params: body,
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      if (response.data.error) {
        commit('SET_ERROR', response.data.error);
        commit('SET_STATU', null);
      } else {
        commit('SET_ROWS', response.data.data.data);
        commit('SET_TOTAL_SURFACE', response.data.total_surface);
        commit('SET_INTERVAL_VALIDATION', response.data.interval);
        commit('TOTAL_ROWS', response.data.data.total);
        if (state.progress >= 100 || state.progress === null) {
          commit('SET_STATU', response.data.meta[0].statue);
          commit('SET_VALIDATION', response.data.validation);
          commit('SET_PORCENTAGE_ANOMALIE', {
            response: response.data.anomalies,
            anomalies: payload.anom,
            changeWeekYear: payload.changeWeekYear
              ? payload.changeWeekYear
              : null
          });
          commit('SET_PORCENTAGE_TYPE', {
            response: response.data.meta[0].pourcentages,
            type: payload.typeData,
            changeWeekYear: payload.changeWeekYear
              ? payload.changeWeekYear
              : null
          });
        } else {
          state.AnomalieData = null;
          state.validiteData = null;
        }
      }
      commit('SET_LOADING', false);
      return true;
    } catch (error) {
      commit('SET_LOADING', false);
      return false;
    }
  },
  async fetchAnalyseChart({ commit, state }, payload) {
    commit('SET_LOADING_EDIT', false);
    commit('SET_CHARGEMENT', true);
    commit('SET_ERROR', null);
    const body = {
      page: payload.page,
      per_page: payload.per_page,
      annee: payload.annee
    };
    try {
      if (request) {
        request.cancel('cancel-request');
      }
      request = axios.CancelToken.source();
      if (payload.switchMonth == true) {
        body.mois = payload.month;
      } else {
        body.semaine = 's' + payload.semaine.value;
      }
      if (payload.type === 'valides') {
        body.type = payload.type;
      } else if (payload.type === 'invalides') {
        body.type = payload.type;
        if (payload.anomalies.length !== 0) {
          body.anomalies = payload.anomalies;
        }
      }
      if (
        payload.champ &&
        (payload.champ !== null || payload.champ !== '') &&
        (payload.name !== null || payload.name !== '')
      ) {
        body.champ = payload.champ.key;
        body.search = payload.name;
      }
      if (payload.filiale_id != null && payload.filiale_id.id) {
        body.filiale_id = payload.filiale_id.id;
      }
      const response = await axios.get(domain + `/api/getanalyses`, {
        params: body,
        cancelToken: request.token,
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      if (response.data.error) {
        commit('SET_ERROR', response.data.error);
      } else {
        commit('SET_ROWS', response.data.data.data);
        commit('SET_TOTAL_SURFACE', response.data.total_surface);
        commit('SET_INTERVAL_VALIDATION', response.data.interval);
        commit('TOTAL_ROWS', response.data.data.total);
        if (state.progress >= 100 || state.progress === null) {
          commit('SET_STATU', response.data.meta[0].statue);
          commit('SET_VALIDATION', response.data.validation);
          commit('SET_PORCENTAGE_TYPE', {
            response: response.data.meta[0].pourcentages,
            type: payload.typeData,
            changeWeekYear: true
          });
          commit('SET_PORCENTAGE_ANOMALIE', {
            response: response.data.anomalies,
            anomalies: payload.anom,
            changeWeekYear: true
          });
        } else {
          state.AnomalieData = null;
          state.validiteData = null;
        }
      }
      commit('SET_CHARGEMENT', false);
      return true;
    } catch (error) {
      if (error.message !== 'cancel-request') {
        commit('SET_CHARGEMENT', false);
      }
      return false;
    }
  },
  async updateRow({ commit, dispatch }, payload) {
    commit('SET_LOADING', true);
    const body = { annee: payload.annee };
    if (payload.type === 'valides') {
      body.type = payload.type;
    } else if (payload.type === 'invalides') {
      body.type = payload.type;
      if (payload.anomalies.length !== 0) {
        body.anomalies = payload.anomalies;
      }
    }
    if (payload.switchMonth == true) {
      body.mois = payload.month;
    } else {
      body.semaine = 's' + payload.semaine.value;
    }
    if (
      payload.champ &&
      (payload.champ !== null || payload.champ !== '') &&
      (payload.name !== null || payload.name !== '')
    ) {
      body.champ = payload.champ.key;
      body.search = payload.name;
    }
    if (payload.filiale != null && payload.filiale.id) {
      body.filiale_id = payload.filiale.id;
    }
    if (
      (payload.column.includes('surface') ||
        payload.column.includes('a_2_euro') ||
        payload.column.includes('a_3_euro')) &&
      (payload.value == '' || payload.value == null)
    ) {
      payload.value = 0;
    }
    try {
      const response = await axios.put(
        domain +
          `/api/update/${payload.row.id}?column=${payload.column}&value=${payload.value}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: response.data.data[0],
        row: payload.row
      };
      commit('UPDATE_ROW', obj);
      commit('SET_TOTAL_SURFACE', response.data.total_surface);
      commit('SET_INTERVAL_VALIDATION', response.data.interval);
      commit('SET_STATU', response.data.meta[0].statue);
      commit('SET_VALIDATION', response.data.validation);
      commit('SET_PORCENTAGE_ANOMALIE', {
        response: response.data.anomalies,
        anomalies: payload.anom,
        changeWeekYear: true
      });
      commit('SET_PORCENTAGE_TYPE', {
        response: response.data.meta[0].pourcentages,
        type: payload.typeData,
        changeWeekYear: true
      });
      commit('SET_RERRESH_CHART_DATA');
      // if (this.state.rapport.anomalieLis.includes(payload.column)) {
      //   dispatch('getAnomalieForFiliale', {
      //     semaine: payload.semaine,
      //     annee: payload.annee
      //   });
      // }

      commit('SET_LOADING', false);
      return true;
    } catch (error) {
      commit('SET_LOADING', false);
      return false;
    }
  },
  async exportFile({ commit }, payload) {
    commit('SET_LOADING', true);
    const params = { annee: payload.annee };
    try {
      if (payload.type === 'valides') {
        params.type = payload.type;
      } else if (payload.type === 'invalides') {
        params.type = payload.type;
        if (payload.anomalies.length !== 0) {
          params.anomalies = payload.anomalies;
        }
      }
      if (payload.switchMonth == true) {
        params.mois = payload.month;
      } else {
        params.semaine = 's' + payload.semaine.value;
      }
      if (payload.filiale_id != null && payload.filiale_id.id) {
        params.filiale_id = payload.filiale_id.id;
      }
      if (
        payload.champ &&
        (payload.champ !== null || payload.champ !== '') &&
        (payload.name !== null || payload.name !== '')
      ) {
        params.champ = payload.champ.key;
        params.search = payload.name;
      }
      const response = await axios.get(domain + `/api/export`, {
        params: params,
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      const name =
        payload.switchMonth == true
          ? 'mois' + payload.month
          : 'Semaine' + payload.semaine.value;
      link.setAttribute(
        'download',
        'Compil ' + name + ' A' + payload.annee + '.xlsx'
      );
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING', false);
      return true;
    } catch (error) {
      commit('SET_LOADING', false);
      return false;
    }
  },
  async fetchProgressAnalyse({ commit, state }, payload) {
    const body = {
      page: payload.page,
      per_page: payload.per_page,
      annee: payload.annee
    };
    try {
      if (payload.switchMonth == true) {
        body.mois = payload.month;
      } else {
        body.semaine = 's' + payload.semaine.value;
      }
      if (payload.type === 'valides') {
        body.type = payload.type;
      } else if (payload.type === 'invalides') {
        body.type = payload.type;
        if (payload.anomalies.length !== 0) {
          body.anomalies = payload.anomalies;
        }
      }
      if (
        payload.champ &&
        (payload.champ !== null || payload.champ !== '') &&
        (payload.name !== null || payload.name !== '')
      ) {
        body.champ = payload.champ.key;
        body.search = payload.name;
      }
      const response = await axios.get(domain + `/api/getanalyses`, {
        params: body,
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('TOTAL_ROWS', response.data.data.total);
      commit('SET_LOADING_EDIT', true);
      if (state.progress >= 100) {
        commit('SET_TOTAL_SURFACE', response.data.total_surface);
        commit('SET_PORCENTAGE_ANOMALIE', {
          response: response.data.anomalies,
          anomalies: payload.anom
        });
        commit('SET_PORCENTAGE_TYPE', {
          response: response.data.meta[0].pourcentages,
          type: payload.typeData
        });
      } else {
        state.AnomalieData = null;
        state.validiteData = null;
      }
      return true;
    } catch (error) {
      commit('SET_LOADING', false);
      return false;
    }
  },
  async validStep({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const params = new URLSearchParams();
      if (payload.switchMonth == true) {
        params.append('mois', payload.month);
      } else {
        params.append('semaine', 's' + payload.semaine.value);
      }
      params.append('year', payload.year);
      params.append('type', payload.type);
      const response = await axios.post(domain + `/api/makebill`, params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      if (response.data.data == 'done') {
        commit('SET_VALIDATION', true);
      }
      commit('SET_LOADING', false);
      return true;
    } catch (error) {
      commit('SET_LOADING', false);
      return false;
    }
  },
  async fetchEmployeParType({ commit }) {
    try {
      const response = await axios.get(domain + `/api/EmployeesByType`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_EMPLOYE_LIST_FOR_ALL_TYPE', response.data);
    } catch {}
  },
  async fastSearch({ commit }, payload) {
    commit('SET_LOADING_SEARCH', true);
    try {
      const body = {
        page: payload.page,
        per_page: payload.per_page,
        annee: payload.annee,
        search: payload.coloneValue
      };
      if (request) {
        request.cancel('cancel-request');
      }
      request = axios.CancelToken.source();
      if (payload.switchMonth == true) {
        body.mois = payload.month;
      } else {
        body.semaine = 's' + payload.semaine.value;
      }
      if (payload.type === 'valides') {
        body.type = payload.type;
      } else if (payload.type === 'invalides') {
        body.type = payload.type;
        if (payload.anomalies.length !== 0) {
          body.anomalies = payload.anomalies;
        }
      }

      if (
        payload.champ &&
        (payload.champ !== null || payload.champ !== '') &&
        (payload.coloneValue !== null || payload.coloneValue !== '')
      ) {
        body.champ = payload.champ.key;
      }
      const response = await axios.get(domain + `/api/getanalyses`, {
        params: body,
        cancelToken: request.token,
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      if (response.data.error) {
        commit('SET_ERROR', response.data.error);
      } else {
        commit('SET_ROWS', response.data.data.data);
        commit('SET_TOTAL_SURFACE', response.data.total_surface);
        commit('SET_INTERVAL_VALIDATION', response.data.interval);

        commit('TOTAL_ROWS', response.data.data.total);
        if (state.progress >= 100 || state.progress === null) {
          commit('SET_STATU', response.data.meta[0].statue);
          commit('SET_VALIDATION', response.data.validation);
          commit('SET_PORCENTAGE_TYPE', {
            response: response.data.meta[0].pourcentages,
            type: payload.typeData
          });
          commit('SET_PORCENTAGE_ANOMALIE', {
            response: response.data.anomalies,
            anomalies: payload.anom
          });
        } else {
          state.AnomalieData = null;
          state.validiteData = null;
        }
      }

      commit('SET_LOADING_SEARCH', false);
      return true;
    } catch (error) {
      if (error.message !== 'cancel-request') {
        commit('SET_LOADING_SEARCH', false);
      }
      return false;
    }
  },
  async fetchSemaineValider({ commit }, payload) {
    const params = {
      year: payload.year
    };
    try {
      const response = await axios.get(domain + `/api/getAllIntervalValid`, {
        params: params,
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_SEMAINE_VALIDER', response.data);
      return true;
    } catch {
      return false;
    }
  },
  async getAllFiliales({ commit }) {
    try {
      const response = await axios.get(domain + `/api/allFiliales`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_ALL_FILIALES', response.data.teams);
      return true;
    } catch {
      return false;
    }
  },
  async getAnomalieForFiliale(
    { commit, state },
    { semaine, annee, month, switchMonth }
  ) {
    const params = {
      annee: annee
    };
    if (switchMonth == true) {
      params.mois = month;
    } else {
      params.semaine = 's' + semaine.value;
    }
    try {
      for (let i = 0; i < state.filiales.length; i++) {
        params.id = state.filiales[i].id;
        state.filiales[i].loadingAnomalie = true;
        const response = await axios.get(domain + `/api/filialeWithanomalie`, {
          params: params,
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        });
        commit('SET_FILIALE_ANOMALIES', {
          response: response.data,
          filiale: state.filiales[i]
        });
        state.filiales[i].loadingAnomalie = false;
      }
    } catch {}
  },

  async getRapportFiliale({ commit }, payload) {
    commit('SET_FILIALE_RAPPORT');
    const body = {
      annee: payload.annee,
      id: payload.filiale.id
    };
    if (payload.switchMonth == true) {
      body.mois = payload.month;
    } else {
      body.semaine = 's' + payload.semaine.value;
    }
    try {
      const response = await axios.get(domain + `/api/filialePDFrapport`, {
        params: body,
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_FILIALE_RAPPORT', response.data);
      return true;
    } catch (error) {
      return false;
    }
  },
  async getPdfRapportFiliale({ commit }, payload) {
    commit('SET_FILIALE_RAPPORT');
    const body = {
      annee: payload.annee,
      url: payload.url
    };
    if (payload.filiale && payload.filiale.id) {
      body.id = payload.filiale.id;
    }
    const dataURLtoFile = (dataurl, filename) => {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1);
        n -= 1; // to make eslint happy
      }
      return new File([u8arr], filename, { type: mime });
    };
    const file = dataURLtoFile(payload.url);
    // put file into form data
    const data = new FormData();
    data.append('annee', payload.annee);
    if (payload.filiale && payload.filiale.id) {
      data.append('id', payload.filiale.id);
    }

    data.append('url', file);
    if (payload.switchMonth == true) {
      data.append('mois', payload.month);
    } else {
      data.append('semaine', 's' + payload.semaine.value);
    }
    try {
      const response = await axios.post(
        domain + `/api/filialePDFrapport2`,
        data,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`,
            'Content-Type': 'multipart/form-data'
          },
          responseType: 'blob'
        }
      );
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));

      let s = payload.switchMonth ? payload.semaine.value : payload.month;
      link.setAttribute(
        'download',
        payload.filiale.name + '_' + '_S' + s + '_A' + payload.annee + '.pdf'
      );
      link.click();
      return true;
    } catch (error) {
      return false;
    }
  },
  async fastSearchColonneCotent({ commit }, payload) {
    commit('SET_LOADING_SEARCH', true);
    try {
      const localStoreValue = JSON.parse(
        localStorage.getItem('Rapport-Filters')
      );
      const body = {
        page: sessionStorage.getItem('page-rapport'),
        search: payload,
        annee: localStoreValue.year
      };
      if (request) {
        request.cancel('cancel-request');
      }
      request = axios.CancelToken.source();
      if (localStoreValue.switchMonth && localStoreValue.switchMonth == true) {
        body.mois = localStoreValue.month;
      } else {
        body.semaine = 's' + localStoreValue.semaine.value;
      }
      if (localStoreValue.type === 'valides') {
        body.type = localStoreValue.type;
      } else if (localStoreValue.type === 'invalides') {
        body.type = localStoreValue.type;
        if (localStoreValue.anomalie.length !== 0) {
          body.anomalies = localStoreValue.anomalie;
        }
      }

      const response = await axios.get(domain + `/api/getanalyses`, {
        params: body,
        cancelToken: request.token,
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      if (response.data.error) {
        commit('SET_ERROR', response.data.error);
      } else {
        commit('SET_ROWS', response.data.data.data);
        commit('SET_TOTAL_SURFACE', response.data.total_surface);
        commit('SET_INTERVAL_VALIDATION', response.data.interval);

        commit('TOTAL_ROWS', response.data.data.total);
        if (state.progress >= 100 || state.progress === null) {
          commit('SET_STATU', response.data.meta[0].statue);
          commit('SET_VALIDATION', response.data.validation);
          commit('SET_PORCENTAGE_TYPE', {
            response: response.data.meta[0].pourcentages,
            changeWeekYear: true
          });
          commit('SET_PORCENTAGE_ANOMALIE', {
            response: response.data.anomalies,
            changeWeekYear: true
          });
        } else {
          state.AnomalieData = null;
          state.validiteData = null;
        }
      }

      commit('SET_LOADING_SEARCH', false);
      return true;
    } catch (error) {
      if (error.message !== 'cancel-request') {
        commit('SET_LOADING_SEARCH', false);
      }
      return false;
    }
  },
  async fetchDoublentDossier({ commit }, payload) {
    try {
      const response = await axios.get(domain + `/api/checkdouble`, {
        params: payload,
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_DOUBLENT_DOSSIER', response.data.project);
    } catch {}
  },
  async deleteProjet({ commit }, payload) {
    const body = {
      id: payload.item.id,
      type: payload.type
    };
    try {
      const response = await axios.post(domain + `/api/delete_project`, body, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('DELETE_DOUBLENT_DOSSIER', payload.item);
    } catch {}
  },
  async ResetDataForWeek({ commit }, payload) {
    const body = {
      annee: payload.year,
      semaine: 's' + payload.semaine.value,
      type: 'ISO'
    };
    try {
      const response = await axios.post(domain + `/api/delete_rapport`, body, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      if (response) {
        commit('RESET_DATA_RAPPORT');
      }
    } catch {}
  },
  async getMissingSemainesRapport({ commit }, payload) {
    try {
      const params = {
        annee: payload.year
      };
      if (payload.switchMonth && payload.switchMonth == true) {
        params.mois = payload.month;
      } else {
        params.semaine = 's' + payload.semaine.value;
      }
      const response = await axios.get(
        domain + `/api/missing_semaines_rapport`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_MISSING_SEMAINE_RAPPORT', response.data.data);
      return true;
    } catch (error) {
      return false;
    }
  },

  resetMissingRapport({ commit }) {
    commit('SET_MISSING_INSTALLATEUR_RAPPORT', null);
  },
  ReloadChartData({ commit }) {
    commit('SET_RERRESH_CHART_DATA');
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
