export default class clientMailCdg {
  constructor(data) {
    this.client = { ...data.client, extra_mails_list: data.client.extra_mails };
    this.client.extra_mails = [];
    this.html = data.html;
    this.id_client = data.id_client;
    this.ids_facture = data.ids_facture;
    this.name_client = data.name_client;
    this.vendeur = {
      ...data.vendeur,
      extra_mails_list: data.vendeur.extra_mails
    };
    this.vendeur.extra_mails = [];
    this.check = true;
    this.send_to_vendeur = 'yes';
    this.send_to_client = 'yes';
  }

  /**
   * @param {*} data
   */

  update(data) {}
  static create(data) {
    if (!data) {
      throw new Error('clientMailCdg::create: "data" is undefined');
    }
    return new clientMailCdg(data);
  }
}
