export default class FraisBureauEtudeBTh {
  constructor(data) {
    this.id = data.id;
    this.installeur = data.installeur;
    this.nom_prenom = data.nom_prenom;
    this.nom = data.nom;
    this.prenom = data.prenom;
    this.month = data.month;
    this.numero_dossier = data.numero_dossier;
    this.montant = data.montant;
    this.montant_ht = data.montant_ht;

    this.week = data.week;
    this.KYNAN = data.KYNAN;
    this.ISO_33 = data.ISO_33;
    this.ISO_AUDIT = data.ISO_AUDIT;
    this.ENERGIE_INVESTE = data.ENERGIE_INVESTE;
    this.BE_TYPE = data.BE_TYPE;
    this.statue = data.statue;
    this.date = data.date;
    this.facture_id = data.facture_id;
    this.facture_numero = data.facture_numero;
    this.downloadable = data.downloadable;
    this.projects = data.projects;
    this.statue_installateur = data.statue_installateur;
    this.email_sent_at = data.email_sent_at;
    this.lot = data.lot;
    //kynan
    this.binifis_kynan = data.binifis_kynan;
    this.statue_kynan = data.statue_kynan;
    this.date_kynan = data.date_kynan;
    this.binifis_kynan_ht = data.binifis_kynan_ht;
    this.facture_kynan_id = data.facture_kynan_id;
    this.facture_kynan_numero = data.facture_kynan_numero;
    this.downloadable_kynan = data.downloadable_kynan;
    this.kynan_statue_installateur = data.kynan_statue_installateur;
    this.kynan_email_sent_at = data.kynan_email_sent_at;
    this.kynan_lot = data.kynan_lot;
    this.kynan_lcr = data.kynan_lcr;
    this.kynan_type_paiement = data.kynan_type_paiement;

    //alteo
    this.binifis_alteo = data.binifis_alteo;
    this.binifis_alteo_ht = data.binifis_alteo_ht;

    this.statue_alteo = data.statue_alteo;
    this.date_alteo = data.date_alteo;
    this.facture_alteo_id = data.facture_alteo_id;
    this.facture_alteo_numero = data.facture_alteo_numero;
    this.downloadable_alteo = data.downloadable_alteo;
    this.alteo_statue_installateur = data.alteo_statue_installateur;
    this.check = false;
    this.alteo_responsable_email = data.alteo_responsable_email;
    this.kynan_responsable_email = data.kynan_responsable_email;
    this.responsable_email = data.responsable_email;
    this.alteo_email_sent_at = data.alteo_email_sent_at;
    this.alteo_lot = data.alteo_lot;
    this.alteo_lcr = data.alteo_lcr;
    this.alteo_type_paiement = data.alteo_type_paiement;

    //
    this.facture_av_id = data.facture_av_id;
    this.facture_kynan_av_id = data.facture_kynan_av_id;
    this.facture_alteo_av_id = data.facture_alteo_av_id;
    this.facture_av_numero = data.facture_av_numero;
    this.facture_kynan_av_numero = data.facture_kynan_av_numero;
    this.facture_alteo_av_numero = data.facture_alteo_av_numero;
    this.lcr = data.lcr;
    this.type_paiement = data.type_paiement;
  }
  update(data) {
    this.KYNAN = data.KYNAN;
    this.ISO_33 = data.ISO_33;
    this.ISO_AUDIT = data.ISO_AUDIT;
    this.ENERGIE_INVESTE = data.ENERGIE_INVESTE;
    this.BE_TYPE = data.BE_TYPE;
    this.statue = data.statue;
    this.date = data.date;
    this.facture_id = data.facture_id;
    this.facture_numero = data.facture_numero;
    this.downloadable = data.downloadable;
    this.statue_installateur = data.statue_installateur;
    this.email_sent_at = data.email_sent_at;
    this.lot = data.lot;
    this.montant = data.montant;
    this.montant_ht = data.montant_ht;
    //kynan
    this.binifis_kynan = data.binifis_kynan;
    this.binifis_kynan_ht = data.binifis_kynan_ht;
    this.statue_kynan = data.statue_kynan;
    this.date_kynan = data.date_kynan;
    this.facture_kynan_id = data.facture_kynan_id;
    this.downloadable_kynan = data.downloadable_kynan;
    this.facture_kynan_numero = data.facture_kynan_numero;
    this.kynan_statue_installateur = data.kynan_statue_installateur;
    this.kynan_email_sent_at = data.kynan_email_sent_at;
    this.kynan_lot = data.kynan_lot;
    //alteo
    this.binifis_alteo = data.binifis_alteo;
    this.binifis_alteo_ht = data.binifis_alteo_ht;

    this.statue_alteo = data.statue_alteo;
    this.date_alteo = data.date_alteo;
    this.facture_alteo_id = data.facture_alteo_id;
    this.downloadable_alteo = data.downloadable_alteo;
    this.facture_alteo_numero = data.facture_alteo_numero;
    this.alteo_statue_installateur = data.alteo_statue_installateur;
    this.alteo_responsable_email = data.alteo_responsable_email;
    this.kynan_responsable_email = data.kynan_responsable_email;
    this.responsable_email = data.responsable_email;
    this.alteo_email_sent_at = data.alteo_email_sent_at;
    this.alteo_lot = data.alteo_lot;
  }
  static create(data) {
    return new FraisBureauEtudeBTh(data);
  }
}
