import moment from 'moment';
export default class projectSubMasterFilialeDossier {
  constructor(data) {
    this.id = data.id;
    this.id_facture = data.id_facture;
    this.nom_pernom = data.nom_pernom;
    this.numero_dossier = data.numero_dossier;
    this.master_filiale = data.master_filiale;
    this.date = data.date;
    if (data.date == '' || data.date == null) {
      this.date = '-';
    } else if (moment(data.date, 'DD-MM-YYYY', true).isValid()) {
      this.date = data.date;
    } else {
      this.date = moment(new Date(data.date)).format('DD-MM-YYYY');
    }
    // this.download = data.download;
    this.numero = data.numero;
    this.statue_installateur = data.statue_installateur;
    this.statue = data.statue;
    this.email_sent_at = data.email_sent_at;
    this.lot = data.lot;
    this.responsable_email = data.responsable_email;
    this.av_id = data.av_id;
    this.av_numero = data.av_numero;
    this.lcr = data.lcr;
    this.type_paiement = data.type_paiement;
    this.pu_master_filiale = data.pu_master_filiale;
    // this.confirme_frais = data.confirme_frais;
    //
    this.montant_total = data.montant_total;
    this.id_vendeur = data.id_vendeur;
    this.id_acheteur = data.id_acheteur;
    this.montant_total_ht = data.montant_total_ht;
    this.total_ht = data.total_ht;
    this.check = false;
    this.detailsShowing = false;
    this.id_template = data.id_template;
    this.type_facture = data.type_facture;
    this.total_tva = data.total_tva;
    this.reglements = data.reglements;
    this.date_echeance = data.date_echeance;
    this.montant_regle = data.montant_regle;
    this.client_id = data.id_acheteur;
    this.client = data.installer_rge;
    this.reste = data.reste;
    this.vendeur = data.vendeur;
    this.date_paiement = data.date_paiement;
    this.belongsTo = data.belongsTo;
    this.vendeur_id = data.id_vendeur;
  }

  update(data) {
    this.id_facture = data.id_facture;
    this.date = data.date;
    // this.download = data.download;
    this.id = data.id;
    this.numero = data.numero;
    this.statue_installateur = data.statue_installateur;
    this.statue = data.statue;
    this.email_sent_at = data.email_sent_at;
    this.responsable_email = data.responsable_email;
    this.av_id = data.av_id;
    this.av_numero = data.av_numero;
    this.lcr = data.lcr;
    this.type_paiement = data.type_paiement;
    this.id_template = data.id_template;
    this.type_facture = data.type_facture;
  }
  updateDataMail(data) {
    this.email_sent_at = data.email_sent_at;
    this.lot = data.mail_lot;
  }
  updateDataReglement(data) {
    this.reste = data.reste;
    this.reglements = data.reglements;
    this.date_echeance = data.date_echeance;
    this.montant_regle = data.montant_regle;
    this.date_paiement = data.date_paiement;
    this.payment_type = data.payment_type;
    this.statue = data.etat;
  }
  static create(data) {
    return new projectSubMasterFilialeDossier(data);
  }
}
