import axios from 'axios';
import domain from '@/environment';
import SettingFilialeTh from '../../models/FilialeTh';
const state = {
  loadingSettingFilialeTh: false,
  errorSettingFilialeTh: null,
  SettingFilialeTh: [],
  responsableFilialeFreeTh: [],
  errorSettingTypeSocieteSupport: null,
  typeSocieteSupportth: [],
  loadingSettingTypeSocieteSupport: false,
  listeFilialesLocateur: [],
  listeFilialesIntermidier: [],
  errorRibFiliale: null,
  loadingRibFiliale: false,
  errorRemoveFileFiliale: null,
  loadingRemoveFileFiliale: false,
  loadingRenameFileFiliale: false,
  errorRenameFileFiliale: null,
  ListeSocieteSupportth: [],
  loadingListeSocieteSupportth: false,
  errorRemoveRibFiliale: null,
  loadingRemoveRibFiliale: false,
  SequencesFacturesFields: [],
  masterFililsList: [],
  societeOfMasterFilial: [],
  ribOfFilile: [],
  ribLoder: false,
  ribErrors: null,
  totalRowFiliale: 0,
  sequanceOfFililale: [],
  sequanceLoder: false,
  bienOfFilial: [],
  bienLoder: false,
  bienErrors: null,
  sequanceErrors: null,
  societeFacturesOfFilile: [],
  societeFactureErrors: null,
  societeFactureLoder: false,
  SettingOrganismeTh: [],
  listExerciceForSociete: [],
  loaderListExerciceForSociete: false
};
const getters = {
  getSettingFilialesTh: state => state.SettingFilialeTh,
  getLoadingSettingFilialeTh: state => state.loadingSettingFilialeTh,
  getErrorSettingFilialeTh: state => state.errorSettingFilialeTh,
  getResponsableFilialeFreeTh: state => state.responsableFilialeFreeTh,
  getErrorSettingTypeSocieteSupport: state =>
    state.errorSettingTypeSocieteSupport,
  getTypeSocieteSupportTh: state => state.typeSocieteSupportth,
  getLoadingSettingTypeSocieteSupport: state =>
    state.loadingSettingTypeSocieteSupport,
  listeFiliales: state => state.listeFiliales,
  getLoadingRibFiliale: state => state.loadingRibFiliale,
  getErrorRibFiliale: state => state.errorRibFiliale,
  getLoadingRemoveFileFiliale: state => state.loadingRemoveFileFiliale,
  getErrorRenameFileFiliale: state => state.errorRemoveFileFiliale,
  getListeSocieteSupportth: state => state.ListeSocieteSupportth,
  getLoadingListeSocieteSupportth: state => state.loadingListeSocieteSupportth,
  getErrorRemoveRibFiliale: state => state.errorRemoveRibFiliale,
  getLoadingRemoveRibFiliale: state => state.loadingRemoveRibFiliale,
  getSequencesFacturesFields: state => state.SequencesFacturesFields,
  getMasterFilialsList: state => state.masterFililsList,
  getSocieteOfMasterFilial: state => state.societeOfMasterFilial,
  getRibOfFilials: state => state.ribOfFilile,
  getRibLoader: state => state.ribLoder,
  getSocieteFacturesOfFilile: state => state.societeFacturesOfFilile,
  getSocieteFactureLoader: state => state.societeFactureLoder,
  getErrors: state => state.ribErrors,
  getTotalFilile: state => state.totalRowFiliale,
  getSequancesofFilile: state => state.sequanceOfFililale,
  getSequanceLoder: state => state.sequanceLoder,
  getBienOfiliale: state => state.bienOfFilial,
  getBienLoader: state => state.bienLoder,
  getSequanceErrors: state => state.sequanceErrors,
  getBienErrors: state => state.bienErrors,
  getListFilialesLocateur: state => state.listeFilialesLocateur,
  getListFilialesIntermidier: state => state.listeFilialesIntermidier,
  SettingOrganismeTh: state => state.SettingOrganismeTh,
  getListExerciceForSociete: state => state.listExerciceForSociete,
  getLoaderListExerciceForSociete: state => state.loaderListExerciceForSociete
};
const mutations = {
  SET_LOADING_SETTING_FILIALE_TH(state, payload) {
    state.loadingSettingFilialeTh = payload;
  },
  SET_ERROR_SETTING_FILIALE_TH(state, payload) {
    state.errorSettingFilialeTh = payload;
  },
  NEW_SETTING_FILIALE_TH(state, payload) {
    state.SettingFilialeTh.unshift(SettingFilialeTh.create(payload));
  },
  SET_SETTING_FILIALE_TH(state, payload) {
    state.SettingFilialeTh = payload.map(item => SettingFilialeTh.create(item));
  },
  SET_SETTING_ORGANISME_TH(state, payload) {
    state.SettingOrganismeTh = payload;
  },
  DELETE_FILIALE_SETTING_TH(state, payload) {
    for (let index = 0; index < state.SettingFilialeTh.length; index++) {
      if (state.SettingFilialeTh[index].id == payload.id) {
        state.SettingFilialeTh.splice(index, 1);
      }
    }
  },
  ADD_DEPOT_TO_FILIALE_TH(state, payload) {
    for (let index = 0; index < state.SettingFilialeTh.length; index++) {
      if (state.SettingFilialeTh[index].id == payload.filiale.id) {
        state.SettingFilialeTh[index].update(payload.response);
      }
    }
  },
  REMOVE_DEPOT_FROM_FILIALE_TH(state, payload) {
    for (let index = 0; index < state.SettingFilialeTh.length; index++) {
      if (state.SettingFilialeTh[index].id == payload.filiale.id) {
        state.SettingFilialeTh[index].update(payload.response);
      }
    }
  },
  UPDATE_RESPONSABLE_DEPOT_FOR_FILIALE_TH(state, payload) {
    for (let index = 0; index < state.SettingFilialeTh.length; index++) {
      if (state.SettingFilialeTh[index].id == payload.filiale.id) {
        for (
          let depot = 0;
          depot < state.SettingFilialeTh[index].depots.length;
          depot++
        ) {
          if (
            state.SettingFilialeTh[index].depots[depot].id == payload.depot.id
          ) {
            state.SettingFilialeTh[index].depots[depot].responsable_depot =
              payload.response.responsable_depot;
          }
        }
      }
    }
  },
  SET_SETTING_RESPONSABLE_FILIALE_TH(state, payload) {
    state.responsableFilialeFreeTh = payload;
  },
  SET_ERROR_SETTING_TYPE_SOCIETE_SUPPORT(state, payload) {
    state.errorSettingTypeSocieteSupport = payload;
  },
  SET_SETTING_TYPE_SOCIETE_SUPPORT(state, payload) {
    let data = payload;

    data.forEach(function(item) {
      const select = { value: item.value, text: item.text };
      state.typeSocieteSupportth.push(select);
    });
  },
  SET_LOADING_SETTING_TYPE_SOCIETE_SUPPORT(state, payload) {
    state.loadingSettingTypeSocieteSupport = payload;
  },
  ADD_RIB_FILIALE(state, payload) {
    payload.ribToUpdate.ribs[payload.index].blocked = false;
    payload.ribToUpdate.ribs[payload.index].id = payload.response.id;
    payload.ribToUpdate.ribs[payload.index].swift = payload.response.swift;
    payload.ribToUpdate.ribs[payload.index].iban = payload.response.iban;
    payload.ribToUpdate.ribs[payload.index].rib =
      payload.response.iban + ' ' + payload.response.swift;
    payload.ribToUpdate.ribs[payload.index].filiale_id =
      payload.response.filiale_id;
    payload.ribToUpdate.ribs[payload.index].changeSave = true;
    payload.ribToUpdate.ribs[payload.index].newLine = false;
  },
  SET_RIB_FILIALE_BLOCK(state, payload) {
    payload.item.ribs[payload.index].blocked = payload.response.blocked;
  },
  SET_RIB_FILIALE(state, payload) {
    payload.item.ribs[payload.index].swift = payload.response.swift;
    payload.item.ribs[payload.index].iban = payload.response.iban;
    payload.item.ribs[payload.index].changeSave = true;
    payload.item.ribs[payload.index].newLine = false;
  },
  SET_FILIALE_RIB_ERROR(state, payload) {
    state.errorRibFiliale = payload;
  },
  SET_FILIALE_RIB_LOADING(state, payload) {
    state.loadingRibFiliale = payload;
  },
  SET_FILIALE_REMOVE_FILE_ERROR(state, payload) {
    state.errorRemoveFileFiliale = payload;
  },
  SET_FILIALE_REMOVE_FILE_LOADING(state, payload) {
    state.loadingRemoveFileFiliale = payload;
  },
  SET_FILE_FILIALE_NAME(state, payload) {
    payload.item.files[payload.index].name = payload.response.name;
    payload.item.files[payload.index].link = payload.response.link;
  },
  SET_FILIALE_RENAME_FILE_ERROR(state, payload) {
    state.errorRenameFileFiliale = payload;
  },
  SET_FILIALE_RENAME_FILE_LOADING(state, payload) {
    state.loadingRenameFileFiliale = payload;
  },
  ADD_FILES_FILIALE(state, payload) {
    payload.response.forEach((element, index) => {
      payload.filiale.files[index] = element;
    });
  },
  UPDATE_REPONSABLES_FILIALE(state, payload) {
    payload.response.forEach((element, index) => {
      payload.filiale.responsables[index] = element;
    });
  },
  SET_LISTE_SOCIETE_SUPPORT(state, payload) {
    let data = payload;

    data.forEach(function(item) {
      const select = { id: item.id, name: item.name };
      state.ListeSocieteSupportth.push(select);
    });
  },
  SET_LOADING_LISTE_SOCIETE_SUPPORT(state, payload) {
    state.loadingListeSocieteSupportth = payload;
  },
  SET_FILIALE_REMOVE_RIB_ERROR(state, payload) {
    state.errorRemoveRibFiliale = payload;
  },
  SET_FILIALE_REMOVE_RIB_LOADING(state, payload) {
    state.loadingRemoveRibFiliale = payload;
  },
  SET_SEQUENCE_FACTURE_FIELDS(state, payload) {
    state.SequencesFacturesFields = payload;
  },
  SET_BIEN_FILIALE_BLOCK(state, payload) {
    payload.item.biens[payload.index].blocked = payload.response.blocked;
  },
  SET_LIST_MASTER_FILIALS(state, payload) {
    state.masterFililsList = payload;
  },
  SET_LIST_SOCIETE_OF_MASTER_FILIALS(state, payload) {
    state.societeOfMasterFilial = payload;
  },
  SET_RESTAURER_SOCIETE(state, payload) {
    const index = state.SettingFilialeTh.findIndex(
      element => element.id === payload
    );
    state.SettingFilialeTh.splice(index, 1);
  },
  SET_LIST_RIBS_OF_FILIALS(state, payload) {
    state.ribOfFilile = payload;
  },
  SET_RIBS_ERRORS(state, payload) {
    state.ribErrors = payload;
  },
  SET_LIST_SOCIETE_FACTURE_OF_FILIALS(state, payload) {
    if (payload.add) {
      let tab = [
        ...payload.response,
        ...state.societeFacturesOfFilile.filter(
          item =>
            !item.id &&
            !(
              item.filiale_id == payload.societeFacture.filiale_id &&
              item.societe_id == payload.societeFacture.societe_id
            )
        )
      ];

      state.societeFacturesOfFilile = tab;
    } else {
      state.societeFacturesOfFilile = payload;
    }
  },
  SET_SOCIETE_FACTURE_ERRORS(state, payload) {
    state.societeFactureErrors = payload;
  },
  SET_SOCIETE_FACTURE_LOADER(state, payload) {
    state.societeFactureLoder = payload;
  },
  SET_RIBS_LOADER(state, payload) {
    state.ribLoder = payload;
  },
  SET_TOTAL_ROWS(state, payload) {
    state.totalRowFiliale = payload;
  },
  SET_LIST_SEQ_OF_FILIALS(state, payload) {
    state.sequanceOfFililale = payload.map(item => {
      return {
        ...item,
        begin_date: item.sequences[0].begin_date,
        end_date: item.sequences[0].end_date,
        loader: false
      };
    });
  },
  ADD_ITEM_TO_LIST_SEQ_OF_FILIALS(state, payload) {
    if (state.sequanceOfFililale != null) {
      state.sequanceOfFililale.push(payload);
    }
  },
  SET_SEQUANCE_LOADER(state, payload) {
    state.sequanceLoder = payload;
  },
  SET_LIST_BIEN_OF_FILIALS(state, payload) {
    state.bienOfFilial = payload;
  },
  SET_BIEN_LOADER(state, payload) {
    state.bienLoder = payload;
  },
  SET_BIEN_ERRORS(state, payload) {
    state.bienErrors = payload;
  },
  SET_SEQUANCE_ERRORS(state, payload) {
    state.sequanceErrors = payload;
  },
  SET_FILIAL_INTERMIDIER(state, payload) {
    state.listeFilialesIntermidier = payload;
  },
  SET_FILIAL_LOCATEUR(state, payload) {
    state.listeFilialesLocateur = payload;
  },
  SET_LIST_EXERCICE_OF_FILIALS(state, payload) {
    state.listExerciceForSociete = payload;
  },
  SET_LOADER_LIST_EXERCICE_OF_FILIALS(state, payload) {
    state.loaderListExerciceForSociete = payload;
  }
};
const actions = {
  resteModalOfRibs({ commit }, payload) {
    commit('SET_LIST_RIBS_OF_FILIALS', []);
    commit('SET_RIBS_ERRORS', null);
  },
  resteModalOfSocieteFacture({ commit }, payload) {
    commit('SET_LIST_SOCIETE_FACTURE_OF_FILIALS', []);
    commit('SET_SOCIETE_FACTURE_ERRORS', null);
  },
  async getAllRibOfFiliale({ commit }, payload) {
    commit('SET_RIBS_LOADER', true);
    try {
      const response = await axios.get(
        domain + `/api/filiale/all-ribs?id=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LIST_RIBS_OF_FILIALS', response.data.data);
      commit('SET_RIBS_LOADER', false);
      return true;
    } catch (error) {
      if (error?.response?.data) {
        commit('SET_RIBS_ERRORS', error.response.data.message);
      } else {
        commit('SET_RIBS_ERRORS', 'Une erreur est survenue');
      }
      commit('SET_RIBS_LOADER', false);
      return false;
    }
  },

  async getAllBienOfFiliale({ commit }, payload) {
    commit('SET_BIEN_LOADER', true);
    try {
      const response = await axios.get(
        domain + `/api/filiale/biens?id=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LIST_BIEN_OF_FILIALS', response.data.data);
      commit('SET_BIEN_LOADER', false);
      return true;
    } catch (error) {
      if (error?.response?.data) {
        commit('SET_BIEN_ERRORS', error.response.data);
      } else {
        commit('SET_BIEN_ERRORS', 'Une erreur est survenue');
      }
      commit('SET_BIEN_LOADER', false);
      return false;
    }
  },

  async getAllSocieteFactureOfFiliale({ commit }, payload) {
    commit('SET_SOCIETE_FACTURE_LOADER', true);
    try {
      const response = await axios.get(
        domain + `/api/filiale/all-societe-factures?filiale_id=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LIST_SOCIETE_FACTURE_OF_FILIALS', response.data.data);
      commit('SET_SOCIETE_FACTURE_LOADER', false);
      return true;
    } catch (error) {
      if (error?.response?.data) {
        commit('SET_SOCIETE_FACTURE_ERRORS', error.response.data.message);
      } else {
        commit('SET_SOCIETE_FACTURE_ERRORS', 'Une erreur est survenue');
      }
      commit('SET_SOCIETE_FACTURE_LOADER', false);
      return false;
    }
  },
  async addBienForSociete({ commit }, payload) {
    commit('SET_LOADING_SETTING_FILIALE_TH', true);
    const params = new FormData();
    if (payload?.biens != null) {
      payload?.biens.forEach((item, key) => {
        if (item) {
          let index = 0;

          params.append('biens[' + key + '][id]', item.id);
          params.append('biens[' + key + '][name]', item.name);
          params.append('biens[' + key + '][address]', item.address);
          params.append('biens[' + key + '][description]', item.description);
          params.append('biens[' + key + '][locataire_id]', item.locataire_id);
          params.append(
            'biens[' + key + '][intermediaire_id]',
            item.intermediaire_id
          );
          params.append('biens[' + key + '][tva]', item.tva);
          if (item.intermediaire_id != null) {
            params.append(
              'biens[' + key + '][tva_intermediaire]',
              item.tva_intermediaire
            );
          } else {
            params.append('biens[' + key + '][tva_intermediaire]', null);
          }
          params.append('biens[' + key + '][blocked]', item.blocked);
          index++;
        }
      });
    }
    if (payload?.id != null) {
      params.append('id', payload.id);
    }
    try {
      const response = await axios.post(
        domain + '/api/filiale/addBien',
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      commit('SET_LOADING_SETTING_FILIALE_TH', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        commit('SET_BIEN_ERRORS', data);
      } else {
        commit('SET_BIEN_ERRORS', 'Une erreur est survenue');
      }
      commit('SET_LOADING_SETTING_FILIALE_TH', false);

      return false;
    }
  },
  async getAllSequancesOfFiliale({ commit }, payload) {
    commit('SET_SEQUANCE_LOADER', true);
    try {
      const response = await axios.get(
        domain + `/api/filiale/sequences?id=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LIST_SEQ_OF_FILIALS', response.data.data);
      commit('SET_SEQUANCE_LOADER', false);
      return true;
    } catch (error) {
      if (error?.response?.data) {
        commit('SET_BIEN_ERRORS', error.response.data);
      } else {
        commit('SET_BIEN_ERRORS', 'Une erreur est survenue');
      }
      commit('SET_SEQUANCE_LOADER', false);
      return false;
    }
  },
  async fetchExerciceForSociete({ commit }, payload) {
    commit('SET_LOADER_LIST_EXERCICE_OF_FILIALS', true);
    commit('SET_LIST_EXERCICE_OF_FILIALS', []);

    try {
      const response = await axios.get(
        domain + `/api/filiale/used_exercices/${payload}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LIST_EXERCICE_OF_FILIALS', response.data.data);
      commit('SET_LOADER_LIST_EXERCICE_OF_FILIALS', false);
      return true;
    } catch (error) {
      // if (error?.response?.data) {
      //   commit('SET_BIEN_ERRORS', error.response.data);
      // } else {
      //   commit('SET_BIEN_ERRORS', 'Une erreur est survenue');
      // }
      commit('SET_LOADER_LIST_EXERCICE_OF_FILIALS', false);
      return false;
    }
  },
  async addNewExerciceaction({ commit }, payload) {
    commit('SET_LOADER_LIST_EXERCICE_OF_FILIALS', true);

    try {
      const response = await axios.post(
        domain + `/api/filiale/generate_sequence_form_exercice`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LIST_SEQ_OF_FILIALS', response.data.data);
      commit('SET_LOADER_LIST_EXERCICE_OF_FILIALS', false);
      return { succes: true };
    } catch (error) {
      if (error?.response?.data) {
        commit('SET_LOADER_LIST_EXERCICE_OF_FILIALS', false);
        return { succes: false, error: error?.response?.data };
      } else {
        commit('SET_LOADER_LIST_EXERCICE_OF_FILIALS', false);
        return { succes: false, error: 'Une erreur est survenue' };
      }
    }
  },
  async addSequanceForSociete({ commit }, payload) {
    commit('SET_LOADING_SETTING_FILIALE_TH', true);
    const params = new FormData();
    if (payload?.sequences_factures != null) {
      let index = 0;

      payload.sequences_factures.sequences.forEach(function(item) {
        // if(item.id == undefined ){
        params.append('sequences_factures[' + index + '][id]', item.id);
        params.append(
          'sequences_factures[' + index + '][sequence]',
          item.sequence
        );
        params.append(
          'sequences_factures[' + index + '][begin_date]',
          item.begin_date
        );
        params.append(
          'sequences_factures[' + index + '][end_date]',
          item.end_date
        );
        params.append(
          'sequences_factures[' + index + '][num_length]',
          item.num_length
        );
        params.append(
          'sequences_factures[' + index + '][begin_from]',
          item.begin_from
        );
        params.append(
          'sequences_factures[' + index + '][last_num]',
          item.last_num
        );
        params.append('sequences_factures[' + index + '][type]', item.type);
        params.append('sequences_factures[' + index + '][index]', item.index);
        index++;
        // }
      });
    }

    if (payload?.id != null) {
      params.append('id', payload.id);
    }
    try {
      const response = await axios.post(
        domain + '/api/filiale/addSequence',
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      commit('SET_LOADING_SETTING_FILIALE_TH', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        commit('SET_BIEN_ERRORS', data);
      } else {
        commit('SET_BIEN_ERRORS', 'Une erreur est survenue');
      }
      commit('SET_LOADING_SETTING_FILIALE_TH', false);

      return false;
    }
  },
  async getAllSociteOfMasterFilials({ commit }, payload) {
    const params = payload;
    commit('SET_LOADING_SETTING_FILIALE_TH', true);
    try {
      const response = await axios.get(
        domain + `/api/filiale/societe_of_master?id=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LIST_SOCIETE_OF_MASTER_FILIALS', response.data);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_SETTING_FILIALE_TH', error.response.data);
      } else {
        commit('SET_ERROR_SETTING_FILIALE_TH', 'Une erreur est survenue');
      }
      commit('SET_LOADING_SETTING_FILIALE_TH', false);

      return false;
    }
  },
  async restoreFiliale({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/filiale/restore_filiale`,
        { id: payload },
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_RESTAURER_SOCIETE', payload);
      return true;
    } catch {
      commit('SET_LOADING_SETTING_FILIALE_TH', false);

      return false;
    }
  },
  async getAllMasterFilials({ commit }, payload) {
    commit('SET_LOADING_SETTING_FILIALE_TH', true);
    try {
      const response = await axios.get(domain + `/api/filiale/master_filiale`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_LIST_MASTER_FILIALS', response.data.data);
      commit('SET_LOADING_SETTING_FILIALE_TH', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_SETTING_FILIALE_TH', error.response.data);
      } else {
        commit('SET_ERROR_SETTING_FILIALE_TH', 'Une erreur est survenue');
      }
      commit('SET_LOADING_SETTING_FILIALE_TH', false);

      return false;
    }
  },
  async AddSettingFilialeTh({ commit }, payload) {
    const params = new FormData();
    params.append('name', payload.name);
    if (payload?.type != null) {
      params.append('type', payload.type);
    }
    if (payload?.is_societe_support != null) {
      params.append('is_societe_support', payload.is_societe_support);
    }
    if (payload?.abreviation != null) {
      params.append('abreviation', payload.abreviation);
    }
    if (payload?.couleur != null) {
      params.append('couleur', payload.couleur);
    }
    if (payload?.type_societe != null) {
      params.append('type_societe', payload.type_societe);
    }
    if (payload.prenom && payload.prenom != null) {
      params.append('prenom', payload.prenom);
    }
    if (payload.pixel_id && payload.pixel_id != null) {
      params.append('pixel_id', payload.pixel_id);
    }
    if (payload.nom_famille && payload.nom_famille != null) {
      params.append('nom_famille', payload.nom_famille);
    }
    if (payload.civilite && payload.civilite != null) {
      params.append('civilite', payload.civilite);
    }
    if (payload?.siren_value != null) {
      params.append('siren_value', payload.siren_value);
    }
    if (payload?.tva_value != null) {
      params.append('tva_value', payload.tva_value);
    }
    if (payload.iban && payload.iban != null) {
      params.append('iban', payload.iban);
    }
    if (payload.swift && payload.swift != null) {
      params.append('swift', payload.swift);
    }
    if (payload.code_postal && payload.code_postal != null) {
      params.append('code_postal', payload.code_postal);
    }
    if (payload.site_internet && payload.site_internet != null) {
      params.append('site_internet', payload.site_internet);
    }
    if (payload.ville && payload.ville != null) {
      params.append('ville', payload.ville);
    }
    if (payload.pays && payload.pays != null) {
      params.append('pays', payload.pays);
    }
    if (payload.banque && payload.banque != null) {
      params.append('banque', payload.banque);
    }
    if (payload.fax && payload.fax != null) {
      params.append('fax', payload.fax);
    }
    if (payload?.in_configs) {
      params.append('in_configs', payload.in_configs);
    }
    if (payload.telephone && payload.telephone != null) {
      params.append('telephone', payload.telephone);
    }
    if (payload.description && payload.description != null) {
      params.append('description', payload.description);
    }
    if (payload.telephone_portable && payload.telephone_portable != null) {
      params.append('telephone_portable', payload.telephone_portable);
    }
    if (payload.rcs && payload.rcs != null) {
      params.append('rcs', payload.rcs);
    }
    if (payload.payment_condition && payload.payment_condition != null) {
      params.append('payment_condition', payload.payment_condition);
    }
    if (payload.capital && payload.capital != null) {
      params.append('capital', payload.capital);
    }
    if (payload.footer_type && payload.footer_type != null) {
      params.append('footer_type', payload.footer_type);
    }
    if (payload.footer && payload.footer != null) {
      params.append('footer', payload.footer);
    }
    if (payload.payment_type && payload.payment_type != null) {
      params.append('payment_type', payload.payment_type);
    }
    if (payload.responsables && payload.responsables.length) {
      for (let i = 0; i < payload.responsables.length; i++) {
        params.append('responsables[' + i + ']', payload.responsables[i].id);
      }
    }
    if (payload?.start_day != null) {
      params.append('start_day', payload.start_day);
    }
    if (payload?.start_month != null) {
      params.append('start_month', payload.start_month);
    }
    if (payload?.start_year != null) {
      params.append('start_year', payload.start_year);
    }
    if (payload?.end_day != null) {
      params.append('end_day', payload.end_day);
    }
    if (payload?.end_month != null) {
      params.append('end_month', payload.end_month);
    }
    if (payload?.end_year != null) {
      params.append('end_year', payload.end_year);
    }
    if (payload?.masterFiliales?.length) {
      for (let i = 0; i < payload?.masterFiliales?.length; i++) {
        params.append(
          'masterFiliales[' + i + '][master_id]',
          payload.masterFiliales[i].master_id
        );
        params.append(
          'masterFiliales[' + i + '][date_debut]',
          payload.masterFiliales[i].date_debut
        );
        params.append(
          'masterFiliales[' + i + '][date_fin]',
          payload.masterFiliales[i].date_fin
        );
      }
    }
    if (payload.siret == null) {
      params.append('siret', '');
    } else {
      params.append('siret', payload.siret);
    }
    if (payload.adresse == null) {
      params.append('adresse', '');
    } else {
      params.append('adresse', payload.adresse);
    }
    if (payload.email == null) {
      params.append('email', '');
    } else {
      params.append('email', payload.email);
    }
    if (payload.volant && payload.volant == true) {
      params.append('responsable_depot', payload.responsable_depot);
      params.append('volant', payload.volant);
    } else {
      let index = 0;
      payload.depots.forEach((element, key) => {
        if (element) {
          params.append('depots[' + index + '][id]', element.id);
          params.append(
            'depots[' + index + '][responsable_depot]',
            element.responsable_depot
          );
          params.append(
            'depots[' + index + '][filiale_id]',
            element.filiale_id
          );
          params.append('depots[' + index + '][depot]', element.depot);
        }
        index++;
      });
    }
    if (payload.image) {
      params.append('logo', payload.image);
    }
    if (payload.filiale?.newFiles && payload.filiale.newFiles.length) {
      let index = 0;
      payload.filiale.newFiles.forEach((element, key) => {
        if (element) {
          params.append('files[' + index + '][file]', element);
          params.append('files[' + index + '][name]', element.name_file);
        }
        index++;
      });
    }
    if (payload.filiale?.files && payload.filiale.files.length) {
      let index = 0;
      payload.filiale.files.forEach((element, key) => {
        if (element) {
          params.append('files[' + index + '][file]', element);
          params.append('files[' + index + '][name]', element.name_file);
        }
        index++;
      });
    }
    if (payload.filiale?.ribs && payload.filiale.ribs.length) {
      let index = 0;
      payload.filiale.ribs.forEach((element, key) => {
        if (element) {
          params.append('ribs[' + index + '][iban]', element.iban);
          params.append('ribs[' + index + '][swift]', element.swift);
          params.append('ribs[' + index + '][blocked]', element.blocked);
        }
        index++;
      });
    }
    if (payload.filiale?.type_societe_support) {
      params.append(
        'type_societe_support',
        payload.filiale?.type_societe_support
      );
    }
    if (payload.filiale?.is_societe_support) {
      params.append('is_societe_support', payload.filiale.is_societe_support);
    }

    try {
      const response = await axios.post(
        domain + `/api/filiale/create_filiale`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );
      commit('NEW_SETTING_FILIALE_TH', response.data.data);
      return {
        success: true
      };
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        return {
          success: false,
          error: data
        };
      } else {
        return {
          success: false,
          error: 'Une erreur est survenue'
        };
      }
    }
  },
  async getSettingFilialeTh({ commit }, payload) {
    const params = {};
    if (payload?.type != null) {
      params.type = payload.type;
    }
    if (payload?.type_societe != null) {
      params.type_societe = payload.type_societe;
    }
    if (payload?.deleted != null) {
      params.deleted = payload.deleted;
    }
    if (payload?.search != null) {
      params.search = payload.search;
    }
    if (payload?.page != null) {
      params.page = payload.page;
    }
    if (payload?.per_page != null) {
      params.per_page = payload.per_page;
    }

    commit('SET_ERROR_SETTING_FILIALE_TH', null);
    commit('SET_LOADING_SETTING_FILIALE_TH', true);
    state.listeFiliales = [];
    try {
      const response = await axios.get(domain + `/api/filiale/all_filiale`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
      });
      if (payload?.page != null) {
        commit('SET_SETTING_FILIALE_TH', response.data.data.data);
        commit('SET_TOTAL_ROWS', response.data.data.total);
      } else {
        commit('SET_SETTING_FILIALE_TH', response.data.data);
      }
      commit('SET_LOADING_SETTING_FILIALE_TH', false);
      let data = response.data.data.data;
      state.listeFiliales.push({ value: null, text: '' });
      data.forEach(function(item) {
        const select = { value: item.id, text: item.name };
        state.listeFiliales.push(select);
      });

      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_SETTING_FILIALE_TH', error.response.data);
      } else {
        commit('SET_ERROR_SETTING_FILIALE_TH', 'Une erreur est survenue');
      }
      commit('SET_LOADING_SETTING_FILIALE_TH', false);

      return false;
    }
  },
  //new rote
  async getSettingFilialeThNew({ commit }) {
    commit('SET_ERROR_SETTING_FILIALE_TH', null);

    commit('SET_LOADING_SETTING_FILIALE_TH', true);
    try {
      const response = await axios.get(domain + `/api/libre/filiales`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_SETTING_FILIALE_TH', response.data);
      commit('SET_LOADING_SETTING_FILIALE_TH', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_SETTING_FILIALE_TH', error.response.data);
      } else {
        commit('SET_ERROR_SETTING_FILIALE_TH', 'Une erreur est survenue');
      }
      commit('SET_LOADING_SETTING_FILIALE_TH', false);

      return false;
    }
  },
  async deleteSettingFilialeTh({ commit }, payload) {
    commit('SET_ERROR_SETTING_FILIALE_TH', null);

    commit('SET_LOADING_SETTING_FILIALE_TH', true);
    try {
      const response = await axios.get(
        domain + `/api/filiale/delete_filiale/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DELETE_FILIALE_SETTING_TH', payload);
      commit('SET_LOADING_SETTING_FILIALE_TH', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_SETTING_FILIALE_TH', error.response.data);
      } else {
        commit('SET_ERROR_SETTING_FILIALE_TH', 'Une erreur est survenue');
      }
      commit('SET_LOADING_SETTING_FILIALE_TH', false);

      return false;
    }
  },

  async addDepotToFilialeAction({ commit }, payload) {
    commit('SET_ERROR_SETTING_FILIALE_TH', null);

    // let arrayVide = [];
    const params = new FormData();
    params.append('id', payload.filiale.id);
    params.append('name', payload.filiale.name);
    if (payload.imageData) {
      params.append('logo', payload.imageData);
    }
    if (payload?.filiale?.couleur != null) {
      params.append('couleur', payload.filiale.couleur);
    }
    if (payload?.filiale?.abreviation != null) {
      params.append('abreviation', payload.filiale.abreviation);
    }
    if (payload?.filiale?.type != null) {
      params.append('type', payload.filiale.type);
    }
    if (payload?.filiale?.type_societe != null) {
      params.append('type_societe', payload.filiale.type_societe);
    }
    if (payload.filiale.prenom && payload.filiale.prenom != null) {
      params.append('prenom', payload.filiale.prenom);
    }
    if (payload?.filiale?.payment_condition != null) {
      params.append('payment_condition', payload.filiale.payment_condition);
    }
    if (payload?.filiale?.payment_type != null) {
      params.append('payment_type', payload.filiale.payment_type);
    }
    if (payload?.filiale?.start_day != null) {
      params.append('start_day', payload.filiale.start_day);
    }
    if (payload?.filiale?.start_month != null) {
      params.append('start_month', payload.filiale.start_month);
    }
    if (payload?.filiale?.start_year != null) {
      params.append('start_year', payload.filiale.start_year);
    }
    if (payload?.filiale?.end_day != null) {
      params.append('end_day', payload.filiale.end_day);
    }
    if (payload?.filiale?.end_month != null) {
      params.append('end_month', payload.filiale.end_month);
    }
    if (payload?.filiale?.end_year != null) {
      params.append('end_year', payload.filiale.end_year);
    }
    if (payload?.filiale?.pixel_id != null) {
      params.append('pixel_id', payload.filiale.pixel_id);
    }
    if (payload.filiale.footer && payload.filiale.footer != null) {
      if (payload.filiale.footer_type == 'image') {
        if (typeof payload.filiale.footer == 'object') {
          params.append('footer_type', payload.filiale.footer_type);
          params.append('footer', payload.filiale.footer);
        }
      } else {
        if (
          payload.filiale.footer_type &&
          payload.filiale.footer_type != null
        ) {
          params.append('footer_type', payload.filiale.footer_type);
        }
        params.append('footer', payload.filiale.footer);
      }
    }
    if (payload.filiale.nom_famille && payload.filiale.nom_famille != null) {
      params.append('nom_famille', payload.filiale.nom_famille);
    }
    if (payload.filiale.civilite && payload.filiale.civilite != null) {
      params.append('civilite', payload.filiale.civilite);
    }
    if (payload?.filiale?.siren_value != null) {
      params.append('siren_value', payload?.filiale?.siren_value);
    }
    if (payload?.filiale?.tva_value != null) {
      params.append('tva_value', payload?.filiale?.tva_value);
    }
    if (payload.filiale.iban && payload.filiale.iban != null) {
      params.append('iban', payload.filiale.iban);
    }
    if (payload.filiale.swift && payload.filiale.swift != null) {
      params.append('swift', payload.filiale.swift);
    }
    if (payload.filiale.code_postal && payload.filiale.code_postal != null) {
      params.append('code_postal', payload.filiale.code_postal);
    }
    if (payload?.filiale?.masterFiliales?.length) {
      for (let i = 0; i < payload?.filiale?.masterFiliales?.length; i++) {
        params.append(
          'masterFiliales[' + i + '][master_id]',
          payload?.filiale?.masterFiliales[i].master_id
        );
        params.append(
          'masterFiliales[' + i + '][date_debut]',
          payload?.filiale?.masterFiliales[i].date_debut
        );
        params.append(
          'masterFiliales[' + i + '][date_fin]',
          payload?.filiale?.masterFiliales[i].date_fin
        );
      }
    }
    if (
      payload.filiale.site_internet &&
      payload.filiale.site_internet != null
    ) {
      params.append('site_internet', payload.filiale.site_internet);
    }

    if (payload.filiale.ville && payload.filiale.ville != null) {
      params.append('ville', payload.filiale.ville);
    }
    if (payload.filiale.capital && payload.filiale.capital != null) {
      params.append('capital', payload.filiale.capital);
    }
    if (payload.filiale.rcs && payload.filiale.rcs != null) {
      params.append('rcs', payload.filiale.rcs);
    }
    if (payload.filiale.pays && payload.filiale.pays != null) {
      params.append('pays', payload.filiale.pays);
    }
    if (payload.filiale.banque && payload.filiale.banque != null) {
      params.append('banque', payload.filiale.banque);
    }
    if (payload.filiale.fax && payload.filiale.fax != null) {
      params.append('fax', payload.filiale.fax);
    }
    if (payload.filiale.telephone && payload.filiale.telephone != null) {
      params.append('telephone', payload.filiale.telephone);
    }
    if (payload.filiale.description && payload.filiale.description != null) {
      params.append('description', payload.filiale.description);
    }
    if (
      payload.filiale.telephone_portable &&
      payload.filiale.telephone_portable != null
    ) {
      params.append('telephone_portable', payload.filiale.telephone_portable);
    }

    // if (payload.filiale.responsable && payload.filiale.responsable != null) {
    //   if (
    //     payload.filiale.responsables.length
    //   ) {
    //     params.append('responsables['+i+']', payload.filiale.responsables[i]);
    //   }
    //   if (!payload.filiale.responsable.id) {
    //     params.append('responsable', payload.filiale.responsable);
    //   }
    // }
    if (payload.filiale.responsables && payload.filiale.responsables.length) {
      for (let i = 0; i < payload.filiale.responsables.length; i++) {
        params.append(
          'responsables[' + i + ']',
          payload.filiale.responsables[i].id
        );
      }
    } else {
      params.append('responsables[0]', []);
    }
    if (payload.filiale.siret == null) {
      params.append('siret', '');
    } else {
      params.append('siret', payload.filiale.siret);
    }
    if (payload.filiale.adresse == null) {
      params.append('adresse', '');
    } else {
      params.append('adresse', payload.filiale.adresse);
    }
    if (payload.filiale.email == null) {
      params.append('email', '');
    } else {
      params.append('email', payload.filiale.email);
    }
    if (payload.dejaVolant && payload.dejaVolant == true) {
      params.append('depots[0][id]', payload.filiale.depots[0].id);
      params.append(
        'depots[0][responsable_depot]',
        payload.filiale.depots[0].responsable_depot
      );
    } else {
      if (payload.filiale.volant && payload.filiale.volant == true) {
        params.append('volant', payload.filiale.volant);
        params.append('responsable_depot', payload.filiale.responsable_depot);
      } else {
        const concat = [...payload.filiale.depots, ...payload.depot];
        let index = 0;
        concat.forEach((element, key) => {
          if (element) {
            params.append('depots[' + index + '][id]', element.id);
            params.append(
              'depots[' + index + '][responsable_depot]',
              element.responsable_depot
            );
          }
          index++;
        });
      }
    }
    if (payload.filiale.newFiles && payload.filiale.newFiles != null) {
      let index = 0;
      payload.filiale.newFiles.forEach((element, key) => {
        if (element) {
          params.append('files[' + index + '][file]', element);
          params.append('files[' + index + '][name]', element.name_file);
        }
        index++;
      });
    }
    if (payload.filiale.ribs && payload.filiale.ribs != null) {
      let index = 0;
      payload.filiale.ribs.forEach((element, key) => {
        if (element) {
          params.append('ribs[' + index + '][iban]', element.iban);
          params.append('ribs[' + index + '][swift]', element.swift);
          params.append('ribs[' + index + '][blocked]', element.blocked);
          params.append('ribs[' + index + '][bank]', element.bank);
          element.favori == false ? (element.favori = 0) : (element.favori = 1);
          params.append('ribs[' + index + '][favori]', element.favori);
        }
        index++;
      });
    }
    if (payload.filiale.image) {
      params.append('logo', payload.filiale.image);
    }

    if (payload.filiale.type_societe_support) {
      params.append(
        'type_societe_support',
        payload.filiale.type_societe_support
      );
    }

    if (payload.filiale.is_societe_support) {
      params.append('is_societe_support', payload.filiale.is_societe_support);
    }

    commit('SET_LOADING_SETTING_FILIALE_TH', true);
    try {
      const response = await axios.post(
        domain + `/api/filiale/edit_filiale`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('ADD_DEPOT_TO_FILIALE_TH', {
        filiale: payload.filiale,
        response: response.data.data
      });
      commit('ADD_FILES_FILIALE', {
        filiale: payload.filiale,
        response: response.data.data.files
      });
      commit('UPDATE_REPONSABLES_FILIALE', {
        filiale: payload.filiale,
        response: response.data.data.responsables
      });
      commit('SET_LOADING_SETTING_FILIALE_TH', false);
      return { success: true };
    } catch (error) {
      if (error?.response?.data?.error) {
        return {
          success: false,
          error: error?.response?.data?.error
        };
      } else {
        return {
          success: false,
          error: 'Une erreur est survenue'
        };
      }
    }
  },

  async removeDepotFromFilialeAction({ commit }, payload) {
    commit('SET_ERROR_SETTING_FILIALE_TH', null);
    const params = {
      id: payload.filiale.id,
      name: payload.filiale.name
    };
    if (payload.filiale.volant && payload.filiale.volant == true) {
      params.depots = [];
    } else {
      params.depots = payload.filiale.depots.filter(
        item => item.id != payload.depot.id
      );
    }
    commit('SET_LOADING_SETTING_FILIALE_TH', true);
    try {
      const response = await axios.post(
        domain + `/api/filiale/edit_filiale`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('REMOVE_DEPOT_FROM_FILIALE_TH', {
        filiale: payload.filiale,
        depot: payload.depot,
        response: response.data.data
      });
      commit('SET_LOADING_SETTING_FILIALE_TH', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_SETTING_FILIALE_TH', error.response.data);
      } else {
        commit('SET_ERROR_SETTING_FILIALE_TH', 'Une erreur est survenue');
      }
      commit('SET_LOADING_SETTING_FILIALE_TH', false);

      return false;
    }
  },

  async updateNomResponsableDepotAction({ commit }, payload) {
    commit('SET_ERROR_SETTING_FILIALE_TH', null);
    const params = {
      id: payload.depot.id,
      name: payload.depot.depot,
      responsable_depot: payload.name,
      filiale_id: payload.depot.filiale_id
    };
    commit('SET_LOADING_SETTING_FILIALE_TH', true);
    try {
      const response = await axios.post(
        domain + `/api/filiale/edit_depot`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_RESPONSABLE_DEPOT_FOR_FILIALE_TH', {
        filiale: payload.filiale,
        depot: payload.depot,
        response: response.data.data
      });
      commit('SET_LOADING_SETTING_FILIALE_TH', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_SETTING_FILIALE_TH', error.response.data);
      } else {
        commit('SET_ERROR_SETTING_FILIALE_TH', 'Une erreur est survenue');
      }
      commit('SET_LOADING_SETTING_FILIALE_TH', false);

      return false;
    }
  },
  async fetchResponsableFilialeFreeTh({ commit }) {
    commit('SET_ERROR_SETTING_FILIALE_TH', null);

    // commit('SET_LOADING_SETTING_FILIALE_TH', true);
    try {
      const response = await axios.get(
        domain + `/api/filiale/free_responsable`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_SETTING_RESPONSABLE_FILIALE_TH', response.data.data);
      // commit('SET_LOADING_SETTING_FILIALE_TH', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_SETTING_FILIALE_TH', error.response.data);
      } else {
        commit('SET_ERROR_SETTING_FILIALE_TH', 'Une erreur est survenue');
      }
      commit('SET_LOADING_SETTING_FILIALE_TH', false);

      return false;
    }
  },

  async getAllTypeSocieteSupport({ commit }) {
    commit('SET_ERROR_SETTING_TYPE_SOCIETE_SUPPORT', null);

    commit('SET_LOADING_SETTING_TYPE_SOCIETE_SUPPORT', true);
    state.typeSocieteSupportth = [];
    try {
      const response = await axios.get(domain + `/api/type-societe-support`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });

      commit('SET_SETTING_TYPE_SOCIETE_SUPPORT', response.data.data);

      commit('SET_LOADING_SETTING_TYPE_SOCIETE_SUPPORT', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_SETTING_TYPE_SOCIETE_SUPPORT', error.response.data);
      } else {
        commit(
          'SET_ERROR_SETTING_TYPE_SOCIETE_SUPPORT',
          'Une erreur est survenue'
        );
      }
      commit('SET_LOADING_SETTING_TYPE_SOCIETE_SUPPORT', false);

      return false;
    }
  },

  async addRibToFiliale({ commit, state }, payload) {
    commit('SET_RIBS_ERRORS', null);

    const params = {
      filiale_id: payload.filiale_id,
      swift: payload.swift,
      iban: payload.iban,
      bank_id: payload.bank_id,
      code_bank: payload.code_bank,
      code_guichet: payload.code_guichet,
      num_compte: payload.num_compte,
      cle_rib: payload.cle_rib,
      familles: payload.familles.map(item => item.famille)
    };

    //if(payload.swift || payload.iban == null){
    //  commit('SET_TEMPLATE_RIB_ERROR', ['Iban et swift obligatoire']);
    //}
    // commit('SET_RIBS_LOADER', true);
    try {
      const response = await axios.post(
        domain + `/api/filiale/add-rib`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LIST_RIBS_OF_FILIALS', response.data.data);
      state.SettingFilialeTh.map(filiale => {
        if (filiale.id == payload.filiale_id) {
          filiale.ribs = response.data.data;
        }
      });
      // commit('SET_RIBS_LOADER', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_RIBS_ERRORS', error.response.data.error);
      } else {
        commit('SET_RIBS_ERRORS', ['Une erreur est survenue']);
      }
      commit('SET_RIBS_LOADER', false);

      return false;
    }
  },
  async updateRibFiliale({ commit }, payload) {
    commit('SET_FILIALE_RIB_ERROR', null);
    commit('SET_FILIALE_RIB_LOADING', true);
    const params = {
      id: payload.id,
      swift: payload.swift,
      iban: payload.iban,
      bank_id: payload.bank_id,
      favori: payload.favori,
      blocked: payload.blocked,
      code_bank: payload.code_bank,
      code_guichet: payload.code_guichet,
      num_compte: payload.num_compte,
      cle_rib: payload.cle_rib,
      familles: payload.familles.map(item => item.famille)
    };
    try {
      const response = await axios.post(
        domain + `/api/filiale/edit-rib`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LIST_RIBS_OF_FILIALS', response.data.data);
      state.SettingFilialeTh.map(filiale => {
        if (filiale.id == payload.filiale_id) {
          filiale.ribs = response.data.data;
        }
      });
      commit('SET_FILIALE_RIB_LOADING', false);

      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_TEMPLATE_RIB_ERROR', error.response.data.error);
      } else {
        commit('SET_TEMPLATE_RIB_ERROR', ['Une erreur est survenue']);
      }
      commit('SET_TEMPLATE_RIB_LOADING', false);

      return false;
    }
  },
  async blockunblockRibFiliale({ commit }, payload) {
    commit('SET_RIBS_ERRORS', null);
    commit('SET_RIBS_LOADER', true);

    const params = {
      id: payload.id,
      blocked: payload.blocked
    };

    try {
      const response = await axios.post(
        domain + `/api/filiale/block_unblock-rib`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_RIBS_LOADER', false);

      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_RIBS_ERRORS', error.response.data.error);
      } else {
        commit('SET_RIBS_ERRORS', ['Une erreur est survenue']);
      }
      commit('SET_RIBS_LOADER', false);

      return false;
    }
  },
  async removeFilialeFile({ commit }, payload) {
    commit('SET_FILIALE_REMOVE_FILE_ERROR', null);
    commit('SET_FILIALE_REMOVE_FILE_LOADING', true);
    try {
      const response = await axios.delete(
        domain + `/api/filiale/delete-file/` + payload.file.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_FILIALE_REMOVE_FILE_LOADING', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_FILIALE_REMOVE_FILE_ERROR', error.response.data);
      } else {
        commit('SET_FILIALE_REMOVE_FILE_ERROR', 'Une erreur est survenue');
      }
      commit('SET_FILIALE_REMOVE_FILE_LOADING', false);
      return false;
    }
  },
  async renameFilialeFile({ commit }, payload) {
    commit('SET_FILIALE_RENAME_FILE_ERROR', null);
    commit('SET_FILIALE_RENAME_FILE_LOADING', true);

    const params = {
      name: payload.newName
    };

    try {
      const response = await axios.post(
        domain + `/api/filiale/rename-file/` + payload.file.id,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      const obj = {
        response: response.data.data,
        item: payload.item,
        index: payload.index
      };

      commit('SET_FILE_FILIALE_NAME', obj);

      commit('SET_FILIALE_RENAME_FILE_LOADING', false);

      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_FILIALE_RENAME_FILE_ERROR', error.response.data);
      } else {
        commit('SET_FILIALE_RENAME_FILE_ERROR', 'Une erreur est survenue');
      }
      commit('SET_FILIALE_RENAME_FILE_LOADING', false);
      return false;
    }
  },
  async getAllSocieteSupport({ commit }) {
    state.ListeSocieteSupportth = [];
    try {
      const response = await axios.get(
        domain + `/api/filiale/all_filiale?is_societe_support=1`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      commit('SET_LISTE_SOCIETE_SUPPORT', response.data.data);

      commit('SET_LOADING_LISTE_SOCIETE_SUPPORT', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_LISTE_SOCIETE_SUPPORT', error.response.data);
      } else {
        commit('SET_ERROR_LISTE_SOCIETE_SUPPORT', 'Une erreur est survenue');
      }
      commit('SET_LOADING_LISTE_SOCIETE_SUPPORT', false);

      return false;
    }
  },
  async removeFilialeRib({ commit }, payload) {
    commit('SET_FILIALE_REMOVE_RIB_ERROR', null);
    commit('SET_FILIALE_REMOVE_RIB_LOADING', true);
    try {
      const response = await axios.delete(
        domain + `/api/filiale/delete-rib/` + payload.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_FILIALE_REMOVE_RIB_LOADING', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_FILIALE_REMOVE_RIB_ERROR', error.response.data);
      } else {
        commit('SET_FILIALE_REMOVE_RIB_ERROR', 'Une erreur est survenue');
      }
      commit('SET_FILIALE_REMOVE_RIB_LOADING', false);
      return false;
    }
  },
  async getAllFilialesListe({ commit }) {
    let listeFilialesLocateur = [];
    let listeFilialesIntermidier = [];
    try {
      const response = await axios.get(domain + `/api/filiale/all_filiale`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });

      let data = response.data.data;

      listeFilialesIntermidier.push({ value: null, text: '' });
      data.forEach(function(item) {
        const select = { value: item.id, text: item.name };
        listeFilialesIntermidier.push(select);
      });

      data.forEach(function(item) {
        const select = { value: item.id, text: item.name };
        listeFilialesLocateur.push(select);
      });
      commit('SET_FILIAL_INTERMIDIER', listeFilialesIntermidier);
      commit('SET_FILIAL_LOCATEUR', listeFilialesLocateur);
      commit('SET_LOADING_LISTE_SOCIETE_SUPPORT', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_LISTE_SOCIETE_SUPPORT', error.response.data);
      } else {
        commit('SET_ERROR_LISTE_SOCIETE_SUPPORT', 'Une erreur est survenue');
      }
      commit('SET_LOADING_LISTE_SOCIETE_SUPPORT', false);

      return false;
    }
  },
  async fetchSequenceFactureFields({ commit }) {
    try {
      const response = await axios.get(`${domain}/api/filiale/variables`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_SEQUENCE_FACTURE_FIELDS', response.data);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit(
          'SET_ERROR_LISTE_SOCIETE_SUPPORT',
          error.response.data.error.messages
        );
      } else {
        commit('SET_ERROR_LISTE_SOCIETE_SUPPORT', ['Une erreur est survenue']);
      }
      return false;
    }
    return true;
  },
  async blockunblockBienFiliale({ commit }, payload) {
    commit('SET_LOADING_SETTING_FILIALE_TH', true);

    const params = {
      id: payload.id,
      blocked: payload.blocked
    };

    try {
      const response = await axios.post(
        domain + `/api/filiale/block_unblock-bien`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      commit('SET_LOADING_SETTING_FILIALE_TH', false);

      return {
        succes: true,
        response: response.data.data
      };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_FILIALE_RIB_ERROR', error.response.data.error);
      } else {
        commit('SET_FILIALE_RIB_ERROR', ['Une erreur est survenue']);
      }
      commit('SET_LOADING_SETTING_FILIALE_TH', false);

      return false;
    }
  },
  async removeFilialeBien({ commit }, payload) {
    commit('SET_FILIALE_REMOVE_RIB_ERROR', null);
    commit('SET_FILIALE_REMOVE_RIB_LOADING', true);
    try {
      const response = await axios.delete(
        domain + `/api/filiale/delete-bien/` + payload.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_FILIALE_REMOVE_RIB_LOADING', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_FILIALE_REMOVE_RIB_ERROR', error.response.data);
      } else {
        commit('SET_FILIALE_REMOVE_RIB_ERROR', 'Une erreur est survenue');
      }
      commit('SET_FILIALE_REMOVE_RIB_LOADING', false);
      return false;
    }
  },
  async removeFilialeSequence({ commit }, payload) {
    commit('SET_FILIALE_REMOVE_RIB_ERROR', null);
    try {
      const response = await axios.delete(
        domain +
          `/api/filiale/delete-sequences/` +
          payload.id +
          '/' +
          payload.index,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_FILIALE_REMOVE_RIB_ERROR', error.response.data);
      } else {
        commit('SET_FILIALE_REMOVE_RIB_ERROR', 'Une erreur est survenue');
      }
      return false;
    }
  },
  async addSocieteFactureToFiliale({ commit }, payload) {
    commit('SET_SOCIETE_FACTURE_LOADER', true);

    const params = {
      filiale_id: payload.filiale_id,
      societe_id: payload.societe_id,
      favori: payload.favori
    };
    try {
      const response = await axios.post(
        domain + `/api/filiale/add_societe_facture`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LIST_SOCIETE_FACTURE_OF_FILIALS', {
        response: response.data.data,
        add: true,
        societeFacture: payload.societeFacture
      });
      commit('SET_SOCIETE_FACTURE_LOADER', false);

      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_SOCIETE_FACTURE_ERRORS', error.response.data.error);
      } else {
        commit('SET_SOCIETE_FACTURE_ERRORS', ['Une erreur est survenue']);
      }
      commit('SET_SOCIETE_FACTURE_LOADER', false);

      return false;
    }
  },
  async updateSocieteFactureFiliale({ commit }, payload) {
    commit('SET_SOCIETE_FACTURE_LOADER', true);
    const params = {
      id: payload.id,
      societe_id: payload.societe_id,
      favori: payload.favori
    };
    try {
      const response = await axios.post(
        domain + `/api/filiale/edit-filiale-societe-facture`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LIST_SOCIETE_FACTURE_OF_FILIALS', response.data.data);
      commit('SET_SOCIETE_FACTURE_LOADER', false);

      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_SOCIETE_FACTURE_ERRORS', error.response.data.error);
      } else {
        commit('SET_SOCIETE_FACTURE_ERRORS', ['Une erreur est survenue']);
      }
      commit('SET_SOCIETE_FACTURE_LOADER', false);

      return false;
    }
  },
  async removeFilialeSocieteFacture({ commit }, payload) {
    commit('SET_SOCIETE_FACTURE_ERRORS', null);
    commit('SET_SOCIETE_FACTURE_LOADER', true);
    try {
      const response = await axios.delete(
        domain + `/api/filiale/delete-societe-facture/` + payload.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_SOCIETE_FACTURE_LOADER', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_SOCIETE_FACTURE_ERRORS', error.response.data);
      } else {
        commit('SET_SOCIETE_FACTURE_ERRORS', 'Une erreur est survenue');
      }
      commit('SET_SOCIETE_FACTURE_LOADER', false);
      return false;
    }
  },
  async getSettingOrgenismeTh({ commit }, payload) {
    const params = { type: 'organisme' };

    commit('SET_ERROR_SETTING_FILIALE_TH', null);
    commit('SET_LOADING_SETTING_FILIALE_TH', true);
    try {
      const response = await axios.get(domain + `/api/filiale/all_filiale`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
      });

      commit('SET_SETTING_ORGANISME_TH', response.data.data);

      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_SETTING_FILIALE_TH', error.response.data);
      } else {
        commit('SET_ERROR_SETTING_FILIALE_TH', 'Une erreur est survenue');
      }
      commit('SET_LOADING_SETTING_FILIALE_TH', false);

      return false;
    }
  },
  transformFormatObjetClientPassage({ commit }, payload) {
    commit('NEW_SETTING_FILIALE_TH', payload);
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
