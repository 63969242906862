import axios from 'axios';
import domain from '@/environment';
import Comptabilite from '../../models/Comptabilite';
import responsable_depot from '../../pusher/responsable_depot';
let requestCompta = null;

const state = {
  facturesComptabilite: [],
  loadingComptabilite: false,
  errorComptabilite: [],
  rowsComptabilite: 0,
  typesComptabilite: [],
  familles: [],
  lotsCompta: []
};
const getters = {
  factures: state => state.facturesComptabilite,
  loadingFactureComptabilite: state => state.loadingComptabilite,
  errorFactures: state => state.errorComptabilite,
  getRows: state => state.rowsComptabilite,
  typesCompta: state => state.typesComptabilite,
  familles: state => state.familles,
  getLotsCompta: state => state.lotsCompta
};
const mutations = {
  SET_ALL_FACTURES(state, payload) {
    state.facturesComptabilite = payload.map(item => Comptabilite.create(item));
  },
  SET_LOADING_FACTURE(state, payload) {
    state.loadingComptabilite = payload;
  },
  SET_ERROR_COMPTA_FACTURES(state, payload) {
    state.errorComptabilite = payload;
  },
  SET_TOTAL_ROWS_COMPTABILITE(state, payload) {
    state.rowsComptabilite = payload;
  },
  UPDATE_FACTURES_COMPTA(state, payload) {
    payload.oldFactures.map(old => {
      let index = payload.factures.findIndex(facture => facture.id == old.id);
      old.update(payload.factures[index]);
    });
  },
  SET_COMPTABILITES(state, payload) {
    state.typesComptabilite = payload;
  },
  SET_FAMILLES(state, payload) {
    state.familles = payload;
  },
  SET_LOTS_COMPTA(state, payload) {
    state.lotsCompta = payload;
  }
};
const actions = {
  async getFactures({ commit }, payload) {
    commit('SET_LOADING_FACTURE', true);
    const params = {
      per_page: payload.per_page,
      page: payload.page,
      famille: payload.famille,
      column: payload.column,
      order: payload.order
    };
    for (let i = 0; i < payload?.type?.length; i++) {
      params['type[' + i + ']'] = payload?.type[i]?.value;
    }
    for (let i = 0; i < payload?.etat?.length; i++) {
      params['etat[' + i + ']'] = payload.etat[i].value;
    }
    for (let i = 0; i < payload?.vendeur_id?.length; i++) {
      params['vendeur_id[' + i + ']'] = payload?.vendeur_id[i]?.value;
    }
    for (let i = 0; i < payload?.categorie_id?.length; i++) {
      params['categorie_id[' + i + ']'] = payload?.categorie_id[i]?.value;
    }
    for (let i = 0; i < payload?.acheteur_id?.length; i++) {
      params['acheteur_id[' + i + ']'] = payload?.acheteur_id[i]?.value;
    }
    for (let i = 0; i < payload?.family?.length; i++) {
      params['family[' + i + ']'] = payload?.family[i]?.value;
    }
    for (let i = 0; i < payload?.mail_lot?.length; i++) {
      params['mail_lot[' + i + ']'] = payload?.mail_lot[i];
    }
    if (payload.date_debut != null) {
      params.date_debut = payload.date_debut;
    }
    if (payload.date_fin != null) {
      params.date_fin = payload.date_fin;
    }
    if (payload.num != null) {
      params.num = payload.num;
    }
    if (payload.comptabilite != null) {
      params.comptabilite = payload.comptabilite;
    }
    if (payload.grouped != null) {
      params.grouped = payload.grouped;
    }
    if (payload.nums != null) {
      params.nums = payload.nums;
    }
    if (payload.no_proforma) {
      params.no_proforma = payload.no_proforma;
    }
    try {
      if (requestCompta) {
        commit('SET_ERROR_COMPTA_FACTURES', null);
        requestCompta.cancel('cancel-request');
      }
      requestCompta = axios.CancelToken.source();
      const response = await axios.get(domain + `/api/libre/factures`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params,
        cancelToken: requestCompta.token
      });
      commit('SET_ALL_FACTURES', response.data.data.data);
      commit('SET_TOTAL_ROWS_COMPTABILITE', response.data.data.count);
      commit('SET_LOADING_FACTURE', false);
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_COMPTA_FACTURES', error.response.data.error);
        commit('SET_LOADING_FACTURE', false);
      } else {
        if (!error.message == 'cancel-request') {
          commit('SET_LOADING_FACTURE', false);
          commit('SET_ERROR_COMPTA_FACTURES', ['Une erreur est survenue']);
        }
      }
    }
  },
  async changeCompta({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/libre/changeComptabilise`,
        payload.factures,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_FACTURES_COMPTA', {
        factures: response.data.data,
        oldFactures: payload.oldFactures
      });
      return true;
    } catch (error) {
      return false;
    }
  },
  async getTypeOfCompta({ commit }) {
    try {
      const response = await axios.get(
        domain + `/api/libre/typeOfComptabilte`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_COMPTABILITES', response.data);
      return true;
    } catch (error) {
      return false;
    }
  },
  async getFamilles({ commit }) {
    try {
      const response = await axios.get(domain + `/api/libre/get_families`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_FAMILLES', response.data);
      return true;
    } catch (error) {
      return false;
    }
  },
  async getLotCompta({ commit }, payload) {
    try {
      const response = await axios.get(
        domain + `/api/libre/lots-mails-online`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );
      commit('SET_LOTS_COMPTA', response.data.lots_mails);
      return true;
    } catch (error) {
      return false;
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
