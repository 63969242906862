export default class configuration {
  constructor(data) {
    this.acheteur = data.acheteur;
    this.acheteur_id = data.acheteur_id;
    this.date_debut = data.date_debut;
    this.date_fin = data.date_fin;
    this.description = data.description;
    this.frequence = data.frequence;
    this.id = data.id;
    this.jour_creation = data.jour_creation;
    this.montant = data.montant;
    this.nom_service_bien = data.nom_service_bien;
    this.objet = data.objet;
    this.reference = data.reference;
    this.service_bien = data.service_bien;
    this.tva = data.tva;
    this.type = data.type;
    this.vendeur = data.vendeur;
    this.vendeur_id = data.vendeur_id;
  }
  update(data) {
    this.acheteur = data.acheteur;
    this.acheteur_id = data.acheteur_id;
    this.date_debut = data.date_debut;
    this.date_fin = data.date_fin;
    this.description = data.description;
    this.frequence = data.frequence;
    this.id = data.id;
    this.jour_creation = data.jour_creation;
    this.montant = data.montant;
    this.nom_service_bien = data.nom_service_bien;
    this.objet = data.objet;
    this.reference = data.reference;
    this.service_bien = data.service_bien;
    this.tva = data.tva;
    this.type = data.type;
    this.vendeur = data.vendeur;
    this.vendeur_id = data.vendeur_id;
  }
  static create(data) {
    return new configuration(data);
  }
}
