export default class ConditionPaiement {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.value = data.value;
    this.fin_du_mois = data.fin_du_mois == 0 ? false : true;
  }
  update(data) {
    this.id = data.id;
    this.name = data.name;
    this.value = data.value;
    this.fin_du_mois = data.fin_du_mois == 0 ? false : true;
  }
  static create(data) {
    return new ConditionPaiement(data);
  }
}
