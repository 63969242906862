export default class ModelExport {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.user_id = data.user_id;
    this.columns = data.columns;
    this.user_name = data.user_name;
    this.statut = data.statut;
    this.is_system = data.is_system;
  }
  update(data) {
    this.id = data.id;
    this.name = data.name;
    this.columns = data.columns;
    this.user_name = data.user_name;
    this.statut = data.statut;
  }
  static create(data) {
    return new ModelExport(data);
  }
}
