import FactureSci from './FactureSci';
export default class Sci {
  constructor(data) {
    this.check = false;
    this.name = data.name;
    this.total_net_ht = data.total_ht;
    this.total_tva = data.total_tva;
    this.total_ttc = data.total_ttc;
    this.factures = data.factures.map(facture => FactureSci.create(facture));
  }

  static create(data) {
    return new Sci(data);
  }
}
