import RowFactureFraisDynamic from './RowFactureFraisDynamic';
export default class RowProjectFraisDynamic {
  constructor(data) {
    this.id = data.id;
    this.nom_prenom = data.nom_prenom;
    this.montant_final = data.montant_final;
    this.montant_net_ht = data.montant_net_ht;
    this.numero_dossier = data.numero_dossier;
    this.showDetails = false;
    this.check = false;
    this.loadingDetailt = false;
    this.showDetails = false;
    this.mode_paiement = data.mode_paiement;
    this.lot = data.lot;
    this.etat = data.etat;
    this.be_type = data.be_type;
    this.avoir_id = data.avoir_id;
    this.avoir_num = data.avoir_num;
    this.client = data.client;
    this.client_email = data.client_email;
    this.date = data.date;
    this.ht = data.ht;
    this.num = data.num;
    this.objet = data.objet;
    this.project_id = data.project_id;
    this.regie = data.regie;
    this.statut_client = data.statut_client;
    this.super_regie = data.super_regie;
    this.template_id = data.template_id;
    this.ttc = data.ttc;
    this.type_facture = data.type_facture;
    this.vendeur = data.vendeur;
    this.vendeur_id = data.vendeur_id;
    this.type_paiement = data.type_paiement;
    this.total_tva = data.total_tva;
    this.total_tva_float = data.total_tva_float;
    this.ttc_float = data.ttc_float;
    this.ht_float = data.ht_float;
    this.coef = data.coef;
    this.index = 0;
    this.modifiable = data.modifiable;
    this.email_sent_at = data.email_sent_at;
    this.reste = data.reste;
    this.client_id = data.client_id;
    this.reglements = data.reglements;
    this.date_echeance = data.date_echeance;
    this.montant_regle = data.montant_regle;
    this.date_paiement = data.date_paiement;
    this.belongsTo = data.belongsTo;
    this.historique = data.logs;
    this.FactureAvoir = data.FactureAvoir;
  }
  updateAvoir(data) {
    this.FactureAvoir = data.FactureAvoir;
    this.client_id = data.client_id;
    this.vendeur = data.vendeur;
    this.vendeur_id = data.vendeur_id;
    this.statut_client = data.statut_client;
    this.etat = data.etat;
  }
  update(data) {
    this.id = data.id;
    this.nom_prenom = data.nom_prenom;
    this.montant_final = data.montant_final;
    this.numero_dossier = data.numero_dossier;
    this.mode_paiement = data.mode_paiement;
    this.lot = data.lot;
    this.etat = data.etat;
    this.be_type = data.be_type;
    this.avoir_id = data.avoir_id;
    this.avoir_num = data.avoir_num;
    this.client = data.client;
    this.client_email = data.client_email;
    this.date = data.date;
    this.ht = data.ht;
    this.num = data.num;
    this.objet = data.objet;
    this.project_id = data.project_id;
    this.regie = data.regie;
    this.statut_client = data.statut_client;
    this.super_regie = data.super_regie;
    this.template_id = data.template_id;
    this.ttc = data.ttc;
    this.type_facture = data.type_facture;
    this.vendeur = data.vendeur;
    this.vendeur_id = data.vendeur_id;
    this.total_tva = data.total_tva;
    this.total_tva_float = data.total_tva_float;
    this.ttc_float = data.ttc_float;
    this.ht_float = data.ht_float;
    this.coef = data.coef;
    this.type_paiement = data.type_paiement;
    this.modifiable = data.modifiable;
    this.email_sent_at = data.email_sent_at;
    this.reste = data.reste;
    this.client_id = data.client_id;
    this.reglements = data.reglements;
    this.date_echeance = data.date_echeance;
    this.montant_regle = data.montant_regle;
    this.historique = data.logs;
    this.FactureAvoir = data.FactureAvoir;
  }
  updateStatue(data) {
    this.id = data.id;
    this.mode_paiement = data.mode_paiement;
    this.lot = data.lot;
    this.etat = data.etat;
    this.be_type = data.be_type;
    this.avoir_id = data.avoir_id;
    this.avoir_num = data.avoir_num;
    this.client = data.client;
    this.client_email = data.client_email;
    this.date = data.date;
    this.ht = data.ht;
    this.num = data.num;
    this.objet = data.objet;
    this.project_id = data.project_id;
    this.regie = data.regie;
    this.statut_client = data.statut_client;
    this.super_regie = data.super_regie;
    this.template_id = data.template_id;
    this.ttc = data.ttc;
    this.type_facture = data.type_facture;
    this.vendeur = data.vendeur;
    this.vendeur_id = data.vendeur_id;
    this.type_paiement = data.type_paiement;
    this.historique = data.logs;
    this.FactureAvoir = data.FactureAvoir;
  }
  updateDataMail(data) {
    this.email_sent_at = data.email_sent_at;
    this.lot = data.mail_lot;
    this.historique = data.logs;
  }
  updateDataReglement(data) {
    this.reste = data.reste;
    this.reglements = data.reglements;
    this.date_echeance = data.date_echeance;
    this.montant_regle = data.montant_regle;
    this.date_paiement = data.date_paiement;
    this.payment_type = data.payment_type;
    this.etat = data.etat;
    this.statut_client = data.statut_client;
    this.historique = data.logs;
  }

  static create(data) {
    return new RowProjectFraisDynamic(data);
  }
}
