import axios from 'axios';
import domain from '@/environment';

import ModelExport from '../../models/ModelExport';
const state = {
  models: [],
  loadingExport: false,
  modelsTotalRows: null
};
const getters = {
  models: state => state.models,
  loadingExport: state => state.loadingExport,
  modelsTotalRows: state => state.modelsTotalRows
};
const mutations = {
  ADD_NEW_MODEL_EXPORT(state, payload) {
    state.models.unshift(ModelExport.create(payload));
  },
  LOADING_MODEL_EXPORT(state, payload) {
    state.loadingExport = payload;
  },
  SET_MODELS_EXPORTS(state, payload) {
    state.models = payload.map(item => ModelExport.create(item));
  },
  DELETE_MODEL_EXPORT(state, payload) {
    let index = state.models.findIndex(model => model.id == payload);
    state.models.splice(index, 1);
  },
  UPDATE_MODEL_EXPORT(state, payload) {
    payload.model.update(payload.response);
  },
  SET_MODELS_TOTAL_ROWS(state, payload) {
    state.modelsTotalRows = payload;
  }
};
const actions = {
  async getAllModelExports({ commit }, payload) {
    commit('LOADING_MODEL_EXPORT', true);
    let params = {};
    if (payload?.page != null) {
      params.page = payload.page;
    }
    if (payload?.per_page != null) {
      params.per_page = payload.per_page;
    }
    try {
      const response = await axios.get(
        domain + '/api/model_export/get_all_model_export',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      if (payload?.per_page != null) {
        commit('SET_MODELS_TOTAL_ROWS', response.data.total);
        commit('SET_MODELS_EXPORTS', response.data.data);
      } else {
        commit('SET_MODELS_EXPORTS', response.data);
      }
      commit('LOADING_MODEL_EXPORT', false);
      return true;
    } catch (error) {
      commit('LOADING_MODEL_EXPORT', false);
      return false;
    }
  },
  async AddNewModelExport({ commit }, payload) {
    commit('LOADING_MODEL_EXPORT', true);
    try {
      const response = await axios.post(
        domain + '/api/model_export/add_model_export',
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      commit('LOADING_MODEL_EXPORT', false);
      commit('ADD_NEW_MODEL_EXPORT', response.data);
      return true;
    } catch (error) {
      commit('LOADING_MODEL_EXPORT', false);
      return false;
    }
  },

  async updateModelExport({ commit }, payload) {
    commit('LOADING_MODEL_EXPORT', true);
    try {
      const response = await axios.post(
        domain + '/api/model_export/update_model_export',
        payload.newModel,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('LOADING_MODEL_EXPORT', false);
      commit('UPDATE_MODEL_EXPORT', {
        model: payload.oldModelToUpdate,
        response: response.data
      });
      return true;
    } catch (error) {
      commit('LOADING_MODEL_EXPORT', false);
      return false;
    }
  },
  async deleteModelExport({ commit }, payload) {
    commit('LOADING_MODEL_EXPORT', true);
    try {
      const response = await axios.delete(
        domain + '/api/model_export/delete_model_export/' + payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('LOADING_MODEL_EXPORT', false);
      commit('DELETE_MODEL_EXPORT', payload);
      return true;
    } catch (error) {
      commit('LOADING_MODEL_EXPORT', false);
      return false;
    }
  },
  async exportFactureDynamicXls({ commit }, payload) {
    this.state.showNotifExportFile = true;
    commit('LOADING_MODEL_EXPORT', true);
    try {
      const response = await axios.post(
        domain + '/api/exportFactureSupport',
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      // var words = response.headers['cache-control'].split('.xlsx');

      // const link = document.createElement('a');
      // link.href = window.URL.createObjectURL(new Blob([response.data]));
      // link.setAttribute('download', words[0] + '.xlsx');
      // document.body.appendChild(link);
      // link.click();
      return { succes: true, msg: response.data.data };
    } catch (error) {
      commit('LOADING_MODEL_EXPORT', false);
      return { succes: false };
    }
  },
  async exportFactureDynamicZip({ commit }, payload) {
    commit('LOADING_MODEL_EXPORT', true);
    try {
      const response = await axios.post(
        domain + '/api/downloadzipSupport',
        payload,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(
        new Blob([response.data], { type: 'application/pdf' })
      );
      link.setAttribute('download', 'GestionGlobalFrais.zip');
      document.body.appendChild(link);
      link.click();
      commit('LOADING_MODEL_EXPORT', false);
      return true;
    } catch (error) {
      commit('LOADING_MODEL_EXPORT', false);
      return false;
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
