export default class vueDynamiqueAVColumns {
  constructor(data) {
    this.key = data.key;
    this.disabled = data.disabled;
    this.label = data.label;
    if (data.tdClass) this.tdClass = data.tdClass;
    if (data.thClass) this.thClass = data.thClass;
    if (data.notEnabled) this.notEnabled = data.notEnabled;
  }
  update(data) {
    this.key = data.key;
    this.label = data.label;
    if (data.tdClass) this.tdClass = data.tdClass;
    if (data.thClass) this.thClass = data.thClass;
    if (data.notEnabled) this.notEnabled = data.notEnabled;
    this.disabled = data.disabled;
  }

  static create(data) {
    return new vueDynamiqueAVColumns(data);
  }
}
