import axios from 'axios';
import domain from '@/environment';
import TypeReglement from '../../models/TypeReglement';

const state = {
  typesReglement: [],
  loadingReglement: false,
  errorReglement: [],
  errorNameReglement: [],
  rowReglement: null
};
const getters = {
  TypeReglement: state => state.typesReglement,
  LoadingReglement: state => state.loadingReglement,
  errorReglement: state => state.errorReglement,
  errorNameReglement: state => state.errorNameReglement,
  rowReglement: state => state.rowReglement
};
const mutations = {
  SET_ALL_TYPES_REGLEMENT(state, payload) {
    state.typesReglement = payload.map(item => TypeReglement.create(item));
  },
  SET_TYPE_REGLEMENT_LOADING(state, payload) {
    state.loadingReglement = payload;
  },
  SET_TYPE_REGLEMENT_ERROR(state, payload) {
    state.errorReglement = payload;
  },
  ADD_NEW_TYPE_REGLEMENT(state, payload) {
    state.typesReglement.unshift(TypeReglement.create(payload));
  },
  DELETE_TYPE_REGLEMENT(state, payload) {
    const index = state.typesReglement.findIndex(
      element => element.id === payload.id
    );
    state.typesReglement.splice(index, 1);
  },
  UPDATE_TYPE_REGLEMENT(state, payload) {
    payload.type.update(payload.response);
  },
  SET_ERROR_NOM_REGLEMENT(state, payload) {
    state.errorNameReglement = payload;
  },
  SET_TOTAL_ROWS_TYPE_REGLEMENT(state, payload) {
    state.rowReglement = payload;
  }
};
const actions = {
  async getAllTypesReglement({ commit }, payload) {
    commit('SET_TYPE_REGLEMENT_ERROR', null);
    commit('SET_TYPE_REGLEMENT_LOADING', true);
    const params = {};
    if (payload?.per_page) {
      params.per_page = payload.per_page;
    }
    if (payload?.page) {
      params.page = payload.page;
    }
    try {
      const response = await axios.get(
        `${domain}/api/reglements/payment-types`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      if (payload?.per_page != null) {
        commit('SET_ALL_TYPES_REGLEMENT', response.data.data.data);
        commit('SET_TOTAL_ROWS_TYPE_REGLEMENT', response.data.data.total);
      } else {
        commit('SET_ALL_TYPES_REGLEMENT', response.data.data);
      }
      commit('SET_TYPE_REGLEMENT_LOADING', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_TYPE_REGLEMENT_ERROR', error?.response?.data?.errors);
      } else {
        commit('SET_TYPE_REGLEMENT_ERROR', 'Une erreur est survenue');
      }
      commit('SET_TYPE_REGLEMENT_LOADING', false);
    }
  },
  async addNewTypeReglement({ commit }, payload) {
    commit('SET_TYPE_REGLEMENT_ERROR', null);
    commit('SET_ERROR_NOM_REGLEMENT', null);
    try {
      const response = await axios.post(
        `${domain}/api/reglements/create-payment-type`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('ADD_NEW_TYPE_REGLEMENT', response.data.data);
      return true;
    } catch (error) {
      if (error?.response?.data) {
        commit('SET_ERROR_NOM_REGLEMENT', error.response.data.errors?.name);
        commit('SET_TYPE_REGLEMENT_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_TYPE_REGLEMENT_ERROR', 'Une erreur est survenue');
      }
    }
  },

  async deleteTypeReglement({ commit }, payload) {
    commit('SET_TYPE_REGLEMENT_ERROR', null);
    try {
      const response = await axios.post(
        `${domain}/api/reglements/delete-payment-type/${payload.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DELETE_TYPE_REGLEMENT', payload);

      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_TYPE_REGLEMENT_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_TYPE_REGLEMENT_ERROR', 'Une erreur est survenue');
      }
    }
  },

  async updateTypeReglement({ commit }, payload) {
    commit('SET_TYPE_REGLEMENT_ERROR', null);
    try {
      const response = await axios.post(
        `${domain}/api/reglements/update-payment-type/${payload.typeReglementToUpdate.id}`,
        payload.typeReglementToUpdate,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_TYPE_REGLEMENT', {
        response: response.data.data,
        type: payload.oldTypeReglement
      });
      return true;
    } catch (error) {
      if (error?.response?.data) {
        commit('SET_ERROR_NOM_REGLEMENT', error.response.data.errors?.name);
        commit('SET_TYPE_REGLEMENT_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_TYPE_REGLEMENT_ERROR', 'Une erreur est survenue');
      }
    }
  },
  resetMutations({ commit }) {
    commit('SET_TYPE_REGLEMENT_ERROR', null);
    commit('SET_ERROR_NOM_REGLEMENT', null);
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
