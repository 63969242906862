import domain from '@/environment';
import axios from 'axios';
import Sci from '../../models/Sci';
let requestSci = null;
const state = {
  factureScis: [],
  errorSci: [],
  loadingsci: false,
  rowsSCI: 0,
  templateFactureSCI: null
};
const getters = {
  factureScis: state => state.factureScis,
  errorSci: state => state.errorSci,
  loadingSci: state => state.loadingsci,
  TotalRowsScis: state => state.rowsSCI,
  templateFactureSCI: state => state.templateFactureSCI
};
const mutations = {
  SET_ERROR_FACTURE_SCI(state, payload) {
    state.errorSci = payload;
  },
  UPDATE_FACTURE_AVOIR_SCI(state, payload) {
    payload.row.updateAvoir(payload.response);
  },
  SET_ALL_FACTURES_SCI(state, payload) {
    state.factureScis = payload.map(item => Sci.create(item));
  },
  SET_LOADING_FACTURES_SCI(state, payload) {
    state.loadingsci = payload;
  },
  SET_TOTAL_ROWS_SCI(state, payload) {
    state.rowsSCI = payload;
  },
  UPDATE_STATUT_CLIENT_SCI(state, payload) {
    payload.item.update(payload.response);
  },
  UPDATE_STATUT_FACTURE_SCI(state, payload) {
    payload.item.update(payload.response);
  },
  UPDATE_FACTURE_SCI(state, payload) {
    payload.item.update(payload.response);
  },
  SET_TEMPLATE_FACTURE_SCI(state, payload) {
    state.templateFactureSCI = payload;
  },
  UPDATE_MONTANT_HT_FACTURE_SCI(state, payload) {
    payload.item.update(payload.response);
  },
  DELETE_TRANSFERT_FACTURE_SCI_IDS(state, payload) {
    for (let i = 0; i < payload.length; i++) {
      let parentindex = state.factureScis.findIndex(
        item => item.name == payload[i].indexOfFather
      );
      let childIndex = state.factureScis[parentindex].factures.findIndex(
        item => item.id == payload[i].indexOfChild
      );
      state.factureScis[parentindex].factures.splice(childIndex, 1);
      if (state.factureScis[parentindex].factures.length === 0) {
        state.factureScis.splice(parentindex, 1);
      }
    }
  },
  UPDATE_VAIDATE_MULTIPLE_FACTURE_SCI(commit, payload) {
    payload.data.map(item => {
      let index = payload.response.findIndex(i => i.id == item.id);
      if (index != -1) {
        {
          item.update(payload.response[index]);
        }
      }
    });
  },
  UPDATE_AVOIR_RESULT_FACTURE_SCI_ID(state, payload) {
    payload.data.map(item => {
      let index = payload.response.findIndex(i => i.id == item.id);
      if (index != -1) {
        {
          item.update(payload.response[index]);
        }
      }
    });
  }
};
const actions = {
  async getFacturesScis({ commit }, payload) {
    commit('SET_LOADING_FACTURES_SCI', true);
    const params = {
      per_page: payload.per_page,
      page: payload.page,
      famille: payload.famille,
      column: payload.column,
      order: payload.order,
      grouped: true
    };
    if (payload && payload.nums) {
      params.nums = payload.nums;
    }
    for (let i = 0; i < payload?.etat?.length; i++) {
      params['etat[' + i + ']'] = payload.etat[i].value;
    }
    for (let i = 0; i < payload?.vendeur_id?.length; i++) {
      params['vendeur_id[' + i + ']'] = payload?.vendeur_id[i]?.value;
    }
    for (let i = 0; i < payload?.acheteur_id?.length; i++) {
      params['acheteur_id[' + i + ']'] = payload?.acheteur_id[i]?.value;
    }
    for (let i = 0; i < payload?.statut_client?.length; i++) {
      params['statut_client[' + i + ']'] = payload?.statut_client[i]?.value;
    }
    if (payload.search != null) {
      params.num = payload.search;
    }
    if (payload.date_debut != null) {
      params.date_debut = payload.date_debut;
    }
    if (payload.date_fin != null) {
      params.date_fin = payload.date_fin;
    }
    try {
      if (requestSci) {
        commit('SET_ERROR_FACTURE_SCI', null);
        requestSci.cancel('cancel-request');
      }
      requestSci = axios.CancelToken.source();
      const response = await axios.get(domain + `/api/libre/factures`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params,
        cancelToken: requestSci.token
      });
      commit('SET_ALL_FACTURES_SCI', response.data.data);
      commit('SET_TOTAL_ROWS_SCI', response.data.count);
      commit('SET_LOADING_FACTURES_SCI', false);
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_FACTURE_SCI', error.response.data.error);
        commit('SET_LOADING_FACTURES_SCI', false);
      } else {
        if (!error.message == 'cancel-request') {
          commit('SET_LOADING_FACTURES_SCI', false);
          commit('SET_ERROR_FACTURE_SCI', ['Une erreur est survenue']);
        }
      }
    }
  },
  async updateStatutClientFactureSci({ commit }, payload) {
    commit('SET_LOADING_FACTURES_SCI', true);
    const params = {
      id: payload.row.id,
      statut_client: payload.value
    };
    try {
      const response = await axios.post(
        domain + `/api/libre/statut_client`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_STATUT_CLIENT_SCI', {
        response: response.data.data,
        item: payload.row
      });
      commit('SET_LOADING_FACTURES_SCI', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_FACTURE_SCI', error.response.data.error);
      } else {
        commit('SET_ERROR_FACTURE_SCI', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_FACTURES_SCI', false);

      return false;
    }
  },
  async updateStatutFactureSci({ commit }, payload) {
    commit('SET_LOADING_FACTURES_SCI', true);
    const params = {
      id: payload.row.id,
      etat: payload.value
    };
    try {
      const response = await axios.put(domain + `/api/libre/etat`, params, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('UPDATE_STATUT_FACTURE_SCI', {
        response: response.data.data,
        item: payload.row
      });

      commit('SET_LOADING_FACTURES_SCI', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_FACTURE_SCI', error.response.data.error);
      } else {
        commit('SET_ERROR_FACTURE_SCI', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_FACTURES_SCI', false);

      return false;
    }
  },
  async createAvoirSci({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/frais/create_avoir_partiel`,
        payload.bodyFormData,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_FACTURE_AVOIR_SCI', {
        response: response.data.data,
        row: payload.item
      });
      return {
        success: true
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          success: false,
          error: error.response.data.error
        };
      } else {
        return {
          success: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async getTemplateFactureSci({ commit }, payload) {
    commit('SET_ERROR_FACTURE_SCI', null);
    commit('SET_TEMPLATE_FACTURE_SCI', null);

    commit('SET_LOADING_FACTURES_SCI', true);
    try {
      const response = await axios.get(
        domain +
          `/api/filiale_template_generator/get_uploaded_template_for_libre`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );
      commit('SET_TEMPLATE_FACTURE_SCI', response.data.data);

      commit('SET_LOADING_FACTURES_SCI', false);

      return { success: true, response: response.data.data };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_FACTURE_SCI', error.response.data.error);
      } else {
        commit('SET_ERROR_FACTURE_SCI', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_FACTURES_SCI', false);

      return false;
    }
  },
  async validateFactureSciTemplate({ commit }, payload) {
    const params = {
      description: payload.description,
      iban: payload.iban,
      rib: payload.rib,
      swift: payload.swift,
      bank: payload.bank,
      date: payload.date,
      date_reglement: payload.date_reglement,
      ids: [payload.id_facture],
      id_template: payload.id_template
    };
    if (payload.payment_condition && payload.payment_condition.id != null) {
      params.payment_condition = payload.payment_condition.id;
    }
    try {
      const response = await axios.post(
        domain + `/api/libre/confirme-master`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_FACTURE_SCI', {
        response: response.data.data[0],
        item: payload.item
      });
      return {
        success: true
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        return {
          succes: false,
          error: error.response.data.errors
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async exportPdfFactureSci({ commit }, payload) {
    commit('SET_ERROR_FACTURE_SCI', null);
    commit('SET_LOADING_FACTURES_SCI', true);
    try {
      const response = await axios.get(
        domain + `/api/filiale_template_generator/download_libre`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );

      var words = response.headers['cache-control'].split('.pdf');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', words[0] + '.pdf');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_FACTURES_SCI', false);
      return true;
    } catch (error) {
      commit('SET_ERROR_FACTURE_SCI', ['Une erreur est servenue']);
      commit('SET_LOADING_FACTURES_SCI', false);
      return false;
    }
  },
  async avoirGlobaleFactureSci({ commit }, payload) {
    try {
      const response = await axios.post(
        `${domain}/api/libre/avoir_globale_for_all `,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { success: true, response: response.data.data };
    } catch (error) {
      if (error?.response?.data?.error) {
        return { success: false, response: error?.response?.data?.error };
      } else {
        return { success: false, response: error?.response?.data?.error };
      }
    }
  },
  async updateMontantHtFactureSci({ commit }, payload) {
    commit('SET_LOADING_FACTURES_SCI', true);
    const params = {
      id: payload.row.id,
      montant_ht: payload.value
    };
    try {
      const response = await axios.post(
        domain + `/api/mensuel/update_montant_ht`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_MONTANT_HT_FACTURE_SCI', {
        response: response.data.data,
        item: payload.row
      });

      commit('SET_LOADING_FACTURES_SCI', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_FACTURE_SCI', error.response.data.error);
      } else {
        commit('SET_ERROR_FACTURE_SCI', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_FACTURES_SCI', false);

      return false;
    }
  },
  async transfertFactureSciToLibre({ commit }, payload) {
    commit('SET_LOADING_FACTURES_SCI', true);
    try {
      const response = await axios.post(
        domain + `/api/libre/transfer-to-libre`,
        payload.ids,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      commit('DELETE_TRANSFERT_FACTURE_SCI_IDS', payload.indexsToRemove);

      commit('SET_LOADING_FACTURES_SCI', false);
      return { succes: true };
    } catch (error) {
      commit('SET_LOADING_FACTURES_SCI', false);
      return false;
    }
  },
  async validateFactureSci({ commit }, payload) {
    payload.map(item => item.id_facture);
    var ids = new FormData();
    for (let i = 0; i < payload.length; i++) {
      ids.append('ids[' + [i] + ']', payload[i].id);
    }
    try {
      const response = await axios.post(
        domain + `/api/libre/confirme-multi-master`,
        ids,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_VAIDATE_MULTIPLE_FACTURE_SCI', {
        response: response.data.data,
        data: payload
      });
      return {
        succes: true
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
