import projectSubMasterFilialeDossier from './projectSubMasterFilialeDossier';
export default class projectSubMasterFiliale {
  constructor(data) {
    this.id = data.id;
    this.montant_total = data.montant_total;
    this.montant_ht = data.montant_ht;
    this.montant_tva = data.montant_tva;
    this.nom = data.nom;
    this.check = false;
    this.detailsShowing = false;
    this.loadingDetailt = false;
    // this.projects = [...]
    this.projects = data.projects.map(item =>
      projectSubMasterFilialeDossier.create(item)
    );
  }

  update(data) {
    this.projects = data.map(item =>
      projectSubMasterFilialeDossier.create(item)
    );
  }
  static create(data) {
    return new projectSubMasterFiliale(data);
  }
}
