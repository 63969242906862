export default class FactureReglement {
  constructor(data) {
    this.acheteur = data.acheteur;
    this.client = data.acheteur;
    this.client_id = data.acheteur_id;
    this.bank = data.bank;
    this.date_creation = data.date_creation;
    this.date_echeance = data.date_echeance;
    this.date_paiement = data.paiement;
    this.devise = data.devise;
    this.etat = data.etat;
    this.iban = data.iban;
    this.id = data.id;
    this.montant_a_regle = 0;
    this.montant_a_regle_initial = 0;
    this.montant_regle = data.montant_regle;
    this.montant_regle_initial = data.montant_regle;
    this.num = data.num;
    this.num_proforma = data.num_proforma;
    this.objet = data.objet;
    this.payment_condition = data.payment_condition;
    this.payment_condition_fin_du_mois = data.payment_condition_fin_du_mois;
    this.payment_condition_id = data.payment_condition_id;
    this.reste = data.reste;
    this.resteInitial = data.reste;
    this.swift = data.swift;
    this.total_ttc = data.total_ttc;
    this.ttc_avoirs = data.ttc_avoirs;
    this.type = data.type;
    this.vendeur = data.vendeur;
    this.vendeur_id = data.vendeur_id;
    this.check = false;
  }
  update(data) {
    this.acheteur = data.acheteur;
    this.client = data.acheteur;
    this.client_id = data.acheteur_id;
    this.bank = data.bank;
    this.date_creation = data.date_creation;
    this.date_echeance = data.date_echeance;
    this.date_paiement = data.paiement;
    this.devise = data.devise;
    this.etat = data.etat;
    this.iban = data.iban;
    this.id = data.id;
    this.montant_regle = data.montant_regle;
    this.num = data.num;
    this.num_proforma = data.num_proforma;
    this.objet = data.objet;
    this.payment_condition = data.payment_condition;
    this.payment_condition_fin_du_mois = data.payment_condition_fin_du_mois;
    this.payment_condition_id = data.this.payment_condition_id;
    this.reste = data.reste;
    this.resteInitial = data.reste;
    this.swift = data.swift;
    this.total_ttc = data.total_ttc;
    this.ttc_avoirs = data.ttc_avoirs;
    this.type = data.type;
    this.vendeur = data.vendeur;
    this.vendeur_id = data.vendeur_id;
    this.check = false;
  }
  static create(data) {
    return new FactureReglement(data);
  }
}
