import RowProjectFraisDynamic from './RowProjectFraisDynamic';
export default class RowFilialeFraisDynamic {
  constructor(data) {
    this.montant_final = data.montant_final;
    this.projects = data.projects.map(item =>
      RowProjectFraisDynamic.create(item)
    );
    this.ttc = data.ttc;
    this.ht = data.ht;
    this.name = data.name;
    this.total_tva = data.total_tva;
    this.montant_net_ht = data.montant_net_ht;
    this.detailsShowing = false;
    this.check = false;
  }
  update(data) {
    this.projects = data.projects.map(item =>
      RowProjectFraisDynamic.create(item)
    );
  }
  static create(data) {
    return new RowFilialeFraisDynamic(data);
  }
}
