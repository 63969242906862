import axios from 'axios';
import domain from '@/environment';
import AppelPaiementCommercial from '../../models/AppelPaiementCommercial';
let resquestAppelPaiementCommercial = null;
const state = {
  loadingCommercialAlppelPaiement: false,
  appelPaiementCommercial: [],
  errorAppelPaiementCommercial: [],
  rowsAppelPaiementCommercial: 0,
  filterInstalleurCommercial: [],
  filterLotsCommercial: [],
  loadingFiltreCommerciaux: false,
  listRegieForCommercialpage: [],
  loaderListRegieForCommercialpage: false,
  loaderLockAppelPaymentCommerciaux: false
};
const getters = {
  getAppelPaiementCommerciaux: state => state.appelPaiementCommercial,
  getLoadingAppelPaiementCommerciaux: state =>
    state.loadingCommercialAlppelPaiement,
  getErrorAppelPaiement: state => state.errorAppelPaiementCommercial,
  getTotalRowsAppelPaiement: state => state.rowsAppelPaiementCommercial,
  filterInstalleurCommercial: state => state.filterInstalleurCommercial,
  filterLotsCommercial: state => state.filterLotsCommercial,
  loadingFiltreCommerciaux: state => state.loadingFiltreCommerciaux,
  getListRegieForCommercialpage: state => state.listRegieForCommercialpage,
  getLoaderListRegieForCommercialpage: state =>
    state.loaderListRegieForCommercialpage
};
const mutations = {
  SET_ERROR_APPEL_PAIEMENT_COMMERCIAL(state, payload) {
    state.errorAppelPaiementCommercial = payload;
  },
  SET_LOADING_APPEL_PAIEMENT_COMMERCIAL(state, payload) {
    state.loadingCommercialAlppelPaiement = payload;
  },
  SET_FACTURES_COMMERCIAUX(state, payload) {
    state.appelPaiementCommercial = payload.map(item =>
      AppelPaiementCommercial.create(item)
    );
  },
  SET_TOTAL_ROWS_COMMERCIAUX(state, payload) {
    state.rowsAppelPaiementCommercial = payload;
  },
  UPDATE_COLUMNS_COMMERCIAL(state, payload) {
    payload.row.update(payload.response);
  },
  SET_INSTALLATEUR_FILTER(state, payload) {
    state.filterInstalleurCommercial = payload;
  },
  SET_LOTS_FILTER(state, payload) {
    state.filterLotsCommercial = payload;
  },
  SET_LOADING_FILTRE_COMMERCIAUX(state, payload) {
    state.loadingFiltreCommerciaux = payload;
  },
  SET_ALL_DATA_REGIE_FOR_COMMERCIAL(state, payload) {
    state.listRegieForCommercialpage = payload;
  },
  SET_LOADER_ALL_REGIE_FOR_COMMERCIAL(state, payload) {
    state.loaderListRegieForCommercialpage = payload;
  },
  SET_LOCK_APPEL_PAYMENT_COMMERCIAL(state, payload) {
    payload.data.forEach(element => {
      let index = payload.response.map(i => i.id).indexOf(element.id);
      if (index >= 0) {
        element.resetPaymentAppel(payload.response[index]);
      }
    });
  },
  SET_FACTURES_COMMERCIAUX_DETAILS(state, payload) {
    payload.data.setDetails(payload.response);
  }
};
const actions = {
  async getFactureCommercial({ commit }, payload) {
    commit('SET_ERROR_APPEL_PAIEMENT_COMMERCIAL', null);
    commit('SET_LOADING_APPEL_PAIEMENT_COMMERCIAL', true);
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      payment: payload.payment
    };
    if (payload.date_debut != null) {
      params.date_debut = payload.date_debut;
    }
    if (payload.date_fin != null) {
      params.date_fin = payload.date_fin;
    }
    if (payload.search != null) {
      params.search = payload.search;
    }
    if (payload.order != null) {
      params.order = payload.order;
    }
    if (payload.orderBy != null) {
      params.orderBy = payload.orderBy;
    }
    if (payload.payment_call != null) {
      params.payment_call = payload.payment_call;
    }
    // if (payload.payment) {
    //   params.payment = payload.payment;
    // }
    if (payload?.lots?.length) {
      params.lots = payload.lots.map(item => item.value);
    }
    if (payload?.installeurs?.length) {
      params.installeurs = payload.installeurs.map(item => item.value);
    }
    if (payload?.regie?.length) {
      params.regie = payload.regie;
    }
    if (payload?.agent_commercial?.length) {
      params.agent_commercial = payload.agent_commercial.map(item => item.id);
    }
    if (payload.commercial_reste_a_charge !== 'tous') {
      params.commercial_reste_a_charge = payload.commercial_reste_a_charge;
    }
    if (payload.statut_appel_commercial.length) {
      params.statut_appel_commercial = payload.statut_appel_commercial.map(
        i => i.id
      );
    }
    try {
      if (resquestAppelPaiementCommercial) {
        commit('SET_ERROR_APPEL_PAIEMENT_COMMERCIAL', null);
      }
      resquestAppelPaiementCommercial = axios.CancelToken.source();
      const response = await axios.get(domain + '/api/projects', {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params,
        cancelToken: resquestAppelPaiementCommercial.token
      });
      commit('SET_FACTURES_COMMERCIAUX', response.data.data.data);
      commit('SET_TOTAL_ROWS_COMMERCIAUX', response.data.data.total);
      commit('SET_LOADING_APPEL_PAIEMENT_COMMERCIAL', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        commit('SET_ERROR_APPEL_PAIEMENT_COMMERCIAL', data);
        commit('SET_LOADING_APPEL_PAIEMENT_COMMERCIAL', false);
      } else {
        if (!error.message == 'cancel-request') {
          commit('SET_ERROR_APPEL_PAIEMENT_COMMERCIAL', [
            'Une erreur est servenue'
          ]);
          commit('SET_LOADING_APPEL_PAIEMENT_COMMERCIAL', false);
        }
      }
      return false;
    }
  },
  async getFactureAndSimulationForCommercialAppelPayment({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + '/api/commercial/simulation_facture_commercial',
        { numero_dossier: payload.numero_dossier },
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_FACTURES_COMMERCIAUX_DETAILS', {
        data: payload,
        response: response.data
      });
      return true;
    } catch (error) {
      return false;
    }
  },
  async getFilterCommerciaux({ commit }, payload) {
    commit('SET_ERROR_APPEL_PAIEMENT_COMMERCIAL', null);
    commit('SET_LOADING_FILTRE_COMMERCIAUX', true);
    const params = {};
    if (payload?.date_debut != null) {
      params.date_debut = payload.date_debut;
    }
    if (payload?.date_fin != null) {
      params.date_fin = payload.date_fin;
    }
    try {
      const response = await axios.get(
        domain + '/api/get_depots_organismes_statuts',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_INSTALLATEUR_FILTER', response.data.data.installeurs);
      commit('SET_LOTS_FILTER', response.data.data.lots);
      commit('SET_LOADING_FILTRE_COMMERCIAUX', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        commit('SET_ERROR_APPEL_PAIEMENT_COMMERCIAL', data);
      } else {
        commit('SET_ERROR_APPEL_PAIEMENT_COMMERCIAL', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_FILTRE_COMMERCIAUX', false);
      return false;
    }
  },
  async updateColumnsCommercial({ commit }, payload) {
    commit('SET_ERROR_APPEL_PAIEMENT_COMMERCIAL', null);
    commit('SET_LOADING_APPEL_PAIEMENT_COMMERCIAL', true);
    const params = {
      id: payload?.row?.id,
      column: payload.column,
      value: null
    };
    if (payload.value != null) {
      if (payload.column == 'commercial_reste_a_charge') {
        params.value = parseInt(payload.value);
      } else {
        params.value = payload.value.id;
      }
    }
    try {
      const response = await axios.post(
        domain + `/api/commercial/update_commercial_columns`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_COLUMNS_COMMERCIAL', {
        response: response.data.data,
        row: payload.row
      });
      commit('SET_LOADING_APPEL_PAIEMENT_COMMERCIAL', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        commit('SET_ERROR_APPEL_PAIEMENT_COMMERCIAL', data);
      } else {
        commit('SET_ERROR_APPEL_PAIEMENT_COMMERCIAL', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_APPEL_PAIEMENT_COMMERCIAL', false);

      return false;
    }
  },
  async updateColumnsCommercialMultiple({ commit }, payload) {
    const params = {};
    if (payload?.commercial_reste_a_charge != null) {
      params.commercial_reste_a_charge = parseInt(
        payload.commercial_reste_a_charge
      );
    }
    if (payload?.sous_regie != null) {
      params.sous_regie = payload.sous_regie;
    }
    if (payload?.agent_commercial_terrain != null) {
      params.agent_commercial_terrain = payload.agent_commercial_terrain;
    }
    if (payload?.statut_appel_commercial != null) {
      params.statut_appel_commercial = payload.statut_appel_commercial;
    }
    if (payload?.ids?.length) {
      params.ids = payload.ids.map(item => item.id);
    }
    try {
      const response = await axios.post(
        domain + `/api/commercial/update_commercial_columns`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { success: true };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        return { success: false, error: data };
      } else {
        return { success: false, error: 'Une erreur est survenue' };
      }
    }
  },
  async paymentCallCommercial({ commit }, payload) {
    commit('SET_ERROR_APPEL_PAIEMENT_COMMERCIAL', null);
    commit('SET_LOADING_APPEL_PAIEMENT_COMMERCIAL', true);
    try {
      const response = await axios.post(
        domain + `/api/commercial/payment_call`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LOADING_APPEL_PAIEMENT_COMMERCIAL', false);
      return { success: true, response: response.data.data };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        commit('SET_ERROR_APPEL_PAIEMENT_COMMERCIAL', data);
      } else {
        commit('SET_ERROR_APPEL_PAIEMENT_COMMERCIAL', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_APPEL_PAIEMENT_COMMERCIAL', false);

      return { success: false };
    }
  },
  async fetchListRegieForCommercialpage({ commit }) {
    commit('SET_LOADER_ALL_REGIE_FOR_COMMERCIAL', true);

    try {
      const response = await axios.get(domain + '/api/commercial/regies', {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_ALL_DATA_REGIE_FOR_COMMERCIAL', response.data);
      commit('SET_LOADER_ALL_REGIE_FOR_COMMERCIAL', false);
      return true;
    } catch (error) {
      commit('SET_LOADER_ALL_REGIE_FOR_COMMERCIAL', false);
      return false;
    }
  },
  async changeLockAppelPaiementCommerciauxTh({ commit }, payload) {
    let params = new FormData();
    payload.forEach((element, index) => {
      params.append('ids[' + index + ']', element.id);
    });
    // = { ids: payload.map(i => i.id) };

    try {
      const response = await axios.post(
        domain + '/api/commercial/verrouillage',
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LOCK_APPEL_PAYMENT_COMMERCIAL', {
        data: payload,
        response: response.data.data
      });
      return true;
    } catch (error) {
      return false;
    }
  },
  async regenerationAppelPaymentCommercaux({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/commercial/generation_facture_commercial`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { succes: true, message: response.data };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: 'Une erreur est servenue'
        };
      }
    }
  },
  async getDataPDFSimulationCommerciaux({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/commercial/see_facture_commercial`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { succes: true, src: response.data.data };
    } catch (error) {
      return { succes: false, error: error.response.data.error };
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
