import axios from 'axios';
import domain from '@/environment';
import ConditionPaiement from '../../models/ConditionPaiement';

const state = {
  typecondition: [],
  loadingCondition: false,
  errorConditionPaiement: [],
  rowCondition: null,
  errorValue: [],
  errorNameCondition: []
};
const getters = {
  ConditionPaiement: state => state.typecondition,
  LoadingCondition: state => state.loadingCondition,
  errorConditionPaiement: state => state.errorConditionPaiement,
  rowCondition: state => state.rowCondition,
  errorValue: state => state.errorValue,
  errorNameCondition: state => state.errorNameCondition
};
const mutations = {
  SET_ALL_CONDITIONS_PAYMENT(state, payload) {
    state.typecondition = payload.map(item => ConditionPaiement.create(item));
  },
  SET_CONDITION_PAIEMENTS_LOADING(state, payload) {
    state.loadingCondition = payload;
  },
  SET_CONDITIONS_PAYMENTS_ERROR(state, payload) {
    state.errorConditionPaiement = payload;
  },
  ADD_NEW_CONDITION_PAIEMENT(state, payload) {
    state.typecondition.unshift(ConditionPaiement.create(payload));
  },
  DELETE_CONDITION_PAIEMENT(state, payload) {
    const index = state.typecondition.findIndex(
      element => element.id === payload.id
    );
    state.typecondition.splice(index, 1);
  },
  UPDATE_CONDITION_PAIEMENT(state, payload) {
    payload.old.update(payload.response);
  },
  SET_TOTAL_ROWS_CONDITION(state, payload) {
    state.rowCondition = payload;
  },
  SET_ERROR_NOM_PAYMENT(state, payload) {
    state.errorNameCondition = payload;
  },
  SET_ERROR_VALUE_PAYMENT(state, payload) {
    state.errorValue = payload;
  }
};
const actions = {
  async getAllConditionsPaiement({ commit }, payload) {
    commit('SET_CONDITIONS_PAYMENTS_ERROR', null);
    commit('SET_CONDITION_PAIEMENTS_LOADING', true);
    const params = {};
    if (payload?.page != null) {
      params.page = payload.page;
    }
    if (payload?.per_page != null) {
      params.per_page = payload.per_page;
    }
    try {
      const response = await axios.get(
        `${domain}/api/reglements/payment-conditions`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      if (payload?.per_page != null) {
        commit('SET_ALL_CONDITIONS_PAYMENT', response.data.data.data);
        commit('SET_TOTAL_ROWS_CONDITION', response.data.data.total);
      } else {
        commit('SET_ALL_CONDITIONS_PAYMENT', response.data.data);
      }

      commit('SET_CONDITION_PAIEMENTS_LOADING', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_CONDITIONS_PAYMENTS_ERROR', error?.response?.data?.errors);
      } else {
        commit('SET_CONDITIONS_PAYMENTS_ERROR', 'Une erreur est survenue');
      }
      commit('SET_CONDITION_PAIEMENTS_LOADING', false);
    }
  },
  async addNewConditionPaiement({ commit }, payload) {
    commit('SET_CONDITIONS_PAYMENTS_ERROR', null);
    commit('SET_ERROR_NOM_PAYMENT', null);
    commit('SET_ERROR_VALUE_PAYMENT', null);
    try {
      const response = await axios.post(
        `${domain}/api/reglements/create-payment-condition`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('ADD_NEW_CONDITION_PAIEMENT', response.data.data);
      return true;
    } catch (error) {
      if (error?.response?.data) {
        commit('SET_ERROR_NOM_PAYMENT', error.response.data.errors?.name);
        commit('SET_ERROR_VALUE_PAYMENT', error.response.data.errors?.value);
        commit('SET_CONDITIONS_PAYMENTS_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_CONDITIONS_PAYMENTS_ERROR', 'Une erreur est survenue');
      }
    }
  },

  async deleteConditionPaiement({ commit }, payload) {
    commit('SET_CONDITIONS_PAYMENTS_ERROR', null);
    try {
      const response = await axios.post(
        `${domain}/api/reglements/delete-payment-condition/${payload.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DELETE_CONDITION_PAIEMENT', payload);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_CONDITIONS_PAYMENTS_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_CONDITIONS_PAYMENTS_ERROR', 'Une erreur est survenue');
      }
    }
  },

  async updateConditionPaiement({ commit }, payload) {
    commit('SET_CONDITIONS_PAYMENTS_ERROR', null);
    commit('SET_ERROR_NOM_PAYMENT', null);
    commit('SET_ERROR_VALUE_PAYMENT', null);
    try {
      const response = await axios.post(
        `${domain}/api/reglements/update-payment-condition/${payload.conditionToUpdate.id}`,
        payload.conditionToUpdate,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_CONDITION_PAIEMENT', {
        response: response.data.data,
        old: payload.oldConditionToUpdate
      });
      return true;
    } catch (error) {
      if (error?.response?.data) {
        commit('SET_ERROR_NOM_PAYMENT', error.response.data.errors?.name);
        commit('SET_ERROR_VALUE_PAYMENT', error.response.data.errors?.value);
        commit('SET_CONDITIONS_PAYMENTS_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_CONDITIONS_PAYMENTS_ERROR', 'Une erreur est survenue');
      }
    }
  },
  resetMutationsConditions({ commit }) {
    commit('SET_CONDITIONS_PAYMENTS_ERROR', null);
    commit('SET_ERROR_VALUE_PAYMENT', null);
    commit('SET_ERROR_NOM_PAYMENT', null);
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
