export default class FilialeTh {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.depots = data.depots;
    this.volant = data.volant;
    this.responsable_depot = null;
    this.logo = data.logo;
    this.imageDataUrl = null;
    this.imageData = '';
    this.siret = data.siret;
    this.responsables = data.responsables;
    this.adresse = data.adresse;
    this.email = data.email;
    //new property
    this.abreviation = data.abreviation;
    this.couleur = data.couleur;
    this.prenom = data.prenom;
    this.nom_famille = data.nom_famille;
    this.civilite = data.civilite;
    this.siren_value = data.siren_value;
    this.tva_value = data.tva_value;
    this.code_postal = data.code_postal;
    this.site_internet = data.site_internet;
    this.ville = data.ville;
    this.pays = data.pays;
    this.iban = data.iban;
    this.banque = data.banque;
    this.swift = data.swift;
    this.fax = data.fax;
    this.telephone = data.telephone;
    this.description = data.description;
    this.telephone_portable = data.telephone_portable;
    this.type = data.type;
    this.type_societe = data.type_societe;
    this.files = data.files;
    this.domaine = data.domaine;
    this.type_societe_support = data.type_societe_support;
    this.is_societe_support = data.is_societe_support;
    this.ribs = data.ribs;
    this.sequences_factures = data.sequences_factures;
    this.biens = data.biens;
    this.masterFiliales = data.masterFiliales;
    this.societes_of_master_filiale = data.societes_of_master_filiale;
    this.deleted_at = data.deleted_at;
    this.active_master = data.active_master;
    this.capital = data.capital;
    this.rcs = data.rcs;
    this.footer_type = data.footer_type;
    this.footer = data.footer;
    this.start_day = data.start_day;
    this.start_month = data.start_month;
    this.start_year = data.start_year;
    this.end_day = data.end_day;
    this.end_month = data.end_month;
    this.end_year = data.end_year;
    this.payment_condition = data.payment_condition?.id
      ? data.payment_condition?.id
      : null;
    this.payment_condition_name = data.payment_condition?.name;
    this.payment_type = data.payment_type?.id ? data.payment_type?.id : null;
    this.payment_type_name = data.payment_type?.name;
    this.pixel_id = data.pixel_id;
    this.has_sequence = data.has_sequence;
    this.name_part = data.prenom + ' ' + data.nom_famille;
  }

  /**
   * @param {*} data
   */

  update(data) {
    this.name = data.name;
    this.depots = data.depots;
    this.volant = data.volant;
    this.responsable_depot = null;
    this.logo = data.logo;
    this.imageData = '';
    this.siret = data.siret;
    this.responsables = data.responsables;
    this.adresse = data.adresse;
    this.email = data.email;
    //new property
    this.abreviation = data.abreviation;
    this.couleur = data.couleur;
    this.prenom = data.prenom;
    this.nom_famille = data.nom_famille;
    this.civilite = data.civilite;
    this.siren_value = data.siren_value;
    this.tva_value = data.tva_value;
    this.code_postal = data.code_postal;
    this.site_internet = data.site_internet;
    this.ville = data.ville;
    this.pays = data.pays;
    this.iban = data.iban;
    this.banque = data.banque;
    this.swift = data.swift;
    this.fax = data.fax;
    this.telephone = data.telephone;
    this.description = data.description;
    this.telephone_portable = data.telephone_portable;
    this.type = data.type;
    this.type_societe = data.type_societe;
    this.files = data.files;
    this.domaine = data.domaine;
    this.type_societe_support = data.type_societe_support;
    this.is_societe_support = data.is_societe_support;
    this.ribs = data.ribs;
    this.sequences_factures = data.sequences_factures;
    this.biens = data.biens;
    this.masterFiliales = data.masterFiliales;
    this.societes_of_master_filiale = data.societes_of_master_filiale;
    this.deleted_at = data.deleted_at;
    this.active_master = data.active_master;
    this.capital = data.capital;
    this.rcs = data.rcs;
    this.footer_type = data.footer_type;
    this.footer = data.footer;
    this.start_day = data.start_day;
    this.start_month = data.start_month;
    this.start_year = data.start_year;
    this.end_day = data.end_day;
    this.end_month = data.end_month;
    this.end_year = data.end_year;
    this.payment_condition = data.payment_condition?.id
      ? data.payment_condition?.id
      : null;
    this.payment_condition_name = data.payment_condition?.name;
    this.payment_type = data.payment_type?.id ? data.payment_type?.id : null;
    this.payment_type_name = data.payment_type?.name;
    this.pixel_id = data.pixel_id;
    this.name_part = data.prenom + ' ' + data.nom_famille;
  }
  static create(data) {
    if (!data) {
      throw new Error('FilialeTh::create: "data" is undefined');
    }
    return new FilialeTh(data);
  }
}
