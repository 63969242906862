export default class AppelCommercialSimulationMail {
  constructor(data) {
    this.client = data.client;
    this.client_id = data.client_id;
    this.client_mail = data.client_mail;
    this.content_mail = data.content_mail;
    this.content_pdf = data.content_pdf;
    this.vendeur = data.vendeur;
    this.vendeur_id = data.vendeur_id;
    this.vendeur_mail = data.vendeur_mail;
    this.check = true;
    this.send_to_vendeur = 'yes';
    this.send_to_client = 'yes';
    this.dossiers = data.dossiers;
  }

  /**
   * @param {*} data
   */

  update(data) {}
  static create(data) {
    if (!data) {
      throw new Error(
        'AppelCommercialSimulationMail::create: "data" is undefined'
      );
    }
    return new AppelCommercialSimulationMail(data);
  }
}
