import moment from 'moment';
export default class Comptabilite {
  constructor(data) {
    this.id = data.id;
    this.num = data.num;
    this.Acheteur = data.acheteur;
    this.Vendeur = data.vendeur;
    this.total_ht = data.total_ht;
    this.total_net_ht = data.total_net_ht;
    this.net_ht = data.net_ht;
    this.ttc = data.ttc;
    this.total_ttc = data.total_ttc;
    this.total_tva = data.total_tva;
    this.type = data.type;
    this.etat = data.etat != '' || data.etat != null ? data.etat : '-';
    this.date_creation = data.date_creation;
    this.date_paiement = data.date_paiement;
    this.factureAvoir = data.FactureAvoir.map(item => {
      return { ...item, checkAvoir: false };
    });
    this.categorie = data.categorie;
    this.confirme = data.confirme;
    this.total_reduction = data.total_reduction;
    this.type_avoir = data.type_avoir;
    this.user_id = data.user_id;
    this.famille = data.famille;
    this.check = false;
    this.comptabilise = data.comptabilise;
    this.statut_client = data.statut_client;
    this.historique = data.logs;
    this.mail_lot = data.lot;
  }

  update(data) {
    this.id = data.id;
    this.numero = data.num;
    this.acheteur = data.acheteur;
    this.vendeur = data.vendeur;
    this.total_ht = data.total_ht;
    this.total_net_ht = data.total_net_ht;
    this.total_ttc = data.total_ttc;
    this.total_tva = data.total_tva;
    this.type = data.type;
    this.etat = data.etat;
    this.date_creation = data.date_creation;
    this.date_paiement = data.date_paiement;
    this.factureAvoir = data.FactureAvoir.map(item => {
      return { ...item, checkAvoir: false };
    });
    this.categorie = data.categorie;
    this.confirme = data.confirme;
    this.total_reduction = data.total_reduction;
    this.type_avoir = data.type_avoir;
    this.user_id = data.user_id;
    this.famille = data.famille;
    this.check = false;
    this.comptabilise = data.comptabilise;
    this.net_ht = data.net_ht;
    this.ttc = data.ttc;
    this.historique = data.logs;
    this.mail_lot = data.lot;
  }

  static create(data) {
    return new Comptabilite(data);
  }
}
