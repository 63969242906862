import domain from '@/environment';
import axios from 'axios';
import Master2 from '../../models/Master2';
let requestMaster2 = null;
const state = {
  factureMaster2: [],
  errorMaster2: [],
  acheteurMaster2: [],
  vendeurMaster2: [],
  loadingFactureMaster2: false,
  loadingMaster2: false,
  rowsMaster2: 0,
  templateFactureMaster2: null
};
const getters = {
  factureMaster2: state => state.factureMaster2,
  errorMaster2: state => state.errorMaster2,
  loadingFactureMaster2: state => state.loadingFactureMaster2,
  loadingMaster2: state => state.loadingMaster2,
  TotalRowsMaster2: state => state.rowsMaster2,
  templateFactureMaster2: state => state.templateFactureMaster2,
  acheteurMaster2: state => state.acheteurMaster2,
  vendeurMaster2: state => state.vendeurMaster2
};
const mutations = {
  SET_ERROR_FACTURE_MASTER_2(state, payload) {
    state.errorMaster2 = payload;
  },
  SET_ALL_FACTURES_MASTER_2(state, payload) {
    state.factureMaster2 = payload.map(item => Master2.create(item));
  },
  SET_LOADING_FACTURES_MASTER_2(state, payload) {
    state.loadingFactureMaster2 = payload;
  },
  SET_LOADING_FACTURES_MASTER_2_CONF(state, payload) {
    state.loadingMaster2 = payload;
  },
  SET_TOTAL_ROWS_MASTER_2(state, payload) {
    state.rowsMaster2 = payload;
  },
  UPDATE_STATUT_CLIENT_MASTER_2(state, payload) {
    payload.item.update(payload.response);
  },
  UPDATE_STATUT_FACTURE_MASTER_2(state, payload) {
    payload.item.update(payload.response);
  },
  UPDATE_FACTURE_MASTER_2(state, payload) {
    payload.item.update(payload.response);
  },
  SET_TEMPLATE_FACTURE_MASTER_2(state, payload) {
    state.templateFactureMaster2 = payload;
  },
  UPDATE_MONTANT_HT_FACTURE_MASTER_2(state, payload) {
    payload.item.update(payload.response);
  },
  DELETE_TRANSFERT_FACTURE_IDS_MASTER_2(state, payload) {
    for (let i = 0; i < payload.length; i++) {
      let parentindex = state.factureMaster2.findIndex(
        item => item.name == payload[i].indexOfFather
      );
      let childIndex = state.factureMaster2[parentindex].factures.findIndex(
        item => item.id == payload[i].indexOfChild
      );
      state.factureMaster2[parentindex].factures.splice(childIndex, 1);
      if (state.factureMaster2[parentindex].factures.length === 0) {
        state.factureMaster2.splice(parentindex, 1);
      }
    }
  },
  UPDATE_VALIDATE_MULTIPLE_FACTURE_MASTER_2(commit, payload) {
    payload.data.map(item => {
      let index = payload.response.findIndex(i => i.id == item.id);
      if (index != -1) {
        {
          item.update(payload.response[index]);
        }
      }
    });
  },
  SET_ACHETEURS_FACTURES_MASTER_2(state, payload) {
    state.acheteurMaster2 = payload;
  },
  SET_VENDEURS_FACTURES_MASTER_2(state, payload) {
    state.vendeurMaster2 = payload;
  },
  UPDATE_STATUT_FACTURE_MASTER_2_ID(state, payload) {
    payload.data.map(item => {
      let index = payload.response.findIndex(i => i.id == item.id);
      if (index != -1) {
        {
          item.update(payload.response[index]);
        }
      }
    });
  },
  UPDATE_STATUT_CLIENT_MASTER_2_GLOBALE(state, payload) {
    payload.data.map(item => {
      let index = payload.response.findIndex(i => i.id == item.id);
      if (index != -1) {
        {
          item.update(payload.response[index]);
        }
      }
    });
  }
};
const actions = {
  async getFacturesMaster2({ commit }, payload) {
    commit('SET_LOADING_FACTURES_MASTER_2', true);
    const params = {
      per_page: payload.per_page,
      page: payload.page,
      famille: payload.famille,
      column: payload.column,
      order: payload.order,
      grouped: true
    };
    if (payload && payload.nums) {
      params.nums = payload.nums;
    }
    for (let i = 0; i < payload?.etat?.length; i++) {
      params['etat[' + i + ']'] = payload.etat[i].value;
    }
    for (let i = 0; i < payload?.vendeur_id?.length; i++) {
      params['vendeur_id[' + i + ']'] = payload?.vendeur_id[i]?.value;
    }
    for (let i = 0; i < payload?.acheteur_id?.length; i++) {
      params['acheteur_id[' + i + ']'] = payload?.acheteur_id[i]?.value;
    }
    for (let i = 0; i < payload?.statut_client?.length; i++) {
      params['statut_client[' + i + ']'] = payload?.statut_client[i]?.value;
    }
    if (payload.search != null) {
      params.num = payload.search;
    }
    if (payload.date_debut != null) {
      params.date_debut = payload.date_debut;
    }
    if (payload.date_fin != null) {
      params.date_fin = payload.date_fin;
    }
    try {
      if (requestMaster2) {
        commit('SET_ERROR_FACTURE_MASTER_2', null);
        requestMaster2.cancel('cancel-request');
      }
      requestMaster2 = axios.CancelToken.source();
      const response = await axios.get(domain + `/api/libre/factures`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params,
        cancelToken: requestMaster2.token
      });
      commit('SET_ALL_FACTURES_MASTER_2', response.data.data);
      commit('SET_TOTAL_ROWS_MASTER_2', response.data.count);
      commit('SET_LOADING_FACTURES_MASTER_2', false);
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_FACTURE_MASTER_2', error.response.data.error);
        commit('SET_LOADING_FACTURES_MASTER_2', false);
      } else {
        if (!error.message == 'cancel-request') {
          commit('SET_LOADING_FACTURES_MASTER_2', false);
          commit('SET_ERROR_FACTURE_MASTER_2', ['Une erreur est survenue']);
        }
      }
    }
  },
  async updateStatutClientFactureMaster2({ commit }, payload) {
    commit('SET_LOADING_FACTURES_MASTER_2', true);
    const params = {
      id: payload.row.id,
      statut_client: payload.value
    };
    try {
      const response = await axios.post(
        domain + `/api/libre/statut_client`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_STATUT_CLIENT_MASTER_2', {
        response: response.data.data,
        item: payload.row
      });
      commit('SET_LOADING_FACTURES_MASTER_2', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_FACTURE_MASTER_2', error.response.data.error);
      } else {
        commit('SET_ERROR_FACTURE_MASTER_2', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_FACTURES_MASTER_2', false);

      return false;
    }
  },
  async addFactureMaster2({ commit }, payload) {
    commit('SET_LOADING_FACTURES_MASTER_2', true);

    try {
      const response = await axios.post(
        domain + `/api/masters2/generateBills`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LOADING_FACTURES_MASTER_2', false);

      return {
        succes: true,
        message: response.data.data
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_LOADING_FACTURES_MASTER_2', false);

        return {
          success: false,
          error: error.response.data.error
        };
      } else {
        commit('SET_LOADING_FACTURES_MASTER_2', false);

        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async updateStatutFactureMaster2({ commit }, payload) {
    commit('SET_LOADING_FACTURES_MASTER_2', true);
    const params = {
      id: payload.row.id,
      etat: payload.value
    };
    try {
      const response = await axios.put(domain + `/api/libre/etat`, params, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('UPDATE_STATUT_FACTURE_MASTER_2', {
        response: response.data.data,
        item: payload.row
      });

      commit('SET_LOADING_FACTURES_MASTER_2', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_FACTURE_MASTER_2', error.response.data.error);
      } else {
        commit('SET_ERROR_FACTURE_MASTER_2', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_FACTURES_MASTER_2', false);

      return false;
    }
  },
  async getTemplateFactureMaster2({ commit }, payload) {
    commit('SET_ERROR_FACTURE_MASTER_2', null);
    commit('SET_LOADING_FACTURES_MASTER_2', true);
    try {
      const response = await axios.get(
        domain +
          `/api/filiale_template_generator/get_uploaded_template_for_libre`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );
      commit('SET_TEMPLATE_FACTURE_MASTER_2', response.data.data);

      commit('SET_LOADING_FACTURES_MASTER_2', false);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_FACTURE_MASTER_2', error.response.data.error);
      } else {
        commit('SET_ERROR_FACTURE_MASTER_2', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_FACTURES_MASTER_2', false);

      return false;
    }
  },
  async validateFactureTemplateMaster2({ commit }, payload) {
    const params = {
      description: payload.description,
      iban: payload.iban,
      rib: payload.rib,
      swift: payload.swift,
      bank: payload.bank,
      date: payload.date,
      date_reglement: payload.date_reglement,
      ids: [payload.id_facture],
      id_template: payload.id_template
    };
    if (payload.payment_condition && payload.payment_condition.id != null) {
      params.payment_condition = payload.payment_condition.id;
    }
    try {
      const response = await axios.post(
        domain + `/api/libre/confirme-master`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_FACTURE_MASTER_2', {
        response: response.data.data[0],
        item: payload.item
      });
      return {
        succes: true
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        return {
          succes: false,
          error: error.response.data.errors
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async exportPdfFactureMaster2({ commit }, payload) {
    commit('SET_ERROR_FACTURE_MASTER_2', null);
    commit('SET_LOADING_FACTURES_MASTER_2', true);
    try {
      const response = await axios.get(
        domain + `/api/filiale_template_generator/download_libre`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );

      var words = response.headers['cache-control'].split('.pdf');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', words[0] + '.pdf');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_FACTURES_MASTER_2', false);
      return true;
    } catch (error) {
      commit('SET_ERROR_FACTURE_MASTER_2', ['Une erreur est servenue']);
      commit('SET_LOADING_FACTURES_MASTER_2', false);
      return false;
    }
  },
  async avoirGlobaleFactureMaster2({ commit }, payload) {
    try {
      const response = await axios.post(
        `${domain}/api/libre/avoir_globale_for_all`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { success: true, response: response.data.data };
    } catch (error) {
      if (error?.response?.data?.error) {
        return {
          success: false,
          response: error?.response?.data?.error
        };
      } else {
        return {
          success: false,
          response: error?.response?.data?.error
        };
      }
    }
  },
  async updateMontantHtFactureMaster2({ commit }, payload) {
    commit('SET_LOADING_FACTURES_MASTER_2', true);
    const params = {
      id: payload.row.id,
      montant_ht: payload.value
    };
    try {
      const response = await axios.post(
        domain + `/api/mensuel/update_montant_ht`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_MONTANT_HT_FACTURE_MASTER_2', {
        response: response.data.data,
        item: payload.row
      });

      commit('SET_LOADING_FACTURES_MASTER_2', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_FACTURE_MASTER_2', error.response.data.error);
      } else {
        commit('SET_ERROR_FACTURE_MASTER_2', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_FACTURES_MASTER_2', false);

      return false;
    }
  },
  async transfertFactureMaster2ToLibre({ commit }, payload) {
    commit('SET_LOADING_FACTURES_MASTER_2', true);
    try {
      const response = await axios.post(
        domain + `/api/libre/transfer-to-libre`,
        payload.ids,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      commit('DELETE_TRANSFERT_FACTURE_IDS_MASTER_2', payload.indexsToRemove);

      commit('SET_LOADING_FACTURES_MASTER_2', false);
      return { succes: true };
    } catch (error) {
      commit('SET_LOADING_FACTURES_MASTER_2', false);
      return false;
    }
  },
  async validateMultiFactureMaster2({ commit }, payload) {
    payload.map(item => item.id_facture);
    var ids = new FormData();
    for (let i = 0; i < payload.length; i++) {
      ids.append('ids[' + [i] + ']', payload[i].id);
    }
    try {
      const response = await axios.post(
        domain + `/api/libre/confirme-multi-master`,
        ids,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_VALIDATE_MULTIPLE_FACTURE_MASTER_2', {
        response: response.data.data,
        data: payload
      });
      return {
        succes: true
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async getVendeurAcheteurMaster2({ commit }) {
    commit('SET_ERROR_FACTURE_MASTER_2', null);
    const payload = {
      famille: 'mensuel'
    };
    try {
      const response = await axios.get(
        domain + `/api/libre/get_vendeurs_acheteurs_by_factures`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );
      commit('SET_ACHETEURS_FACTURES_MASTER_2', response.data.acheteurs);
      commit('SET_VENDEURS_FACTURES_MASTER_2', response.data.vendeurs);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_FACTURE_MASTER_2', error.response.data.error);
      } else {
        commit('SET_ERROR_FACTURE_MASTER_2', ['Une erreur est servenue']);
      }
      return false;
    }
  },
  async updateStatutGlobaleMaster2Sci({ commit }, payload) {
    let bodyFormData = new FormData();
    bodyFormData.append('etat', payload.etat);
    for (let i = 0; i < payload.ids_facture.length; i++) {
      bodyFormData.append('ids[' + i + ']', payload.ids_facture[i].id);
    }
    commit('SET_ERROR_FACTURE_MASTER_2', null);
    commit('SET_LOADING_FACTURES_MASTER_2', true);
    try {
      const response = await axios.post(
        domain + `/api/libre/multiple_etat`,
        bodyFormData,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_STATUT_FACTURE_MASTER_2_ID', {
        data: payload.ids_facture,
        response: response.data.data
      });
      commit('SET_LOADING_FACTURES_MASTER_2', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        commit('SET_ERROR_FACTURE_MASTER_2', error.response.data.errors);
      } else {
        commit('SET_ERROR_FACTURE_MASTER_2', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_FACTURES_MASTER_2', false);

      return false;
    }
  },
  //update statue installateur
  async updateStatutClientGlobaleMaster2({ commit }, payload) {
    let bodyFormData = new FormData();
    bodyFormData.append('statut_client', payload.etat);
    for (let i = 0; i < payload.ids_facture.length; i++) {
      bodyFormData.append('ids[' + i + ']', payload.ids_facture[i].id);
    }
    commit('SET_ERROR_FACTURE_MASTER_2', null);
    commit('SET_LOADING_FACTURES_MASTER_2', true);
    try {
      const response = await axios.post(
        domain + `/api/libre/multiple_statut_client`,
        bodyFormData,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      commit('UPDATE_STATUT_CLIENT_MASTER_2_GLOBALE', {
        data: payload.ids_facture,
        response: response.data.data
      });
      commit('SET_LOADING_FACTURES_MASTER_2', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        commit('SET_ERROR_FACTURE_MASTER_2', error.response.data.errors);
      } else {
        commit('SET_ERROR_FACTURE_MASTER_2', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_FACTURES_MASTER_2', false);

      return false;
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
