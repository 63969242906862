export default class RowThNew {
  constructor(data) {
    this.check = false;
    this.BE_TYPE = data.BE_TYPE;
    this.RO = data.RO;
    this.RR = data.RR;
    this.VMC = data.VMC;
    this.acompte = data.acompte;
    this.adresse = data.adresse;
    this.agent_administratif = data.agent_administratif;
    this.agent_commercial = data.agent_commercial;
    this.agent_commercial_terrain = data.agent_commercial_terrain;
    this.agent_technique = data.agent_technique;
    this.annee = data.annee;
    this.ballon = data.ballon;
    this.campagne = data.campagne;
    this.categorie = data.categorie;
    this.code_postal = data.code_postal;
    this.coef_prime_installateur = data.coef_prime_installateur;
    this.cofrac_bureau_de_control = data.cofrac_bureau_de_control;
    this.commentaire = data.commentaire;
    this.commercial_id = data.commercial_id;
    this.commercial_reste_a_charge = data.commercial_reste_a_charge;
    this.commercial_terrain_id = data.commercial_terrain_id;
    this.commissions_cee = data.commissions_cee;
    this.conducteur_de_travaux = data.conducteur_de_travaux;
    this.confirme_frais = data.confirme_frais;
    this.coposeur_id = data.coposeur_id;
    this.coposeurs = data.coposeurs;
    this.couleur = data.couleur;
    this.date_avoir = data.date_avoir;
    this.date_creation_de_la_fiche = data.date_creation_de_la_fiche;
    this.date_dengagement = data.date_dengagement;
    this.date_depot_dossier = data.date_depot_dossier;
    this.date_facture = data.date_facture;
    this.departement = data.departement;
    this.depot = data.depot;
    this.depot_stockage = data.depot_stockage;
    this.devis_signe_par = data.devis_signe_par;
    this.dossier_ID = data.dossier_ID;
    this.dossier_mpr = data.dossier_mpr;
    this.email = data.email;
    this.etape = data.etape;
    this.etat_de_paiement = data.etat_de_paiement;
    this.etat_de_statut = data.etat_de_statut;
    this.etat_du_devis = data.etat_du_devis;
    this.group_id = data.group_id;
    this.id = data.id;
    this.installateur_id = data.installateur_id;
    this.installeur = data.installeur;
    this.kwh_cumac = data.kwh_cumac;
    this.master_filiale_id = data.master_filiale_id;
    this.montant_TVA = data.montant_TVA;
    this.montant_a_5_ht = data.montant_a_5_ht;
    this.montant_final = data.montant_final;
    this.montant_min_lock = data.montant_min_lock;
    this.montant_mpr = data.montant_mpr;
    this.montant_net_ht = data.montant_net_ht;
    this.montant_operation = data.montant_operation;
    this.montant_prime_cee = data.montant_prime_cee;
    this.montant_total_ht = data.montant_total_ht;
    this.montant_total_ttc = data.montant_total_ttc;
    this.montant_ttc_avoir = data.montant_ttc_avoir;
    this.montant_tva_avoir = data.montant_tva_avoir;
    this.nom1 = data.nom1;
    this.numero_avoir = data.numero_avoir;
    this.numero_de_depot = data.numero_de_depot;
    this.numero_de_facture = data.numero_de_facture;
    this.numero_de_lot = data.numero_de_lot;
    this.numero_dossier = data.numero_dossier;
    this.numero_facture_commissions = data.numero_facture_commissions;
    this.numero_facture_prime = data.numero_facture_prime;
    this.numero_facture_surprime = data.numero_facture_surprime;
    this.organisme = data.organisme;
    this.organisme_id = data.organisme_id;
    this.personnes_au_foyer = data.personnes_au_foyer;
    this.pipedrive_lead_id = data.pipedrive_lead_id;
    this.pose_date = data.pose_date;
    this.pose_semaine = data.pose_semaine;
    this.poseur = data.poseur;
    this.poseur_id = data.poseur_id;
    this.poseur_pac = data.poseur_pac;
    this.precarite = data.precarite;
    this.prenom1 = data.prenom1;
    this.previsite_date = data.previsite_date;
    this.previsiteur = data.previsiteur;
    this.previsiteur_id = data.previsiteur_id;
    this.prime_cee = data.prime_cee;
    this.prime_installateur = data.prime_installateur;
    this.ref_dossier_externe = data.ref_dossier_externe;
    this.regie = data.regie;
    this.regie_id = data.regie_id;
    this.responsable_qualite = data.responsable_qualite;
    this.responsable_zone = data.responsable_zone;
    this.reste_a_charge = data.reste_a_charge;
    this.revenu_fiscal_ref = data.revenu_fiscal_ref;
    this.sedentaire_id = data.sedentaire_id;
    this.societe_facturation_id = data.societe_facturation_id;
    this.societe_poseur = data.societe_poseur;
    this.source = data.source;
    this.sous_regie = data.sous_regie;
    this.sous_regie_id = data.sous_regie_i;
    this.statue_date = data.statue_date;
    this.statut = data.statut;
    this.super_regie = data.super_regie;
    this.surface_101_deroule = data.surface_101_deroule;
    this.surface_101_rampants = data.surface_101_rampants;
    this.surface_101_souflee = data.surface_101_souflee;
    this.surface_102_murs = data.surface_102_murs;
    this.surface_102_murs_ite = data.surface_102_murs_ite;
    this.surface_102_murs_iti = data.surface_102_murs_iti;
    this.surface_103_plafond = data.surface_103_plafond;
    this.surprime_cee = data.surprime_cee;
    this.tele_operateur = data.tele_operateur;
    this.tva_coef_prime_installateur = data.tva_coef_prime_installateur;
    this.type_de_chauffage = data.type_de_chauffage;
    this.type_lead = data.type_lead;
    this.version = data.version;
    this.zone_climatique = data.zone_climatique;
    this.statue_paye_non_paye = data.statue_paye_non_paye;
    this.type_isolation = data.type_isolation;
  }
  update(data) {
    this.BE_TYPE = data.BE_TYPE;
    this.RO = data.RO;
    this.RR = data.RR;
    this.VMC = data.VMC;
    this.acompte = data.acompte;
    this.adresse = data.adresse;
    this.agent_administratif = data.agent_administratif;
    this.agent_commercial = data.agent_commercial;
    this.agent_commercial_terrain = data.agent_commercial_terrain;
    this.agent_technique = data.agent_technique;
    this.annee = data.annee;
    this.ballon = data.ballon;
    this.campagne = data.campagne;
    this.categorie = data.categorie;
    this.code_postal = data.code_postal;
    this.coef_prime_installateur = data.coef_prime_installateur;
    this.cofrac_bureau_de_control = data.cofrac_bureau_de_control;
    this.commentaire = data.commentaire;
    this.commercial_id = data.commercial_id;
    this.commercial_reste_a_charge = data.commercial_reste_a_charge;
    this.commercial_terrain_id = data.commercial_terrain_id;
    this.commissions_cee = data.commissions_cee;
    this.conducteur_de_travaux = data.conducteur_de_travaux;
    this.confirme_frais = data.confirme_frais;
    this.coposeur_id = data.coposeur_id;
    this.coposeurs = data.coposeurs;
    this.couleur = data.couleur;
    this.date_avoir = data.date_avoir;
    this.date_creation_de_la_fiche = data.date_creation_de_la_fiche;
    this.date_dengagement = data.date_dengagement;
    this.date_depot_dossier = data.date_depot_dossier;
    this.date_facture = data.date_facture;
    this.departement = data.departement;
    this.depot = data.depot;
    this.depot_stockage = data.depot_stockage;
    this.devis_signe_par = data.devis_signe_par;
    this.dossier_ID = data.dossier_ID;
    this.dossier_mpr = data.dossier_mpr;
    this.email = data.email;
    this.etape = data.etape;
    this.etat_de_paiement = data.etat_de_paiement;
    this.etat_de_statut = data.etat_de_statut;
    this.etat_du_devis = data.etat_du_devis;
    this.group_id = data.group_id;
    this.id = data.id;
    this.installateur_id = data.installateur_id;
    this.installeur = data.installeur;
    this.kwh_cumac = data.kwh_cumac;
    this.master_filiale_id = data.master_filiale_id;
    this.montant_TVA = data.montant_TVA;
    this.montant_a_5_ht = data.montant_a_5_ht;
    this.montant_final = data.montant_final;
    this.montant_min_lock = data.montant_min_lock;
    this.montant_mpr = data.montant_mpr;
    this.montant_net_ht = data.montant_net_ht;
    this.montant_operation = data.montant_operation;
    this.montant_prime_cee = data.montant_prime_cee;
    this.montant_total_ht = data.montant_total_ht;
    this.montant_total_ttc = data.montant_total_ttc;
    this.montant_ttc_avoir = data.montant_ttc_avoir;
    this.montant_tva_avoir = data.montant_tva_avoir;
    this.nom1 = data.nom1;
    this.numero_avoir = data.numero_avoir;
    this.numero_de_depot = data.numero_de_depot;
    this.numero_de_facture = data.numero_de_facture;
    this.numero_de_lot = data.numero_de_lot;
    this.numero_dossier = data.numero_dossier;
    this.numero_facture_commissions = data.numero_facture_commissions;
    this.numero_facture_prime = data.numero_facture_prime;
    this.numero_facture_surprime = data.numero_facture_surprime;
    this.organisme = data.organisme;
    this.organisme_id = data.organisme_id;
    this.personnes_au_foyer = data.personnes_au_foyer;
    this.pipedrive_lead_id = data.pipedrive_lead_id;
    this.pose_date = data.pose_date;
    this.pose_semaine = data.pose_semaine;
    this.poseur = data.poseur;
    this.poseur_id = data.poseur_id;
    this.poseur_pac = data.poseur_pac;
    this.precarite = data.precarite;
    this.prenom1 = data.prenom1;
    this.previsite_date = data.previsite_date;
    this.previsiteur = data.previsiteur;
    this.previsiteur_id = data.previsiteur_id;
    this.prime_cee = data.prime_cee;
    this.prime_installateur = data.prime_installateur;
    this.ref_dossier_externe = data.ref_dossier_externe;
    this.regie = data.regie;
    this.regie_id = data.regie_id;
    this.responsable_qualite = data.responsable_qualite;
    this.responsable_zone = data.responsable_zone;
    this.reste_a_charge = data.reste_a_charge;
    this.revenu_fiscal_ref = data.revenu_fiscal_ref;
    this.sedentaire_id = data.sedentaire_id;
    this.societe_facturation_id = data.societe_facturation_id;
    this.societe_poseur = data.societe_poseur;
    this.source = data.source;
    this.sous_regie = data.sous_regie;
    this.sous_regie_id = data.sous_regie_i;
    this.statue_date = data.statue_date;
    this.statut = data.statut;
    this.super_regie = data.super_regie;
    this.surface_101_deroule = data.surface_101_deroule;
    this.surface_101_rampants = data.surface_101_rampants;
    this.surface_101_souflee = data.surface_101_souflee;
    this.surface_102_murs = data.surface_102_murs;
    this.surface_102_murs_ite = data.surface_102_murs_ite;
    this.surface_102_murs_iti = data.surface_102_murs_iti;
    this.surface_103_plafond = data.surface_103_plafond;
    this.surprime_cee = data.surprime_cee;
    this.tele_operateur = data.tele_operateur;
    this.tva_coef_prime_installateur = data.tva_coef_prime_installateur;
    this.type_de_chauffage = data.type_de_chauffage;
    this.type_lead = data.type_lead;
    this.version = data.version;
    this.zone_climatique = data.zone_climatique;
    this.statue_paye_non_paye = data.statue_paye_non_paye;
    this.type_isolation = data.type_isolation;
  }
  static create(data) {
    return new RowThNew(data);
  }
}
