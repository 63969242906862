import axios from 'axios';
import domain from '@/environment';
const state = {
  loadingBadgeReglement: false,
  errorBadgeReglement: null,
  BadgeReglement: 0
};
const getters = {
  getBadgeReglements: state => state.BadgeReglement,
  getLoadingBadgeReglement: state => state.loadingBadgeReglement,
  getErrorBadgeReglement: state => state.errorBadgeReglement
};
const mutations = {
  SET_LOADING_BADGE_REGLEMENT(state, payload) {
    state.loadingBadgeReglement = payload;
  },
  SET_ERROR_BADGE_REGLEMENT(state, payload) {
    state.errorBadgeReglement = payload;
  },
  SET_BADGE_REGLEMENT(state, payload) {
    state.BadgeReglement = payload;
  }
};
const actions = {
  async fetchBadgeNumberReglement({ commit }, payload) {
    commit('SET_ERROR_BADGE_REGLEMENT', null);

    commit('SET_LOADING_BADGE_REGLEMENT', true);
    try {
      const response = await axios.get(
        domain + `/api/reglements/total-non-lettrer`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_BADGE_REGLEMENT', response.data);
      commit('SET_LOADING_BADGE_REGLEMENT', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_BADGE_REGLEMENT', error.response.data);
      } else {
        commit('SET_ERROR_BADGE_REGLEMENT', 'Une erreur est survenue');
      }
      commit('SET_LOADING_BADGE_REGLEMENT', false);

      return false;
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
