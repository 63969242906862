export default class User {
  constructor(data) {
    this.id = data.id;
    this.full_name = data.full_name;
    this.email = data.email;
    this.type = data.type;
    this.phone = data.phone;
    this.team = data.team;
    this.depot = data.depot;
    this.password = null;
    this.confirmationPassword = null;
    this.filiales = data.filiales;
    this.deleted = data.deleted;
    this.permissions = data.permissions;
    this.profil_id = data.profil_id;
    this.loaderChangeCompte = false;
    this.societe_traitement = data.societe_traitement;
  }
  update(data) {
    this.full_name = data.full_name;
    this.email = data.email;
    this.type = data.type;
    this.phone = data.phone;
    this.team = data.team;
    this.depot = data.depot;
    this.password = null;
    this.confirmationPassword = null;
    this.filiales = data.filiales;
    this.deleted = data.deleted;
    this.permissions = data.permissions;
    this.profil_id = data.profil_id;
    this.societe_traitement = data.societe_traitement;
  }

  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('User::create: "data" is undefined');
    }
    return new User(data);
  }
}
