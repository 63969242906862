import axios from 'axios';
import domain from '@/environment';
import SimulationFactureFraisModel from '@/models/SimulationFactureFraisModel';
let requestSimulationFactureFrais = null;
let prefixSimulationFactureFrauis = 'frais';
let requestSimulationFactureFraisDossier = null;

const state = {
  simulationFactureFrais: [],
  loadingSimulationFactureFrais: false,
  errorSimulationFactureFrais: null,
  simulationTotalRow: null,
  loaderDataFilterListSimulationDyn: false,
  ListMasterSimulationDync: [],
  ListSupportSimulationDync: [],
  ListInstallagteurSimulationDync: [],
  ListLotsSimulationDync: [],
  erreurDataFilterListSimulationDyn: null,
  simulationFactureFraisDossier: [],
  loadingSimulationFactureFraisDossier: false,
  errorSimulationFactureFraisDossier: null,
  loaderNumberFactureSimulation: false,
  loaderExportSimulation: false
};
const getters = {
  getSimulationFactureFraisData: state => state.simulationFactureFrais,
  getLoadingSimulationFactureFrais: state =>
    state.loadingSimulationFactureFrais,
  getErrorSimulationFactureFrais: state => state.errorSimulationFactureFrais,
  getSimulationTotalRow: state => state.simulationTotalRow,
  getLoaderDataFilterListSimulationDyn: state =>
    state.loaderDataFilterListSimulationDyn,
  getListMasterSimulationDync: state => state.ListMasterSimulationDync,
  getListSupportSimulationDync: state => state.ListSupportSimulationDync,
  getListInstallagteurSimulationDync: state =>
    state.ListInstallagteurSimulationDync,
  getListLotsSimulationDync: state => state.ListLotsSimulationDync,
  getErreurDataFilterListSimulationDyn: state =>
    state.erreurDataFilterListSimulationDyn,
  getSimulationFactureFraisDataDossier: state =>
    state.simulationFactureFraisDossier,
  getLoadingSimulationFactureFraisDossier: state =>
    state.loadingSimulationFactureFraisDossier,
  getErrorSimulationFactureFraisDossier: state =>
    state.errorSimulationFactureFraisDossier,
  getLoaderNumberFactureSimulation: state =>
    state.loaderNumberFactureSimulation,
  getMsgNumberFactureSimulation: state => state.msgNumberFactureSimulation,
  getLoaderExportSimulation: state => state.loaderExportSimulation
};
const mutations = {
  SET_DATA_SIMULATION_FACTURE_FRAIS(state, payload) {
    state.simulationFactureFrais = payload.map(item =>
      SimulationFactureFraisModel.create(item)
    );
  },
  SET_LOADING_SIMULATION_FACTURE_FRAIS(state, payload) {
    state.loadingSimulationFactureFrais = payload;
  },
  SET_ERROR_SIMULATION_FACTURE_FRAIS(state, payload) {
    state.errorSimulationFactureFrais = payload;
  },
  UPDATE_SIMULATION_FACTURE_FRAIS_STAT(state, payload) {
    payload.payload.update(payload.response);
  },
  UPDATE_SIMULATION_ROWS(state, payload) {
    state.simulationTotalRow = payload;
  },
  LOEDER_GET_DATA_FILTER_SIMULATION_DYN(state, payload) {
    state.loaderDataFilterListSimulationDyn = payload;
  },
  SET_DATA_FILTER_DYNC_LIST_SIMULATION(state, payload) {
    if (payload.refresh.find(element => element == 'refreshMaster')) {
      state.ListMasterSimulationDync = payload.response.masters;
    }
    if (payload.refresh.find(element => element == 'refreshSupports')) {
      state.ListSupportSimulationDync = payload.response.supports;
    }
    if (payload.refresh.find(element => element == 'refreshClient')) {
      state.ListInstallagteurSimulationDync = payload.response.clients;
    }
    if (payload.refresh.find(element => element == 'refreshLots')) {
      state.ListLotsSimulationDync = payload.response.lots;
    }
  },
  SET_DATA_SIMULATION_DYNC_DETAILS(state, payload) {
    payload.row.update(payload.response);
  },
  ERROR_GET_SUPPORT_SIMULATION_DYN(state, payload) {
    state.erreurDataFilterListSimulationDyn = payload;
  },
  UPDATE_PRODUCT_IN_FACTURE_SIMULATION(state, payload) {
    // if(payload.interface&& payload.interface=="GGDF"){
    payload.item.update(payload.response);
    // }
  },
  SET_DATA_SIMULATION_FACTURE_FRAIS_DOSSIER(state, payload) {
    state.simulationFactureFraisDossier = payload;
  },
  SET_LOADING_SIMULATION_FACTURE_FRAIS_DOSSIER(state, payload) {
    state.loadingSimulationFactureFraisDossier = payload;
  },
  SET_ERROR_SIMULATION_FACTURE_FRAIS_DOSSIER(state, payload) {
    state.errorSimulationFactureFraisDossier = payload;
  },
  UPDATE_SIMULATION_DYNC_ID(commit, payload) {
    payload.data.map(item => {
      let index = payload.response.findIndex(
        i => i.facture_id == item.facture_id
      );
      if (index != -1) {
        {
          item.update(payload.response[index]);
        }
      }
    });
  },
  SET_LOADING_SIMULATION_FACTURE_NUMBER_SIMULATION(state, payload) {
    state.loaderNumberFactureSimulation = payload;
  },
  SET_MSG_SIMULATION_FACTURE_NUMBER_SIMULATION(state, payload) {
    state.msgNumberFactureSimulation = payload;
  },
  SET_LOADING_EXTRACTION_SIMULATION(state, payload) {
    state.loaderExportSimulation = payload;
  }
};
const actions = {
  async getAllSimulationFactureFrais({ commit }, payload) {
    commit('SET_ERROR_SIMULATION_FACTURE_FRAIS', null);
    commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', true);
    const params = {
      page: payload.page,
      per_page: payload.perPage,
      type_date: payload.type_date
    };
    if (payload.date_debut != null) {
      params.date_debut = payload.date_debut;
    }
    if (payload.date_fin != null) {
      params.date_fin = payload.date_fin;
    }
    if (payload.search != null) {
      params.search = payload.search;
    }
    if (payload?.filterFiliale?.length) {
      params.clients = payload.filterFiliale.map(item => item.id);
    }
    if (payload.filterMasterFiliale.length) {
      params.masters = payload.filterMasterFiliale.map(item => item.id);
    }
    if (payload?.lots?.length) {
      params.lots = payload.lots;
    }
    if (payload?.support?.length) {
      params.supports = payload.support.map(item => item.id);
    }
    if (payload.filterFamilly != null) {
      params.support_familly = payload.filterFamilly.id;
    }
    if (payload.confirmation != null) {
      params.confirmation = payload.confirmation;
    }
    if (payload.statut.length) {
      params.statut = payload.statut.map(item => item.value);
    }
    try {
      if (requestSimulationFactureFrais) {
        commit('SET_ERROR_SIMULATION_FACTURE_FRAIS', null);
        requestSimulationFactureFrais.cancel('cancel-request');
      }
      requestSimulationFactureFrais = axios.CancelToken.source();
      const response = await axios.get(
        domain +
          '/api/' +
          prefixSimulationFactureFrauis +
          '/vision_confirmation',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params,
          cancelToken: requestSimulationFactureFrais.token
        }
      );
      commit('SET_DATA_SIMULATION_FACTURE_FRAIS', response.data.data);
      commit('UPDATE_SIMULATION_ROWS', response.data.total);
      commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', false);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_SIMULATION_FACTURE_FRAIS', error.response.data.error);
      } else {
        if (!error.message == 'cancel-request') {
          commit('SET_ERROR_SIMULATION_FACTURE_FRAIS', [
            'Une erreur est servenue'
          ]);
          commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', false);
        }
      }

      return false;
    }
  },
  async updateCoefFraisSimulation({ commit }, payload) {
    commit('SET_ERROR_SIMULATION_FACTURE_FRAIS', null);
    commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', true);

    const params = {
      id: payload.row.id,
      column: payload.column == 'alteo_value' ? 'alteo1_value' : payload.column,
      value: payload.value
    };
    try {
      const response = await axios.post(
        domain + `/api/frais/update_frais`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: response.data.data,
        payload: payload.row
      };
      commit('UPDATE_SIMULATION_FACTURE_FRAIS_STAT', obj);
      commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_SIMULATION_FACTURE_FRAIS', error.response.data.error);
      } else {
        commit('SET_ERROR_SIMULATION_FACTURE_FRAIS', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', false);

      return false;
    }
  },

  async confirmationDossierSimulation({ commit }, payload) {
    commit('SET_ERROR_SIMULATION_FACTURE_FRAIS', null);
    commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', true);
    const params = new FormData();
    payload?.supports?.forEach((item, index) =>
      params.append('supports[' + index + ']', item)
    );
    params.append('document', payload.document);
    try {
      const response = await axios.post(
        domain + `/api/frais/confirmation/${payload.data.item.id}`,
        params,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: response.data.data,
        payload: payload.data.item
      };
      // commit('UPDATE_SIMULATION_FACTURE_FRAIS_STAT', obj);
      commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', false);
      return { succes: true, data: response.data.project, obj: obj };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_SIMULATION_FACTURE_FRAIS', error.response.data.error);
      } else if (error?.response.data?.errors?.supports) {
        commit(
          'SET_ERROR_SIMULATION_FACTURE_FRAIS',
          error.response.data.errors.supports
        );
      } else {
        commit('SET_ERROR_SIMULATION_FACTURE_FRAIS', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', false);

      return { succes: false };
    }
  },
  // async confirmationMultipleDossierSimulation({ commit }, payload) {
  //   commit('SET_ERROR_SIMULATION_FACTURE_FRAIS', null);
  //   // commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', true);

  //   try {
  //     const response = await axios.post(
  //       domain + `/api/frais/confirmation_multiple`,
  //       payload,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${this.state.User.access_token}`
  //         }
  //       }
  //     );
  //     return { succes: true, message: response.data };
  //   } catch (error) {
  //     if (error.response && error.response.data && error.response.data.error) {
  //       // commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', false);

  //       return {
  //         succes: false,
  //         error: error.response.data.error
  //       };
  //     } else {
  //       // commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', false);
  //       return {
  //         succes: false,
  //         error: 'Une erreur est servenue'
  //       };
  //     }
  //   }
  // },
  async confirmationMultipleDossierSimulation({ commit }, payload) {
    commit('SET_ERROR_SIMULATION_FACTURE_FRAIS', null);
    // commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', true);

    try {
      const response = await axios.post(
        domain + `/api/frais/confirmation_multiple`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { succes: true, data: response.data.data };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        // commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', false);

        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        // commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', false);
        return {
          succes: false,
          error: 'Une erreur est servenue'
        };
      }
    }
  },
  async refreshSimulation({ commit }, payload) {
    commit('SET_ERROR_SIMULATION_FACTURE_FRAIS', null);
    // commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', true);

    try {
      const response = await axios.post(
        domain + `/api/frais/refresh`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { succes: true, message: response.data };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        // commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', false);

        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        // commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', false);
        return {
          succes: false,
          error: 'Une erreur est servenue'
        };
      }
    }
  },
  async getFactureNumberSimulation({ commit }, payload) {
    commit('SET_LOADING_SIMULATION_FACTURE_NUMBER_SIMULATION', true);

    try {
      const response = await axios.get(
        domain + `/api/frais/how_many_factures`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );
      commit(
        'SET_MSG_SIMULATION_FACTURE_NUMBER_SIMULATION',
        response.data.data
      );
      commit('SET_LOADING_SIMULATION_FACTURE_NUMBER_SIMULATION', false);

      return true;
    } catch (error) {
      commit('SET_LOADING_SIMULATION_FACTURE_NUMBER_SIMULATION', false);
      if (error.response && error.response.data && error.response.data.error) {
        return false;
      } else {
        return false;
      }
    }
  },
  async generationParFilterSimulation({ commit }, payload) {
    commit('SET_ERROR_SIMULATION_FACTURE_FRAIS', null);
    // commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', true);

    try {
      const response = await axios.post(
        domain + `/api/frais/refresh_without_ids`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { succes: true, message: response.data };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        // commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', false);

        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        // commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', false);
        return {
          succes: false,
          error: 'Une erreur est servenue'
        };
      }
    }
  },
  async getAllDataForFilterDynamicSimulation({ commit }, payload) {
    const params = { type_date: payload.type_date };
    if (payload.date_debut != null) {
      params.date_debut = payload.date_debut;
    }
    if (payload.date_fin != null) {
      params.date_fin = payload.date_fin;
    }
    // if (payload.masters.length) {
    //   params.masters = payload.masters;
    // }
    if (payload.confirmation != null) {
      params.confirmation = payload.confirmation;
    }
    // if (payload.lots.length) {
    //   params.lots = payload.lots;
    // }
    // if (payload.supports.length) {
    //   params.supports = payload.supports;
    // }
    commit('LOEDER_GET_DATA_FILTER_SIMULATION_DYN', true);
    try {
      const response = await axios.get(domain + '/api/frais/filter_online', {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
      });
      commit('SET_DATA_FILTER_DYNC_LIST_SIMULATION', {
        response: response.data,
        refresh: payload.refresh
      });
      commit('LOEDER_GET_DATA_FILTER_SIMULATION_DYN', false);
    } catch (error) {
      if (error?.response?.error) {
        commit('ERROR_GET_SUPPORT_SIMULATION_DYN', error?.response?.error);
      } else {
        commit('ERROR_GET_SUPPORT_SIMULATION_DYN', ['Une erreur est servenue']);
      }

      commit('LOEDER_GET_DATA_FILTER_SIMULATION_DYN', false);
    }
  },
  async getDataForRowSimulationDyncs({ commit }, payload) {
    payload.item.loadingDetailt = true;
    const params = {
      id: payload.item.id
      // supports: payload.supports.map(item => item.id)
    };

    try {
      const response = await axios.get(
        domain + `/api/frais/vision_confirmation_project`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_DATA_SIMULATION_DYNC_DETAILS', {
        response: response.data,
        row: payload.item
      });
      payload.item.loadingDetailt = false;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        commit('SET_ERROR_RAIS_BUREAU_ETUDE', error.response.data.errors);
      } else {
        commit('SET_ERROR_RAIS_BUREAU_ETUDE', ['Une erreur est servenue']);
      }
      payload.item.loadingDetailt = false;

      return false;
    }
  },
  async lockFactureSimulation({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/frais/lock_simulation/${payload.facture_id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      // payload.update(response.data.data);
      const obj = {
        response: response.data.data,
        item: payload
      };
      commit('UPDATE_PRODUCT_IN_FACTURE_SIMULATION', obj);
      // payload.lock = !payload.lock;
      return { succes: true };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        return { succes: false, error: error.response.data.error };
      } else {
        return { succes: false, error: 'Une erreur est servenue' };
      }
    }
  },
  async lockGlobaleFactureSimulation({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/frais/lock_multiple_simulation`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      // payload.update(response.data.data);
      const obj = {
        response: response.data.data,
        data: payload.data
      };
      commit('UPDATE_SIMULATION_DYNC_ID', obj);
      // payload.lock = !payload.lock;
      return { succes: true };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        return { succes: false, error: error.response.data.error };
      } else {
        return { succes: false, error: 'Une erreur est servenue' };
      }
    }
  },
  async generateFactureProformaSimulation({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/frais/generate_manuel_proforma`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: response.data.data,
        item: payload.data,
        interface: payload.interface
      };
      commit('UPDATE_PRODUCT_IN_FACTURE_SIMULATION', obj);
      return { succes: true, message: response.data.data };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        return { succes: false, error: error.response.data.error };
      } else {
        return { succes: false, error: 'Une erreur est servenue' };
      }
    }
  },
  async getAllDossierForSimulationProjects({ commit }, payload) {
    commit('SET_ERROR_SIMULATION_FACTURE_FRAIS_DOSSIER', null);
    commit('SET_LOADING_SIMULATION_FACTURE_FRAIS_DOSSIER', true);
    const params = { type_date: payload.type_date };
    if (payload.date_debut != null) {
      params.date_debut = payload.date_debut;
    }
    if (payload.date_fin != null) {
      params.date_fin = payload.date_fin;
    }
    if (payload.search != null) {
      params.search = payload.search;
    }
    if (payload.filterFiliale && payload.filterFiliale.length) {
      params.clients = payload.filterFiliale.map(item => item.id);
    }
    if (payload.filterMasterFiliale.length) {
      params.masters = payload.filterMasterFiliale.map(item => item.id);
    }
    if (payload.lots && payload.lots.length) {
      params.lots = payload.lots;
    }
    if (payload.support && payload.support.length) {
      params.supports = payload.support.map(item => item.id);
    }
    if (payload.statut.length) {
      params.statut = payload.statut.map(item => item.value);
    }
    // if (payload.confirmation != null) {
    //   params.confirmation = payload.confirmation;
    // }
    try {
      if (requestSimulationFactureFraisDossier) {
        commit('SET_ERROR_SIMULATION_FACTURE_FRAIS_DOSSIER', null);
        requestSimulationFactureFraisDossier.cancel('cancel-request');
      }
      requestSimulationFactureFraisDossier = axios.CancelToken.source();
      const response = await axios.get(
        domain + '/api/' + prefixSimulationFactureFrauis + '/confirmation_view',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params,
          cancelToken: requestSimulationFactureFraisDossier.token
        }
      );
      commit('SET_DATA_SIMULATION_FACTURE_FRAIS_DOSSIER', response.data.data);
      commit('SET_LOADING_SIMULATION_FACTURE_FRAIS_DOSSIER', false);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit(
          'SET_ERROR_SIMULATION_FACTURE_FRAIS_DOSSIER',
          error.response.data.error
        );
      } else {
        if (!error.message == 'cancel-request') {
          commit('SET_ERROR_SIMULATION_FACTURE_FRAIS_DOSSIER', [
            'Une erreur est servenue'
          ]);
          commit('SET_LOADING_SIMULATION_FACTURE_FRAIS_DOSSIER', false);
        }
      }

      return false;
    }
  },
  async exportSimulation({ commit }, payload) {
    commit('SET_LOADING_EXTRACTION_SIMULATION', true);
    try {
      const response = await axios.post(
        domain + `/api/frais/export_simulation`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      // const link = document.createElement('a');
      // link.href = window.URL.createObjectURL(new Blob([response.data]));
      // link.setAttribute('download', 'SIMULATION.xlsx');
      // document.body.appendChild(link);
      // link.click();
      commit('SET_LOADING_EXTRACTION_SIMULATION', false);
      this.state.showNotifExportFile = true;
      return { succes: true, msg: response.data.data };
    } catch (error) {
      commit('SET_LOADING_EXTRACTION_SIMULATION', false);
      return { succes: false };
    }
  },
  async getDataPDFSimulation({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/frais/see_facture`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { succes: true, src: response.data.data };
    } catch (error) {
      return { succes: false, error: error.response.data.error };
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
