export default class TypeReglement {
  constructor(data) {
    this.id = data.id;
    this.name = data.nom;
  }
  update(data) {
    this.id = data.id;
    this.name = data.nom;
  }
  static create(data) {
    return new TypeReglement(data);
  }
}
