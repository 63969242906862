import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import store from '../store/index';
window.pusher = require('pusher-js');
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: 'b6722dd7fbf68f2d98fb',
  encrypt: true,
  cluster: 'eu'
});
const getNotificationExportFile = function() {
  window.Echo.channel('notification').listen(
    '.user_' + store.state.User.id,
    function(e) {
      var newNotification = e.notification;
      store.commit('NEW_NOTIFICATION', newNotification);
      store.dispatch('fecthListFichierExporteCdg');
    }
  );
};

const destroyed = function() {
  window.Echo.leave('notification');
};
export default {
  getNotificationExportFile,
  destroyed
};
