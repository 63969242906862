import axios from 'axios';
import domain from '@/environment';
import FraisComptaRhTh from '../../models/FraisComptaRhTh';
const prefixFraisComptaRh = 'filiale_template_generator_GE';
let requestComptaRh = null;
const state = {
  fraisComptaRh: [],
  loadingFraisComptaRh: false,
  errorFraisComptaRh: null,
  templateComptaRhToUpload: null,
  loadingGetDataTemplateComptaRh: false,
  errorTemplateComptaRhDataToUpploadeed: null,
  loadingExtactionFriasVisionComptaRh: false,
  onlineFilialeInVisionFilialeFraisComptaRh: [],
  errorOneDayComptaRh: {},
  errorInvalidElmailComptaRh: {},
  errorHasntEmailComptaRh: {},
  successSendMailsComptaRh: {},
  errorHasentTemplateComptaRh: {},
  errorHasentTemplateEmailComptaRh: {},
  errorNotValidYet: {},
  otherErrorMailComptaRhMail: null,
  totalRowComptaRhTh: null,
  factures: []
};
const getters = {
  getFacturesCompta: state => state.factures,
  getFraisComptaRh: state => state.fraisComptaRh,
  getLoadingFraisComptaRh: state => state.loadingFraisComptaRh,
  getErrorFraisComptaRh: state => state.errorFraisComptaRh,
  getTemplateComptaRhToUpload: state => state.templateComptaRhToUpload,
  getLoadingGetDataTemplateComptaRh: state =>
    state.loadingGetDataTemplateComptaRh,
  getErrorTemplateComptaRhDataToUpploadeed: state =>
    state.errorTemplateComptaRhDataToUpploadeed,
  getLoadingExtactionFriasVisionComptaRh: state =>
    state.loadingExtactionFriasVisionComptaRh,
  getOnlineFilialeInVisionFilialeFraisComptaRh: state =>
    state.onlineFilialeInVisionFilialeFraisComptaRh,
  getErrorOneDayComptaRh: state => state.errorOneDayComptaRh,
  getSuccessSendMailsComptaRh: state => state.successSendMailsComptaRh,
  getErrorInvalidElmailComptaRh: state => state.errorInvalidElmailComptaRh,
  getErrorHasntEmailComptaRh: state => state.errorHasntEmailComptaRh,
  getOtherErrorMailComptaRhMail: state => state.otherErrorMailComptaRhMail,
  getErrorHasentTemplateComptaRh: state => state.errorHasentTemplateComptaRh,
  getErrorHasentTemplateEmailComptaRh: state =>
    state.errorHasentTemplateEmailComptaRh,
  getErrorNotValidYet: state => state.errorNotValidYet,
  geTtotalRowComptaRhTh: state => state.totalRowComptaRhTh
};
const mutations = {
  SET_ALL_FACTURES_COMPTA(state, payload) {
    state.factures = payload;
  },
  SET_RAIS_COMPTA_RH(state, payload) {
    state.fraisComptaRh = payload.map(item => FraisComptaRhTh.create(item));
  },
  SET_RAIS_COMPTA_RH_ROW(state, payload) {
    state.totalRowComptaRhTh = payload;
  },
  SET_LOADING_RAIS_COMPTA_RH(state, payload) {
    state.loadingFraisComptaRh = payload;
  },
  SET_ERROR_RAIS_COMPTA_RH(state, payload) {
    state.errorFraisComptaRh = payload;
  },
  SET_TEMPLATE_COMPTA_RH_TO_UPLOAD(state, payload) {
    state.templateComptaRhToUpload = payload;
  },
  SET_LOADING_GET_TEMPLATE_COMPTA_RH_DATA(state, payload) {
    state.loadingGetDataTemplateComptaRh = payload;
  },
  SET_ERROR_TEMPLATE_COMPTA_RH_DATA_TO_UPLOAD(state, payload) {
    state.errorTemplateComptaRhDataToUpploadeed = payload;
  },
  SET_DATA_FOR_FACTURE_COMPTA_RH_UPLOADED(state, payload) {
    payload.item.update(payload.response);
  },
  SET_TYPE_RAIS_COMPTA_RH_STATUE(state, payload) {
    payload.item.update(payload.response);
  },
  SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_COMPTA_RH(state, payload) {
    state.loadingExtactionFriasVisionComptaRh = payload;
  },
  DELETE_FACTURE_COMPTA_RH(state, payload) {
    const index = state.fraisComptaRh.findIndex(
      element => element.id === payload
    );
    state.fraisComptaRh.splice(index, 1);
  },
  SET_FILIALE_ONLINE_FRAIS_VISION_FILIALE_COMPTA_RH(state, payload) {
    state.onlineFilialeInVisionFilialeFraisComptaRh = payload;
  },
  SET_ERROR_ONE_DAY_COMPTA_RH(state, payload) {
    state.errorOneDayComptaRh = payload;
  },
  SET_SUCCESS_SEND_MAILS_COMPTA_RH(state, payload) {
    state.successSendMailsComptaRh = payload;
  },
  SET_HASNT_EMAIL_COMPTA_RH(state, payload) {
    state.errorHasntEmailComptaRh = payload;
  },
  SET_INVALID_EMAIL_COMPTA_RH(state, payload) {
    state.errorInvalidElmailComptaRh = payload;
  },
  SET_OTHER_ERROR_COMPTA_RH(state, payload) {
    state.otherErrorMailComptaRhMail = payload;
  },
  SET_NOT_VALID_YET_COMPTA_RH(state, payload) {
    state.errorNotValidYet = payload;
  },
  SET_EMAIL_HASENT_TEMPLATE_COMPTA_RH(state, payload) {
    state.errorHasentTemplateComptaRh = payload;
  },
  SET_EMAIL_HASENT_TEMPLATE_EMAIL_COMPTA_RH(state, payload) {
    state.errorHasentTemplateEmailComptaRh = payload;
  },

  SET_ITEM_RAIS_COMPTA_RH(state, payload) {
    let newItem = FraisComptaRhTh.create(payload);

    const index = state.fraisComptaRh.findIndex(
      element => element.id === payload.id
    );

    state.fraisComptaRh.splice(index, 1, newItem);
  },
  UPDATE_COMPTA_RH_FACTURE_VALIDATION(commit, payload) {
    payload.data.map(item => {
      let index = payload.response.findIndex(i => i.id == item.id);
      if (index != -1) {
        {
          item.update(payload.response[index]);
        }
      }
    });
  }
};
const actions = {
  //COMPTA && RH
  //fatch all facture
  async getFacturesComptaRh({ commit }, payload) {
    commit('SET_LOADING_RAIS_COMPTA_RH', true);
    const params = {
      per_page: payload.per_page,
      page: payload.page,
      famille: payload.famille,
      column: payload.column,
      order: payload.order
    };
    for (let i = 0; i < payload?.type?.length; i++) {
      params['type[' + i + ']'] = payload?.type[i]?.value;
    }
    for (let i = 0; i < payload?.etat?.length; i++) {
      params['etat[' + i + ']'] = payload.etat[i].value;
    }
    for (let i = 0; i < payload?.vendeur_id?.length; i++) {
      params['vendeur_id[' + i + ']'] = payload?.vendeur_id[i]?.value;
    }
    for (let i = 0; i < payload?.categorie_id?.length; i++) {
      params['categorie_id[' + i + ']'] = payload?.categorie_id[i]?.value;
    }
    for (let i = 0; i < payload?.acheteur_id?.length; i++) {
      params['acheteur_id[' + i + ']'] = payload?.acheteur_id[i]?.value;
    }
    for (let i = 0; i < payload?.family?.length; i++) {
      params['family[' + i + ']'] = payload?.family[i]?.value;
    }

    if (payload.date_debut != null) {
      params.date_debut = payload.date_debut;
    }
    if (payload.date_fin != null) {
      params.date_fin = payload.date_fin;
    }
    if (payload.num != null) {
      params.num = payload.num;
    }
    if (payload.comptabilite != null) {
      params.comptabilite = payload.comptabilite;
    }
    if (payload.grouped != null) {
      params.grouped = payload.grouped;
    }
    try {
      if (requestComptaRh) {
        commit('SET_ERROR_RAIS_COMPTA_RH', null);
        requestComptaRh.cancel('cancel-request');
      }
      requestComptaRh = axios.CancelToken.source();
      const response = await axios.get(domain + `/api/libre/factures`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params,
        cancelToken: requestComptaRh.token
      });
      commit('SET_ALL_FACTURES_COMPTA', response.data.data);
      commit('SET_TOTAL_ROWS', response.data.count);
      commit('SET_LOADING_RAIS_COMPTA_RH', false);
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_RAIS_COMPTA_RH', error.response.data.error);
        commit('SET_LOADING_RAIS_COMPTA_RH', false);
      } else {
        if (!error.message == 'cancel-request') {
          commit('SET_LOADING_RAIS_COMPTA_RH', false);
          commit('SET_ERROR_RAIS_COMPTA_RH', ['Une erreur est survenue']);
        }
      }
    }
  },
  async fetchFraisComptaRh({ commit }, payload) {
    commit('SET_ERROR_RAIS_COMPTA_RH', null);

    const params = {
      year: payload.year,
      month: payload.month,
      page: payload.page,
      per_page: payload.per_page
    };
    if (payload.type && payload.type != null) {
      params.type = payload.type;
    }
    if (payload.search != null) {
      params.search = payload.search;
    }
    if (payload.master_filiale != null) {
      params.master_filiale = payload.master_filiale;
    }
    if (payload.filiale != null) {
      params.filiale = payload.filiale;
    }
    if (payload.statue != null) {
      params.statue = payload.statue;
    }
    commit('SET_LOADING_RAIS_COMPTA_RH', true);
    try {
      if (requestComptaRh) {
        commit('SET_ERROR_RAIS_COMPTA_RH', null);
        requestComptaRh.cancel('cancel-request');
      }
      requestComptaRh = axios.CancelToken.source();
      const response = await axios.get(
        domain + `/api/${prefixFraisComptaRh}/get_all`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params,
          cancelToken: requestComptaRh.token
        }
      );
      commit('SET_RAIS_COMPTA_RH', response.data.data.data);
      commit('SET_RAIS_COMPTA_RH_ROW', response.data.data.total);
      commit('SET_LOADING_RAIS_COMPTA_RH', false);
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_RAIS_COMPTA_RH', error.response.data.error);
      } else {
        if (!error.message == 'cancel-request') {
          commit('SET_LOADING_RAIS_COMPTA_RH', false);
          commit('SET_ERROR_RAIS_COMPTA_RH', ['Une erreur est servenue']);
        }
      }
    }
  },
  //get Template Data
  async getComptaRhTemplateAndFiledsDataToUpdate({ commit }, payload) {
    commit('SET_ERROR_TEMPLATE_COMPTA_RH_DATA_TO_UPLOAD', null);
    commit('SET_LOADING_GET_TEMPLATE_COMPTA_RH_DATA', true);
    const params = {
      id: payload.id
    };

    try {
      const response = await axios.get(
        domain + `/api/${prefixFraisComptaRh}/get_uploaded_template`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_TEMPLATE_COMPTA_RH_TO_UPLOAD', response.data.data);

      commit('SET_LOADING_GET_TEMPLATE_COMPTA_RH_DATA', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit(
          'SET_ERROR_TEMPLATE_COMPTA_RH_DATA_TO_UPLOAD',
          error.response.data.error
        );
      } else {
        commit('SET_ERROR_TEMPLATE_COMPTA_RH_DATA_TO_UPLOAD', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_GET_TEMPLATE_COMPTA_RH_DATA', false);

      return false;
    }
  },
  //upload facture
  async uploadComptaRhTemplateInServieVisionFiliale({ commit }, payload) {
    const params = {
      id: payload.item.id,
      description: payload.description,
      iban: payload.iban,
      swift: payload.swift,
      date: payload.date,
      date_reglement: payload.date_reglement
    };
    try {
      const response = await axios.get(
        domain + `/api/${prefixFraisComptaRh}/upload_one`,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_DATA_FOR_FACTURE_COMPTA_RH_UPLOADED', {
        response: response.data.data,
        item: payload.item
      });
      return {
        succes: true,
        message: response.data.data
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  //update statue facture
  async updateStatueFraisComptaRh({ commit }, payload) {
    commit('SET_LOADING_RAIS_COMPTA_RH', true);
    const params = {
      id: payload.row.id,
      column: payload.column,
      value: payload.value
    };
    try {
      const response = await axios.post(
        domain + `/api/${prefixFraisComptaRh}/update`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_TYPE_RAIS_COMPTA_RH_STATUE', {
        response: response.data.data,
        item: payload.row
      });

      commit('SET_LOADING_RAIS_COMPTA_RH', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_RAIS_COMPTA_RH', error.response.data.error);
      } else {
        commit('SET_ERROR_RAIS_COMPTA_RH', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_RAIS_COMPTA_RH', false);

      return false;
    }
  },
  //update statue installateur
  async updateStatueInstallateurFraisComptaRh({ commit }, payload) {
    commit('SET_LOADING_RAIS_COMPTA_RH', true);
    const params = {
      id: payload.row.id,
      statue: payload.value
    };
    try {
      const response = await axios.post(
        domain +
          `/api/filiale_template_generator_GE/update_installateur_statue`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_TYPE_RAIS_COMPTA_RH_STATUE', {
        response: response.data.data,
        item: payload.row
      });

      commit('SET_LOADING_RAIS_COMPTA_RH', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_RAIS_COMPTA_RH', error.response.data.error);
      } else {
        commit('SET_ERROR_RAIS_COMPTA_RH', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_RAIS_COMPTA_RH', false);

      return false;
    }
  },
  //export one pdf facture
  async exportPdfFactureUploadedTypeComptaRh({ commit }, payload) {
    commit('SET_LOADING_RAIS_COMPTA_RH', true);
    const params = {
      id: payload.id
    };
    try {
      const response = await axios.get(
        domain + `/api/${prefixFraisComptaRh}/download_one`,
        {
          params: params,
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      var words = response.headers['cache-control'].split('.pdf');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', words[0] + '.pdf');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_RAIS_COMPTA_RH', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_RAIS_COMPTA_RH', false);
      return false;
    }
  },
  // add new facture
  async addNewFactureComptaRSciActions({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/mensuel/add_facture`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return {
        success: true,
        message: response.data.data
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          success: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  //deletev facture
  async deleteFactureComptaRhActions({ commit }, payload) {
    try {
      const response = await axios.get(
        domain + `/api/${prefixFraisComptaRh}/delete`,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: {
            id: payload
          }
        }
      );
      commit('DELETE_FACTURE_COMPTA_RH', payload);
      return {
        succes: true
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  //generate facute auto
  async generateFactureComptaRh({ commit }, payload) {
    try {
      const response = await axios.get(
        domain + `/api/${prefixFraisComptaRh}/generated_last_month`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );
      return {
        succes: true,
        data: response.data.data
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  //export excel globale
  async exportExcelFraisVisionComptaRhTh({ commit }, payload) {
    commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_COMPTA_RH', true);
    try {
      const response = await axios.post(
        domain + `/api/${prefixFraisComptaRh}/export`,
        payload,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const type = (payload.get('type') != 'null'
        ? payload.get('type')
        : ''
      ).toUpperCase();
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute(
        'download',
        'VISION FRAIS COMPTA et RH' +
          type +
          ' - ' +
          payload.get('year') +
          '.xlsx'
      );
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_COMPTA_RH', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_COMPTA_RH', false);
      return false;
    }
  },
  setDaraTemplateComptaRhUploded({ commit }) {
    commit('SET_TEMPLATE_COMPTA_RH_TO_UPLOAD', null);
  },
  //global validation Compta &&   RH
  async getGlobalTemplateAndFiledsDataToUpdateForComptaRh({ commit }, payload) {
    commit('SET_ERROR_TEMPLATE_COMPTA_RH_DATA_TO_UPLOAD', null);
    commit('SET_LOADING_GET_TEMPLATE_COMPTA_RH_DATA', true);
    const params = {
      type: payload.type
    };

    try {
      const response = await axios.get(domain + `/api/templates/get_one`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
      });
      commit('SET_TEMPLATE_COMPTA_RH_TO_UPLOAD', response.data.data);

      commit('SET_LOADING_GET_TEMPLATE_COMPTA_RH_DATA', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit(
          'SET_ERROR_TEMPLATE_COMPTA_RH_DATA_TO_UPLOAD',
          error.response.data.error
        );
      } else {
        commit('SET_ERROR_TEMPLATE_COMPTA_RH_DATA_TO_UPLOAD', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_GET_TEMPLATE_COMPTA_RH_DATA', false);

      return false;
    }
  },
  async globalTemplateAndFiledsDataToUpdateForComptaRhNew({ commit }, payload) {
    commit('SET_ERROR_TEMPLATE_COMPTA_RH_DATA_TO_UPLOAD', null);
    commit('SET_LOADING_GET_TEMPLATE_COMPTA_RH_DATA', true);
    let params = { ids: payload.map(i => i.id) };
    try {
      const response = await axios.post(
        domain + `/api/filiale_template_generator_GE/confirmation_multiple`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      // commit('SET_TEMPLATE_COMPTA_RH_TO_UPLOAD', response.data.data);
      commit('UPDATE_COMPTA_RH_FACTURE_VALIDATION', {
        data: payload,
        response: response.data.data
      });
      commit('SET_LOADING_GET_TEMPLATE_COMPTA_RH_DATA', false);

      return {
        succes: true,
        message: {
          confirmed: response.data.confirmed,
          not_confirmed: response.data.not_confirmed
        }
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit(
          'SET_ERROR_TEMPLATE_COMPTA_RH_DATA_TO_UPLOAD',
          error.response.data.error
        );
      } else {
        commit('SET_ERROR_TEMPLATE_COMPTA_RH_DATA_TO_UPLOAD', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_GET_TEMPLATE_COMPTA_RH_DATA', false);

      return { succes: false };
    }
  },
  async uploadGlobalTemplateForComptaRh({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/${prefixFraisComptaRh}/upload_multiple`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      return {
        succes: true,
        message: response.data.data
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  //global export
  async exportAllPdfFraisVisionComptaRhTh({ commit }, payload) {
    commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_COMPTA_RH', true);
    try {
      const response = await axios.post(
        domain + `/api/${prefixFraisComptaRh}/download_multiple`,
        payload,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const type = payload.get('type') != 'null' ? payload.get('type') : '';
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'VISION FRAIS ' + type + '.zip');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_COMPTA_RH', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_COMPTA_RH', false);
      return false;
    }
  },
  // get online filiale
  async getAllOnlineFilialeInFraisVisionFilialeComptaRh({ commit }, payload) {
    commit('SET_LOADING_RAIS_COMPTA_RH', true);
    const params = {
      year: payload.year
    };
    if (payload.month != null) {
      params.month = payload.month;
    }
    if (payload.type != null) {
      params.type = payload.type;
    }
    if (payload.statue != null) {
      params.statue = payload.statue;
    }
    try {
      const response = await axios.get(
        domain + `/api/${prefixFraisComptaRh}/filiale_online`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit(
        'SET_FILIALE_ONLINE_FRAIS_VISION_FILIALE_COMPTA_RH',
        response.data.data
      );

      commit('SET_LOADING_RAIS_COMPTA_RH', false);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_RAIS_COMPTA_RH', error.response.data.error);
      } else {
        commit('SET_ERROR_RAIS_COMPTA_RH', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_RAIS_COMPTA_RH', false);

      return false;
    }
  },
  async sendMailComptaRh({ commit }, payload) {
    commit('SET_MAIL_LODING', true);
    try {
      const response = await axios.post(
        domain + `/api/facture/envoiMails_ge`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_MAIL_LODING', false);
      commit('SET_ERROR_ONE_DAY_COMPTA_RH', response.data[2]);
      commit('SET_SUCCESS_SEND_MAILS_COMPTA_RH', response.data[6]);
      commit('SET_HASNT_EMAIL_COMPTA_RH', response.data[0]);
      commit('SET_NOT_VALID_YET_COMPTA_RH', response.data[4]);
      commit('SET_EMAIL_HASENT_TEMPLATE_COMPTA_RH', response.data[3]);
      commit('SET_EMAIL_HASENT_TEMPLATE_EMAIL_COMPTA_RH', response.data[5]);
      commit('SET_INVALID_EMAIL_COMPTA_RH', response.data[1]);
      return { succes: true };
    } catch (error) {
      if (error?.response?.data?.errors?.ids[0]) {
        commit('SET_OTHER_ERROR_COMPTA_RH', 'Selectionner  une filiale');
      } else {
        commit('SET_OTHER_ERROR_COMPTA_RH', 'Une erreur est servenue');
      }
      commit('SET_MAIL_LODING', false);
      return { succes: false };
    }
  },
  //generate avoir for facture selected
  async generateAvoir({ commit }, payload) {
    commit('SET_LOADING_RAIS_COMPTA_RH', true);
    const params = {
      ids: payload
    };
    try {
      const response = await axios.post(
        domain + `/api/filiale_template_generator_GE/avoir`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      response.data.data.forEach(element => {
        commit('SET_ITEM_RAIS_COMPTA_RH', element);
      });

      commit('SET_LOADING_RAIS_COMPTA_RH', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_RAIS_COMPTA_RH', error.response.data.error);
      } else {
        commit('SET_ERROR_RAIS_COMPTA_RH', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_RAIS_COMPTA_RH', false);

      return false;
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
