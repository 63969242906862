import axios from 'axios';
import domain from '@/environment';
import ConfigurationComptaRh from '../../models/ConfigurationComptaRh';
import configuration from '../../models/configuration';

const state = {
  allConfMensuel: [],
  confLoading: false,
  confErrors: null,
  totalRowconf: null,
  errorConfig: [],
  filtre: {
    vendeur: [],
    acheteur: [],
    period: null
  }
};
const getters = {
  getAllConfMensuel: state => state.allConfMensuel,
  getConfLoading: state => state.confLoading,
  getConfErrors: state => state.confErrors,
  getTotalRowConfComptaRh: state => state.totalRowconf,
  errorConfig: state => state.errorConfig,
  getFiltre: state => state.filtre
};
const mutations = {
  SET_CONFIG_NEW_ERROR_DUPLICATION(state, payload) {
    state.errorConfig = payload;
  },
  SET_CONF_COMPTA_RH_ERROR(state, payload) {
    state.confErrors = payload;
  },
  ADD_NEW_CONF_COMPTA_RH(state, payload) {
    let existe = false;
    state.allConfMensuel.map(mensuel => {
      if (mensuel.vendeur == payload.response.name) {
        existe = true;
        mensuel.confs.unshift(configuration.create(payload.response.confs[0]));
      } else {
        if (mensuel.vendeur == payload.response.confs[0].nom_service_bien) {
          existe = true;
          mensuel.confs.unshift(
            configuration.create(payload.response.confs[0])
          );
        }
      }
    });
    if (existe == false) {
      if (payload.type == 'client') {
        state.allConfMensuel.unshift(
          ConfigurationComptaRh.create(payload.response)
        );
      } else {
        let model = {
          name: payload.response.confs[0].nom_service_bien,
          confs: payload.response.confs
        };
        state.allConfMensuel.unshift(ConfigurationComptaRh.create(model));
      }
    }
  },
  SET_CONF_COMPTA_RH_LODING(state, payload) {
    state.confLoading = payload;
  },
  SET_ALL_CONF_COMPTA_RH(state, payload) {
    state.allConfMensuel = payload.map(item =>
      ConfigurationComptaRh.create(item)
    );
  },
  SET_ALL_CONF_COMPTA_RH_NEW_MODEL(state, payload) {
    state.allConfMensuel = payload.map(item =>
      ConfigurationComptaRhNewModel.create(item)
    );
  },
  SET_TOTAL_ROWS_CONF_COMPTA_RH(state, payload) {
    state.totalRowconf = payload;
  },
  DELETE_CONF_COMPTA_RH(state, payload) {
    state.allConfMensuel.map(mensuel => {
      if (payload.type == 'client') {
        if (mensuel.vendeur == payload.data.vendeur) {
          let index = mensuel.confs.findIndex(
            element => element.id == payload.data.id
          );
          mensuel.confs.splice(index, 1);
        }
      } else {
        if (mensuel.vendeur == payload.data.nom_service_bien) {
          let index = mensuel.confs.findIndex(
            element => element.id == payload.data.id
          );
          mensuel.confs.splice(index, 1);
        }
      }
    });
    state.allConfMensuel = state.allConfMensuel.filter(
      item => item.confs.length != 0
    );
  },
  UPDATE_CONFIG_COMPTA_RH(state, payload) {
    payload.configuration.update(payload.response);
  },
  SET_CONFIGURATION_FILTRE(state, payload) {
    state.filtre.vendeur = payload?.vendeur;
    state.filtre.acheteur = payload?.acheteur;
    state.filtre.period = payload?.period;
  },
  DELETE_VENDEUR_FROM_FILTRE(state, payload) {
    const index = state.filtre.vendeur.findIndex(
      element => element.value === payload.value
    );
    state.filtre.vendeur.splice(index, 1);
  },
  DELETE_ACHETEUR_FROM_FILTRE(state, payload) {
    const index = state.filtre.acheteur.findIndex(
      element => element.value === payload.value
    );
    state.filtre.acheteur.splice(index, 1);
  },
  DELETE_PERIOD_FROM_FILTRE(state, payload) {
    state.filtre.period = null;
  }
};
const actions = {
  async addNewconfComptaRh({ commit }, payload) {
    try {
      const response = await axios.post(
        `${domain}/api/mensuel/create_config`,
        payload.configurationToAdd,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('ADD_NEW_CONF_COMPTA_RH', {
        response: response?.data[0],
        type: payload.type
      });
      return { success: true };
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        return { success: false, error: data };
      } else if (error.response.data.error) {
        return {
          success: false,
          error: error.response.data.error
        };
      } else {
        return { success: false, error: 'Une erreur est survenue' };
      }
    }
  },
  async fetchAllComptaRh({ commit }, payload) {
    const params = {};
    if (payload?.page) {
      params.page = payload.page;
    }
    if (payload?.per_page) {
      params.per_page = payload.per_page;
    }
    if (payload?.column) {
      params.column = payload.column;
    }
    if (payload?.order) {
      params.order = payload.order;
    }
    if (payload?.famille) {
      params.famille = payload.famille;
    }
    if (payload?.grouped) {
      params.grouped = payload.grouped;
    }
    if (payload?.period) {
      params.periode = payload?.period?.value;
    }
    for (let i = 0; i < payload?.vendeur_ids?.length; i++) {
      params['vendeur_ids[' + i + ']'] = payload?.vendeur_ids[i]?.value;
    }
    for (let i = 0; i < payload?.acheteur_ids?.length; i++) {
      params['acheteur_ids[' + i + ']'] = payload?.acheteur_ids[i]?.value;
    }
    commit('SET_CONF_COMPTA_RH_ERROR', null);
    commit('SET_CONF_COMPTA_RH_LODING', true);
    try {
      const response = await axios.get(`${domain}/api/mensuel/all_configs`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
      });
      if (payload?.page != null) {
        commit('SET_ALL_CONF_COMPTA_RH', response.data.data);
        commit('SET_TOTAL_ROWS_CONF_COMPTA_RH', response.data.count);
      } else {
        commit('SET_ALL_CONF_COMPTA_RH', response.data);
      }
      commit('SET_CONF_COMPTA_RH_LODING', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_PRODUCTS_ERROR', error?.response?.data?.errors?.nom);
      } else {
        commit('SET_PRODUCTS_ERROR', 'Une erreur est survenue');
      }
      commit('SET_CONF_COMPTA_RH_LODING', false);
    }
  },
  async deleteConfComptaRh({ commit }, payload) {
    commit('SET_CONF_COMPTA_RH_ERROR', null);
    try {
      const response = await axios.delete(
        `${domain}/api/mensuel/delete_config/${payload.configToDelete.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DELETE_CONF_COMPTA_RH', {
        data: payload.configToDelete,
        type: payload.type
      });
      return { success: true };
    } catch (error) {
      if (error?.response?.data?.error) {
        return { success: false, error: error?.response?.data?.error };
      } else {
        return { success: false, error: 'Une erreur est survenue' };
      }
    }
  },
  async updateConfigurationComptaRh({ commit }, payload) {
    let params = payload.configurationToUpdate;
    // payload.filiale_id = payload.filiale_id.id;
    commit('SET_CONF_COMPTA_RH_LODING', true);
    try {
      const response = await axios.post(
        `${domain}/api/mensuel/update_config`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_CONFIG_COMPTA_RH', {
        response: response.data,
        configuration: payload.oldConfigurationToUpdate
      });
      commit('SET_CONF_COMPTA_RH_LODING', false);

      return { success: true };
    } catch (error) {
      if (error?.response?.data?.error) {
        return { success: false, error: error?.response?.data?.error };
      } else {
        return { success: false, error: 'Une erreur est survenue' };
      }
    }
  },
  async checkPeriodConfig({ commit }, payload) {
    commit('SET_CONFIG_NEW_ERROR_DUPLICATION', null);
    try {
      const response = await axios.post(
        `${domain}/api/mensuel/manage_errors_config`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { success: true, response: response.data.success };
    } catch (error) {
      return { success: false, error: error?.response?.data?.error };
    }
  },
  fillFlitre({ commit }, payload) {
    commit('SET_CONFIGURATION_FILTRE', payload);
  },
  removeVendeur({ commit }, payload) {
    commit('DELETE_VENDEUR_FROM_FILTRE', payload);
  },
  removeAcheteur({ commit }, payload) {
    commit('DELETE_ACHETEUR_FROM_FILTRE', payload);
  },
  removePeriod({ commit }, payload) {
    commit('DELETE_PERIOD_FROM_FILTRE', payload);
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
