import axios from 'axios';
import Role from '../../models/Role';
import domain from '@/environment';

export default {
  state: {
    roles: [],
    errorAddRole: [],
    loadingAdd: false,
    usersRole: []
  },
  getters: {
    roles: state => state.roles,
    errorAddRole: state => state.errorAddRole,
    loadingAdd: state => state.loadingAdd,
    Allusers: state => state.usersRole
  },
  mutations: {
    NEW_ROLES(state, payload) {
      state.roles.unshift(Role.create(payload));
    },
    ERROR_ADD_ROLES(state, payload) {
      state.errorAddRole = payload;
    },
    LOADING_ROLE(state, payload) {
      state.loadingAdd = payload;
    },
    SET_ALL_ROLES(state, payload) {
      state.roles = payload.map(item => Role.create(item));
    },
    ASSIGN_ROLE_PERMISSION(state, payload) {
      state.roles.map(role => {
        if (role.id == payload.role) {
          payload.assigned.map(permission => {
            role.permissions.push(permission.key);
          });
        }
      });
    },
    SET_USERS(state, payload) {
      state.usersRole = payload;
    },
    REMOVE_PERMISSION(state, payload) {
      state.roles.map(role => {
        if (role.id == payload.role) {
          payload.deleted.map(item => {
            let index = role.permissions.indexOf(item.key);
            role.permissions.splice(index, 1);
          });
        }
      });
    },
    ASSIGN_USER_PERMISSION(state, payload) {
      state.usersRole.map(user => {
        if (user.id == payload.user) {
          payload.assigned.map(permission => {
            user.permissions.push(permission.key);
          });
        }
      });
    },
    REMOVE_PERMISSIONS_USER(state, payload) {
      state.usersRole.map(user => {
        if (user.id == payload.user) {
          payload.deleted.map(item => {
            let index = user.permissions.indexOf(item.key);
            user.permissions.splice(index, 1);
          });
        }
      });
    },
    UPDATE_BLOCKED_ROLE(state, payload) {
      payload.role.update(payload.response);
    }
  },
  actions: {
    async addNewRole({ commit }, payload) {
      commit('LOADING_ROLE', true);
      try {
        const response = await axios.post(
          domain + '/api/create_profil',
          payload,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('NEW_ROLES', response.data);
        commit('ERROR_ADD_ROLES', null);
        commit('LOADING_ROLE', false);

        return true;
      } catch (error) {
        if (error && error.response && error.response.data) {
          commit('ERROR_ADD_ROLES', error.response.data.errors.name);
        } else {
          commit('ERROR_ADD_ROLES', 'Une erreur est survenue');
        }
        commit('LOADING_ROLE', false);
        return false;
      }
    },
    async getAllRoles({ commit }) {
      commit('LOADING_ROLE', true);
      try {
        const response = await axios.get(domain + '/api/profiles', {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        });
        commit('SET_ALL_ROLES', response.data);
        commit('LOADING_ROLE', false);
        return true;
      } catch (error) {
        commit('LOADING_ROLE', false);
        return { success: false, errors: 'Une erreur est survenue' };
      }
    },
    async getAllUsers({ commit }, payload) {
      const params = {
        deleted: 'no'
      };
      if (payload.search != null && payload.search != '') {
        params.search = payload.search;
      }
      commit('LOADING_ROLE', true);
      try {
        const response = await axios.get(domain + '/api/all_user', {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        });
        commit('SET_USERS', response.data.date);
        commit('LOADING_ROLE', false);

        return true;
      } catch (error) {
        commit('LOADING_ROLE', false);

        return false;
      }
    },
    async assignAutorisationToProfil({ commit }, payload) {
      try {
        const response = await axios.post(
          domain + '/api/assignAutorisationToProfil',
          payload.bodyFormData,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('ASSIGN_ROLE_PERMISSION', {
          assigned: payload.assigned,
          role: payload.role
        });
        return { success: true };
      } catch (error) {
        return false;
      }
    },
    async removeAutorisationToProfil({ commit }, payload) {
      try {
        const response = await axios.post(
          domain + '/api/removeAutorisationToProfil',
          payload.bodyFormData,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('REMOVE_PERMISSION', {
          deleted: payload.deleted,
          role: payload.role
        });
        return { success: true };
      } catch (error) {
        return { success: false };
      }
    },
    async assignAutorisationToUser({ commit }, payload) {
      try {
        await axios.post(
          domain + '/api/assignAutorisationToUser',
          payload.bodyFormData,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('ASSIGN_USER_PERMISSION', {
          assigned: payload.assigned,
          user: payload.user
        });
        return { success: true };
      } catch (error) {
        return { success: false };
      }
    },

    async removeAutorisationToUser({ commit }, payload) {
      try {
        await axios.post(
          domain + '/api/removeAutorisationToUser',
          payload.bodyFormData,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('REMOVE_PERMISSIONS_USER', {
          deleted: payload.deleted,
          user: payload.user
        });
        return { success: true };
      } catch (error) {
        return false;
      }
    },

    async updateBlocked({ commit }, payload) {
      try {
        const data = {
          profil: payload.profile,
          blocked: payload.blocked
        };
        const response = await axios.post(domain + '/api/updateBlocked', data, {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        });
        const params = {
          role: payload.role,
          response: response.data
        };
        commit('UPDATE_BLOCKED_ROLE', params);
        return { success: true };
      } catch (error) {
        return false;
      }
    }
  }
};
