import paramsMasterFilialeSocieteLines from './paramsMasterFilialeSocieteLines';

export default class paramsMasterFilialeSociete {
  constructor(data) {
    this.id = data.id;
    this.begin_date = data.begin_date;
    this.coef = data.coef;
    this.end_date = data.end_date;
    this.master_id = data.master_id;
    this.master_name = data.master_name;
    this.societe_id = data.societe_id;
    this.societe_name = data.societe_name;
    this.tva = data.tva;
    this.type_calcul = data.type_calcul;
    this.detailsShowing = false;
    this.loader_add = false;
    this.loader_delete = false;
    this.label = data.label;
    this.lines = data.lines.map(item =>
      paramsMasterFilialeSocieteLines.create(item)
    );
  }
  update(data) {
    this.begin_date = data.response.begin_date;
    this.coef = data.response.coef;
    this.label = data.response.label;
    this.end_date = data.response.end_date;
    this.tva = data.response.tva;
    this.type_calcul = data.response.type_calcul;
    if (data.payload.column == 'type_calcul') {
      this.lines.map(
        item => (item[data.payload.column] = this[data.payload.column])
      );
    }
  }
  updateSum(data) {
    this.coef = data.coef;
    this.tva = data.tva;
  }
  updatelignes(data) {
    let dataToSave = data.payload.row.lines.filter(
      (item, index) => !item.id && index != data.payload.indexc
    );
    this.lines = data.response.lines.map(item =>
      paramsMasterFilialeSocieteLines.create(item)
    );
    dataToSave.forEach(element => {
      this.lines.push(paramsMasterFilialeSocieteLines.create(element));
    });
  }
  static create(data) {
    return new paramsMasterFilialeSociete(data);
  }
}
