import axios from 'axios';
import domain from '@/environment';
import itemExport from '@/models/itemExport';
const state = {
  loadingListFichierExporteCdg: false,
  errorListFichierExporteCdg: null,
  listFichierExporteCdg: [],
  loadingdeleteFichierExporteCdg: false
};
const getters = {
  getLoadingListFichierExporteCdg: state => state.loadingListFichierExporteCdg,
  getErrorListFichierExporteCdg: state => state.errorListFichierExporteCdg,
  getListFichierExporteCdg: state => state.listFichierExporteCdg,
  getLoadingdeleteFichierExporteCdg: state =>
    state.loadingdeleteFichierExporteCdg
};
const mutations = {
  SET_LOADER_LIST_FICHIER_EXPORTE_CDG(state, payload) {
    state.loadingListFichierExporteCdg = payload;
  },
  SET_LIST_FICHIER_EXPORTE_CDG(state, payload) {
    state.listFichierExporteCdg = payload.map(item => itemExport.create(item));
  },
  ADD_FILE_FROM_LIST_FICHIER_EXPORTE_CDG(state, payload) {
    state.listFichierExporteCdg.push(itemExport.create(payload));
  },
  REMOVE_FILE_FROM_LIST_FICHIER_EXPORTE_CDG(state, payload) {
    state.listFichierExporteCdg = state.listFichierExporteCdg.filter(
      item => item.id != payload
    );
  },
  SET_LOADER_DELETE_FICHIER_EXPORTE_CDG(state, payload) {
    state.loadingdeleteFichierExporteCdg = payload;
  }
};
const actions = {
  async fecthListFichierExporteCdg({ commit }) {
    commit('SET_LOADER_LIST_FICHIER_EXPORTE_CDG', true);
    try {
      const response = await axios.get(domain + '/api/excel_files', {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });

      commit('SET_LIST_FICHIER_EXPORTE_CDG', response.data.data);
      commit('SET_LOADER_LIST_FICHIER_EXPORTE_CDG', false);
    } catch (error) {
      commit('SET_LOADER_LIST_FICHIER_EXPORTE_CDG', false);
    }
  },
  async deleteFileInListExporte({ commit }, payload) {
    try {
      const response = await axios.get(
        domain + '/api/delete_excel_file/' + payload.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('REMOVE_FILE_FROM_LIST_FICHIER_EXPORTE_CDG', payload.id);
      return true;
    } catch (error) {
      return false;
    }
  },
  async exportFileInListExporte({ commit }, payload) {
    commit('LOADING_MODEL_EXPORT', true);
    try {
      const response = await axios.get(
        domain + '/api/download_excel_file/' + payload.id,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', payload.file_name);
      document.body.appendChild(link);
      link.click();
      return true;
    } catch (error) {
      commit('LOADING_MODEL_EXPORT', false);
      return false;
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
