import paramsMasterFilialeSociete from './paramsMasterFilialeSociete';
import moment from 'moment';
export default class paramsMasterFiliale {
  constructor(data) {
    this.id = data.id;
    this.begin_date =
      data.begin_date != null
        ? moment(data.begin_date, 'YYYY-MM-DD ').format('DD-MM-YYYY')
        : data.begin_date;
    this.coef = data.coef;
    this.end_date =
      data.end_date != null
        ? moment(data.end_date, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.end_date;
    this.id_config = data.id_config;
    this.name = data.name;
    this.label = data.label;
    this.societes = data.societes.map(item =>
      paramsMasterFilialeSociete.create(item)
    );
    this.tva = data.tva;
    this.type_calcul = data.type_calcul;
  }
  update(data) {
    this.begin_date =
      data.response.begin_date != null
        ? moment(data.response.begin_date, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.response.begin_date;
    this.coef = data.response.coef;
    this.end_date =
      data.response.end_date != null
        ? moment(data.response.end_date, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.response.end_date;
    this.tva = data.response.tva;
    this.type_calcul = data.response.type_calcul;
    this.label = data.response.label;
    if (
      data.payload.column == 'begin_date' ||
      data.payload.column == 'end_date'
    ) {
      this.societes.map(
        item => (item[data.payload.column] = this[data.payload.column])
      );
    }
  }
  static create(data) {
    return new paramsMasterFiliale(data);
  }
}
